<ion-header class="system-utilities-header ion-no-border">

  <ion-toolbar>

    <ion-title>System Utils</ion-title>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content class="system-utilities-content">

  <ion-list>

    <!--  <ion-item lines="inset">
      <ion-label position="stacked">Clubs file</ion-label>
      <input type="file" (change)="onClubFileSelected($event)">
    </ion-item>

    <ion-item lines="inset">
      <ion-label position="stacked">Courses file</ion-label>
      <input type="file" (change)="onCourseFileSelected($event)">
    </ion-item>

    <ion-item lines="inset">
      <ion-label position="stacked">Tees file</ion-label>
      <input type="file" (change)="onTeeFileSelected($event)">
    </ion-item> -->

    <ion-item>
      <ion-label>
        <ion-button expand="block" fill="solid" shape="round" (click)="updateMemberData()">Zero Out Member Posts
          Attribute
        </ion-button>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        <ion-button expand="block" fill="solid" shape="round" (click)="fixClubZipCodes()">Fix club zipcodes
        </ion-button>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        <ion-button expand="block" fill="solid" shape="round" (click)="fixGroupEventData()">Update Event Group Data for
          Group Trip Implementation
        </ion-button>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        <ion-button expand="block" fill="solid" shape="round" (click)="fixEventData()">Update Event Data for
          Group Trip Implementation
        </ion-button>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        <ion-button expand="block" fill="solid" shape="round" (click)="updateClubDataForAlgolia()">Update Club Data for
          Algolia Search
        </ion-button>
      </ion-label>
    </ion-item>

  </ion-list>

</ion-content>