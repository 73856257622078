<ion-header class="contact-search-header ion-no-border">

  <ion-toolbar>

    <ion-title>Contacts</ion-title>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content class="contact-search-content">

  <ion-searchbar (ionChange)="searchCriteriaChange($event)" (ionClear)="searchCriteriaClear($event)"
    show-cancel-button="focus" inputmode="search"></ion-searchbar>

  <ion-virtual-scroll [items]="filteredContacts" approxItemHeight="74" class="itemVirtualLines">

    <ion-item *virtualItem="let contact">

      <ion-avatar slot="start">
        <ion-img [src]="contact.photo"></ion-img>
      </ion-avatar>

      <ion-label>
        <h2>{{ contact.givenName }} {{ contact.familyName }}</h2>
        <h4>{{ contact.email }}</h4>
      </ion-label>

      <ion-button class="listAddButton" fill="solid" shape="round" (click)="addContact(contact)" slot="end"
        *ngIf="!contactSelected(contact.email)">Add</ion-button>
      <ion-icon name="checkmark-circle" *ngIf="contactSelected(contact.email)"
        style="font-size: 25px; color: var(--ion-color-yes)" slot="end"></ion-icon>

    </ion-item>

  </ion-virtual-scroll>

  <ion-modal #modal class="selectedContacts" [initialBreakpoint]="0.25" [breakpoints]="[0.1, 0.25, 0.5, 0.75]"
    [isOpen]="showSelectedContactModal" [backdropDismiss]="false" [backdropBreakpoint]="0.5">
    <ng-template>
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <ion-title>Selected Contacts</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list class="itemLines">

          <ion-item *ngFor="let contact of selectedContacts">

            <ion-avatar slot="start">
              <ion-img [src]="contact.photo"></ion-img>
            </ion-avatar>

            <ion-label>
              <h2>{{ contact.givenName }} {{ contact.familyName }}</h2>
              <h4>{{ contact.email }}</h4>
            </ion-label>

            <ion-button class="listRemoveButton" fill="solid" shape="round" (click)="removeSelectedContact(contact);"
              slot="end">Remove
            </ion-button>

          </ion-item>

        </ion-list>
      </ion-content>
    </ng-template>
  </ion-modal>

</ion-content>