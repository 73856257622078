<ion-header class="course-hole-header ion-no-border">

  <ion-item lines="none">

    <div slot="start" class="numberCircle">{{number}}</div>

    <div slot="end" style="height: 100%; padding-top: 5px; margin-right: 0px; margin-left: 0px;" (click)="done();">
      <ion-icon name="close-circle" style="color: #cbcbcb; font-size: 30px;">
      </ion-icon>
    </div>

  </ion-item>

</ion-header>

<modal-content class="course-hole-content">

  <ion-list class="itemLines">

    <!-- par -->
    <ion-item>

      <ion-label>
        
        <ion-text>Par</ion-text>

        <ion-grid class="counter ion-no-padding">
          <ion-row class="ion-no-padding">
            <ion-col class="ion-text-center ion-no-padding">
              <ion-button shape="round" color="secondary" [disabled]="hole.par===1" (click)="decrementCounter('par')">
                <ion-icon slot="icon-only" name="remove"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col class="ion-text-center ion-no-padding">
              <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                {{hole.par}}</ion-label>
            </ion-col>
            <ion-col class="ion-text-center ion-no-padding">
              <ion-button shape="round" color="secondary" [disabled]="hole.par===7" (click)="incrementCounter('par')">
                <ion-icon slot="icon-only" name="add"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-label>

    </ion-item>

    <!-- distance -->
    <ion-item lines="inset">
      <ion-label position="stacked">Distance (yards)</ion-label>
      <ion-input [(ngModel)]="hole.distance" type="number"></ion-input>
    </ion-item>

    <!-- handicap -->
    <ion-item>

      <ion-label>
        <ion-text>Hdcp</ion-text>

        <ion-grid class="counter ion-no-padding">
          <ion-row class="ion-no-padding">
            <ion-col class="ion-text-center ion-no-padding">
              <ion-button shape="round" color="secondary" [disabled]="hole.hdcp===1" (click)="decrementCounter('hdcp')">
                <ion-icon slot="icon-only" name="remove"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col class="ion-text-center ion-no-padding">
              <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                {{hole.hdcp}}</ion-label>
            </ion-col>
            <ion-col class="ion-text-center ion-no-padding">
              <ion-button shape="round" color="secondary" [disabled]="hole.hdcp===18"
                (click)="incrementCounter('hdcp')">
                <ion-icon slot="icon-only" name="add"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-label>

    </ion-item>

  </ion-list>

</modal-content>

<ion-footer class="course-hole-footer ion-no-border">

  <ion-grid class="ion-no-padding ion-nowrap" class="buttonBar">

    <ion-row class="ion-nowrap" >

      <ion-col class="ion-text-center">

        <ion-button size="small" fill="clear" (click)="previous()" [disabled]="firstHole()" mode="ios">
          <ion-icon name="chevron-back"></ion-icon>
          Previous
        </ion-button>

      </ion-col>

      <ion-col class="ion-text-center">

        <ion-button size="small" fill="clear" (click)="next()" [disabled]="lastHole()" mode="ios">
          Next
          <ion-icon name="chevron-forward"></ion-icon>
        </ion-button>

      </ion-col>

    </ion-row>

  </ion-grid>

</ion-footer>