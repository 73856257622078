<ion-header class="rule-helper-header ion-no-border">
  <ion-toolbar>
    <ion-title>AI Rules Advisor</ion-title>
    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="rule-helper-content" fullscreen="false">

  <div class="wave-container">
    <div class="wave wave1"></div>
    <div class="wave wave2"></div>
    <div class="wave wave3"></div>
  </div>

  <!-- create a scrollable container for the chat messages -->
  <ion-content #content class="scrollContent">
    <ion-list class="list" lines="none">
      <ion-item *ngFor="let msg of messages">
        <chat-bubble [chatMessage]="msg"></chat-bubble>
      </ion-item>
    </ion-list>
  </ion-content>

</ion-content>

<ion-footer class="rule-helper-footer chatPageFooter ion-no-border ion-no-padding">
  <form [formGroup]="promptForm">
    <ion-toolbar class="ion-no-padding">
      <ion-item lines="none">
        <ion-textarea formControlName="question" rows="1" autoGrow="true" placeholder="Ask a question"
          [maxlength]="maxPromptLength"
          style="border: 1px var(--ion-color-black-d) solid; border-radius: 16px; padding-left: 5px;"></ion-textarea>
      </ion-item>
      <ion-buttons slot="end">
        <ion-button slot="icon-only" (click)="sendQuestion()">
          <ion-icon name="send-outline" size="large" color="primary"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-item lines="none" class="validation"
        *ngIf="promptForm.controls.question.hasError('required') && !promptForm.controls.question.valid && promptForm.controls.question.touched">
        <ion-label>Please ask a question.</ion-label>
      </ion-item>
    </ion-toolbar>
  </form>
</ion-footer>