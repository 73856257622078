import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppFunction } from '../../app.function';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'email',
  templateUrl: './email.page.html',
  styleUrls: ['./email.page.scss'],
})
export class EmailPage implements OnInit {

  emailForm: FormGroup;
  //subject: string;
  //body: string;

  constructor(
    public builder: FormBuilder,
    public appFunction: AppFunction,
    public navParams: NavParams) {

  }

  ngOnInit() {

    //build form group
    this.emailForm = this.builder.group({
      subject: ['', Validators.required],
      message: ['']
    });

  }

  addAttachment() {

  }

  done() {

    //console.log('post-new.ts done');

    try {

      //save the form if dirty
      if (this.emailForm.dirty) {

        //console.log('post-new.ts done dirty');

        //save the form if valid 
        if (this.emailForm.valid) {

          //console.log('post-new.ts done valid');

          this.appFunction
            .loadingCtrl
            .create({ message: 'Sending...' })
            .then((loading) => {

              loading.present();

              //send email...
              loading.dismiss();
              this.appFunction.modalCtrl.dismiss({ action: 'send', subject: this.emailForm.controls.subject.value, message: this.emailForm.controls.message.value });

            });

        } else {
          //show any untouched errors
          this.appFunction.setDirtyControlAsTouched(this.emailForm);
        }

      } else {
        this.appFunction.modalCtrl.dismiss({ action: 'cancel' });
      }

    } catch (err) {
      console.log('email-page.ts modal present error', err);
    }

  }

  cancel() {

    //console.log('email.page.ts cancel');

    //confirm that user wants to discard changes
    if (this.emailForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your email. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {

              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.appFunction.modalCtrl.dismiss({ action: 'cancel' });
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss({ action: 'cancel' });
    }

  }

}
