<ion-header class="maintenance-header ion-no-border">

  <ion-toolbar>

  </ion-toolbar>

</ion-header>

<ion-content class="maintenance-content">

  <ion-grid fixed>

    <img class="auth-image" src="../../../assets/images/logowhitebackground.png" draggable="false">

    <h3 class="auth-title">Double Ace GolfMaintenance</h3>

    <h6 class="auth-instructions">Double Ace Golf is current undergoing maintenance. Sorry for this inconvenience and
      please check back shortly.</h6>

    <div [innerHTML]="message" *ngIf="message" style="margin-bottom: 5px;"></div>

  </ion-grid>

</ion-content>