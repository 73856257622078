<ion-grid style="padding: 2px 0px;">

  <ion-row class="ion-align-items-center">

    <ion-col size="auto" class="ion-no-padding">
      <div [class]="cirleClass" style="float: left; margin-right: 5px;">
        <div class="leftCircle" [ngStyle]="{'background-color': tee.firstTeeColor ? tee.firstTeeColor.value : 'initial'}">
        </div>
        <div class="rightCircle"
          [ngStyle]="{'background-color': tee.secondTeeColor ? tee.secondTeeColor.value : (tee.firstTeeColor ? tee.firstTeeColor.value : 'initial')}">
        </div>
      </div>
    </ion-col>

    <ion-col class="ion-no-padding">
      <div>
        {{tee.name}}
        <!-- if course is an eighteen hole course but the event is only for nine holes then show which nine -->
        <div style="font-size: 14px; color: var(--ion-color-black-c);">
          <ng-container *ngIf="appFunction.isNumeric(nineHolesOnlyIndex)">
            {{tee.nines[nineHolesOnlyIndex]?.name}},</ng-container>
          {{teeDistance}} yards, {{teeSlope}}/{{teeRating}}
        </div>
      </div>
    </ion-col>

  </ion-row>

</ion-grid>