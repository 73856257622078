import { Injectable } from '@angular/core';
import { AppConfig } from './app.config'
import { AppFunction, IonColor, SortByOrder } from './app.function'
import firebase from 'firebase/compat/app';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GeoFirestore, GeoCollectionReference, GeoQuery } from 'geofirestore';
import { Observable, Subscriber } from 'rxjs';
import algoliasearch from 'algoliasearch/lite';
import { environment } from '../environments/environment';

export interface AppCourseHoleI {
    number: number;
    distance: number;
    hdcp: number;
    par: number;
}

export interface AppCourseNineI {
    name: string,
    holes: AppCourseHoleI[],
    par: number,
    slope: number,
    rating: number
}

interface AppTeeI {
    courseId: string;
    teeId: string;
    name: string; //tee name
    distance: number; //total tee distance
    par: number; //total tee par
    rating: number;
    slope: number;
    nines: AppCourseNineI[];
    firstTeeColor: IonColor;
    secondTeeColor: IonColor;
    updatedDt: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
}

export class AppTee implements AppTeeI {

    id: string;
    teeId: string;
    courseId: string;
    name: string;
    distance: number;
    par: number;
    rating: number;
    slope: number;
    nines: AppCourseNineI[] = [];
    firstTeeColor: IonColor = null;
    secondTeeColor: IonColor = null;
    updatedDt: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
    exists: boolean = false;
    private _teeDoc: firebase.firestore.DocumentSnapshot;
    private _appFunction: AppFunction;

    constructor() {

        //get services
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.club.ts AppTee shutdown', this.id);
            });

    }

    initialize(teeDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Promise<AppTee> {

        return new Promise<AppTee>((resolve) => {

            if (teeDoc) {

                //console.log('app.club.ts AppTee constructor');

                //save document refenence
                this._teeDoc = teeDoc;
                this.id = teeDoc.id;

                //listen for tee updates
                const teeDocSnapShotUnsubscribe = teeDoc
                    .ref
                    .onSnapshot((teeUpdate) => {
                        this.update(teeUpdate);
                        resolve(this);
                    });

                this._appFunction.registerUnsubscribe(teeDocSnapShotUnsubscribe);

            } else {

                //create new tee doc ref
                const teeDoc: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.Tees).doc();

                //now get it, it should be empty as this is a new tee object
                teeDoc
                    .get()
                    .then((newTeeDoc) => {

                        //save doc, this will be used when saving
                        this._teeDoc = newTeeDoc;
                        this.id = teeDoc.id;
                        resolve(this);

                    });

            }

        });

    }

    private update(updatedTee: firebase.firestore.DocumentSnapshot) {

        try {

            //console.log('app.club.ts AppTee update', updatedTee);

            if (updatedTee.exists) {

                //update high level attributes
                this.teeId = updatedTee.data().teeId;
                this.courseId = updatedTee.data().courseId;
                this.name = updatedTee.data().name;
                this.distance = updatedTee.data().distance;
                this.par = updatedTee.data().par;
                this.rating = updatedTee.data().rating;
                this.slope = updatedTee.data().slope;
                this.updatedDt = updatedTee.data().updatedDt || undefined;
                this.createdDt = updatedTee.data().createdDt || undefined;
                this.nines = updatedTee.data().nines;
                this.firstTeeColor = updatedTee.data().firstTeeColor || null;
                this.secondTeeColor = updatedTee.data().secondTeeColor || null;
                this.exists = true;

                //warning message
                if (this.slope === null || this.rating === null) {
                    //console.error('app.club.ts AppTee get teeHandicap. Tee Name: ', this.name, 'TeeId: ' + this.id, 'missing slope or rating.')
                }

            } else {
                this.exists = false;
            }

        } catch (err) {
            console.log('app.club.ts AppTee update error', JSON.stringify(err));
        }

    }

    save(): Promise<AppTee> {

        //console.log('app.club.ts AppTee save');

        return new Promise<AppTee>((resolve, reject) => {

            try {

                this._teeDoc
                    .ref
                    .set(this.data(), { merge: true })
                    .then(() => {
                        //console.log('app.club.ts AppTee save set success');
                        resolve(this);
                    })
                    .catch((err) => {
                        console.log('app.club.ts AppTee save set error', JSON.stringify(err));
                        reject(err);
                    });

            }
            catch (err) {
                console.log('app.club.ts AppTee batch set error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    delete(): Promise<void> {

        //console.log('app.club.ts AppTee delete');

        return new Promise<void>((resolve, reject) => {

            this._teeDoc
                .ref
                .delete()
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    console.log('app.club.ts AppTee delete error', JSON.stringify(err));
                    reject(err);
                });

        });

    }

    private data(): AppTeeI {

        try {

            var coursePar: number = 0;

            /* is this even needed */
            this.nines
                .forEach((nine) => {

                    var ninePar: number = 0;

                    //add up par
                    nine.holes
                        .forEach((hole) => {
                            ninePar = ninePar + hole.par;
                        });

                    //set par for the nine holes
                    nine.par = ninePar;

                    //set course par 
                    coursePar = coursePar + ninePar;

                });

            return {
                teeId: this.teeId,
                courseId: this.courseId,
                name: this.name,
                distance: this.distance,
                par: coursePar,
                rating: this.rating,
                slope: this.slope,
                nines: this.nines,
                firstTeeColor: this.firstTeeColor,
                secondTeeColor: this.secondTeeColor,
                createdDt: this.createdDt || firebase.firestore.Timestamp.fromDate(new Date()),
                updatedDt: firebase.firestore.Timestamp.fromDate(new Date())
            };

        }
        catch (err) {
            console.log('app.club.ts AppTee data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

interface AppCourseI {
    courseId: string;
    clubId: string;
    name: string;
    designer: string;
    year: string;
    holes: number;
    createdDt: firebase.firestore.Timestamp;
    updatedDt: firebase.firestore.Timestamp;
}

export class AppCourse implements AppCourseI {

    id: string; //doc id
    clubId: string;
    courseId: string;
    name: string;
    designer: string;
    year: string;
    exists: boolean;
    holes: number;
    createdDt: firebase.firestore.Timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    updatedDt: firebase.firestore.Timestamp;
    private _tees: AppTee[];
    private _appFunction: AppFunction;
    private _courseDoc: firebase.firestore.DocumentSnapshot;

    constructor() {

        //get services
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.club.ts AppCourse shutdown event', this.id);
            });

    }

    initialize(courseDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Promise<AppCourse> {

        return new Promise<AppCourse>((resolve) => {

            try {

                if (courseDoc) {

                    //console.log('app.club.ts AppCourse initialize');

                    //save document refenence
                    this._courseDoc = courseDoc;
                    this.id = courseDoc.id;

                    //listen for course tee updates
                    const courseDocSnapShotUnsubscribe = courseDoc
                        .ref
                        .onSnapshot((courseUpdate) => {

                            this.update(courseUpdate);

                            //get course tees
                            this.getTees()
                                .then(() => {
                                    resolve(this);
                                });

                        });

                    this._appFunction.registerUnsubscribe(courseDocSnapShotUnsubscribe);

                } else {

                    //create new course doc ref
                    const courseDoc: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.Courses).doc();

                    //now get it, it should be empty as this is a new group object
                    courseDoc
                        .get()
                        .then((newCourseDoc) => {

                            //save doc, this will be used when saving
                            this._courseDoc = newCourseDoc;
                            this.id = courseDoc.id;

                            //get course tees
                            this.getTees()
                                .then(() => {
                                    resolve(this);
                                });

                        });

                }

            } catch (err) {
                console.log('app.club.ts AppCourse initialize', err);
            }

        });

    }

    private update(updatedCourse: firebase.firestore.DocumentSnapshot) {

        try {

            //console.log('app.club.ts AppCourse update', updatedCourse.data().name);

            if (updatedCourse.exists) {

                //console.log('app.club.ts AppCourse update');

                //update high level attributes
                this.courseId = updatedCourse.data().courseId;
                this.clubId = updatedCourse.data().clubId;
                this.name = updatedCourse.data().name;
                this.designer = updatedCourse.data().designer;
                this.year = updatedCourse.data().year;
                this.holes = updatedCourse.data().holes;
                this.exists = true;

            } else {
                this.exists = false;
            }

        } catch (err) {
            console.log('app.club.ts AppCourse update error', JSON.stringify(err));
        }

    }

    save(): Promise<AppCourse> {

        return new Promise<AppCourse>((resolve, reject) => {

            try {

                //create batch transaction
                const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();
                batch.set(this._courseDoc.ref, this.data(), { merge: true });

                batch
                    .commit()
                    .then(() => {
                        console.log('app.club.ts AppCourse save set success');
                        resolve(this);
                    })
                    .catch((err) => {
                        console.log('app.club.ts AppCourse save set error', err);
                        reject(err);
                    });

            }
            catch (err) {
                console.log('app.club.ts AppCourse save set error', err, JSON.stringify(err));
                reject(err);
            }

        });

    }

    get tees(): AppTee[] {

        //return only "active" tees
        return this._tees?.filter((tee) => {
            return tee.exists;
        }) || [];

    }

    moveTees(from, to) {

        //move dragged item (TODO: i think this will cause an issue if there are any "exists === false" in the _tees array)
        //console.log('app.club.ts AppClub moveTees', from, to);
        const itemMove: AppTee = this._tees.splice(from, 1)[0];
        this._tees.splice(to, 0, itemMove);

    }

    getTee(teeId: string): AppTee {

        return this.tees.find((tee) => {
            return tee.teeId === teeId;
        });

    }

    private getTees(): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //only retrieve once
                if (!Array.isArray(this._tees)) {

                    //initialize the array
                    this._tees = [];

                    const promiseArray: any[] = [];

                    //get the tees
                    const teeUnsubscribe = this._appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Tees)
                        .where('courseId', '==', this.courseId)
                        .orderBy('distance', 'desc')
                        .onSnapshot((foundTees) => {

                            //for each tee found...
                            foundTees
                                .docChanges()
                                .forEach((foundTee) => {

                                    if (foundTee.type === AppConfig.UPDATE_TYPE.Added) {

                                        //create and initialize
                                        const tee: AppTee = new AppTee();
                                        const p = tee.initialize(foundTee.doc)
                                            .then(() => {
                                                this._tees.push(tee);
                                            });

                                        promiseArray.push(p);

                                    }

                                }, reject);

                            Promise.all(promiseArray)
                                .then(() => {
                                    //sort the tees before returning
                                    this._tees.sortBy('distance', SortByOrder.DESC);
                                    resolve();
                                });

                        });

                    this._appFunction.registerUnsubscribe(teeUnsubscribe);

                } else {
                    resolve();
                }

            } catch (err) {
                console.log('app.club.ts AppCourse getTees', err, JSON.stringify(err));
            }

        });

    };

    private data(): AppCourseI {

        try {

            return {
                clubId: this.clubId,
                courseId: this.courseId,
                name: this.name,
                designer: this.designer,
                year: this.year,
                holes: this.holes,
                updatedDt: firebase.firestore.Timestamp.fromDate(new Date()),
                createdDt: this.createdDt
            };

        }
        catch (err) {
            console.log('app.club.ts AppCourse data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

export interface AppClubSearchI {
    clubId: string; //surragate id
    name: string;
    fullAddress: string;
    distance: number;
}

interface AppClubI {
    clubId: string; //surragate id
    searchName: string;
    name: string;
    latitude: number;
    longitude: number;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    phone: string;
    g: any;
    coordinates: firebase.firestore.GeoPoint;
}

export class AppClub implements AppClubI {

    id: string; //doc id
    clubId: string; //id from club data provider
    name: string;
    searchName: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    latitude: number;
    longitude: number;
    phone: string;
    exists: boolean = false;
    coordinates: firebase.firestore.GeoPoint;
    distance: number;
    g: any;
    private _courses: AppCourse[];
    private _appFunction: AppFunction;
    private _appClubService: ClubService;
    private _clubDoc: firebase.firestore.DocumentSnapshot;

    constructor() {

        //get services
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);
        this._appClubService = AppFunction.serviceLocator.get(ClubService);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.club.ts AppClub shutdown event', this.id);
            });

    }

    initialize(clubDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Promise<AppClub> {

        return new Promise<AppClub>((resolve) => {

            try {

                if (clubDoc) {

                    //save document ref
                    this._clubDoc = clubDoc;
                    this.id = clubDoc.id;

                    //listen for course updates
                    const clubDocSnapShotUnsubscribe = clubDoc
                        .ref
                        .onSnapshot((clubUpdate) => {

                            this.update(clubUpdate);

                            //get courses
                            this.getCourses()
                                .then(() => {
                                    resolve(this);
                                });

                        });

                    this._appFunction.registerUnsubscribe(clubDocSnapShotUnsubscribe);

                } else {

                    //create new course doc ref
                    const clubDoc: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.Clubs).doc();

                    //now get it, it should be empty as this is a new group object
                    clubDoc
                        .get()
                        .then((newClubDoc) => {

                            //save doc, this will be used when saving
                            this._clubDoc = newClubDoc;
                            this.id = clubDoc.id;

                            //get course
                            this.getCourses()
                                .then(() => {
                                    resolve(this);
                                });

                        });

                }

            } catch (err) {
                console.log('app.club.ts AppClub initialize', err);
            }

        });

    }

    private update(updatedClub: firebase.firestore.DocumentSnapshot) {

        try {

            if (updatedClub.exists) {

                //update high level attributes
                this.clubId = updatedClub.data().clubId;
                this.name = updatedClub.data().name;
                this.searchName = updatedClub.data().searchName;
                this.address = updatedClub.data().address;
                this.city = updatedClub.data().city;
                this.phone = updatedClub.data().phone;
                this.state = updatedClub.data().state;
                this.zipcode = updatedClub.data().zipcode;
                this.latitude = updatedClub.data().latitude;
                this.longitude = updatedClub.data().longitude;
                this.coordinates = updatedClub.data().coordinates;
                this.g = updatedClub.data().g;
                this.exists = true;

            } else {
                this.exists = false;
            }

        } catch (err) {
            console.log('app.club.ts AppClub update error', JSON.stringify(err));
        }

    }

    save(): Promise<AppClub> {

        return new Promise<AppClub>((resolve, reject) => {

            try {

                //create batch transaction
                const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();
                batch.set(this._clubDoc.ref, this.data(), { merge: true });

                batch
                    .commit()
                    .then(() => {
                        console.log('app.club.ts AppClub save set success');
                        resolve(this);
                    })
                    .catch((err) => {
                        console.log('app.club.ts AppClub save set error', err);
                        reject(err);
                    });

            }
            catch (err) {
                console.log('app.club.ts AppClub save set error', err, JSON.stringify(err));
                reject(err);
            }

        });

    }

    fullAddress(): string {
        return this.address + ' ' + this.city + ', ' + this.state + ' ' + this.zipcode;
    }

    get courses(): AppCourse[] {
        return this._courses;
    }

    getCourse(courseId: string): AppCourse {

        return this._courses.find((course) => {
            return course.courseId === courseId;
        });

    }

    private getCourses(): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //only retrieve once
                if (!Array.isArray(this._courses)) {

                    //initialize the array
                    this._courses = [];

                    const promiseArray: any[] = [];

                    //get the tees
                    const courseUnsubscribe = this._appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Courses)
                        .where('clubId', '==', this.clubId)
                        .onSnapshot((foundCourses) => {

                            //console.log('app.club.ts getCourse onSnapshot success');

                            //for each tee found...
                            foundCourses
                                .docChanges()
                                .forEach((foundCourse) => {

                                    if (foundCourse.type === AppConfig.UPDATE_TYPE.Added) {

                                        //create and initialize
                                        const course: AppCourse = new AppCourse();
                                        const p = course.initialize(foundCourse.doc)
                                            .then(() => {
                                                this._courses.push(course);
                                            });

                                        promiseArray.push(p);

                                    }

                                }, reject);

                            //console.log('app.club.ts getCourse onSnapshot success', this);

                            Promise.all(promiseArray)
                                .then(() => {
                                    resolve();
                                });

                        });

                    this._appFunction.registerUnsubscribe(courseUnsubscribe);

                } else {
                    resolve();
                }

            } catch (err) {
                console.log('app.club.ts AppClub getCourses', err, JSON.stringify(err));
            }

        });

    };

    private data(): AppClubI {

        try {

            return {
                clubId: this.clubId,
                address: this.address,
                city: this.city,
                state: this.state,
                zipcode: this.zipcode,
                phone: this.phone,
                name: this.name,
                searchName: this.name.trim().toUpperCase(),
                latitude: this.latitude,
                longitude: this.longitude,
                coordinates: this.coordinates,
                g: {
                    geohash: this._appClubService.geohash(this.latitude, this.longitude, 10),
                    geopoint: new firebase.firestore.GeoPoint(Number(this.latitude), Number(this.longitude))
                }
            };

        }
        catch (err) {
            console.log('app.club.ts AppClub data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

@Injectable({
    providedIn: 'root'
})
export class ClubService {

    private clubCache: AppClub[] = [];
    private clubCacheRequest: {
        clubId: string,
        obseverer: Subscriber<AppClub>,
        processed: boolean
    }[] = [];
    private client = algoliasearch(environment.ALGOLIA_CONFIG.appId, environment.ALGOLIA_CONFIG.searchAPIKey);
    private index = this.client.initIndex(environment.ALGOLIA_CONFIG.clubIndex);

    constructor(
        public appFunction: AppFunction,
        public geolocation: Geolocation) {

        //clean up
        this.appFunction
            .shutDown
            .subscribe(() => {

                console.log('app.club.ts ClubService shutdown');

                //clear course cache
                this.clubCache = [];
                this.clubCacheRequest = []

            });

    }

    searchClub(searchCriteria: string): Promise<AppClubSearchI[]> {

        return new Promise<AppClubSearchI[]>((resolve) => {

            this.index
                .search(searchCriteria)
                .then((foundClubs) => {

                    const clubs: AppClubSearchI[] = [];

                    if (foundClubs.hits.length > 0) {

                        foundClubs
                            .hits
                            .forEach((foundClub: any) => {

                                clubs.push(<AppClubSearchI>{
                                    clubId: foundClub.clubId,
                                    name: foundClub.name,
                                    fullAddress: foundClub.address + ' ' + foundClub.city + ', ' + foundClub.state + ' ' + foundClub.zipcode,
                                    distance: undefined
                                });

                            });

                        resolve(clubs);

                    } else {
                        resolve(clubs);
                    }

                });

        });

    }

    searchClubGeo(): Promise<AppClubSearchI[]> {

        //console.log('app.club.ts searchCourseGeo');

        return new Promise<AppClubSearchI[]>((resolve, reject) => {

            //get current location
            this.geolocation
                .getCurrentPosition()
                .then((position) => {

                    //console.log('app.club.ts searchClubGeo getCurrentPosition', position.coords.latitude, position.coords.longitude, JSON.stringify(position));

                    const clubsGeo: AppClubSearchI[] = [];

                    //create a GeoFirestore index
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment 
                    // @ts-ignore
                    const geoFirestore: GeoFirestore = new GeoFirestore(firebase.firestore());

                    // Create a GeoCollection reference
                    const geoCollection: GeoCollectionReference = geoFirestore.collection(AppConfig.COLLECTION.Clubs);

                    const geoQuery: GeoQuery = geoCollection.near({
                        center: new firebase.firestore.GeoPoint(position.coords.latitude, position.coords.longitude),
                        radius: 20
                    });

                    /* now get the clubs */
                    geoQuery
                        .get()
                        .then((value) => {

                            //console.log('app.club.ts searchCourseGeo geoQuery get', value.docs.length);

                            //save the array of promises
                            const promiseArray: any[] = [];

                            value
                                .docs
                                .forEach((doc) => {

                                    //console.log('app.club.ts searchCourseGeo forEach', doc.id);

                                    const t = new Promise<void>((resolve) => {

                                        //GeoFireStore result does return DocumentSnapshot/QueryDocumentSnapshot that has a firesote "ref"
                                        //so requery the document. this is sort of ineffiecent in the sense of extra firestore reads 
                                        this.appFunction
                                            .firestore
                                            .collection(AppConfig.COLLECTION.Clubs)
                                            .doc(doc.id)
                                            .get({ source: 'server' })
                                            .then((foundClub) => {

                                                clubsGeo.push(<AppClubSearchI>{
                                                    clubId: foundClub.data().clubId,
                                                    name: foundClub.data().name,
                                                    fullAddress: foundClub.data().address + ' ' + foundClub.data().city + ', ' + foundClub.data().state + ' ' + foundClub.data().zipcode,
                                                    distance: doc.distance
                                                });

                                                resolve();

                                            });

                                    });

                                    promiseArray.push(t);

                                });

                            Promise
                                .all(promiseArray)
                                .then(() => {

                                    //console.log('app.club.ts searchCourseGeo promise done');

                                    //sort by distance
                                    clubsGeo.sortBy('distance', SortByOrder.ASC);

                                    //return
                                    resolve(clubsGeo);

                                })
                                .catch((err) => {
                                    console.log('app.club.ts searchClubGeo geoQuery get error', JSON.stringify(err));
                                    reject(err);
                                });

                        });

                })
                .catch((err) => {
                    console.log('app.club.ts searchClubGeo getCurrentPosition err', err);
                    reject([]);
                });

        });

    }

    getClub(clubId: string): Observable<AppClub> {

        return new Observable((observer) => {

            try {

                //look for cached club
                const index: number = this.clubCache.findIndex((club) => {
                    return club.clubId === clubId;
                });

                //if found then return
                if (index > -1) {
                    //console.log('app.club.ts getCourse getting from cache', clubId);
                    observer.next(this.clubCache[index]);
                    observer.complete();
                } else { //get from database

                    //store request
                    this.clubCacheRequest.push({ clubId: clubId, obseverer: observer, processed: false });

                    //look for cached request, should find 1 (from about statement)
                    const existingRequest = this.clubCacheRequest.filter((request) => {
                        return request.clubId === clubId;
                    });

                    //if course hasn't been requested (should be at least one) then go get it...
                    if (existingRequest.length === 1) {

                        this.appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Clubs)
                            .where('clubId', '==', clubId)
                            .get()
                            .then((foundClub) => {

                                //if member foun,...
                                if (foundClub.size === 1) {

                                    try {

                                        //create and initialize 
                                        const club: AppClub = new AppClub();
                                        club
                                            .initialize(foundClub.docs[0])
                                            .then(() => {

                                                //...and then save to cache
                                                this.clubCache.push(club);

                                                //return course to those waiting
                                                this.clubCacheRequest
                                                    .forEach((request) => {

                                                        if (request.clubId === club.clubId && request.processed === false) {

                                                            //console.log('app.club.ts getCourse return response', request.clubId, appCourse.id, clubId);

                                                            //return club and close observable
                                                            request.obseverer.next(club);
                                                            request.obseverer.complete();

                                                            //mark as processed
                                                            request.processed = true;

                                                        }

                                                    });

                                            });

                                    } catch (err) {
                                        console.log('app.club.ts getCourse error processing club', clubId, err);
                                    }

                                } else if (foundClub.size === 0) {
                                    console.log('Error', 'app.club.ts', 'ClubService', 'getCourse', 'get club', 'app.club.ts getCourse course not found', { 'clubId': clubId });
                                    observer.error('app.club.ts getCourse club not found. clubId: ' + clubId);
                                    observer.complete();
                                } else if (foundClub.size > 1) {
                                    console.log('Error', 'app.club.ts', 'ClubService', 'getCourse', 'get club', 'app.club.ts getCourse more than 1 course found', { 'clubId': clubId });
                                    observer.error('app.club.ts getCourse more than 1 club found for clubId: ' + clubId);
                                    observer.complete();
                                }

                            })
                            .catch((err) => {
                                console.log('app.club.ts getClub error', err, JSON.stringify(err));
                            });

                    } else {
                        //noop
                        //console.log('app.club.ts getClub waiting response from subsciption', clubId);
                    }


                }
            } catch (err) {
                console.log('app.club.ts ClubService getClub', err, JSON.stringify(err));
            }

        });

    }

    geohash(lat: number, lon: number, precision: number): string {

        /* (Geohash-specific) Base32 map */
        const base32 = '0123456789bcdefghjkmnpqrstuvwxyz';

        let idx: number = 0; // index into base32 map
        let bit: number = 0; // each char holds 5 bits
        let evenBit: boolean = true;
        let geohash: string = '';

        let latMin: number = -90, latMax: number = 90;
        let lonMin: number = -180, lonMax: number = 180;

        while (geohash.length < precision) {

            if (evenBit) {

                // bisect E-W longitude
                var lonMid = (lonMin + lonMax) / 2;
                if (lon >= lonMid) {
                    idx = idx * 2 + 1;
                    lonMin = lonMid;
                } else {
                    idx = idx * 2;
                    lonMax = lonMid;
                }

            } else {

                // bisect N-S latitude
                var latMid = (latMin + latMax) / 2;
                if (lat >= latMid) {
                    idx = idx * 2 + 1;
                    latMin = latMid;
                } else {
                    idx = idx * 2;
                    latMax = latMid;
                }

            }
            evenBit = !evenBit;

            if (++bit == 5) {

                // 5 bits gives us a character: append it and start over
                geohash += base32.charAt(idx);
                bit = 0;
                idx = 0;

            }

        }

        return geohash;
    };

    validateClubData(club: AppClub) {

        let message: string = '';

        //for given club...
        club.courses
            .forEach((course) => {

                //...loop through courses
                course
                    .tees
                    .forEach((tee) => {

                        //confirm the tee info...
                        if (tee.rating === undefined || tee.slope === undefined || tee.par === undefined) {
                            message = message + '<div>' + course.name + ', ' + tee.name + '</div>';
                        }

                        //...then loop through nine info
                        tee
                            .nines
                            .forEach((nine) => {

                                //confirm the nine info...
                                if (nine.rating === undefined || nine.slope === undefined || nine.par === undefined) {
                                    message = message + '<div>' + course.name + ', ' + tee.name + ', ' + nine.name + '</div>';
                                }

                            })

                    });

            });

        if (message.length > 0) {

            const personalizations = [];
            personalizations.push({
                "templateId": AppConfig.SENDGRID_TEMPLATES.MissClubData,
                "to": {
                    "email": AppConfig.SUPPORT_EMAIL
                },
                "from": {
                    "email": AppConfig.SUPPORT_EMAIL
                },
                "replyTo": {
                    "email": AppConfig.SUPPORT_EMAIL
                },
                "dynamic_template_data": {
                    "subject": 'Missing Club information for ' + club.name,
                    "message": message,
                    "address": club.fullAddress(),
                },
                "hideWarnings": true
            });

            this.appFunction.sendEmail(personalizations);

        }


    }

}