<ion-header class="match-member-header ion-no-border">

  <ion-toolbar>

    <ion-title><span *ngIf="editMode===formEditMode.new">New&nbsp;</span>Match</ion-title>

    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" [screenKey]="screenKey">
        <ion-icon name="help-circle-outline"></ion-icon>
      </ion-button>
      <ion-button (click)="cancel()" *ngIf="matchForm?.dirty">
        Cancel
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button *ngIf="matchForm?.dirty" fill="solid" shape="round" (click)="done()">Save</ion-button>
      <ion-button *ngIf="!matchForm?.dirty" fill="solid" shape="round" color="light" (click)="cancel()">Close
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <!-- validation hero, don't show if match is deleted (!exisits) -->
  <validation-hero
    [display]="this.match?.exists && ((isFormSubmitted && matchForm.invalid ) || (match?.validationErrors.length > 0))"
    [messages]="match?.validationErrors">
  </validation-hero>

</ion-header>

<ion-content class="match-member-detail ion-no-padding">

  <!-- show players if match is a member match -->
  <ion-list *ngIf="match?.isMemberMatch" class="itemLines" style="width: 100%">

    <ion-list-header>
      <ion-label>Match Players</ion-label>
    </ion-list-header>

    <!-- allow update to four player to be invited to the match...the first player created the match -->
    <ion-item (click)="addEventPlayer();">

      <ion-avatar style="background-color: lightgrey;" slot="start">
        <ion-icon color="primary" name="person-add" style="height: 35px; width: 35px; padding: 10px;"></ion-icon>
      </ion-avatar>

      <ion-label class="ion-text-wrap">
        <h2>Add up to four Event players to a match</h2>
      </ion-label>

    </ion-item>

    <!-- list all players in the match -->
    <ion-item *ngFor="let player of match?.players; let i = index;">

      <avatar #avatar [member]="player.member" slot="start"></avatar>

      <ion-label (click)="avatar.displayProfileSummary($event)">

        <h2>{{player.firstName}} {{player.lastName}}</h2>
        <p>{{player.email}}</p>

      </ion-label>

      <ion-icon name="ellipsis-vertical" (click)="removeMatchPlayerConfirm(i)" slot="end"
        style="align-self: end; margin-right: 0px;" color="primary">
      </ion-icon>

    </ion-item>

  </ion-list>

  <!-- match -->
  <match-edit *ngIf="match" [match]="match" [parent]="parent" [matchOptions]="matchOptions"
    (matchChange)="matchChange($event);" (matchFormChange)="matchFormChange($event);" style="width: 100%;">
  </match-edit>

  <ion-fab #fab vertical="bottom" horizontal="end" slot="fixed" haptic *ngIf="editMode===formEditMode.update"
    [fabClose]="fab">
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-button shape="round" (click)="deleteMatchConfirmation(); fab.close();">
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label>Delete Match</ion-label>
      </ion-button>
    </ion-fab-list>
  </ion-fab>

</ion-content>