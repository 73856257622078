<ion-header class="group-trip-detail-header ion-no-border">

  <ion-toolbar>
    <ion-title><span *ngIf="editMode===formEditMode.new">New&nbsp;</span>Trip</ion-title>
    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" [screenKey]="screenKey">
        <ion-icon name="help-circle-outline"></ion-icon>
      </ion-button>
      <ion-button (click)="cancel()" *ngIf="groupForm.dirty">
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="primary">
      <ion-button *ngIf="groupForm.dirty" fill="solid" shape="round" (click)="done()" haptic>Save</ion-button>
      <ion-button *ngIf="!groupForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>

  <!-- validation hero -->
  <validation-hero [display]="isFormSubmitted && groupForm.invalid"></validation-hero>

</ion-header>

<ion-content #content class="group-trip-detail-content">

  <!-- segment -->
  <div class="ion-padding">

    <ion-segment [(ngModel)]="groupDetailSegment" [scrollable]="true" (ionChange)="groupSegmentChange($event)">

      <ion-segment-button [value]="GroupTripSegment.Information">
        <ion-label>Details</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="GroupTripSegment.Members">
        <ion-label>Members</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="GroupTripSegment.Events" *ngIf="group.exists">
        <ion-label>Events</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="GroupTripSegment.Matches" *ngIf="group.exists">
        <ion-label>Matches</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="GroupTripSegment.Posts" *ngIf="group.exists">
        <ion-label>Posts</ion-label>
      </ion-segment-button>

    </ion-segment>

  </div>

  <!-- main tab with group trip details -->
  <div *ngIf="groupDetailSegment === GroupTripSegment.Information">

    <form class="group-trip-detail-form" [formGroup]="groupForm">

      <!-- cover and avatar -->
      <div class="coverAvatar" style="margin-top: -10px">

        <!-- cover -->
        <div class="cover" style="padding: 16px;" (click)="updateCover()">
          <img [src]="localCoverURL | sanitizeUrl" style="border-radius: 16px;" draggable="false">
          <div class="avatarImageOverlay imageSelector" style="right: 23px; bottom: -10px;">
            <ion-icon name="camera" size="small" style="color: black"></ion-icon>
          </div>
        </div>

        <!-- avatar -->
        <div class="avatar" style="margin-top: -30px;">
          <ion-avatar class="avatar-large avatarImageOverlay" (click)="updateAvatar($event, imgAvatar)">
            <img #imgAvatar [src]="localAvatarURL | sanitizeUrl" draggable="false">
            <div class="avatarImageOverlay imageSelector">
              <ion-icon name="camera" size="small" style="color: black"></ion-icon>
            </div>
          </ion-avatar>
        </div>

      </div>

      <!-- trip details -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Trip Details</ion-label>
        </ion-list-header>

        <!-- Name -->
        <ion-item [class.invalid]="!groupForm.controls.name.valid && groupForm.controls.name.touched" lines="inset">
          <ion-label position="stacked">Name</ion-label>
          <ion-input formControlName="name"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="groupForm.controls.name.hasError('required') && !groupForm.controls.name.valid && groupForm.controls.name.touched">
          <ion-label autocapitalize="on">Please enter a group name.</ion-label>
        </ion-item>

        <!-- Details -->
        <ion-item lines="inset">
          <ion-label position="stacked">Details</ion-label>
          <ion-textarea formControlName="description" auto-grow="true" autocomplete="on" autocorrect="on"
            autocapitalize="on" maxlength="400" rows="3">
          </ion-textarea>
        </ion-item>

        <!-- departure date -->
        <ion-item class="accordionItem">
          <ion-label position="stacked">Departure Date</ion-label>
          <ion-accordion-group #departureDtAccordian>
            <ion-accordion>
              <ion-item slot="header" value="departureDt">
                <ion-label class="readOnly" *ngIf="!groupForm.controls.departureDt.value">Please select a departure date
                </ion-label>
                <ion-label *ngIf="groupForm.controls.departureDt.value">{{ groupForm.controls.departureDt.value | amUtc
                  | amLocal | amDateFormat:'dddd, MMMM Do' }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-datetime presentation="date" formControlName="departureDt">
                </ion-datetime>

                <!-- <ion-datetime presentation="date" formControlName="departureDt" [min]="minDepartureDate">
                </ion-datetime> -->
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="groupForm.controls.departureDt.hasError('required') && !groupForm.controls.departureDt.valid && groupForm.controls.departureDt.touched">
          <ion-label autocapitalize="on">Please select a departure date.</ion-label>
        </ion-item>

        <!-- return date -->
        <ion-item class="accordionItem">
          <ion-label position="stacked">Return Date</ion-label>
          <ion-accordion-group #returnDtAccordian [value]="returnDtAccordianName">
            <ion-accordion value="returnDt">
              <ion-item slot="header">
                <ion-label class="readOnly" *ngIf="!groupForm.controls.returnDt.value">Please select a return date
                </ion-label>
                <ion-label *ngIf="groupForm.controls.returnDt.value">{{ groupForm.controls.returnDt.value | amUtc |
                  amLocal | amDateFormat:'dddd, MMMM Do' }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-datetime #returnDt presentation="date" formControlName="returnDt" [min]="minReturnDate">
                </ion-datetime>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="groupForm.controls.returnDt.hasError('required') && !groupForm.controls.returnDt.valid && groupForm.controls.returnDt.touched">
          <ion-label autocapitalize="on">Please select a return date.</ion-label>
        </ion-item>

        <!-- number of trip players -->
        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Number of Trip Players</ion-text>
            <ion-grid class="counter">
              <ion-row>
                <ion-col class="ion-text-center">
                  <ion-button shape="round" color="secondary" haptic
                    [disabled]="groupForm.controls.numberOfPlayers.value===1"
                    (click)="decrementCounter('numberOfPlayers')">
                    <ion-icon slot="icon-only" name="remove"></ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col class="ion-text-center">
                  <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                    {{groupForm.controls.numberOfPlayers.value}}</ion-label>
                </ion-col>
                <ion-col class="ion-text-center">
                  <ion-button shape="round" color="secondary" haptic
                    [disabled]="groupForm.controls.numberOfPlayers.value===100"
                    (click)="incrementCounter('numberOfPlayers')">
                    <ion-icon slot="icon-only" name="add"></ion-icon>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-label>
        </ion-item>

      </ion-list>

      <!-- match details -->
      <ion-list class="itemLines" *ngIf="false">

        <ion-list-header>
          <ion-label>Matches</ion-label>
          <ion-button style="height: auto;" (click)="matchEdit();">
            <ion-icon name="add-circle" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-list-header>

        <!-- get event matches (vs member matches) -->
        <ng-container *ngIf="group.matches.parent.length > 0, else noGroupTripMatches">

          <ng-container *ngFor="let match of group.matches.parent">

            <!-- match format -->
            <ion-item (click)="matchEdit(match)" detail="true" lines="full">

              <match-view [match]="match" [formatLabel]="'Format'">
              </match-view>

            </ion-item>

          </ng-container>

        </ng-container>

        <!-- if no event matches then show this messsage -->
        <ng-template #noGroupTripMatches>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <p>There are no matches for this Group. Tap <ion-icon name="add-circle" slot="icon-only"
                  style="color: var(--ion-color-primary); font-size: large;"></ion-icon> above to create the first Group
                match.</p>
            </ion-label>
          </ion-item>
        </ng-template>

      </ion-list>

      <!-- trip notification preferences -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Event Notifications</ion-label>
        </ion-list-header>
        <ion-list-header class="help">
          <ion-text>Customize event notifications.</ion-text>
          <ion-text (click)="helpService.showFeatureHelp('eventCommunicationPreferences')">Learn more.</ion-text>
        </ion-list-header>

        <!-- Email -->
        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Email</ion-text>
            <round-segment (change)="updateEmailPreferences($event)" [value]="this.memberGroupPreferences?.e"
              [buttons]="emailPreference"></round-segment>
          </ion-label>
        </ion-item>

        <!-- Notification -->
        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Notification</ion-text>
            <round-segment (change)="updateNotificationPreferences($event)" [value]="this.memberGroupPreferences?.n"
              [buttons]="emailPreference">
            </round-segment>
          </ion-label>
        </ion-item>

      </ion-list>

      <!-- post notification preferences -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Post Notifications</ion-label>
        </ion-list-header>
        <ion-list-header class="help">
          <ion-text>Customize post notifications.</ion-text>
          <ion-text (click)="helpService.showFeatureHelp('postCommunicationPreferences')">Learn more.</ion-text>
        </ion-list-header>

        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Notification</ion-text>
            <round-segment (change)="updatePostNotificationPreferences($event)" [value]="this.memberGroupPreferences?.p"
              [buttons]="postPreference"></round-segment>
          </ion-label>
        </ion-item>

      </ion-list>

      <!-- attendance notification preferences -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Trip Attendance Notifications</ion-label>
        </ion-list-header>
        <ion-list-header class="help">
          <ion-text>Customize trip attendance notifications.</ion-text>
          <ion-text (click)="helpService.showFeatureHelp('tripAttendanceNotificationPreferences')">Learn
            more.</ion-text>
        </ion-list-header>

        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Notification</ion-text>
            <round-segment (change)="updateTripNotificationPreferences($event)"
              [value]="this.memberGroupPreferences?.tripNotification" [buttons]="tripAttendancePreference">
            </round-segment>
          </ion-label>
        </ion-item>

      </ion-list>

    </form>

  </div>

  <!-- invitee list -->
  <div *ngIf="groupDetailSegment === GroupTripSegment.Members">

    <!-- <ion-searchbar (ionChange)="searchCriteriaChange($event)" (ionClear)="searchCriteriaClear()"
      show-cancel-button="focus" inputmode="search"></ion-searchbar> -->

    <ion-list class="itemLines">

      <ion-list-header>
        <ion-label>Members</ion-label>
        <ion-button #popoverNewMember style="height: auto;" (click)="addMemberConfirm();" popoverHelp>
          <ion-icon name="add-circle" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-list-header>

      <!-- list group members that are "in" and with the numberOfPlayers -->
      <ng-container *ngIf="group.invited.in.length > 0;">

        <!-- attendance status header -->
        <ion-item-group>

          <ion-item-divider>
            <ion-label>In</ion-label>
          </ion-item-divider>

          <!-- group members -->
          <ion-item *ngFor="let groupMember of group.invited.in;">

            <avatar #avatar [member]="groupMember.member" slot="start"></avatar>

            <ion-label (click)="avatar.displayProfileSummary($event)">

              <h2>{{groupMember.member.firstName}} {{groupMember.member.lastName}}</h2>
              <p [ngStyle]="{'color': group.isMemberAdmin(groupMember.member) ? 'red': 'initial' }">
                {{(groupMember.member.id === group.ownerMemberId) ? 'organizer' :
                group.isMemberAdmin(groupMember.member) ? 'administrator' : groupMember.member.email}}
              </p>

            </ion-label>

            <ion-icon name="ellipsis-vertical" (click)="memberAction(groupMember)" slot="end"
              style="align-self: end; margin-right: 0px;" color="primary">
            </ion-icon>

          </ion-item>

        </ion-item-group>

      </ng-container>

      <!-- list group members that are "waitlist" -->
      <ng-container *ngIf="group.invited.waitList.length > 0;">

        <!-- attendance status header -->
        <ion-item-group class="itemGroupLines">

          <ion-item-divider>
            <ion-label *ngIf="group.allFull">Waitlist</ion-label>
            <ion-label *ngIf="!group.allFull">Invited</ion-label>
          </ion-item-divider>

          <!-- group members -->
          <ion-item *ngFor="let groupMember of group.invited.waitList;">

            <avatar #avatar [member]="groupMember.member" slot="start"></avatar>

            <ion-label (click)="avatar.displayProfileSummary($event)">

              <h2>{{groupMember.member.firstName}} {{groupMember.member.lastName}}</h2>
              <p [ngStyle]="{'color': group.isMemberAdmin(groupMember.member) ? 'red': 'initial' }">
                {{(groupMember.member.id === group.ownerMemberId) ? 'organizer' :
                group.isMemberAdmin(groupMember.member)
                ?
                'administrator' :
                groupMember.member.email}}
              </p>
              <p *ngIf="group.allFull">{{TripAttendanceStatus[groupMember.status]}}</p>

            </ion-label>

            <ion-icon name="ellipsis-vertical" (click)="memberAction(groupMember)" slot="end"
              style="align-self: end; margin-right: 0px;" color="primary">
            </ion-icon>

          </ion-item>

        </ion-item-group>

      </ng-container>

      <!-- list group members that are "out" -->
      <ng-container *ngIf="group.invited.out.length > 0;">

        <!-- attendance status header -->
        <ion-item-group>

          <ion-item-divider>
            <ion-label>Out</ion-label>
          </ion-item-divider>

          <!-- group members -->
          <ion-item *ngFor="let groupMember of group.invited.out;">

            <avatar #avatar [member]="groupMember.member" slot="start"></avatar>

            <ion-label (click)="avatar.displayProfileSummary($event)">

              <h2>{{groupMember.member.firstName}} {{groupMember.member.lastName}}</h2>
              <p [ngStyle]="{'color': group.isMemberAdmin(groupMember.member) ? 'red': 'initial' }">
                {{(groupMember.member.id === group.ownerMemberId) ? 'organizer' :
                group.isMemberAdmin(groupMember.member)
                ?
                'administrator' :
                groupMember.member.email}}
              </p>

            </ion-label>

            <ion-icon name="ellipsis-vertical" (click)="memberAction(groupMember)" slot="end"
              style="align-self: end; margin-right: 0px;" color="primary">
            </ion-icon>

          </ion-item>

        </ion-item-group>

      </ng-container>

    </ion-list>

  </div>

  <div *ngIf="groupDetailSegment === GroupTripSegment.Events"
    [ngClass]="{'greyBackgroundForSegment': group.events.all.length > 0}">

    <ion-list>

      <ion-list-header>
        <ion-label>Events</ion-label>
        <ion-button #popoverNewEvent style="height: auto;" (click)="newEvent(group);" popoverHelp>
          <ion-icon name="add-circle" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-list-header>
      <ion-list-header class="help">
        <ion-text>Create Events for the Trip.</ion-text>
        <ion-text (click)="helpService.showFeatureHelp('tripEvents')">Learn more.</ion-text>
      </ion-list-header>

      <ng-container *ngIf="group.events.all.length > 0, else noEvents">
        <ng-container *ngFor="let event of group.events.all">
          <event-card [event]="event"></event-card>
        </ng-container>
      </ng-container>

    </ion-list>

  </div>

  <div *ngIf="groupDetailSegment === GroupTripSegment.Matches">

    <!-- TRIP MATCHES -->
    <ion-list class="itemLines" lines="full">

      <ion-list-header>
        <ion-label>Trip Matches</ion-label>
        <ion-button #popoverNewMatch style="height: auto;" (click)="matchEdit();" popoverHelp>
          <ion-icon name="add-circle" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-list-header>
      <ion-list-header class="help">
        <ion-text>Create matches across all Events of the trip.</ion-text>
        <ion-text (click)="helpService.showFeatureHelp('tripMatches')">Learn more.</ion-text>
      </ion-list-header>

      <ng-container *ngIf="group.matches.parent.length > 0, else noTripMatches">

        <!-- interate through group matches -->
        <ng-container *ngFor="let match of group.matches.parent">
          <trip-match-live-view [match]="match" [group]="group" [openScorecardMethod]="openScorecard"
            [callback]="matchUpdate">
          </trip-match-live-view>
        </ng-container>

      </ng-container>

    </ion-list>

    <!-- TRIP MEMBER MATCHES -->
    <ion-list class="itemLines" lines="full">

      <ion-list-header>
        <ion-label>Trip Member Matches</ion-label>
        <ion-button style="height: auto;" (click)="newMemberMatch();">
          <ion-icon name="add-circle" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-list-header>
      <ion-list-header class="help">
        <ion-text>Challenge other Trip players to a match.</ion-text>
        <ion-text (click)="helpService.showFeatureHelp('tripMemberMatches')">Learn more.</ion-text>
      </ion-list-header>

      <!-- show all of the current member event matches -->
      <ng-container
        *ngIf="group.matches.getPlayerMemberMatches(group.players.memberPlayer).length > 0; else noTripMemberMatches">

        <ng-container *ngFor="let match of group.matches.getPlayerMemberMatches(group.players.memberPlayer)">
          <trip-match-live-view [match]="match" [group]="group" [openScorecardMethod]="openScorecard"
            [callback]="matchUpdate">
          </trip-match-live-view>
        </ng-container>

      </ng-container>

    </ion-list>

  </div>

  <div *ngIf="groupDetailSegment === GroupTripSegment.Posts"
    [ngClass]="{'greyBackgroundForSegment': posts?.length > 0}">

    <ion-list>

      <ion-list-header>
        <ion-label>Posts</ion-label>
        <ion-button #popoverNewPost style="height: auto;" (click)="newPost();" popoverHelp>
          <ion-icon name="add-circle" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-list-header>
      <ion-list-header class="help">
        <ion-text>Create Posts for the Trip.</ion-text>
        <ion-text (click)="helpService.showFeatureHelp('tripPosts')">Learn more.</ion-text>
      </ion-list-header>

      <ng-container *ngIf="posts?.length > 0, else noPosts">
        <ng-container *ngFor="let post of posts;">
          <post-card [post]="post" [content]="content"></post-card>
        </ng-container>
      </ng-container>

    </ion-list>

    <ion-infinite-scroll (ionInfinite)="getPosts($event)" threshold="1px">
      <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more posts...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <ion-note *ngIf="noMorePosts" class="instructional"
      style="height: 85px; margin-bottom: 10px; background-color: var(--ion-color-white-b);">No more posts
    </ion-note>


  </div>

  <ion-fab #fab vertical="bottom" horizontal="end" slot="fixed" [fabClose]="fab" haptic>
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-button shape="round" (click)="deleteGroupConfirmation(group); fab.close();" *ngIf="group.exists">
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label>Delete Trip</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="composeEmail(); fab.close();" *ngIf="group.exists">
        <ion-icon name="mail" slot="start"></ion-icon>
        <ion-label>Send Email</ion-label>
      </ion-button>
    </ion-fab-list>
  </ion-fab>

</ion-content>

<ng-template #noPosts>
  <div class="emptyState">
    <div class="emptyStateIcon">
      <ion-icon name="chatbubbles" class="emptyStateIcon"></ion-icon>
    </div>
    <ion-note class="emptyStateText message">
      There are no Posts for this trip.
    </ion-note>
  </div>
</ng-template>

<ng-template #noEvents>
  <div class="emptyState">
    <div class="emptyStateIcon">
      <ion-icon name="calendar"></ion-icon>
    </div>
    <ion-note class="emptyStateText message">
      There are no Events for this trip.
    </ion-note>
  </div>
</ng-template>

<ng-template #noTripMatches>

  <div class="emptyState">
    <div class="emptyStateIcon">
      <fa-icon [icon]="faGolfBallTee"></fa-icon>
    </div>
    <ion-note class="emptyStateText message">
      You don't have any Trip matches. Tap <ion-icon name="add-circle" color="primary"
        style="font-size: large; vertical-align: text-bottom;"></ion-icon> above to start a match.
    </ion-note>
  </div>

</ng-template>

<ng-template #noTripMemberMatches>

  <div class="emptyState">
    <div class="emptyStateIcon">
      <fa-icon [icon]="faGolfBallTee"></fa-icon>
    </div>
    <ion-note class="emptyStateText message">
      You don't have any Trip member matches. Tap <ion-icon name="add-circle" color="primary"
        style="font-size: large; vertical-align: text-bottom;"></ion-icon> above to start a match.
    </ion-note>
  </div>

</ng-template>