<div class="video_box" (click)="play()">

  <div #videoEndWrapper class="videoEndWrapper">

    <!-- <ion-button shape="round" size="small" color="secondary">Replay</ion-button> -->

  </div>

  <div #videoPlayWrapper class="videoPlayWrapper" (click)="mute()">

    <div class="bottom-left">
      <ion-icon [name]="muteIcon" style="color: white; font-size: 30px;"></ion-icon>
    </div>

  </div>

  <div #videoPauseWrapper class="videoEndWrapper">

  </div>

  <video #videoPlayer preload="auto" playsinline muted>
    <source [src]="src" type="video/mp4">
  </video>

</div>