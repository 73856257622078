import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../app.account';
import { ClubService, AppClub, AppCourse, AppTee } from '../../app.club';
import { AppFunction, leaveToRightAnimation, enterFromRightAnimation, IonColor, ColorService, SortByOrder } from '../../app.function';
import { AppConfig } from 'src/app/app.config';
import { CourseDetailPage } from '../course-detail/course-detail.page';
import * as XLSX from 'xlsx';
import { DocumentReference } from '@firebase/firestore-types';
import firebase from 'firebase/compat/app';
import { GeoFirestore } from 'geofirestore';

@Component({
  selector: 'club-detail',
  templateUrl: './club-detail.page.html',
  styleUrls: ['./club-detail.page.scss']
})
export class ClubDetailPage implements OnInit {

  club: AppClub;
  clubForm: FormGroup;
  clubDetailSegment: string = 'detail';
  closeLabel: string;

  constructor(
    public builder: FormBuilder,
    public accountService: AccountService,
    public clubService: ClubService,
    public appFunction: AppFunction,
    public navParams: NavParams,
    public colorService: ColorService) {

    //console.log('club-detail.page.ts constructor');

  }

  ngOnInit() {

    //console.log('club-detail.page.ts ngOnInit');

    //create group class
    this.club = <AppClub>this.navParams.get('club');

    //build form
    this.clubForm = this.builder.group({
      name: [this.club.name, Validators.required],
      address: [this.club.address, Validators.required],
      latitude: [this.club.latitude, Validators.required],
      longitude: [this.club.longitude, Validators.required]
    });

    //get close label
    this.closeLabel = this.navParams.get('closeLabel') || 'Select';

  }

  async openCourse(course: AppCourse) {

    //TODO: this admin statement is temporary
    if (this.accountService.member.admin) {

      this.appFunction
        .modalCtrl
        .create({
          component: CourseDetailPage,
          presentingElement: await this.appFunction.routerOutlet(),
          enterAnimation: enterFromRightAnimation,
          leaveAnimation: leaveToRightAnimation,
          cssClass: 'custom-modal', //for md
          componentProps: {
            action: AppConfig.EDIT_MODE.update,
            course: course
          }
        })
        .then((modal) => {

          modal
            .present()
            .catch((err) => {
              console.log('club-detail.page.ts openCourse modal present error', err);
            });

        })
        .catch((err) => {
          console.log('club-detail.page.ts openCourse modal create error', err);
        });

    }

  }

  //export JSON to Excel file
  exportJSONtoExcel() {

    //create a new blank workbook
    const wb = XLSX.utils.book_new();

    //create a new blank worksheet for club
    const ws_clubs = XLSX.utils.json_to_sheet([{
      clubId: this.club.clubId,
      name: this.club.name,
      address: this.club.address,
      city: this.club.city,
      state: this.club.state,
      zipcode: this.club.zipcode,
      phone: this.club.phone,
      latitude: this.club.latitude,
      longitude: this.club.longitude
    }]);

    //create a new blank worksheet for courses
    const ws_courses = XLSX.utils.json_to_sheet(this.club.courses.map((course) => {
      return {
        clubId: this.club.clubId,
        courseId: course.courseId,
        name: course.name,
        holes: course.holes,
        designer: course.designer,
        year: course.year
      };
    }));

    //create a new blank worksheet for course tees
    const ws_courseTees = XLSX.utils.json_to_sheet(this.club.courses.flatMap((course) => {

      //first sort tees by distance...
      const tees: AppTee[] = course.tees;
      tees.sortBy('distance', SortByOrder.DESC);

      //...then export tees
      return tees.map((tee) => {

        console.log('tee', tee.distance);

        const frontNine = tee.nines[0];
        const backNine = tee.nines[1];

        //create tee JSON
        var teeJSON: any = {
          teeId: tee.teeId,
          courseId: tee.courseId,
          tee_name: tee.name,
          tee_color: tee.firstTeeColor?.key,
          second_tee_color: tee.secondTeeColor?.key,
          total_distance: tee.distance,
          slope: tee.slope,
          par: tee.par,
          rating: tee.rating,
        };

        //get front nine distances
        teeJSON.hole1_dist = frontNine.holes[0]?.distance;
        teeJSON.hole2_dist = frontNine.holes[1]?.distance;
        teeJSON.hole3_dist = frontNine.holes[2]?.distance;
        teeJSON.hole4_dist = frontNine.holes[3]?.distance;
        teeJSON.hole5_dist = frontNine.holes[4]?.distance;
        teeJSON.hole6_dist = frontNine.holes[5]?.distance;
        teeJSON.hole7_dist = frontNine.holes[6]?.distance;
        teeJSON.hole8_dist = frontNine.holes[7]?.distance;
        teeJSON.hole9_dist = frontNine.holes[8]?.distance;
        teeJSON.front_nine_name = frontNine?.name;
        teeJSON.front_nine_slope = frontNine?.slope;
        teeJSON.front_nine_rating = frontNine?.rating;
        teeJSON.front_nine_par = frontNine?.par;

        //check for back nine distances
        if (backNine) {
          teeJSON.hole10_dist = backNine.holes[0]?.distance;
          teeJSON.hole11_dist = backNine.holes[1]?.distance;
          teeJSON.hole12_dist = backNine.holes[2]?.distance;
          teeJSON.hole13_dist = backNine.holes[3]?.distance;
          teeJSON.hole14_dist = backNine.holes[4]?.distance;
          teeJSON.hole15_dist = backNine.holes[5]?.distance;
          teeJSON.hole16_dist = backNine.holes[6]?.distance;
          teeJSON.hole17_dist = backNine.holes[7]?.distance;
          teeJSON.hole18_dist = backNine.holes[8]?.distance;
          teeJSON.back_nine_name = backNine?.name;
          teeJSON.back_nine_slope = backNine?.slope;
          teeJSON.back_nine_rating = backNine?.rating;
          teeJSON.back_nine_par = backNine?.par;
        }

        //get front nine pars
        teeJSON.hole1_par = frontNine.holes[0]?.par;
        teeJSON.hole2_par = frontNine.holes[1]?.par;
        teeJSON.hole3_par = frontNine.holes[2]?.par;
        teeJSON.hole4_par = frontNine.holes[3]?.par;
        teeJSON.hole5_par = frontNine.holes[4]?.par;
        teeJSON.hole6_par = frontNine.holes[5]?.par;
        teeJSON.hole7_par = frontNine.holes[6]?.par;
        teeJSON.hole8_par = frontNine.holes[7]?.par;
        teeJSON.hole9_par = frontNine.holes[8]?.par;

        //check for back nine pars
        if (backNine) {
          teeJSON.hole10_par = backNine.holes[0]?.par;
          teeJSON.hole11_par = backNine.holes[1]?.par;
          teeJSON.hole12_par = backNine.holes[2]?.par;
          teeJSON.hole13_par = backNine.holes[3]?.par;
          teeJSON.hole14_par = backNine.holes[4]?.par;
          teeJSON.hole15_par = backNine.holes[5]?.par;
          teeJSON.hole16_par = backNine.holes[6]?.par;
          teeJSON.hole17_par = backNine.holes[7]?.par;
          teeJSON.hole18_par = backNine.holes[8]?.par;
        }

        //get front nine handicaps
        teeJSON.hole1_handicap = frontNine.holes[0]?.hdcp;
        teeJSON.hole2_handicap = frontNine.holes[1]?.hdcp;
        teeJSON.hole3_handicap = frontNine.holes[2]?.hdcp;
        teeJSON.hole4_handicap = frontNine.holes[3]?.hdcp;
        teeJSON.hole5_handicap = frontNine.holes[4]?.hdcp;
        teeJSON.hole6_handicap = frontNine.holes[5]?.hdcp;
        teeJSON.hole7_handicap = frontNine.holes[6]?.hdcp;
        teeJSON.hole8_handicap = frontNine.holes[7]?.hdcp;
        teeJSON.hole9_handicap = frontNine.holes[8]?.hdcp;

        //check for back nine handicaps
        if (backNine) {
          teeJSON.hole10_handicap = backNine.holes[0]?.hdcp;
          teeJSON.hole11_handicap = backNine.holes[1]?.hdcp;
          teeJSON.hole12_handicap = backNine.holes[2]?.hdcp;
          teeJSON.hole13_handicap = backNine.holes[3]?.hdcp;
          teeJSON.hole14_handicap = backNine.holes[4]?.hdcp;
          teeJSON.hole15_handicap = backNine.holes[5]?.hdcp;
          teeJSON.hole16_handicap = backNine.holes[6]?.hdcp;
          teeJSON.hole17_handicap = backNine.holes[7]?.hdcp;
          teeJSON.hole18_handicap = backNine.holes[8]?.hdcp;
        }

        //add tee to array
        return teeJSON;

      });

    }));

    //add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws_clubs, 'Club');
    XLSX.utils.book_append_sheet(wb, ws_courses, 'Courses');
    XLSX.utils.book_append_sheet(wb, ws_courseTees, 'Tees');

    //prompt to save file
    XLSX.writeFile(wb, this.club.name.trim() + '.xlsx');

  }

  //import excel file
  importExceltoJSON() {

    //open file dialog
    var input = document.createElement('input');
    input.type = 'file';
    input.click();

    //once file selected
    input.onchange = (e) => {

      //get file
      var file = e.target['files'][0];

      //read file
      var reader = new FileReader();
      reader.onload = (e) => {

        //get file data
        const data = e.target['result'];

        //read workbook
        const wb = XLSX.read(data, { type: 'binary' });

        //get club worksheet
        const ws_clubs = wb.SheetNames[0];

        //get courses worksheet
        const ws_courses = wb.SheetNames[1];

        //get tees worksheet
        const ws_courseTees = wb.SheetNames[2];

        //convert club to json
        const club = XLSX.utils.sheet_to_json(wb.Sheets[ws_clubs], { header: 1 });
        this.clubImport(club);

        //convert courses to json
        const courses = XLSX.utils.sheet_to_json(wb.Sheets[ws_courses], { header: 1 });
        this.courseImport(courses);

        //convert tees to json
        const tees = XLSX.utils.sheet_to_json(wb.Sheets[ws_courseTees], { header: 1 });
        this.teeImport(tees);

        console.log('club-detail.page.ts importExceltoJSON', club, courses, tees);

      };

      reader.readAsBinaryString(file);

    }

  }

  private clubImport(clubs) {

    try {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment 
      // @ts-ignore
      const geoFirestore = new GeoFirestore(this.appFunction.firestore);
      const geoClubs = geoFirestore.collection(AppConfig.COLLECTION.Clubs)
      let clubCount: number = 0;

      clubs
        .forEach((clubProperties, index) => {

          //skip the header row which is index 0
          if (index > 0) {

            //club properties
            const ClubID = 0;
            const ClubName = 1;
            const Address = 2;
            const City = 3;
            const State = 4;
            const Zipcode = 5;
            const Phone = 6;
            const Latitude = 7;
            const Longitude = 8;

            const clubObject = {
              "clubId": clubProperties[ClubID],
              "name": clubProperties[ClubName].replace('"', ''),
              "searchName": clubProperties[ClubName].replace('"', '').toUpperCase(),
              "address": clubProperties[Address].replace('"', ''),
              "city": clubProperties[City],
              "state": clubProperties[State],
              "zipcode": clubProperties[Zipcode],
              "phone": clubProperties[Phone],
              "latitude": Number(clubProperties[Latitude]),
              "longitude": Number(clubProperties[Longitude])
            }

            try {

              const p = new Promise<DocumentReference>((resolve) => {

                //get document with clubId
                this.appFunction
                  .firestore
                  .collection(AppConfig.COLLECTION.Clubs)
                  .where('clubId', '==', clubProperties[ClubID])
                  .get()
                  .then((club) => {

                    //if empty then it is a new club so add it
                    if (club.empty) {

                      this.appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Clubs)
                        .add(clubObject)
                        .then((club) => {
                          console.log('club added/saved', club.id, 'count: ', clubCount++);
                          resolve(club);
                        })
                        .catch((err) => {
                          console.log('Error during club collection add', err, club);
                        });

                    } else { //update

                      const clubRef = this.appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Clubs)
                        .doc(club.docs[0].id);

                      clubRef
                        .set(clubObject)
                        .then(() => {
                          console.log('club updated', club.docs[0].id, 'count: ', clubCount++);
                          resolve(clubRef);
                        })
                        .catch((err) => {
                          console.log('Error during club update', err, club);
                        });

                    }

                  });

              });

              Promise
                .all([p])
                .then((clubRef) => {

                  try {

                    clubRef[0]
                      .get()
                      .then((clubData) => {

                        //set coord
                        const data = clubData.data();
                        data.coordinates = new firebase.firestore.GeoPoint(clubData.data().latitude, clubData.data().longitude);

                        console.log('clubData: ', clubData.data());

                        //save the coordinate
                        geoClubs
                          .doc(clubData.id)
                          .set(data)
                          .then(() => {
                            //console.log('Provided key has been added to GeoFirestore', doc.id);
                            console.log('Provided key has been added to GeoFirestore');
                          })
                          .catch((error) => {
                            console.log('Error: ' + error, clubData.id);
                          });

                      });

                  } catch (err) {
                    console.log('Geocode error: ' + err);
                  }

                });

            } catch (err) {
              console.log('club-detail.page.ts importClub error during club add', err, clubObject);
            }

          }

        });

    } catch (err) {
      console.log('club-detail.page.ts importClub error', err);
    }

  }

  private courseImport(courses) {

    try {

      let courseCount: number = 0;

      courses
        .forEach((courseProperties, index) => {

          //skip the header row
          if (index > 0) {

            //course properties
            const ClubID = 0;
            const CourseID = 1;
            const CourseName = 2;
            const Holes = 3;
            const Designer = 4;
            const Year = 5;

            const courseObject = {
              "clubId": courseProperties[ClubID],
              "courseId": courseProperties[CourseID],
              "name": courseProperties[CourseName].replace('"', ''),
              "holes": this.returnNumber(courseProperties[Holes]),
              "designer": courseProperties[Designer].replace('"', ''),
              "year": courseProperties[Year].toString().replace('"', '')
            };

            //get document with clubId
            this.appFunction
              .firestore
              .collection(AppConfig.COLLECTION.Courses)
              .where('courseId', '==', courseProperties[CourseID])
              .get()
              .then((course) => {

                //if empty then it is a new club and add
                if (course.empty) {

                  this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Courses)
                    .add(courseObject)
                    .then((course) => {
                      console.log('course added/saved', course.id, 'count: ', courseCount++);
                    }).
                    catch((err) => {
                      console.log('Error during course add', err, courseProperties[CourseID], course);
                    });

                } else { //update

                  const courseRef = this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Courses)
                    .doc(course.docs[0].id);

                  courseRef
                    .set(courseObject)
                    .then(() => {
                      console.log('course updated', course.docs[0].id, 'count: ', courseCount++);
                    })
                    .catch((err) => {
                      console.log('Error during course update', err, course);
                    });

                }

              });

          }

        });

      console.log('course load complete');

    } catch (err) {
      console.log('system-utilities.page.ts onCourseFileSelected error', err);
    }

  }

  private teeImport(tees) {

    try {

      const teeColors = this.colorService.colorList;
      let teeCount: number = 0;

      console.log('Number of Tees', tees.length);

      tees
        .forEach((teeProperties, index) => {

          //skip the header row
          if (index > 0) {

            //set up constants
            const TeeID = 0;
            const CourseID = 1;
            const TeeName = 2;
            const TeeColor1 = 3;
            const TeeColor2 = 4;
            const TotalDistance = 5;
            const TotalSlope = 6;
            const TotalPar = 7;
            const TotalRating = 8;
            const Hole1Distance = 9;
            const Hole2Distance = 10;
            const Hole3Distance = 11;
            const Hole4Distance = 12;
            const Hole5Distance = 13;
            const Hole6Distance = 14;
            const Hole7Distance = 15;
            const Hole8Distance = 16;
            const Hole9Distance = 17;
            const FrontNineName = 18;
            const FrontNineSlope = 19;
            const FrontNineRating = 20;
            const FrontNinePar = 21;
            const Hole10Distance = 22;
            const Hole11Distance = 23;
            const Hole12Distance = 24;
            const Hole13Distance = 25;
            const Hole14Distance = 26;
            const Hole15Distance = 27;
            const Hole16Distance = 28;
            const Hole17Distance = 29;
            const Hole18Distance = 30;
            const BackNineName = 31;
            const BackNineSlope = 32;
            const BackNineRating = 33;
            const BackNinePar = 34;
            const Hole1Par = 35;
            const Hole2Par = 36;
            const Hole3Par = 37;
            const Hole4Par = 38;
            const Hole5Par = 39;
            const Hole6Par = 40;
            const Hole7Par = 41;
            const Hole8Par = 42;
            const Hole9Par = 43;
            const Hole10Par = 44;
            const Hole11Par = 45;
            const Hole12Par = 46;
            const Hole13Par = 47;
            const Hole14Par = 48;
            const Hole15Par = 49;
            const Hole16Par = 50;
            const Hole17Par = 51;
            const Hole18Par = 52;
            const Hole1Handicap = 53;
            const Hole2Handicap = 54;
            const Hole3Handicap = 55;
            const Hole4Handicap = 56;
            const Hole5Handicap = 57;
            const Hole6Handicap = 58;
            const Hole7Handicap = 59;
            const Hole8Handicap = 60;
            const Hole9Handicap = 61;
            const Hole10Handicap = 62;
            const Hole11Handicap = 63;
            const Hole12Handicap = 64;
            const Hole13Handicap = 65;
            const Hole14Handicap = 66;
            const Hole15Handicap = 67;
            const Hole16Handicap = 68;
            const Hole17Handicap = 69;
            const Hole18Handicap = 70;

            //get the first tee color
            const firstTeeIonColor: IonColor = teeColors.find((teeColor) => {
              return teeColor.friendlyName.toLowerCase() === teeProperties[TeeColor1];
            });

            //get the second tee color
            const secondTeeIonColor: IonColor = teeColors.find((teeColor) => {
              return teeColor.friendlyName.toLowerCase() === teeProperties[TeeColor2];
            }) || null;

            //set defaults
            const nines: any[] = [];

            //push the first nine holes
            nines.push({
              "name": teeProperties[FrontNineName],
              "slope": this.returnNumber(teeProperties[FrontNineSlope]),
              "rating": this.returnNumber(teeProperties[FrontNineRating]),
              "par": this.returnNumber(teeProperties[FrontNinePar]),
              "holes": [
                {
                  number: 1,
                  distance: this.returnNumber(teeProperties[Hole1Distance]),
                  par: this.returnNumber(teeProperties[Hole1Par]),
                  hdcp: this.returnNumber(teeProperties[Hole1Handicap])
                },
                {
                  number: 2,
                  distance: this.returnNumber(teeProperties[Hole2Distance]),
                  par: this.returnNumber(teeProperties[Hole2Par]),
                  hdcp: this.returnNumber(teeProperties[Hole2Handicap])
                },
                {
                  number: 3,
                  distance: this.returnNumber(teeProperties[Hole3Distance]),
                  par: this.returnNumber(teeProperties[Hole3Par]),
                  hdcp: this.returnNumber(teeProperties[Hole3Handicap])
                },
                {
                  number: 4,
                  distance: this.returnNumber(teeProperties[Hole4Distance]),
                  par: this.returnNumber(teeProperties[Hole4Par]),
                  hdcp: this.returnNumber(teeProperties[Hole4Handicap])
                },
                {
                  number: 5,
                  distance: this.returnNumber(teeProperties[Hole5Distance]),
                  par: this.returnNumber(teeProperties[Hole5Par]),
                  hdcp: this.returnNumber(teeProperties[Hole5Handicap])
                },
                {
                  number: 6,
                  distance: this.returnNumber(teeProperties[Hole6Distance]),
                  par: this.returnNumber(teeProperties[Hole6Par]),
                  hdcp: this.returnNumber(teeProperties[Hole6Handicap])
                },
                {
                  number: 7,
                  distance: this.returnNumber(teeProperties[Hole7Distance]),
                  par: this.returnNumber(teeProperties[Hole7Par]),
                  hdcp: this.returnNumber(teeProperties[Hole7Handicap])
                },
                {
                  number: 8,
                  distance: this.returnNumber(teeProperties[Hole8Distance]),
                  par: this.returnNumber(teeProperties[Hole8Par]),
                  hdcp: this.returnNumber(teeProperties[Hole8Handicap])
                },
                {
                  number: 9,
                  distance: this.returnNumber(teeProperties[Hole9Distance]),
                  par: this.returnNumber(teeProperties[Hole9Par]),
                  hdcp: this.returnNumber(teeProperties[Hole9Handicap])
                }
              ]
            });

            //determine if there is a second nine holes
            if (!Number.isNaN(Number(teeProperties[Hole10Distance]))) {

              nines.push({
                "name": teeProperties[BackNineName],
                "slope": this.returnNumber(teeProperties[BackNineSlope]),
                "rating": this.returnNumber(teeProperties[BackNineRating]),
                "par": this.returnNumber(teeProperties[BackNinePar]),
                "holes": [
                  {
                    number: 10,
                    distance: this.returnNumber(teeProperties[Hole10Distance]),
                    par: this.returnNumber(teeProperties[Hole10Par]),
                    hdcp: this.returnNumber(teeProperties[Hole10Handicap])
                  },
                  {
                    number: 11,
                    distance: this.returnNumber(teeProperties[Hole11Distance]),
                    par: this.returnNumber(teeProperties[Hole11Par]),
                    hdcp: this.returnNumber(teeProperties[Hole11Handicap])
                  },
                  {
                    number: 12,
                    distance: this.returnNumber(teeProperties[Hole12Distance]),
                    par: this.returnNumber(teeProperties[Hole12Par]),
                    hdcp: this.returnNumber(teeProperties[Hole12Handicap])
                  },
                  {
                    number: 13,
                    distance: this.returnNumber(teeProperties[Hole13Distance]),
                    par: this.returnNumber(teeProperties[Hole13Par]),
                    hdcp: this.returnNumber(teeProperties[Hole13Handicap])
                  },
                  {
                    number: 14,
                    distance: this.returnNumber(teeProperties[Hole14Distance]),
                    par: this.returnNumber(teeProperties[Hole14Par]),
                    hdcp: this.returnNumber(teeProperties[Hole14Handicap])
                  },
                  {
                    number: 15,
                    distance: this.returnNumber(teeProperties[Hole15Distance]),
                    par: this.returnNumber(teeProperties[Hole15Par]),
                    hdcp: this.returnNumber(teeProperties[Hole15Handicap])
                  },
                  {
                    number: 16,
                    distance: this.returnNumber(teeProperties[Hole16Distance]),
                    par: this.returnNumber(teeProperties[Hole16Par]),
                    hdcp: this.returnNumber(teeProperties[Hole16Handicap])
                  },
                  {
                    number: 17,
                    distance: this.returnNumber(teeProperties[Hole17Distance]),
                    par: this.returnNumber(teeProperties[Hole17Par]),
                    hdcp: this.returnNumber(teeProperties[Hole17Handicap])
                  },
                  {
                    number: 18,
                    distance: this.returnNumber(teeProperties[Hole18Distance]),
                    par: this.returnNumber(teeProperties[Hole18Par]),
                    hdcp: this.returnNumber(teeProperties[Hole18Handicap])
                  }
                ]
              });

            }

            const teeObject = {
              "teeId": teeProperties[TeeID],
              "courseId": teeProperties[CourseID],
              "name": teeProperties[TeeName],
              "firstTeeColor": firstTeeIonColor || null,
              "secondTeeColor": secondTeeIonColor || null,
              "par": this.returnNumber(teeProperties[TotalPar]),
              "rating": this.returnNumber(teeProperties[TotalRating]),
              "slope": this.returnNumber(teeProperties[TotalSlope]),
              "distance": this.returnNumber(teeProperties[TotalDistance]),
              "nines": nines
            };

            //get document with clubId
            this.appFunction
              .firestore
              .collection(AppConfig.COLLECTION.Tees)
              .where('teeId', '==', teeProperties[TeeID])
              .get()
              .then((tee) => {

                //if empty then it is a new tee and add
                if (tee.empty) {

                  this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Tees)
                    .add(teeObject)
                    .then((tee) => {
                      console.log('tee added/saved', tee.id, 'count: ', teeCount++);
                    }).
                    catch((err) => {
                      console.log('Error during tee add', err, teeProperties[TeeID], tee);
                    });

                } else { //update

                  const teeRef = this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Tees)
                    .doc(tee.docs[0].id);

                  teeRef
                    .set(teeObject)
                    .then(() => {
                      console.log('tee updated', 'count: ', teeCount++, teeProperties[TeeID]);
                    })
                    .catch((err) => {
                      console.log('Error during tee update', tee.docs[0].id, err, tee);
                    });

                }

              });

          }

        });

      console.log('tee load complete');

    } catch (err) {
      console.log('system-utilities.page.ts onCourseFileSelected error', err);
    }

  }

  private returnNumber(value: string): number {
    return Number.isNaN(Number(value)) ? null : Number(value);
  }

  done() {

    //console.log('club-detail.page.ts done');

    if (this.clubForm.dirty) {

      //save the form if valid 
      if (this.clubForm.valid) {

        //console.log('club-details.page.ts done valid');

        this.appFunction
          .loadingCtrl
          .create({ message: 'Saving club...' })
          .then((loading) => {

            loading.present();

            //set dat to save
            this.club.name = this.clubForm.controls.name.value;
            this.club.address = this.clubForm.controls.address.value;
            this.club.latitude = this.clubForm.controls.latitude.value;
            this.club.longitude = this.clubForm.controls.longitude.value;

            //save 
            this.club
              .save()
              .then(() => {

                loading.dismiss();

                //close this page
                this.appFunction
                  .modalCtrl
                  .dismiss({ course: this.club }, 'select');

              });

          });

      } else {
        //show any untouched errors
        this.appFunction.setDirtyControlAsTouched(this.clubForm);
      }

    } else {

      //close this page
      this.appFunction
        .modalCtrl
        .dismiss({ course: this.club }, 'select')
        .catch((err) => {
          console.log('club-detail.page.ts back modal dismiss error', err);
        });
      ;

    }

  }

  back() {

    //confirm that user wants to discard changes
    if (this.clubForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your selected club. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {

                //close this page
                this.appFunction
                  .modalCtrl
                  .dismiss(undefined, 'cancel');

              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {

      //close this page
      this.appFunction
        .modalCtrl
        .dismiss(undefined, 'cancel');

    }

  }

}
