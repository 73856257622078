<ion-header class="course-hole-header ion-no-border">

  <ion-toolbar>

    <div slot="start">
      <ion-icon name="chatbubbles" style="color: var(--ion-color-primary);"></ion-icon>
      A note from {{player.firstName}} {{player.lastName}}...
    </div>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">Got it</ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<modal-content class="note-content ion-padding">
  <div class="preserveFormat">{{player.note}}</div>
</modal-content>