<div class="scorecardPage">
  <!-- because this page is being rotated to landscape we are using pain html markup. ionic's page html wasn't behaving as expected. -->

  <div class="header">
    <ion-toolbar>

      <!-- segment -->
      <ion-segment [(ngModel)]="selectedScorecard" slot="start">
        <ion-segment-button value="matchLiveView" *ngIf="match">
          <ion-label>{{match.getGame(match.game).name}} Live View</ion-label>
        </ion-segment-button>
        <ion-segment-button value="matchScorecard" *ngIf="match">
          <ion-label>{{match.getGame(match.game).name}} Scorecard</ion-label>
        </ion-segment-button>
        <ion-segment-button value="playerScorecard">
          <ion-label>Player Scorecard</ion-label>
        </ion-segment-button>
      </ion-segment>

      <ion-buttons slot="primary">
        <ion-button fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
      </ion-buttons>

    </ion-toolbar>
  </div>

  <div class="content">

    <ng-container [ngSwitch]="selectedScorecard">

      <!-- MATCH LIVE VIEW -->
      <div class="liveViewContainer" *ngSwitchCase="'matchLiveView'">
        <event-match-live-view [match]="match" [event]="event"></event-match-live-view>
      </div>

      <!-- MATCH SCORECARD -->
      <div *ngSwitchCase="'matchScorecard'">
        <div class="subMatchContainer" *ngFor="let subMatch of match.subMatches;">
          <ng-container *ngTemplateOutlet="scorecard; context: { $implicit: subMatch }"></ng-container>
        </div>
      </div>

      <!-- PLAYER SCORECARD -->
      <div class="scorecardContainer" *ngSwitchCase="'playerScorecard'">

        <ion-grid class="nine" *ngFor="let nine of event.nines; let nineIndex = index;">

          <!-- scorecard header -->
          <ng-container
            *ngTemplateOutlet="scorecardHeader; context: {nineIndex: nineIndex, showIndex: true, nine: nine, event: event}"></ng-container>

          <!-- player info and scores -->
          <ion-row class="scoreRow backgroundPlayer" *ngFor="let player of activePlayers">

            <!-- player info -->
            <ng-container
              *ngTemplateOutlet="playerInfo; context: {nineIndex: nineIndex, match: match, player: player, showIndex: true}"></ng-container>

            <!-- nine holes -->
            <ion-col class="holeScore" *ngFor="let hole of player.nines[nineIndex].holes; let holeIndex = index;">

              <!-- birdie or bogie -->
              <div
                [ngClass]="{ 'birdie': hole.grossScore && hole.grossScore <= hole.par - 1, 'bogey': hole.grossScore && hole.grossScore >= hole.par + 1}">

                <!-- eagle or double+ bogie -->
                <div
                  [ngClass]="{ 'eagle': hole.grossScore === hole.par - 2, 'double-bogey-plus': hole.grossScore >= hole.par + 2}">

                  <!-- gross score -->
                  <span>{{hole.grossScore===null || !hole.scoreEntered ? '&nbsp;' : hole.grossScore}}</span>

                </div>

              </div>

              <!-- if match is present then show the strokes per the match setup -->
              <ng-container *ngIf="match">

                <!-- get strokes for player and hole -->
                <ng-container
                  *ngVar="match.calcStrokesForHole(player.nines[nineIndex].holes[holeIndex], player.nines[nineIndex].holes[holeIndex].courseHoleIndex + 1); let strokes">

                  <!-- non-plus handicap -->
                  <ng-container *ngIf="strokes > 0">
                    <span class="stroke" [ngClass]="{ strokeRight: strokes >= 1 }"></span>
                    <span class="stroke" [ngClass]="{ strokeLeft: strokes >= 2 }"></span>
                  </ng-container>

                  <!-- plus handicap -->
                  <ng-container *ngIf="strokes < 0">
                    <span class="stroke strokesPlus" [ngClass]="{ strokeRight: -strokes >= 1 }"></span>
                    <span class="stroke strokesPlus" [ngClass]="{ strokeLeft: -strokes >= 2 }"></span>
                  </ng-container>

                </ng-container>

              </ng-container>

              <!-- if no match is present then show the strokes per the match setup -->
              <ng-container *ngIf="!match">

                <!-- non-plus handicap -->
                <ng-container *ngIf="player.nines[nineIndex].holes[holeIndex].strokes > 0">
                  <span class="stroke" [ngClass]="{ strokeRight: hole.strokes >= 1 }"></span>
                  <span class="stroke" [ngClass]="{ strokeLeft: hole.strokes >= 2 }"></span>
                </ng-container>

                <!-- plus handicap -->
                <ng-container *ngIf="player.nines[nineIndex].holes[holeIndex].strokes < 0">
                  <span class="stroke strokesPlus" [ngClass]="{ strokeRight: -hole.strokes >= 1 }"></span>
                  <span class="stroke strokesPlus" [ngClass]="{ strokeLeft: -hole.strokes >= 2 }"></span>
                </ng-container>

              </ng-container>

            </ion-col>

            <!-- nine hole score, only show nine hole score if event is for 18 holes -->
            <ion-col class="nineHoleScore" *ngIf="event.numberOfHoles===18">
              <span>{{player.nines[nineIndex].score === 0 ? '&nbsp;' : player.nines[nineIndex].score}}</span>
            </ion-col>

            <!-- total score -->
            <ion-col class="totalScore" *ngIf="nineIndex===event.nines.length-1">
              <span> {{player.score.totalGross === 0 ? '&nbsp;' : player.score.totalGross}}</span>
            </ion-col>

          </ion-row>

        </ion-grid>

      </div>

    </ng-container>

    <ion-note class="instructional" style="margin-top: 10px;" *ngIf="match?.handicapType===HandicapType.LowBall">
      Strokes are based on low ball of the match.
    </ion-note>

    <ion-note class="instructional" style="margin-top: 10px;"
      *ngIf="match?.handicapAllowance!==HandicapAllowance.Percent100">
      Strokes are based on the event match handicap allowance.
    </ion-note>

  </div>

</div>

<ng-template #scorecardHeader let-nineIndex="nineIndex" let-showIndex="showIndex" let-nine="nine" let-event="event">

  <!-- hole number -->
  <ion-row class="scorecardHeader scoreRow backgroundHoleNumber">

    <ion-col class="firstColumn" *ngIf="nineIndex===0">Hole</ion-col>
    <ion-col class="strokes" *ngIf="nineIndex===0 && showIndex"></ion-col>
    <ion-col *ngFor="let hole of nine.holes">{{hole.number}}</ion-col>
    <ion-col *ngIf="nineIndex===0 && event.numberOfHoles===18">Out</ion-col>
    <ion-col *ngIf="nineIndex===1 && event.numberOfHoles===18">In</ion-col>
    <ion-col class="totalScore" *ngIf="nineIndex===event.nines.length-1">Total</ion-col>

  </ion-row>

  <!-- hole par -->
  <ion-row class="scorecardHeader scoreRow backgroundPar">

    <ion-col class="firstColumn" *ngIf="nineIndex===0">Par</ion-col>
    <ion-col class="strokes" *ngIf="nineIndex===0 && showIndex"></ion-col>
    <ion-col *ngFor="let hole of nine.holes;">{{hole.par}}</ion-col>
    <ion-col *ngIf="event.numberOfHoles===18">{{nine.par}}</ion-col>
    <ion-col class="totalScore" *ngIf="nineIndex===event.nines.length-1">{{event.numberOfHoles===18 ?
      event.tee.par : nine.par}}
    </ion-col>

  </ion-row>

  <!-- hole handicap -->
  <ion-row class="scorecardHeader scoreRow backgroundHdcp">

    <ion-col class="firstColumn" *ngIf="nineIndex===0">Hdcp</ion-col>
    <ion-col class="strokes" *ngIf="nineIndex===0 && showIndex"></ion-col>
    <ion-col *ngFor="let hole of nine.holes">{{hole.hdcp}}</ion-col>
    <ion-col *ngIf="event.numberOfHoles===18">&nbsp;</ion-col>
    <ion-col class="totalScore" *ngIf="nineIndex===event.nines.length-1">&nbsp;</ion-col>

  </ion-row>

</ng-template>

<ng-template #playerInfo let-nineIndex="nineIndex" let-match="match" let-player="player" let-showIndex="showIndex">

  <ion-col class="firstColumn fadeText" *ngIf="nineIndex===0">
    {{player.lastName}},&nbsp;{{player.firstName.charAt(0)}}
  </ion-col>

  <!-- player strokes for match -->
  <ion-col class="strokes" *ngIf="nineIndex===0 && showIndex">
    <ion-text style="font-size: x-small;">{{player.teeHandicapDisplay(match)}}
    </ion-text>
  </ion-col>

</ng-template>

<ng-template #scorecard let-subMatch>

  <div style="width: fit-content; padding-right: 20px; flex: 0 0 auto;">

    <!-- sub match name -->
    <div class="ion-no-padding matchName">{{subMatch.subMatchName()}}</div>

    <!-- match config -->
    <div class="ion-no-padding matchConfig">{{subMatch.match.config()}}</div>

    <div class="scorecardContainer">

      <ion-grid class="nine">

        <!-- create scorecard header -->
        <ion-row class="scorecardHeader scoreRow backgroundHoleNumber">
          <ion-col class="firstColumn">Hole</ion-col>
          <ion-col *ngFor="let holeIndex of subMatch.holes">{{holeIndex + 1}}</ion-col>
          <ion-col class="totalScore">Total</ion-col>
        </ion-row>

        <!-- if there are any teams and the first team has an event in eventPlayerScorecard object... -->
        <ng-container
          *ngIf="subMatch.teams.length > 0 && subMatch.teams[0].eventPlayerScorecard?.eventList?.length > 0; else noscores">

          <!-- ...then iterate through teams -->
          <ng-container *ngFor="let team of subMatch.teams;">

            <ion-row>

              <ion-col size="12" style="border: 0;">

                <!-- this is the team grid -->
                <ion-grid>

                  <!-- iterate through team players -->
                  <ion-row class="scoreRow backgroundPlayer" *ngFor="let player of team.players.all;">

                    <!-- player name -->
                    <ion-col
                      class="firstColumn fadeText">{{player.lastName}},&nbsp;{{player.firstName.charAt(0)}}</ion-col>

                    <!-- interate through submatch holes and display scores -->
                    <ng-container *ngFor="let holeIndex of subMatch.holes;">

                      <!-- if game is not nines -->
                      <ng-container *ngIf="subMatch.match.game !== GameType.Nines">
                        <ion-col class="holeScore">
                          <span>{{team.eventPlayerScorecard?.eventList[0]?.players[player.scoringPlayerId]?.holes[holeIndex]
                            ?
                            team.eventPlayerScorecard.eventList[0].players[player.scoringPlayerId].holes[holeIndex].score
                            :
                            '&nbsp;'}}</span>
                        </ion-col>
                      </ng-container>

                      <!-- if game is nines -->
                      <ng-container *ngIf="subMatch.match.game === GameType.Nines">
                        <ion-col class="holeScore">
                          <span>{{team?.eventTeamScoreCard?.eventList[0]?.holes[holeIndex] ?
                            team.eventTeamScoreCard.eventList[0].holes[holeIndex].score : '&nbsp;'}}</span>
                        </ion-col>
                      </ng-container>

                    </ng-container>

                    <!-- display player total score and NOT NINES -->
                    <ng-container *ngIf="subMatch.match.game !== GameType.Nines">
                      <ion-col
                        class="totalScore">{{team.playerScorecardTotal(team.eventPlayerScorecard.eventList[0]?.players[player.scoringPlayerId])}}</ion-col>
                    </ng-container>

                    <!-- display player total score and NINES -->
                    <ng-container *ngIf="subMatch.match.game == GameType.Nines">
                      <ion-col
                        class="totalScore">{{team.teamScorecardTotal(team.eventTeamScoreCard.eventList[0]?.event)}}</ion-col>
                    </ng-container>

                  </ion-row>

                </ion-grid>

              </ion-col>

            </ion-row>

            <!-- show team score if the team has two or more players-->
            <ng-container *ngIf="team.players.all.length >= 2">

              <ion-row class="scoreRow backgroundTeamScore">

                <ion-col class="firstColumn fadeText">Team</ion-col>

                <!-- interate through holes-->
                <ng-container *ngFor="let holeIndex of subMatch.holes;">
                  <ion-col class="holeScore">
                    <span>{{team.eventTeamScoreCard.eventList[0]?.holes[holeIndex] ?
                      team.eventTeamScoreCard.eventList[0].holes[holeIndex].score :
                      '&nbsp;'}}</span>
                  </ion-col>
                </ng-container>

                <ion-col
                  class="totalScore">{{team.teamScorecardTotal(team.eventTeamScoreCard.eventList[0]?.event)}}</ion-col>

              </ion-row>

            </ng-container>

          </ng-container>

        </ng-container>

      </ion-grid>

    </div>

  </div>

  <ng-container *ngIf="subMatch.press">
    <ng-container *ngTemplateOutlet="scorecard; context: { $implicit: subMatch.press }"></ng-container>
  </ng-container>

</ng-template>

<ng-template #noscores>
  <ion-row class="scoreRow">
    <ion-col size="12" class="ion-text-left">
      <ion-note style="font-size: smaller; padding-left: 3px;">No scores have been posted for this match.</ion-note>
    </ion-col>
  </ion-row>
</ng-template>