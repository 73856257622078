import { Component, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import {
  Platform,
  ModalController,
  NavController,
  LoadingController,
  AlertController,
} from "@ionic/angular";
import { TouchID } from "@ionic-native/touch-id/ngx";
import { Storage } from "@ionic/storage";
import { AppConfig } from "./app.config";
import { AccountService } from "./app.account";
import {
  AppFunction,
  AnimateSplashService,
  ScreenSizeService,
  HelpService,
  SplashScreenType,
  DeepLinkService
} from "./app.function";
import { MaintenancePage } from "./pages/maintenance/maintenance.page";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Keyboard } from "@capacitor/keyboard";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import * as SentryAngular from "@sentry/angular-ivy";
import posthog from 'posthog-js';
import { environment } from '../environments/environment';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html"
})
export class AppComponent {
  networkConnectionModal: HTMLIonModalElement = undefined;
  maintenanceModal: HTMLIonModalElement = undefined;
  mandatoryAppUpdateModal: HTMLIonModalElement = undefined;

  constructor(
    public router: Router,
    platform: Platform,
    public accountService: AccountService,
    public appFunction: AppFunction,
    public helpService: HelpService,
    public storage: Storage,
    touchId: TouchID,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public afAuth: AngularFireAuth,
    public loadingCtrl: LoadingController,
    public alertController: AlertController,
    public animateSplashService: AnimateSplashService,
    public screenSizeService: ScreenSizeService,
    public deepLinkService: DeepLinkService
  ) {

    console.log('app.components.ts constructor');

    platform
      .ready()
      .then(() => {

        console.log('app.components.ts constructor platform.ready');

        //initialize posthog event logging
        posthog.init(environment.POSTHOG_CONFIG.writeAPIKey, { api_host: 'https://us.i.posthog.com', person_profiles: 'identified_only' });

        //save the array of promises
        const promiseArray: any[] = [];

        if (platform.is('capacitor')) {

          //open splash screen
          SplashScreen
            .hide()
            .then(() => {
              const p = animateSplashService.show();
              promiseArray.push(p);
            });

          //enable offline mode
          this.appFunction.firestore
            .enablePersistence()
            .then(() => {
              //console.log('app.components.ts constructor enablePersistence success');
            })
            .catch((err) => {
              console.log('app.components.ts constructor enablePersistence error', err);
            });

          //was the app opened via an url
          App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {

            this.appFunction
              .ngZone
              .run(async () => {

                const slug = data.url.split('.com').pop();
                if (slug.indexOf('email-link') > -1) {

                  console.log('app.components.ts constructor appUrlOpen looks like we have an email link...', data);

                  animateSplashService.show(SplashScreenType.Spinner, 'Signing in...');

                  // User opened the link on a different device. To prevent session fixation
                  // attacks, ask the user to provide the associated email again. For example:
                  const email = await this.storage.get('email');
                  if (!email) {
                    // TODO: handle this somehow...
                  }

                  //the client SDK will parse the code from the link for you.
                  const self = this;
                  this.afAuth
                    .signInWithEmailLink(email, data.url)
                    .catch(async function (error) {

                      // Some error occurred, you can inspect the code: error.code
                      // Common errors could be invalid email and invalid or expired OTPs.
                      //console.error(error);

                      if (error.code === "auth/invalid-action-code") {

                        console.error('redirecting to login...');

                        // this catches links that are invalid, expired, or have already been used
                        // TODO: #2 figure out why this isn't navigating
                        const alert = await self.alertController.create({
                          header: 'Please try again',
                          message: 'We had a problem logging you in. The link may have expired or already been used.',
                          buttons: ['OK'],
                        });

                        await alert.present();

                        self.navCtrl
                          .navigateRoot('/login')
                          .catch((err) => {
                            console.log('app.components.ts constructor appUrlOpen signInWithEmailLink error', err);
                          });

                      }

                    });

                } else if (slug.indexOf('login') > -1) { //what does this do?

                  this.navCtrl
                    .navigateRoot('/login')
                    .catch((err) => {
                      console.log('app.components.ts constructor appUrlOpen navigateRoot(/login) error', err);
                    });

                }

              });

          });

          //disallow landscape. Need this here because if the app is close while allowing landscape it will reopen allowing lanscape
          this.appFunction.screenLock({ orientation: 'portrait-primary' });

        } else {
          const p = animateSplashService.show();
          promiseArray.push(p);
        }

        //set initial screen size
        this.screenSizeService.onResize(platform.width());

        //#region start up parameters

        //touch id
        if (platform.is('capacitor') && platform.is('ios')) {

          const p = touchId
            .isAvailable()
            .then(() => {
              //console.log('app.component.js touchID.isAvailable success');
              accountService.isTouchIdAvailable = true;
            })
            .catch((err) => {
              console.log('app.component.js touchID.isAvailable error', JSON.stringify(err));
            });
          promiseArray.push(p);

          /* did user activate touch id */
          const q = storage
            .get(AppConfig.TOUCH_ID_ACTIVE)
            .then((value) => {

              //true else false (or null, undefined)
              accountService.isTouchId = value ? true : false;

            })
            .catch((err) => {
              console.log('app.component.js storage.get(AppConfig.TOUCH_ID_ACTIVE) error', JSON.stringify(err));
            });
          promiseArray.push(q);

        }

        //is this the first install on this device
        const r = storage
          .get(AppConfig.FIRST_TIME)
          .then((value) => {

            //if true (or null) then true else false
            accountService.isFirstTime = value === null ? true : value ? true : false;
            //console.log('app.component.js storage.get(AppConfig.FIRST_TIME) success', this.isFirstTime);
          })
          .catch((err) => {
            console.log(
              "app.compenent.js storage.get(firstTime) error",
              JSON.stringify(err)
            );
          });
        promiseArray.push(r);

        //get remote configurations
        const t = appFunction.getRemoteConfig();
        promiseArray.push(t);

        //get help and whatsNew configurations
        const u = this.helpService.getHelpTopics();
        promiseArray.push(u);

        //#endregion start up parameters

        //********** main bootstrap of application **********
        Promise
          .all(promiseArray)
          .then(() => {

            //configure the keyboard
            if (appFunction.platform.is('capacitor')) {

              if (appFunction.platform.is('ios')) {
                Keyboard.setAccessoryBarVisible({ isVisible: true });
              }

              //this hides elements when the keyboard is visible
              Keyboard.addListener('keyboardWillShow', () => {
                document.body.classList.add('keyboard-is-open');
              });

              //this re-displays elements when the keyboard is not visible
              Keyboard.addListener('keyboardWillHide', () => {
                document.body.classList.remove('keyboard-is-open');
              });

            }

            //start listening for deeplinks
            this.deepLinkService.listenForDeepLinks();

            //call this to see if there is an authenticated user
            accountService
              .currentUser()
              .then(async (user) => {
                //const token: firebase.auth.IdTokenResult = await user.getIdTokenResult(false);
                //console.log('app.component.ts AppComponent currentUser', token.token);

                //log user in...this is where all the magic happens
                accountService
                  .authStateChange()
                  .then(() => {
                    if (appFunction.platform.is('capacitor')) {
                      StatusBar.setStyle({ style: Style.Light });
                    }
                  });

              });

          })
          .catch((err) => {
            console.log('app.component.ts ready promise all error', JSON.stringify(err));
          });

        /* listen to remote configuration changes */
        appFunction.remoteConfigurations.subscribe((configurations) => {

          //config update
          console.log('app.component.ts appFunction.configurations', configurations);

          //set firebase log level
          appFunction.setFirebaseLogLevel(configurations.firebaseLogLevel || 'silent');

          //show maintenance modal if not already opened
          if (configurations.maintenance && !this.maintenanceModal) {

            appFunction.modalCtrl
              .create({
                component: MaintenancePage,
                backdropDismiss: false,
                componentProps: {
                  message: configurations.maintenanceMessage,
                },
              })
              .then((modal) => {

                //save modal reference
                this.maintenanceModal = modal;

                modal.present()
                  .catch((err) => {
                    console.log(
                      "app.component.ts appFunction.configurations MaintenancePage modal present error",
                      err
                    );
                  });

              })
              .catch((err) => {
                console.log(
                  "app.component.ts appFunction.configurations MaintenancePage modal create error",
                  err
                );
              });
          } else if (!configurations.maintenance && this.maintenanceModal) {
            //close maintenance modal if already opened
            this.maintenanceModal.dismiss();
            this.maintenanceModal = undefined;
          }
        });

        platform.pause.subscribe(() => {
          console.log("app.component.ts: you are in the background");
        });

        platform.resume.subscribe(() => {
          console.log("app.component.ts: you have resumed");
        });

      })
      .catch((err) => {
        console.log('app.component.ts platform ready error', JSON.stringify(err));
        SentryAngular.captureException(err, {
          tags: {
            method: 'app.component.ts platform ready error'
          }
        });
      });
  }

  //listen for ongoing screen size changes
  @HostListener("window:resize", ["$event"])
  private onResize(event) {
    this.screenSizeService.onResize(event.target.innerWidth);
  }

}
