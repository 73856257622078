<ion-header class="deeplink-welcome-message-header ion-no-border">

  <ion-toolbar>

  </ion-toolbar>

</ion-header>

<ion-content class="deeplink-welcome-message-content">

  <img class="auth-image" src="../../../assets/images/logowhitebackground.png" draggable="false">

  <h2 class="welcome-title">We're glad you're here!</h2>

  <ion-list lines="none">
    <ion-item>
      <h6 class="welcome-message" [innerHTML]="deepLinkParms.welcomeMessage"></h6>
    </ion-item>
    <ion-item>
      <h6 class="welcome-message">Before we get going, tap <b>Let's Go</b> below to {{ message }}.</h6>
    </ion-item>
  </ion-list>

  <ion-toolbar>
    <ion-button expand="block" (click)="done()">Let's Go</ion-button>
  </ion-toolbar>

</ion-content>