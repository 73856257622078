import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AvatarComponent } from './avatar/avatar.component';
import { AnimatedLikeComponent } from './animated-like/animated-like.component';
import { ScrollAutoPlayComponent } from './scroll-auto-play/scroll-auto-play.component';
import { OfflineHeroComponent } from './offline-hero/offline-hero.component';
import { ValidationHeroComponent } from './validation-hero/validation-hero.component';
import { HeaderHelpComponent } from './header-help/header-help.component';
import { EventPlayerScoreComponent } from './event-player-score/event-player-score.component';
import { TeeComponent } from './tee/tee.component';
import { EventMatchLiveViewComponent } from './event-match-live-view/event-match-live-view.component';
import { TripMatchLiveViewComponent } from './trip-match-live-view/trip-match-live-view.component';
import { MatchViewComponent } from './match-view/match-view.component';
import { MatchEditComponent } from './match-edit/match-edit.component';
import { RoundSegmentComponent } from './round-segment/round-segment.component';
import { GroupTripCardComponent } from './group-trip-card/group-trip-card.component';
import { GroupEventCardComponent } from './group-event-card/group-event-card.component';
import { EventCardComponent } from './event-card/event-card.component';
import { PostCardComponent } from './post-card/post-card.component';
import { TripStatusComponent } from './trip-status/trip-status.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from './../directives/directives.module';
import { PipesModule } from './../pipes/pipes.module';
import { MomentModule } from 'ngx-moment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChatBubbleComponent } from './chat-bubble/chat-bubble.component';
import { ChatBotComponent } from './chat-bot/chat-bot.component';

@NgModule({
	declarations: [
		AvatarComponent,
		AnimatedLikeComponent,
		ScrollAutoPlayComponent,
		ValidationHeroComponent,
		EventPlayerScoreComponent,
		MatchViewComponent,
		MatchEditComponent,
		EventMatchLiveViewComponent,
		TripMatchLiveViewComponent,
		OfflineHeroComponent,
		TeeComponent,
		HeaderHelpComponent,
		RoundSegmentComponent,
		GroupTripCardComponent,
		GroupEventCardComponent,
		EventCardComponent,
		PostCardComponent,
		TripStatusComponent,
		ChatBubbleComponent,
		ChatBotComponent,
	],
	exports: [
		AvatarComponent,
		AnimatedLikeComponent,
		ScrollAutoPlayComponent,
		ValidationHeroComponent,
		EventPlayerScoreComponent,
		CommonModule,
		MatchViewComponent,
		MatchEditComponent,
		FormsModule,
		ReactiveFormsModule,
		EventMatchLiveViewComponent,
		TripMatchLiveViewComponent,
		OfflineHeroComponent,
		TeeComponent,
		HeaderHelpComponent,
		RoundSegmentComponent,
		GroupTripCardComponent,
		GroupEventCardComponent,
		EventCardComponent,
		PostCardComponent,
		TripStatusComponent,
		ChatBubbleComponent,
		ChatBotComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		IonicModule,
		DirectivesModule,
		MomentModule,
		PipesModule,
		FontAwesomeModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
