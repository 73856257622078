import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppFunction } from '../../app.function';

@Component({
  selector: 'preferences',
  templateUrl: './preferences.page.html',
  styleUrls: ['./preferences.page.scss'],
})
export class PreferencesPage implements OnInit {

  title: string;
  value: number;
  preferences: any;
  parentUrl: string;

  constructor(
    public navParams: NavParams,
    public appFunction: AppFunction) {
  }

  ngOnInit() {

    console.log('preferences.ts ngOnInit');

    //get screen title
    this.title = this.navParams.get('title');

    //get current value
    this.value = this.navParams.get('value');

    //get list of options
    this.preferences = this.navParams.get('preferences');

  }

  done(value) {
    this.appFunction.modalCtrl.dismiss({ value: value });
  }

  back() {
    this.appFunction.modalCtrl.dismiss({ value: this.value });
  }

}
