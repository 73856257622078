import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { sanitizeUrl } from './sanitizeUrl/sanitizeUrl.pipe';

@NgModule({
	declarations: [
		sanitizeUrl
	],
	imports: [
	],
	exports: [
		sanitizeUrl
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PipesModule { }
