import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppFunction } from '../../app.function';
import { AppMatch, MatchPlayerI } from 'src/app/app.match';

@Component({
  selector: 'match-player-search',
  templateUrl: './match-player-search.page.html',
  styleUrls: ['./match-player-search.page.scss'],
})
export class MatchPlayerSearchPage implements OnInit {

  match: AppMatch;
  private _filteredMembersCriteria: string = '';
  private _selectedPlayers: MatchPlayerI[] = [];

  constructor(
    public appFunction: AppFunction,
    public navParams: NavParams) {
  }

  ngOnInit() {
    this.match = this.navParams.get('match');
  }

  searchCriteriaChange(event: any) {

    //only perform a search if the user has entered criteria
    if (event.target.value && event.target.value.length > 0) {
      this._filteredMembersCriteria = event.target.value;
    } else {
      this._filteredMembersCriteria = '';
    }

  }

  searchCriteriaClear() {
    this._filteredMembersCriteria = '';
  }

  get filteredPlayers(): MatchPlayerI[] {

    //if there is search criteria then filter
    if (this._filteredMembersCriteria.length > 0) {

      return (<MatchPlayerI[]>this.match.parent.players.active.withTeeTime).filter((player) => {

        //combine first, last and email together lower case
        const searchIn: string = player.firstName.toLowerCase() + player.lastName.toLowerCase() + player.email.toLowerCase();

        //perform search
        return (searchIn.indexOf(this._filteredMembersCriteria.toLowerCase().replace(' ', '')) >= 0);

      });

    } else {
      return this.match.parent.players.active.withTeeTime;
    }

  }

  addPlayerToMatch(player: MatchPlayerI) {
    this._selectedPlayers.push(player);
  }

  isPlayerInMatch(player: MatchPlayerI) {

    const newlySelectedPlayer: boolean = this._selectedPlayers
      .some((matchPlayer) => {
        return matchPlayer.id === player.id;
      });

    const previouslySelectedPlayer: boolean = this.match.players
      .some((matchPlayer) => {
        return matchPlayer.id === player.id;
      });

    //if player was either previsouly or newly selected then return true
    return newlySelectedPlayer || previouslySelectedPlayer;

  }

  done() {
    this.appFunction.modalCtrl.dismiss({ selectedPlayers: this._selectedPlayers });
  }

}