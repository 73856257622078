import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavParams } from '@ionic/angular';
import { AccountService } from '../../app.account';
import { ClubService, AppCourse, AppTee } from '../../app.club';
import { AppFunction } from '../../app.function';

@Component({
  selector: 'course-scorecard',
  templateUrl: './course-scorecard.page.html',
  styleUrls: ['./course-scorecard.page.scss']
})
export class CourseScorecardPage implements OnInit, OnChanges {

  course: AppCourse;
  courseForm: FormGroup;
  copyTee: AppTee;

  constructor(
    public accountService: AccountService,
    public builder: FormBuilder,
    public clubService: ClubService,
    public appFunction: AppFunction,
    public navParams: NavParams) {

    //console.log('course-scorecard.page.ts constructor');

  }

  ngOnInit() {

    //console.log('course-scorecard.page.ts ngOnInit');

    //create group class
    this.course = <AppCourse>this.navParams.get('course');

    //build form group
    this.courseForm = this.builder.group({
    });

    //
    this.courseForm.markAsPristine();

  }

  update() {
    //console.log('course-scorecard.page.ts update');
    this.courseForm.markAsDirty();
  }

  updatePar(tee: AppTee) {

    //console.log('course-scorecard.page.ts updatePar');

    var parTotal: number = 0;
    tee
      .nines
      .forEach((nine) => {

        nine
          .holes
          .forEach((hole) => {
            parTotal = parTotal + hole.par || 0;
          });

      });

    tee.par = parTotal;

    this.courseForm.markAsDirty();

  }

  updateDistance(tee: AppTee) {

    //console.log('course-scorecard.page.ts updateDistance');

    var distanceTotal: number = 0;
    tee
      .nines
      .forEach((nine) => {

        nine
          .holes
          .forEach((hole) => {
            distanceTotal = distanceTotal + hole.distance || 0;
          });

      });

    tee.distance = distanceTotal;

    this.courseForm.markAsDirty();

  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    console.log('course-scorecard.page.ts ngOnChanges');
  }

  copyHdcpPar(tee: AppTee) {
    this.copyTee = tee;
  }

  pasteHdcpPar(pasteTee: AppTee) {

    pasteTee
      .nines
      .forEach((nine, nineIndex) => {

        nine
          .holes
          .forEach((hole, holeIndex) => {

            hole.hdcp = this.copyTee.nines[nineIndex].holes[holeIndex].hdcp;
            hole.par = this.copyTee.nines[nineIndex].holes[holeIndex].par;

          });

      });

  }

  done() {

    //console.log('course-scorecard.page.ts done');

    if (this.courseForm.dirty) {

      //save the form if valid 
      if (this.courseForm.valid) {

        //console.log('course-details.page.ts done valid');

        this.appFunction
          .loadingCtrl
          .create({ message: 'Saving course...' })
          .then((loading) => {

            loading.present();
            const promiseArray: any[] = [];

            //save 
            this.course
              .tees
              .forEach((tee) => {
                const p = tee.save();
                promiseArray.push(p);
              });

            Promise
              .all(promiseArray)
              .then(() => {

                loading.dismiss();

                //close this page
                this.appFunction
                  .modalCtrl
                  .dismiss({ course: this.course }, 'select');

              });

          });

      };

    } else {

      //close this page
      this.appFunction
        .modalCtrl
        .dismiss()
        .catch((err) => {
          console.log('course-scorecard.page.ts back modal dismiss error', err);
        });

    }

  }

  back() {

    //confirm that user wants to discard changes
    if (this.courseForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your selected course. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {

                //close this page
                this.appFunction
                  .modalCtrl
                  .dismiss();

              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {

      //close this page
      this.appFunction
        .modalCtrl
        .dismiss();

    }

  }

}
