<ion-card id="post{{post.id}}" style="--background: white;" class="customCard">

  <!-- header -->
  <ion-item lines="none" style="--background: white; padding-top: 10px;">

    <!-- member avatar -->
    <avatar [member]="post.member" slot="start" *ngIf="post.member"></avatar>

    <!-- group avatar -->
    <ion-avatar slot="start" class="avatar-top" *ngIf="post.group">
      <img #imgAvatar [imgLoader]="post.group.avatar.URI" (click)="appFunction.showAvatarView($event, imgAvatar)"
        draggable="false">
    </ion-avatar>

    <ion-label>
      <h2 *ngIf="post.member">{{post.member.firstName}} {{post.member.lastName}}</h2>
      <h2 *ngIf="post.group">{{post.group.name}}</h2>
      <p *ngIf="post.group">by {{post.createdMember?.firstName}} {{post.createdMember?.lastName}}</p>
      <p>{{post.getFromNow}}</p>
    </ion-label>

  </ion-item>

  <!-- media -->
  <ion-slides [pager]="post.media.length > 1" class="postSlides">

    <ion-slide *ngFor="let fileURI of post.media; let i = index">

      <img [src]="fileURI | sanitizeUrl" *ngIf="mediaService.isExtension(fileURI, ['jpeg', 'jpg', 'png'])"
        draggable="false">

      <scroll-auto-play [src]="fileURI + '#t=0.1' | sanitizeUrl" [content]="content"
        *ngIf="mediaService.isExtension(fileURI, ['mov', 'mp4'])">
      </scroll-auto-play>

    </ion-slide>

  </ion-slides>

  <!-- post text -->
  <ion-card-content (click)="comments(post)">
    <!-- be sure to keep this on one line. otherwise spaces will be added to the beginning of the post.text -->
    <div class="preserveFormat">{{post.text}}</div>
  </ion-card-content>

  <!-- like, comment, delete actions -->
  <ion-grid>

    <ion-row>

      <ion-col size="2.5">
        <ion-note (click)="likePost(post)" class="iconTextSingleLine">
          <animated-like [liked]="post.memberLike"></animated-like>
          <ion-text>{{post.totalLikes}}&nbsp;like</ion-text>
          <ion-text *ngIf="post.totalLikes !== 1">s</ion-text>
        </ion-note>
      </ion-col>

      <ion-col size="6">
        <ion-note (click)="comments(post)" class="iconTextSingleLine">
          <ion-icon name="chatbubbles"></ion-icon>
          <ion-text>{{post.totalComments}}&nbsp;comment</ion-text>
          <ion-text *ngIf="post.totalComments !== 1">s</ion-text>
        </ion-note>
      </ion-col>

      <ion-col style="margin-right: 10px;">
        <ion-icon name="ellipsis-horizontal" *ngIf="post.createdMemberId === accountService.member.id"
          (click)="postActionConfirm(post)" style="font-size: 20px; float: right;"></ion-icon>
      </ion-col>

    </ion-row>
  </ion-grid>

</ion-card>