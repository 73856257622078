import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from './../../components/components.module';
import { DirectivesModule } from './../../directives/directives.module';
import { EventViewPage } from './event-view.page';

const routes: Routes = [
  {
    path: '',
    component: EventViewPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    MomentModule,
    ComponentsModule,
    DirectivesModule
  ],
  declarations: [EventViewPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventViewPageModule { }
