<ion-header class="event-scoring-header ion-no-border">

  <ion-toolbar>

    <ion-title>Live Scoring</ion-title>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>

    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" [screenKey]="screenKey">
        <ion-icon name="help-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content class="event-scoring-content">

  <!-- segment -->
  <div class="ion-padding">
    <ion-segment [(ngModel)]="eventScoringSegment" (ionChange)="eventScoringSegmentChange($event)">
      <ion-segment-button [value]="EventScoringSegment.Scoring">
        <ion-label>Scoring</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="EventScoringSegment.Leaderboard">
        <ion-label>Leaderboards</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="EventScoringSegment.Matches">
        <ion-label>Matches</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>

  <div *ngIf="eventScoringSegment === EventScoringSegment.Scoring">

    <!-- if event is today -->
    <ng-container *ngIf="event.isEventTodayOrPast, else eventNotToday">

      <!-- header list -->
      <ion-list lines="full">

        <!-- scoring NOT ACTIVE: display intro hero -->
        <ion-item lines="full" *ngIf="event.players.active.teeTimeScoringMode===ScoringMode.ScoringNotStarted">

          <ion-grid class="ion-no-padding">

            <ion-row class="ion-no-padding">

              <ion-col class="ion-no-padding">
                <h2 style="color: var(--ion-color-primary)">
                  Welcome to live scoring!
                </h2>
              </ion-col>

            </ion-row>

            <ion-row class="ion-no-padding">
              <ion-col class="ion-no-padding" style="padding-bottom: 10px">
                <ion-text>To get started please confirm each player's handicap index and tee. Then tap on <fa-icon
                    [icon]="faUserCheck" style="color: var(--ion-color-primary);"></fa-icon> to save.</ion-text>
              </ion-col>
            </ion-row>

          </ion-grid>

        </ion-item>

        <!-- scoring ACTIVE: display hole navigator -->
        <ng-container *ngIf="event.players.active.teeTimeScoringMode===ScoringMode.ScoringActive">

          <ion-item lines="full" style="--padding-start: 10px;">

            <ion-grid class="counter ion-no-padding">

              <!-- hole navigation -->
              <ion-row class="ion-no-padding ion-text-nowrap ion-align-items-center">

                <!-- previous hole button -->
                <ion-col class="ion-text-center ion-no-padding" size="3">
                  <ion-button shape="round" color="primary" style="height: 60px; width: 60px" haptic
                    [disabled]="currentCourseHoleIndex === startingHoleIndex" (click)="decrementHole()">
                    <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
                  </ion-button>
                </ion-col>

                <!-- current hole number -->
                <ion-col class="ion-text-center ion-no-padding"
                  (click)="openHoleSelector(holeSelectorModal, 'Please select a hole to view...')" size="6">
                  <ion-label color="primary" style="font-size: 40px; margin-top: 0px">Hole
                    {{event.nines[nineIndex].holes[holeIndex].number}}</ion-label>
                  <p color="secondary" style="font-size: 18px; margin-top: -5px; margin-bottom: 5px">
                    {{event.nines[nineIndex].holes[holeIndex].distance}} yds, par
                    {{event.nines[nineIndex].holes[holeIndex].par}}, hdcp
                    {{event.nines[nineIndex].holes[holeIndex].hdcp}}
                  </p>
                </ion-col>

                <!-- next hole button -->
                <ion-col class="ion-text-center ion-no-padding" *ngIf="currentCourseHoleIndex !== finishingHoleIndex"
                  size="3">
                  <ion-button shape="round" color="primary" style="height: 60px; width: 60px" (click)="incrementHole()"
                    haptic>
                    <ion-icon slot="icon-only" name="chevron-forward"></ion-icon>
                  </ion-button>
                </ion-col>

                <!-- finish button -->
                <ion-col class="ion-text-center ion-no-padding" *ngIf="currentCourseHoleIndex === finishingHoleIndex">
                  <ion-button shape="round" color="primary" style="height: 60px; width: 60px" (click)="incrementHole()"
                    haptic>
                    <ion-text>Finish<br />Round</ion-text>
                  </ion-button>
                </ion-col>

              </ion-row>

            </ion-grid>

          </ion-item>

          <!-- if there are any matches then display match swiper -->
          <ion-item *ngIf="loggedInPlayerMatches.length" class="ion-no-padding matchSwiperItem">

            <div class="swiper matchSwiper" (activeIndexChange)="matchSlideChange($event)">

              <div class="swiper-wrapper">

                <div class="swiper-slide" *ngFor="let match of loggedInPlayerMatches;">

                  <ion-label class="match">
                    <h2>{{match.getGame(match.game).name}}</h2>
                    <ion-icon src="../../../assets/icons/score.svg"
                      (click)="openScorecard(match.players, match)"></ion-icon>
                  </ion-label>

                  <ion-label class="matchConfig">
                    <h6 style="float: left; margin-left: 10px;">{{match.config()}}</h6>
                  </ion-label>

                </div>

              </div>

              <!-- pagination -->
              <div class="swiper-pagination" *ngIf="loggedInPlayerMatches.length > 1"></div>

            </div>

          </ion-item>

        </ng-container>

        <!-- scoring COMPLETE: display outro hero -->
        <ion-item lines="full" *ngIf="event.players.active.teeTimeScoringMode===ScoringMode.ScoringComplete">
          <h2 style="color: var(--ion-color-primary)">Scoring complete</h2>
        </ion-item>

      </ion-list>

      <!-- player list -->
      <ion-list class="itemLines" lines="full">

        <!-- tee time players: all scoring modes -->
        <ion-item player style="--padding-start: 0; --inner-padding-end: 0;"
          *ngFor="let player of event.players.active.teeTime; let i = index;">

          <ion-list class="playerInnerList" style="width: 100%;">

            <!-- player avatar, name, match strokes, and stats -->
            <ion-item style="--padding-top: 5px;" lines="none">

              <avatar [member]="player.member" slot="start"></avatar>

              <!-- player info -->
              <ion-label class="ion-align-self-start" (click)="openPlayerStats(player);"
                style="padding-top: 10px !important; margin-top: 0px !important; margin-bottom: 0px !important;">

                <!-- player info -->
                <h2 class="playerName">{{player.firstName}} {{player.lastName}}

                  <!-- show strokes if there are NO MATCHES -->
                  <ion-text class="textSmall" *ngIf="player.nines[nineIndex].holes[holeIndex].strokes && !currentMatch">
                    (<ion-text *ngIf="player.nines[nineIndex].holes[holeIndex].strokes < 0">
                      +
                    </ion-text>
                    <ion-text class="strokes"
                      *ngFor="let item of [].constructor(Math.abs(player.nines[nineIndex].holes[holeIndex].strokes));">
                    </ion-text>)
                  </ion-text>

                  <!-- show strokes for the CURRENT MATCH - determine if player is in match -->
                  <ng-container [ngSwitch]="currentMatch?.isPlayerInMatch(player.id)">

                    <!-- player is in match -->
                    <ng-container *ngSwitchCase="true">

                      <!-- get strokes -->
                      <ng-container
                        *ngVar="currentMatch?.calcStrokesForHole(player.nines[nineIndex].holes[holeIndex], currentCourseHoleIndex + 1); let strokes">

                        <ion-text class="textSmall" *ngIf="strokes">
                          (
                          <ion-text *ngIf="strokes < 0">+</ion-text>
                          <ion-text class="strokes" *ngFor="let item of [].constructor(Math.abs(strokes));">
                          </ion-text>
                          )
                        </ion-text>

                      </ng-container>

                    </ng-container>

                    <!-- player is NOT in match -->
                    <ng-container *ngSwitchCase="false">
                      <ion-text class="textSmall">
                        (not in match)
                      </ion-text>
                    </ng-container>

                  </ng-container>

                </h2>

                <!-- display tee handicap if in info confirmed mode -->
                <ion-text class="textSmall" *ngIf="!player.infoConfirmed">
                  <ion-note>Handicap: {{player.teeHandicapDisplay()}}</ion-note>
                </ion-text>

                <!-- display hole-by-hole player stats if tee time in scoring active mode -->
                <div *ngIf="event.players.active.teeTimeScoringMode === ScoringMode.ScoringActive">

                  <!-- fairway -->
                  <ion-text class="textSmall" *ngIf="player.nines[nineIndex].holes[holeIndex].fairway"
                    style="margin-right: 5px">
                    <ion-note>Frwy:</ion-note>
                    <ion-note class="playerStats"
                      [ngClass]="{ 'playerStatsOn': player.nines[nineIndex].holes[holeIndex].fairway === FairwayHit.Yes, 'playerStatsOff' : player.nines[nineIndex].holes[holeIndex].fairway !== FairwayHit.Yes }">
                    </ion-note>
                  </ion-text>

                  <!-- gir -->
                  <ion-text class="textSmall" *ngIf="player.nines[nineIndex].holes[holeIndex].gir"
                    style="margin-right: 5px">
                    <ion-note>GIR:</ion-note>
                    <ion-note class="playerStats"
                      [ngClass]="{ 'playerStatsOn': player.nines[nineIndex].holes[holeIndex].gir===GreenInRegulation.Yes, 'playerStatsOff' : player.nines[nineIndex].holes[holeIndex].gir!==GreenInRegulation.Yes }">
                    </ion-note>
                  </ion-text>

                  <!-- putts -->
                  <ion-text class="textSmall"
                    *ngIf="appFunction.isNumeric(player.nines[nineIndex].holes[holeIndex].putts)">
                    <ion-note style="margin-right: 5px">Putts:</ion-note>
                    <ion-note>{{player.nines[nineIndex].holes[holeIndex].putts}}</ion-note>
                  </ion-text>

                  <!-- if there are no stats then... -->
                  <ion-text class="textSmall"
                    *ngIf="!player.nines[nineIndex].holes[holeIndex].fairway && !player.nines[nineIndex].holes[holeIndex].gir && !appFunction.isNumeric(player.nines[nineIndex].holes[holeIndex].putts)">
                    <ion-note>Tap here for hole statistics</ion-note>
                  </ion-text>

                </div>

                <!-- display player stats summary if in scoring complete mode -->
                <ng-container *ngIf="player.scoringMode === ScoringMode.ScoringComplete">

                  <!-- fairway -->
                  <ion-text class="textSmall" style="margin-right: 5px">
                    <ion-note>Frwy: {{player.statistics.fairwaysHit}}</ion-note>
                  </ion-text>

                  <!-- gir -->
                  <ion-text class="textSmall" style="margin-right: 5px">
                    <ion-note>GIR: {{player.statistics.gir}}</ion-note>
                  </ion-text>

                  <!-- putts -->
                  <ion-text>
                    <ion-note class="textSmall">Putts: {{player.statistics.putts}}</ion-note>
                  </ion-text>

                </ng-container>

              </ion-label>

              <fa-icon [icon]="player.infoConfirmed && !player.dynamicData.playerInfoSaving ? faUserPen : faUserCheck"
                class="fa-flip"
                style="font-size: 27px; padding-right: 0px; color: var(--ion-color-primary); margin-right: 5px; margin-top: -15px;"
                [id]="'confirm' + player.id" slot="end" [ngClass]="{ 'fa-flip': player.dynamicData.playerInfoSaving }"
                [ngStyle]="{'color': player.infoConfirmed && !player.dynamicData.playerInfoSaving ? 'var(--ion-color-white-d)' : 'var(--ion-color-primary)' }"
                (click)="openPlayerInfo($event, player, currentCourseHoleIndex);"></fa-icon>

            </ion-item>

            <!-- scoring state -->
            <ng-container [ngSwitch]="true">

              <!-- if player info IS NOT confirmed, show index and tee drop doiwn -->
              <ng-container *ngSwitchCase="!player.infoConfirmed">

                <!-- handicap Index -->
                <ion-item lines="inset">
                  <ion-label>
                    <ion-text class="labelPositionStacked">Handicap Index</ion-text>
                    <ion-grid class="counter">
                      <ion-row class="ion-no-padding">
                        <ion-col class="ion-text-center ion-no-padding">
                          <ion-button class="bigStep" shape="round" color="secondary" haptic [disabled]="false"
                            (click)="updateHandicapIndex(player, -1)">
                            -1
                          </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center ion-no-padding">
                          <ion-button class="smallStep" shape="round" color="secondary" haptic [disabled]="false"
                            (click)="updateHandicapIndex(player, -0.1)">
                            -0.1
                          </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center ion-no-padding">
                          <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                            {{player.handicapIndex}}</ion-label>
                        </ion-col>
                        <ion-col class="ion-text-center ion-no-padding">
                          <ion-button class="smallStep" shape="round" color="secondary" haptic [disabled]="false"
                            (click)="updateHandicapIndex(player, 0.1)">
                            +0.1
                          </ion-button>
                        </ion-col>
                        <ion-col class="ion-text-center ion-no-padding">
                          <ion-button class="bigStep" shape="round" color="secondary" haptic [disabled]="false"
                            (click)="updateHandicapIndex(player, 1)">
                            +1
                          </ion-button>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-label>
                </ion-item>

                <!-- tees -->
                <ion-item class="accordionItem" lines="full">
                  <ion-label position="stacked">Tees</ion-label>
                  <ion-accordion-group #accordionTee>
                    <ion-accordion>
                      <ion-item slot="header">
                        <ion-label>
                          <h2 style="overflow: visible">
                            <tee [tee]="player.tee" [nineHolesOnlyIndex]="event.nineHolesOnlyIndex">
                            </tee>
                          </h2>
                        </ion-label>
                      </ion-item>
                      <div slot="content" class="lineSeparatorTop">
                        <tee [tee]="tee" [nineHolesOnlyIndex]="event.nineHolesOnlyIndex"
                          *ngFor="let tee of player.event.course.tees"
                          (click)="selectCourseTee(player, tee); accordionTee.value = undefined;"></tee>
                      </div>
                    </ion-accordion>
                  </ion-accordion-group>
                </ion-item>

              </ng-container>

              <!-- if player info IS confirmed and scoring is NOT complete, show  -->
              <ion-item *ngSwitchCase="player.infoConfirmed && player.scoringMode !== ScoringMode.ScoringComplete"
                lines="full">
                <event-player-score [player]="player" [nineIndex]="nineIndex" [holeIndex]="holeIndex">
                </event-player-score>
              </ion-item>

              <!-- if player info IS confirmed and scoring IS complete -->
              <ion-item *ngSwitchCase="player.infoConfirmed && player.scoringMode === ScoringMode.ScoringComplete">

                <ion-label>

                  <!-- gross/net total score -->
                  <ion-grid class="ion-no-padding">

                    <!-- header -->
                    <ion-row class="ion-no-padding">
                      <ion-col class="ion-text-center">Out</ion-col>
                      <ion-col class="ion-text-center" *ngIf="player.event.numberOfHoles===18">In</ion-col>
                      <ion-col class="ion-text-center" *ngIf="player.event.numberOfHoles===18">Total</ion-col>
                    </ion-row>

                    <!-- out/in/total scores -->
                    <ion-row class="ion-no-padding">

                      <ion-col class="ion-text-center">
                        <div class="score">
                          {{player.score.outGross}}/<span class="net">{{player.score.outNet}}</span>
                        </div>
                      </ion-col>

                      <ion-col class="ion-text-center" *ngIf="player.event.numberOfHoles===18">
                        <div class="score">
                          {{player.score.inGross}}/<span class="net">{{player.score.inNet}}</span>
                        </div>
                      </ion-col>

                      <ion-col class="ion-text-center" *ngIf="player.event.numberOfHoles===18">
                        <div class="score">
                          {{player.score.totalGross}}/<span class="net">{{player.score.totalNet}}</span>
                        </div>
                      </ion-col>

                    </ion-row>

                    <!-- show hole scores for player -->
                    <ion-row class="ion-no-padding" style="padding-top: 10px;">
                      <ion-col>
                        <ion-button expand="block" shape="round" color="secondary"
                          (click)="setScoreListPlayer(playerScoreListModal, player)">View/Update Scores
                        </ion-button>
                      </ion-col>
                    </ion-row>

                  </ion-grid>

                </ion-label>

              </ion-item>

            </ng-container>

          </ion-list>

        </ion-item>

      </ion-list>

    </ng-container>

    <!-- if event isn't today -->
    <ng-template #eventNotToday>

      <ion-list lines="full">

        <ion-item lines="none">
          <h2 style="color: var(--ion-color-primary)">
            Welcome to live scoring!
          </h2>
        </ion-item>

        <ion-item lines="none">
          <ion-text>Live scoring will be available the day of the event at which time you will be able do the
            following...</ion-text>
        </ion-item>

        <ion-item lines="none">
          <ul style="padding-left: 20px;">
            <li>Confirm the tee and handicap index of each player in your tee time</li>
            <li>Hole-by-hole enter the scores of your foursome</li>
            <li>See the leaderboards update in realtime</li>
            <li>See the Event Match and Member Matches update in realtime (you can challenge other Event players right
              now
              though!)</li>
          </ul>
        </ion-item>

      </ion-list>

    </ng-template>

  </div>

  <div *ngIf="eventScoringSegment === EventScoringSegment.Leaderboard">

    <!-- event is today OR logged in member is a sys admin -->
    <ng-container
      *ngIf="(accountService.member.admin || (event.isEventTodayOrPast && event.players.active.scoringMode !== ScoringMode.ScoringNotStarted)), else eventScoringNotStarted">

      <ion-slides #leaderboardSlides pager="true" [options]="slideOpts" class="leaderboardSlides">

        <!-- net score -->
        <ion-slide>

          <ion-list style="width: 100%">

            <ion-item-group>

              <!-- header -->
              <ion-item-divider>

                <ion-grid>

                  <ion-row class="ion-align-items-center"
                    style="margin-bottom: 10px; margin-top: 10px; font-size: 14px">
                    <ion-col class="ion-no-padding" size="1.5">
                      <ion-text class="ion-float-left"> Pos </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4.5">
                      <ion-text class="ion-float-left"> Player </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4">
                      <ion-text class="ion-float-right"> Net Score </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right"> Thru </ion-text>
                    </ion-col>
                  </ion-row>

                </ion-grid>

              </ion-item-divider>

              <!-- all confirmed players -->
              <ion-item *ngFor="let player of event.players.active.leaderboardNet;">

                <ion-grid>

                  <ion-row class="ion-no-padding ion-align-items-center">

                    <ion-col class="ion-no-padding" size="1.5">
                      {{player.score.tiedIndicatorNet + player.score.positionNet}}
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2.5">
                      <avatar [member]="player.member"></avatar>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4">
                      <ion-label>
                        {{player.firstName}} {{player.lastName}}
                      </ion-label>
                      <ion-note style="font-size: 14px">Handicap {{player.teeHandicapDisplay()}}</ion-note>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right">
                        <ion-text>{{ player.score.totalNet }}</ion-text>
                      </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right">
                        {{player.holeThruDisplay}}
                      </ion-text>
                    </ion-col>

                  </ion-row>

                </ion-grid>

              </ion-item>

            </ion-item-group>

          </ion-list>

        </ion-slide>

        <!-- to par net -->
        <ion-slide>

          <ion-list style="width: 100%">

            <ion-item-group>

              <!-- header -->
              <ion-item-divider>

                <ion-grid>

                  <ion-row class="ion-align-items-center"
                    style="margin-bottom: 10px; margin-top: 10px; font-size: 14px">
                    <ion-col class="ion-no-padding" size="1.5">
                      <ion-text class="ion-float-left"> Pos </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4.5">
                      <ion-text class="ion-float-left"> Player </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4">
                      <ion-text class="ion-float-right"> Par To (net) </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right"> Thru </ion-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item-divider>

              <!-- all confirmed players -->
              <ion-item *ngFor="let player of event.players.active.leaderboardNet;">
                <ion-grid>
                  <ion-row class="ion-no-padding ion-align-items-center">
                    <ion-col class="ion-no-padding" size="1.5">
                      {{player.score.tiedIndicatorNet + player.score.positionNet}}
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2.5">
                      <avatar [member]="player.member"></avatar>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4">
                      <ion-label>
                        {{player.firstName}} {{player.lastName}}
                      </ion-label>
                      <ion-note style="font-size: 14px">Handicap {{player.teeHandicapDisplay()}}</ion-note>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right">
                        <ion-text>{{ player.score.toParNet <= 0 ? '' : '+' }}</ion-text>
                            <ion-text>{{ player.score.toParNet === 0 ? 'E' :
                              player.score.toParNet }}</ion-text>
                        </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right">
                        {{player.holeThruDisplay}}
                      </ion-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item>
            </ion-item-group>

          </ion-list>

        </ion-slide>

        <!-- gross score -->
        <ion-slide>

          <ion-list style="width: 100%">

            <ion-item-group>

              <!-- header -->
              <ion-item-divider>

                <ion-grid>

                  <ion-row class="ion-align-items-center"
                    style="margin-bottom: 10px; margin-top: 10px; font-size: 14px">

                    <ion-col class="ion-no-padding" size="1.5">
                      <ion-text class="ion-float-left"> Pos </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4.5">
                      <ion-text class="ion-float-left"> Player </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4">
                      <ion-text class="ion-float-right"> Gross Score </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right"> Thru </ion-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item-divider>

              <!-- all confirmed players -->
              <ion-item *ngFor="let player of event.players.active.leaderboard;">
                <ion-grid>
                  <ion-row class="ion-no-padding ion-align-items-center">
                    <ion-col class="ion-no-padding" size="1.5">
                      {{player.score.tiedIndicator + player.score.position}}
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2.5">
                      <avatar [member]="player.member"></avatar>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4">
                      <ion-label>
                        {{player.firstName}} {{player.lastName}}
                      </ion-label>
                      <ion-note style="font-size: 14px">Handicap {{player.teeHandicapDisplay()}}</ion-note>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right">
                        <ion-text>{{ player.score.totalGross }}</ion-text>
                      </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right">
                        {{player.holeThruDisplay}}
                      </ion-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item>

            </ion-item-group>
          </ion-list>
        </ion-slide>

        <!-- to Par Gross -->
        <ion-slide>

          <ion-list style="width: 100%">

            <ion-item-group>

              <!-- header -->
              <ion-item-divider>

                <ion-grid>

                  <ion-row class="ion-align-items-center"
                    style="margin-bottom: 10px; margin-top: 10px; font-size: 14px">

                    <ion-col class="ion-no-padding" size="1.5">
                      <ion-text class="ion-float-left"> Pos </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4.5">
                      <ion-text class="ion-float-left"> Player </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4">
                      <ion-text class="ion-float-right">
                        Par To (gross)
                      </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right"> Thru </ion-text>
                    </ion-col>

                  </ion-row>

                </ion-grid>

              </ion-item-divider>

              <!-- all confirmed players -->
              <ion-item *ngFor="let player of event.players.active.leaderboard;">

                <ion-grid>

                  <ion-row class="ion-no-padding ion-align-items-center">

                    <ion-col class="ion-no-padding" size="1.5">
                      {{player.score.tiedIndicator + player.score.position}}
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2.5">
                      <avatar [member]="player.member"></avatar>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="4">
                      <ion-label>
                        {{player.firstName}} {{player.lastName}}
                      </ion-label>
                      <ion-note style="font-size: 14px">Handicap {{player.teeHandicapDisplay()}}</ion-note>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right">
                        <ion-text>{{ player.score.toParGross <= 0 ? '' : '+' }}</ion-text>
                            <ion-text>{{ player.score.toParGross === 0 ? 'E' :
                              player.score.toParGross }}</ion-text>
                        </ion-text>
                    </ion-col>

                    <ion-col class="ion-no-padding" size="2">
                      <ion-text class="ion-float-right">
                        {{player.holeThruDisplay}}
                      </ion-text>
                    </ion-col>

                  </ion-row>

                </ion-grid>

              </ion-item>

            </ion-item-group>

          </ion-list>

        </ion-slide>

      </ion-slides>

    </ng-container>

    <!-- if event isn't today -->
    <ng-template #eventScoringNotStarted>

      <ion-list>

        <ion-item lines="none">
          <h2 style="color: var(--ion-color-primary)">
            Welcome to the leaderboards!
          </h2>
        </ion-item>

        <ion-item lines="none">
          <ion-text>
            Once live scoring begins you will be able to follow along with the action using the following leaderboards.
          </ion-text>
        </ion-item>

        <ion-item lines="none">
          <ul style="padding-left: 20px;">
            <li>Gross score to par</li>
            <li>Net score to par</li>
            <li>Gross score</li>
            <li>Net score</li>
          </ul>
        </ion-item>

      </ion-list>

    </ng-template>

  </div>

  <div *ngIf="eventScoringSegment === EventScoringSegment.Matches">

    <!-- EVENT MATCHES -->
    <ion-list class="itemLines" lines="full">

      <ion-list-header>
        <ion-label>Event Matches
          <ion-note
            *ngIf="event.matches.parent.length > 0 && event.matches.parent[0].status===ScoringMode.ScoringNotStarted"
            style="font-size: 16px">Not
            yet started</ion-note>
          <ion-note
            *ngIf="event.matches.parent.length > 0 && event.matches.parent[0].status===ScoringMode.ScoringActive"
            style="font-size: 16px">Playing
          </ion-note>
          <ion-note
            *ngIf="event.matches.parent.length > 0 && event.matches.parent[0].status===ScoringMode.ScoringComplete"
            style="font-size: 16px; color: green">Finished</ion-note>
        </ion-label>
        <ion-button style="height: auto;" (click)="matchEdit();" *ngIf="event.isMemberAdmin(accountService.member)">
          <ion-icon name="add-circle" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-list-header>

      <!-- if there any event matches then... -->
      <ng-container *ngIf="event.matches.parent.length > 0, else noEventMatches">

        <!-- interate through event matches -->
        <ng-container *ngFor="let match of event.matches.parent">
          <event-match-live-view [match]="match" [event]="event" [openScorecardMethod]="openScorecard"
            [callback]="matchUpdate">
          </event-match-live-view>
        </ng-container>

      </ng-container>

      <!-- if no event matches then show this messsage -->
      <ng-template #noEventMatches>
        <ion-item>
          <ion-label class="ion-text-wrap">
            <p *ngIf="!event.isMemberAdmin(accountService.member)">The Group organzier has not created any Event
              matches.</p>
            <p *ngIf="event.isMemberAdmin(accountService.member)">There are no matches for this Event. Tap <ion-icon
                name="add-circle" slot="icon-only"
                style="color: var(--ion-color-primary); font-size: large; vertical-align: text-bottom;"></ion-icon>
              above to create
              the first Event match.</p>
          </ion-label>
        </ion-item>
      </ng-template>

    </ion-list>

    <!-- MEMBER MATCHES -->
    <ion-list class="itemLines" lines="full">

      <ion-list-header>
        <ion-label>Member Matches</ion-label>
        <ion-button style="height: auto;" (click)="newMemberMatch();">
          <ion-icon name="add-circle" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-list-header>
      <ion-list-header class="help">
        <ion-text>Challenge other Event players to a match.</ion-text>
        <ion-text (click)="helpService.showFeatureHelp('memberMatches')">Learn more.</ion-text>
      </ion-list-header>

      <!-- show all of the current member event matches -->
      <ng-container
        *ngIf="event.matches.getPlayerMemberMatches(event.players.memberPlayer).length > 0; else noPlayerMatches">

        <ng-container *ngFor="let match of event.matches.getPlayerMemberMatches(event.players.memberPlayer)">
          <event-match-live-view #matches [match]="match" [event]="event" [openScorecardMethod]="openScorecard"
            [callback]="matchUpdate"></event-match-live-view>
        </ng-container>

      </ng-container>

    </ion-list>

  </div>

  <ion-fab #fab vertical="bottom" horizontal="end" slot="fixed" haptic [fabClose]="fab">
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">

      <!-- scorecard: display just event tee time players -->
      <ion-button shape="round"
        *ngIf="(event.players.active.teeTimeScoringMode !== ScoringMode.ScoringNotStarted) && (eventScoringSegment === 'scoring')"
        (click)="openScorecard(event.players.active.teeTime); fab.close();">
        <ion-icon name="exit" slot="start"></ion-icon>
        <ion-label>Score Card</ion-label>
      </ion-button>

      <!-- scorecard: display all event players -->
      <ion-button shape="round"
        *ngIf="(event.players.active.teeTimeScoringMode !== ScoringMode.ScoringNotStarted) && (eventScoringSegment !== 'scoring')"
        (click)="openScorecard(event.players.active.all); fab.close();">
        <ion-icon name="exit" slot="start"></ion-icon>
        <ion-label>Score Card</ion-label>
      </ion-button>

      <!-- send email -->
      <ion-button shape="round" *ngIf="event.isMemberAdmin(accountService.member)"
        (click)="event.composeEmailConfirm(); fab.close();">
        <ion-icon name="mail" slot="start"></ion-icon>
        <ion-label>Send Email</ion-label>
      </ion-button>

      <!-- view event -->
      <ion-button shape="round" (click)="viewEvent(); fab.close();">
        <ion-icon name="exit" slot="start"></ion-icon>
        <ion-label>Event Info</ion-label>
      </ion-button>

    </ion-fab-list>
  </ion-fab>

</ion-content>

<!-- if no matches then show this messsage -->
<ng-template #noPlayerMatches>

  <ion-item lines="none">
    <ion-label class="ion-text-wrap">
      <p>You don't have any member matches. Tap <ion-icon name="add-circle" color="primary"
          style="font-size: large; vertical-align: text-bottom;"></ion-icon> above to start a match.</p>
    </ion-label>
  </ion-item>

</ng-template>

<!-- player score inline modal -->
<ion-modal #playerScoreListModal>

  <ng-template>

    <ion-header class="ion-no-border">

      <ion-toolbar>

        <ion-title>{{scoreListPlayer.firstName + ' ' + scoreListPlayer.lastName}}</ion-title>

        <ion-buttons slot="primary">
          <ion-button fill="solid" shape="round" color="light" (click)="playerScoreListModal.isOpen = false;">Close
          </ion-button>
        </ion-buttons>

      </ion-toolbar>

    </ion-header>

    <ion-content class="ion-padding-start">

      <ion-grid class="ion-no-padding">

        <ng-container *ngFor="let nine of scoreListPlayer.nines; let nineIndex = index;">

          <ng-container *ngFor="let hole of nine.holes; let holeIndex = index;">

            <ion-row class="ion-no-padding">
              <ion-col class="ion-no-padding" style="margin-top: 5px;" size="auto">
                <div class="numberCircle hole">
                  {{hole.displayNumber}}
                </div>
              </ion-col>

              <ion-col class="ion-no-padding">
                <event-player-score [player]="scoreListPlayer" [nineIndex]="nineIndex" [holeIndex]="holeIndex"
                  style="margin-right: 0px"></event-player-score>
              </ion-col>

            </ion-row>

          </ng-container>

        </ng-container>

      </ion-grid>

    </ion-content>

  </ng-template>

</ion-modal>

<!-- hole selector inline modal -->
<ion-modal #holeSelectorModal [initialBreakpoint]="0.35" [breakpoints]="[0, 0.35]"
  (didDismiss)="closeHoleSelector(holeSelectorModal);">

  <ng-template>

    <ion-content class="ion-padding">

      <div style="margin: 10px;" *ngIf="holeSelectorMessage">
        <ion-label>{{holeSelectorMessage}}</ion-label>
      </div>

      <div class="holeSelectorModal">
        <ng-container *ngFor="let nine of event.nines; let n = index;">
          <div *ngFor="let hole of nine.holes; let h = index;" class="numberCircle hole"
            [ngClass]="{ 
                			'played': ((hole.number - 1) < event.players.active.teeTime[0].courseHoleThruIndex + event.startingHoleIndex + 1), 
                			'current': (hole.number - 1) === event.players.active.teeTime[0].courseHoleThruIndex + event.startingHoleIndex + 1, 
                			'notPlayed': (hole.number - 1) > event.players.active.teeTime[0].courseHoleThruIndex+ event.startingHoleIndex + 1}"
            (click)="closeHoleSelector(holeSelectorModal, (hole.number - 1));">
            {{hole.number}}
            <ion-icon name="checkmark-circle"></ion-icon>
          </div>
        </ng-container>
      </div>

    </ion-content>

  </ng-template>

</ion-modal>