import { Component, ViewChild, OnInit } from "@angular/core";
import { IonContent, NavParams } from "@ionic/angular";
import { AppFunction, HelpService, ChatMessageType, ChatMessageI } from "../../app.function";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "src/app/app.config";

@Component({
  selector: AppConfig.PAGE.RuleHelper,
  templateUrl: "./rule-helper.page.html",
  styleUrls: ["./rule-helper.page.scss"],
})
export class RuleHelperPage implements OnInit {
  promptForm: FormGroup;
  messages: ChatMessageI[] = [];
  maxPromptLength: number = 280;
  screenKey: string = AppConfig.PAGE.RuleHelper;

  //get reference to ion-content for scrolling
  @ViewChild("content", { static: false }) content: IonContent;

  constructor(
    public builder: FormBuilder,
    public appFunction: AppFunction,
    public navParams: NavParams,
    public helpService: HelpService,
    private http: HttpClient
  ) {
    //helpService.screenWhatsNew(this.screenKey);
  }

  ngOnInit() {

    //add welcome text to messages
    this.messages.push({ message: "Welcome to the Double Ace Golf Rules Advisor!", type: ChatMessageType.MSG_REQUEST });

    //add disclaimer text to messages
    this.messages.push({ message: "The information provided by the Double Ace Golf Rules Advisor is for educational and informational purposes only. While efforts have been made to ensure the accuracy of the information presented, we cannot guarantee that it is error-free. The use of Double Ace Golf Rules Advisor is at your own risk and should not be used as a substitute for professional advice or guidance.", type: ChatMessageType.MSG_REQUEST });

    //build form group
    this.promptForm = this.builder.group({
      question: ["", Validators.required],
    });

  }

  async sendQuestion() {

    //save the form if dirty
    if (this.promptForm.dirty) {

      //save the form if valid
      if (this.promptForm.valid) {

        //add question to view
        this.messages.push({ message: this.promptForm.controls.question.value, type: ChatMessageType.MSG_RESPONSE });

        //get question and clear form
        const question = this.promptForm.controls.question.value;
        this.promptForm.reset();

        //turn on loading bubbles
        const response = <ChatMessageI>{ message: undefined, type: ChatMessageType.MSG_REQUEST };
        this.messages.push(response);
        await this.scrollToBottom();

        //call OpenAI cloud function
        this.http
          .post(AppConfig.CLOUD_FUNCTIONS.ruleHelper, { prompt: question })
          .toPromise()
          .then(async (resp) => {
            const data = resp as any;
            response.message = data.choices[0].message.content;
            await this.scrollToBottom();
          })
          .catch(async (err) => {
            console.log('rule-helper.page.ts: sendQuestion() error: ', err);
            response.message = "Sorry, I don't know the answer to that. Please try again.";
            await this.scrollToBottom();
          });

      } else {
        //show any untouched errors
        this.appFunction.setDirtyControlAsTouched(this.promptForm);
      }

    }

  }

  private scrollToBottom(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.content
          .scrollToBottom(400)
          .then(() => {
            resolve();
          });
      });
    });
  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}