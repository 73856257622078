import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppFunction } from '../../app.function';
import { AppPost, AppPostComment } from 'src/app/app.social';
import { AccountService } from 'src/app/app.account';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-post-comment',
  templateUrl: './post-comment.page.html',
  styleUrls: ['./post-comment.page.scss'],
})
export class PostCommentPage implements OnInit {

  post: AppPost;
  comment: string;
  comments: AppPostComment[] = [];
  postCommentForm: FormGroup;

  constructor(
    public navParams: NavParams,
    public appFunction: AppFunction,
    public accountService: AccountService,
    public builder: FormBuilder) {
  }

  ngOnInit() {

    //console.log('post-comments.ts ngOnInit');

    //build form group
    this.postCommentForm = this.builder.group({
      text: ['', Validators.required]
    });

    //get post
    this.post = <AppPost>this.navParams.get('post');

    //get comments
    this.appFunction
      .loadingCtrl
      .create({ message: 'Getting comments...' })
      .then((loading) => {

        loading.present();

        //get comments
        this.post
          .getComments()
          .then((comments) => {
            this.comments = comments;
            loading.dismiss();
          })
          .catch((err) => {
            loading.dismiss();
          });

      });

  }

  postComment() {

    //save the form if dirty
    if (this.postCommentForm.dirty) {

      //console.log('post-comment.ts postComment dirty');

      //save the form if valid 
      if (this.postCommentForm.valid) {

        this.appFunction
          .loadingCtrl
          .create({ message: 'Saving comment...' })
          .then((loading) => {

            loading
              .present()
              .then(() => {

                this.post
                  .saveComment(this.postCommentForm.controls.text.value)
                  .then(() => {

                    //once comment has been saved clear out for another comment
                    //this.postCommentForm.controls['text'].setValue('');
                    this.postCommentForm.reset();

                    loading.dismiss();

                  });

              });

          });

      }

    } else {
      //show any untouched errors
      this.appFunction.setDirtyControlAsTouched(this.postCommentForm);
    }

  }

  deleteCommentConfirm(postComment: AppPostComment) {

    console.log('posts.ts deleteCommentConfirm');

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Delete Comment',
            role: 'destructive',
            handler: () => {
              this
                .post
                .deleteComment(postComment);
            }
          },
          {
            text: 'No',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  deletePostConfirm(post: AppPost) {

    console.log('posts.ts deletePostConfirm');

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Delete Post',
            role: 'destructive',
            handler: () => {
              post
                .delete()
                .then(() => {
                  this.done();
                });
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}
