import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Output, EventEmitter, ViewChild, Directive, } from '@angular/core';
import { IonTextarea } from '@ionic/angular';
import { createAnimation } from '@ionic/core';
import { AppConfig } from 'src/app/app.config';
import { AppFunction, ChatMessageType } from 'src/app/app.function';

interface AppAnswerI {
  id: string;
  display: string;
  aiPrompt: string;
  nextChat?: string;
  callOpenAI: boolean;
}

interface AppOpenAIPromptI {
  role: string;
  content: string;
}

@Directive()
class AppChat {

  id: string = this.appFunction.newGuid();
  prompts: string[] = [];
  answers: AppAnswerI[] = [];
  private currentPromptsIndex: number = -1;
  private _answer: AppAnswerI;
  @Output() selectedAnswer = new EventEmitter<AppAnswerI>();
  private _enteredInput: string;

  constructor(public chatConfig: any,
    public appFunction: AppFunction,
    public chatbotTextarea: IonTextarea) {
    this.getNextPrompt();
  }

  getNextPrompt() {

    //increment the prompt index
    if (this.currentPromptsIndex < this.chatConfig.prompts?.length - 1) {
      this.currentPromptsIndex++;
      this.prompts.push(this.chatConfig.prompts[this.currentPromptsIndex]);
    } else { //if all "prompts" have been displayed then display the answer buttons, input box or openAiResponse

      //if there are answers then display them
      if (this.chatConfig.answers?.length > 0) {

        //get/display the answer buttons
        this.answers.push(...this.chatConfig.answers);

        //animate the answer mainAnswerContainer
        const mainAnswerContainer = <HTMLDivElement>document.getElementById(this.id);
        createAnimation()
          .addElement(mainAnswerContainer)
          .duration(200)
          .to('opacity', '1')
          .play()
          .then(() => {
            //scroll buttoms into view
            const chatbotContainer: HTMLDivElement = <HTMLDivElement>document.getElementById('answerContainer-' + this.id);
            chatbotContainer.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
          });

      } else if (this.chatConfig.input) { //else if the chat is calling user input then display the input box
        this.showInputContainer(this.chatConfig.input.placeholder);
      } else if (this.chatConfig.openAiResponse) { //else if the chat is calling OpenAI then wait for answer

        //animate aiResultsContainer
        const aiResultsContainer = <HTMLDivElement>document.getElementById('aiResultsContainer-' + this.id);
        createAnimation()
          .addElement(aiResultsContainer)
          .duration(200)
          .to('opacity', '1')
          .play()
          .then(() => {
            //scroll buttoms into view
            const aiResultsContainer: HTMLDivElement = <HTMLDivElement>document.getElementById('aiResultsContainer-' + this.id);
            aiResultsContainer.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
          });
      }

    }

  }

  answerSelected(answer: AppAnswerI) {

    //save the answer
    this._answer = answer;

    const answerContainer = document.getElementById('answerContainer-' + this.id);

    //get selected answer
    const selectedAnswer = document.getElementById(answer.id + '-' + this.id);

    console.log(selectedAnswer.getBoundingClientRect().width, selectedAnswer.offsetTop, selectedAnswer.offsetLeft, selectedAnswer);

    //style the newly cloned item, this is the item that will animate
    const cloneAnswer: HTMLElement = <HTMLElement>document.createElement('div');
    cloneAnswer.classList.add('buttonClone');
    cloneAnswer.classList.add('confetti-button');
    cloneAnswer.style.position = 'absolute';
    cloneAnswer.style.top = (selectedAnswer.offsetTop - 5) + 'px';
    cloneAnswer.style.left = (selectedAnswer.offsetLeft - 5) + 'px';
    cloneAnswer.style.width = 'auto';
    cloneAnswer.style.height = selectedAnswer.getBoundingClientRect().height + 'px';
    cloneAnswer.innerText = selectedAnswer.innerText;
    answerContainer.appendChild(cloneAnswer);

    //get the width of the cloned answer button before setting its width to the selected answer button, this will be used during animation
    const cloneAnswerWidthAuto = cloneAnswer.getBoundingClientRect().width;

    //now set the width of the cloned answer button to the selected answer button
    cloneAnswer.style.width = selectedAnswer.getBoundingClientRect().width + 'px';

    //get the width of the cloned answer button after setting its width to the selected answer button, this will be used during animation
    const cloneAnswerFullWidth = cloneAnswer.getBoundingClientRect().width;

    //get the distance the cloned answer button needs to move
    const moveRight: number = answerContainer.getBoundingClientRect().right - cloneAnswer.getBoundingClientRect().right;

    //get the distance the cloned answer button needs to move
    const moveUp: number = answerContainer.getBoundingClientRect().top - cloneAnswer.getBoundingClientRect().top;

    //console.log(cloneAnswerFullWidth, cloneAnswerWidthAuto, cloneAnswerFullWidth - cloneAnswerWidthAuto);

    //create animation for the cloned answer button...
    const animateClone = createAnimation()
      .addElement(cloneAnswer)
      .duration(300)
      .to('transform', 'translate(' + (moveRight + (cloneAnswerFullWidth - cloneAnswerWidthAuto)) + 'px, ' + (moveUp + 5) + 'px)')
      .to('borderBottomRightRadius', '0px')
      .to('color', 'white')
      .to('border', '0')
      .to('width', cloneAnswerWidthAuto + 'px')
      .to('background', 'var(--ion-color-primary)')
      .easing('ease-out');

    //iterate through the answer buttons and hide them
    const answerButtons: NodeListOf<Element> = answerContainer.querySelectorAll('.hideAnswerButton');
    const hideAnswerButtons = createAnimation()
      .addElement(answerButtons)
      .duration(300)
      .to('opacity', '0')
      .onFinish(() => {
        cloneAnswer.classList.add('animate');
      });

    //create animation for the answer button container...
    const sizeAnswerButtonContainer = createAnimation()
      .addElement(answerContainer)
      .duration(300)
      .to('height', (cloneAnswer.getBoundingClientRect().height + 10) + 'px');

    //run the animations...
    createAnimation()
      .addAnimation([animateClone, hideAnswerButtons, sizeAnswerButtonContainer])
      .play()
      .then(() => {
        //return selected answer to the parent component
        this.selectedAnswer.emit(answer);
      });

  }

  userEnteredInput(enteredInput: string) {

    //save the entered input
    this._enteredInput = enteredInput;

    //get the user input container
    const userInputContainer = <HTMLDivElement>document.getElementById('userInputContainer-' + this.id);

    //animate userInputContainer
    createAnimation()
      .addElement(userInputContainer)
      .duration(300)
      .to('opacity', '1')
      .onFinish(() => {
        //animate confetti  
        const userInputChat = <HTMLDivElement>document.getElementById('userInputChat-' + this.id);
        userInputChat.classList.remove('animate');
        userInputChat.classList.add('animate');

        //scroll input into view
        userInputContainer.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      })
      .play();

    //hide the input container
    this.hideInputContainer();

    //return entered input to the parent component
    this.selectedAnswer.emit({ id: this.chatConfig.input.id, display: enteredInput, aiPrompt: enteredInput, nextChat: this.chatConfig.input.nextChat, callOpenAI: this.chatConfig.input.callOpenAI });

  }

  get enteredInput(): string {
    return this._enteredInput;
  }

  private showInputContainer(placeholder: string) {

    const chatbotInputContainer: HTMLElement = document.getElementById('chatbotInput');

    //set the placeholder
    this.chatbotTextarea.placeholder = placeholder;

    //animate chatbotInputContainer
    const showInputContainer = createAnimation()
      .addElement(chatbotInputContainer)
      .duration(200)
      .beforeStyles({ 'display': 'block' })
      .to('opacity', '1');

    //animate bottom of chatbotInputContainer
    const changeChatbotConversationBottom = createAnimation()
      .addElement(document.getElementById('chatbotConversation'))
      .duration(200)
      .to('bottom', chatbotInputContainer.getBoundingClientRect().height + 'px');

    createAnimation()
      .addAnimation([showInputContainer, changeChatbotConversationBottom])
      .play();

  }

  private hideInputContainer() {

    const chatbotInputContainer: HTMLElement = document.getElementById('chatbotInput');

    //animate chatbotInputContainer
    const hideInputContainer = createAnimation()
      .addElement(chatbotInputContainer)
      .duration(200)
      .to('opacity', '0');

    //animate bottom of chatbotInputContainer
    const changeChatbotConversationBottom = createAnimation()
      .addElement(document.getElementById('chatbotConversation'))
      .duration(200)
      .to('bottom', '0px');

    createAnimation()
      .addAnimation([hideInputContainer, changeChatbotConversationBottom])
      .play();

  }

  get answer(): AppAnswerI {
    return this._answer;
  }

}

@Component({
  selector: 'chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss'],
  animations: [
    trigger("animateBubbleLeft", [
      state(
        "*",
        style({
          opacity: "1",
          transformOrigin: "left top",
          transform: "scale(1)"
        })
      ),
      state(
        "void",
        style({
          opacity: "0",
          transform: "scale(0)"
        })
      ),
      transition(":enter", [
        animate("500ms")
      ])
    ])
  ]
})
export class ChatBotComponent {

  chats: AppChat[] = [];
  chatConfig: any[] = [];
  public chatType = ChatMessageType;
  sayIndex: number = 0;
  @ViewChild('chatbotTextarea', { static: false }) chatbotTextarea: IonTextarea;
  currentChat: AppChat;

  constructor(public appFunction: AppFunction) {

  }

  start(chatConfig: any) {

    //save config
    this.chatConfig = chatConfig;

    //get first chat
    this.createChat('start');

  }

  private getChat(chatName: string): any {
    return this.chatConfig[chatName];
  }

  private createChat(chatName: string) {

    //get the first chat from config
    const chatObject = this.getChat(chatName);

    //confirm chat exists
    if (chatObject) {

      //create new chat
      this.currentChat = new AppChat(chatObject, this.appFunction, this.chatbotTextarea);
      this.chats.push(this.currentChat);

      this.currentChat
        .selectedAnswer
        .subscribe((answer: AppAnswerI) => {

          //if the previsous chat has callOpenAI set to true then call OpenAI
          if (answer.callOpenAI) {
            this.callOpenAI(answer);
          } else {
            this.createChat(answer.nextChat);
          }

        });

    } else {
      console.log('Chat not found: ' + chatName);
    }

  }

  sendChatInput(chatbotTextarea: IonTextarea) {

    //get the input from the textarea and send back to currenr chat
    this.currentChat.userEnteredInput(chatbotTextarea.value);

    //clear the textarea
    chatbotTextarea.value = '';

  }

  async callOpenAI(previousAnswer: AppAnswerI) {

    //construct the chat config for calling OpenAI
    const openAiChat = {
      prompts: ['Please wait while we create your lesson plan!'],
      openAiResponse: {
        id: 'openAiResponse',
        results: undefined,
        nextChat: previousAnswer.nextChat
      }
    }

    //create new chat
    this.currentChat = new AppChat(openAiChat, this.appFunction, this.chatbotTextarea);
    this.chats.push(this.currentChat);

    //add the configured prompts
    const prompts: AppOpenAIPromptI[] = [];
    const configuredPrompts: AppOpenAIPromptI[] = this.chatConfig['openAiPrompt'];
    configuredPrompts.forEach((prompt: AppOpenAIPromptI) => {
      prompts.push(prompt);
    });

    //now loop through chats and get the user prompts
    this.chats.forEach((chat: AppChat) => {

      //if the chat has a prompt then add it to the prompts array
      if (chat?.answer?.aiPrompt) {
        prompts.push(<AppOpenAIPromptI>{
          role: 'user',
          content: chat.answer.aiPrompt
        });
      }

    });

    //call OpenAI cloud function
    this.appFunction
      .http
      .post(AppConfig.CLOUD_FUNCTIONS.openAI, { prompts: prompts })
      .toPromise()
      .then(async (resp) => {
        const data = resp as any;

        //set the results to the chat config
        openAiChat.openAiResponse.results = data.choices[0].message.content;

        //navigate to the next chat
        this.createChat(openAiChat.openAiResponse.nextChat);

        //await this.scrollToBottom();
      })
      .catch(async (err) => {
        console.log('rule-helper.page.ts: sendQuestion() error: ', err);
        //response.message = "Sorry, I don't know the answer to that. Please try again.";
        //await this.scrollToBottom();
      });

  }

  restart(chat: any) {
    this.chats = [];
    this.start(this.chatConfig);
  }

}
