import { Component, Input, ViewChild } from '@angular/core';
import { AppEvent, ScoringMode, } from 'src/app/app.event';
import { AppMatch, AppSubMatch, HoleByHoleLeaderboardEventI, HoleByHoleLeaderboardI, HoleByHoleLeaderboardTeamI, TotalScoreLeaderboardEventI, TotalScoreLeaderboardEventTeamI, TotalScoreLeaderboardI } from 'src/app/app.match';
import { GameType, HandicapType } from '../../app.config';
import { MatchMemberDetailPage } from '../../pages/match-member-detail/match-member-detail.page';
import { MatchFormatViewPage } from '../../pages/match-format-view/match-format-view.page';
import { AppFunction } from '../../app.function';
import { AccountService } from '../../app.account';
import { AppConfig } from '../../app.config';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'event-match-live-view',
  templateUrl: './event-match-live-view.component.html',
  styleUrls: ['./event-match-live-view.component.scss']
})
export class EventMatchLiveViewComponent {

  //get from the host html
  @Input() match: AppMatch;
  @Input() event: AppEvent;
  @Input() callback;
  @Input() openScorecardMethod: (...args: any) => void;
  @ViewChild('matchSlides') matchSlides: IonSlides;
  HandicapType: typeof HandicapType = HandicapType;
  ScoringMode: typeof ScoringMode = ScoringMode;
  GameType: typeof GameType = GameType;

  slideOpts = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets'
    }
  };

  constructor(
    public appFunction: AppFunction,
    public accountService: AccountService
  ) {

  }

  viewMatchFormat() {

    this.appFunction
      .modalCtrl
      .create({
        component: MatchFormatViewPage,
        componentProps: {
          match: this.match,
        },
        cssClass: 'custom-modal-auto-height',
        canDismiss: true
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('match.component.ts viewMatchFormat modal present error', err);
          });

      })
      .catch((err) => {
        console.log('match.component.ts viewMatchFormat modal create error', err);
      });

  }

  async editMatchFormat() {

    this.appFunction
      .modalCtrl
      .create({
        component: MatchMemberDetailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        componentProps: {
          match: this.match,
          editMode: AppConfig.EDIT_MODE.update,
          matchOptions: AppConfig.EVENT_MATCH_CONFIGURATION
        },
        cssClass: 'custom-modal', //for md
        canDismiss: true
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('match.component.ts editMatchFormat modal present error', err);
          });

        modal
          .onDidDismiss()
          .then((result) => {

            //if match was updated...
            if (result.role === 'update') {

              //...signal back to parent
              this.callback();

            }

          });

      })
      .catch((err) => {
        console.log('match.component.ts editMatchFormat modal create error', err);
      });

  }

  updateSlides() {

    if (this.matchSlides) {

      //settime is to over come a defect where the update method doesn't do anything
      setTimeout(() => {
        this.matchSlides.update();
      }, 1);

    }

  }

  createTeam(subMatch: AppSubMatch) {

    //clear teamCreatePrompted
    subMatch.teamCreatePrompted = false;

    //create team
    this.match.createTeam(this.event.players.active.all, this.event);

  }

  //#region total score leaderboard start

  get totalScoreLeaderboard(): TotalScoreLeaderboardI {
    //total score matches only have one subMatch so [0] is appropriate
    return this.match.subMatches[0].totalScoreLeaderboard;
  }

  get totalScoreLeaderboardEventList(): TotalScoreLeaderboardEventI[] {
    return this.totalScoreLeaderboard.eventsList;
  }

  get totalScoreLeaderboardEventTeamList(): TotalScoreLeaderboardEventTeamI[] {

    if (this.totalScoreLeaderboardEventList.length > 0) {
      //if there is only one event then it is a event match
      //if there are more than one event then it is a trip match
      //either way get the last event
      return this.totalScoreLeaderboardEventList[this.totalScoreLeaderboardEventList.length - 1].teamsList;
    } else {
      return [];
    }

  }

  //#endregion total score leaderboard start

  //#region hole by hole leaderboard start

  get holeByHoleLeaderboard(): HoleByHoleLeaderboardI {
    return this.match.subMatches[0].holeByHoleLeaderboard;
  }

  get totalHoleByHoleLeaderboardEventList(): HoleByHoleLeaderboardEventI[] {
    return this.holeByHoleLeaderboard.eventsList;
  }

  get totalHoleByHoleLeaderboardEventTeamList(): HoleByHoleLeaderboardTeamI[] {

    if (this.totalHoleByHoleLeaderboardEventList.length > 0) {
      //if there is only one event then it is a event match
      //if there are more than one event then it is a trip match
      //either way get the last event
      return this.totalHoleByHoleLeaderboardEventList[this.totalHoleByHoleLeaderboardEventList.length - 1].teamsList;
    } else {
      return [];
    }

  }

  //#endregion hole by hole leaderboard start

  get showInScorecard(): boolean {
    //if scorecard callback is defined then show the toolbar, this will happen when the parent component is a scorecard
    return this.openScorecardMethod === undefined;
  }

}
