import { Injectable } from '@angular/core';
import { AppFunction } from './app.function';

import { AppConfig } from './app.config';
import firebase from 'firebase/compat/app';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ContentService {

    constructor(
        private appFunction: AppFunction) {
    }

    getPartnerContent(clubId: string): Promise<string> {

        return new Promise<string>((resolve, reject) => {

            try {

                if (clubId) {

                    const now: firebase.firestore.Timestamp = firebase.firestore.Timestamp.fromDate(moment().startOf('day').toDate());

                    //get events for given group
                    this.appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.PartnerContent)
                        .where('clubId', '==', clubId)
                        .where('endDt', '>=', now)
                        .get({ source: 'server' })
                        .then((foundContent) => {

                            //console.log('app.content.ts getPartnerContent success', clubId);

                            let partnerContent: string = '';

                            if (foundContent.empty) {
                                resolve(undefined);
                            } else {

                                foundContent
                                    .forEach((content) => {

                                        //console.log('app.content.ts getPartnerContent', content.data().content);

                                        //because firestore can't do two ranges filter out any future ads
                                        if (content.data().startDt <= now) {
                                            partnerContent = partnerContent + content.data().content;
                                        }

                                    });

                                resolve(partnerContent);

                            }

                        })
                        .catch((err) => {
                            console.log('app.content.ts getPartnerContent success', err);
                            reject(err);
                        });

                } else {
                    resolve(undefined);
                }

            } catch (err) {
                console.log('app.content.ts getPartnerContent error', err);
                reject(err);
            }

        });

    }

}

