import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppEventPlayer } from 'src/app/app.event';
import { AppFunction } from 'src/app/app.function';

@Component({
  selector: 'note',
  templateUrl: './note.page.html',
  styleUrls: ['./note.page.scss'],
})
export class NotePage {

  player: AppEventPlayer;

  constructor(
    public navParams: NavParams,
    public appFunction: AppFunction
  ) {

    //console.log('note.ts constructor');

    this.player = this.navParams.get('player');

  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}
