import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppMatch } from 'src/app/app.match';
import { AppFunction } from 'src/app/app.function';

@Component({
  selector: 'match-format-view',
  templateUrl: './match-format-view.page.html',
  styleUrls: ['./match-format-view.page.scss'],
})
export class MatchFormatViewPage implements OnInit {

  match: AppMatch;

  constructor(
    private navParams: NavParams,
    public appFunction: AppFunction) {

  }

  ngOnInit() {
    this.match = <AppMatch>this.navParams.get('match');
  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}
