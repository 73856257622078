<ion-header class="course-detail-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="secondary">
      <ion-button (click)="back()">
        <ion-icon name="chevron-back"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" (click)="done()">Save</ion-button>
    </ion-buttons>

    <ion-title>Course</ion-title>

  </ion-toolbar>

</ion-header>

<ion-content class="course-detail-content">

  <div class="ion-padding">

    <ion-segment [(ngModel)]="courseDetailSegment">

      <ion-segment-button value="detail">
        <ion-label>Details</ion-label>
      </ion-segment-button>

      <ion-segment-button value="tees">
        <ion-label>Tees</ion-label>
      </ion-segment-button>

    </ion-segment>

  </div>

  <div [hidden]="courseDetailSegment !== 'detail'">

      <ion-list class="itemLines">

        <ion-item>
          <ion-label>
            <ion-label position="stacked">Club Id</ion-label>
            <div>{{course.clubId}}</div>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <ion-label position="stacked">Course Id</ion-label>
            <div>{{course.courseId}}</div>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <ion-label position="stacked">Name</ion-label>
            <div>{{course.name}}</div>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <ion-label position="stacked">Holes</ion-label>
            <div>{{course.holes}}</div>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <ion-label position="stacked">Designer</ion-label>
            <div>{{course.designer}}</div>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <ion-label position="stacked">Year</ion-label>
            <div>{{course.year}}</div>
          </ion-label>
        </ion-item>

      </ion-list>

  </div>

  <div [hidden]="courseDetailSegment !== 'tees'">

    <ion-list class="itemLines">

      <ion-item *ngFor="let tee of course.tees;" (click)="viewTee(tee)">

        <tee [tee]="tee" [cirleClass]="'circle'"></tee>

        <ion-icon name="ellipsis-vertical" (click)="courseActionConfirm($event, tee)" slot="end"
          style="align-self: end; margin-right: 0px;" color="primary">
        </ion-icon>

      </ion-item>

    </ion-list>

  </div>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed" haptic #fab>
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-button shape="round" (click)="scoreCard(); fab.close();">
        <ion-icon name="add-circle" slot="start"></ion-icon>
        <ion-label>Edit Scorecard</ion-label>
      </ion-button>
    </ion-fab-list>
  </ion-fab>

</ion-content>