import { Component, OnInit } from '@angular/core';
import { AppFunction, AppUpdateType } from 'src/app/app.function';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-update',
  templateUrl: './app-update.page.html',
  styleUrls: ['./app-update.page.scss'],
})
export class AppUpdatePage implements OnInit {

  message: string;
  type: AppUpdateType;
  appUpdateTypeOptional: typeof AppUpdateType.OPTIONAL = AppUpdateType.OPTIONAL;
  appUpdateTypeMandatory: typeof AppUpdateType.MANDATORY = AppUpdateType.MANDATORY;

  constructor(
    public appFunction: AppFunction,
    private navParams: NavParams) {
  }

  ngOnInit() {
    this.message = this.navParams.get('message');
    this.type = <AppUpdateType>this.navParams.get('type');
  }

  openAppStore() {
    this.appFunction.openAppStore();
  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}
