<!-- validation hero -->
<validation-hero [display]="match.validationErrors.length > 0"
  [header]="'This match has some setup problems. Please open match configuration for more information.'">
</validation-hero>

<!-- match -->
<ion-item class="ion-no-padding" style="--inner-padding-end: 0px" lines="none">

  <!-- *********** STROKE PLAY *********** -->
  <ion-grid class="ion-no-padding game" *ngIf="match.game === GameType.StrokePlay">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- header row -->
    <ion-row class="ion-align-items-center" style="font-size: 14px">
      <ion-col class="ion-no-padding" size="1.2">
        <ion-text class="ion-float-left">Pos</ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4.8">
        <ion-text class="ion-float-left">Player</ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right">{{'R' + (currentEventIndex() + 1).toString()}}</ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right">Thru</ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right">Total</ion-text>
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container
      *ngIf="this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()]?.teamsList.length > 0;">

      <!-- use the aggregate event to drive the display of the teams -->
      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()].teamsList;">

        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="4.8">{{teamScoreCard.team.name}}</ion-col>

        <!-- most current event to par score -->
        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            <ion-text>{{currentEventTeam(teamScoreCard.team.id)?.teamToPar <= 0 ? '' : '+' }}</ion-text>
                <ion-text>{{currentEventTeam(teamScoreCard.team.id)?.teamToPar === 0 ? 'E' :
                  currentEventTeam(teamScoreCard.team.id)?.teamToPar}}</ion-text>
            </ion-text>
        </ion-col>

        <!-- most current event hole thru index -->
        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            {{currentEventTeam(teamScoreCard.team.id)?.holeThruMatchIndex + 1}}
          </ion-text>
        </ion-col>

        <!-- aggregate event score -->
        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            {{teamScoreCard.teamToPar}}
          </ion-text>
        </ion-col>

      </ion-row>

    </ng-container>

    <!-- display teams when there are no scores for the sub match -->
    <ng-container *ngIf="this.match.subMatches[0].totalScoreLeaderboard.eventsList.length === 0;">

      <!-- display teams if available -->
      <ng-container *ngIf="this.match.subMatches[0].teams?.length > 0; else noteams">

        <ng-container *ngFor="let team of this.match.subMatches[0].teams;">

          <ion-row class="ion-no-padding ion-align-items-center">

            <ion-col class="ion-no-padding" size="1.2">-</ion-col>

            <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="4.8">{{team.name}}</ion-col>

            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">-</ion-text>
            </ion-col>

            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">
                <ion-text>0</ion-text>
              </ion-text>
            </ion-col>

            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">-</ion-text>
            </ion-col>

          </ion-row>

        </ng-container>

      </ng-container>

    </ng-container>

  </ion-grid>

  <!-- *********** STABLEFORD *********** -->
  <ion-grid class="ion-no-padding game" *ngIf="match.game === GameType.Stableford">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- header row -->
    <ion-row class="ion-align-items-center" style="font-size: 14px">
      <ion-col class="ion-no-padding" size="1.2">
        <ion-text class="ion-float-left">Pos</ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4.8">
        <ion-text class="ion-float-left">Player</ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right">{{'R' + (currentEventIndex() + 1).toString()}}</ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right">Thru</ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right">Total</ion-text>
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container
      *ngIf="this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()]?.teamsList.length > 0;">

      <!-- use the aggregate event to drive the display of the teams -->
      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()].teamsList;">

        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="4.8">{{teamScoreCard.team.name}}</ion-col>

        <!-- most current event to par score -->
        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            <ion-text>{{currentEventTeam(teamScoreCard.team.id)?.teamScore}}</ion-text>
          </ion-text>
        </ion-col>

        <!-- most current event hole thru index -->
        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            {{currentEventTeam(teamScoreCard.team.id)?.holeThruMatchIndex + 1}}
          </ion-text>
        </ion-col>

        <!-- aggregate event score -->
        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            {{teamScoreCard.teamScore}}
          </ion-text>
        </ion-col>

      </ion-row>

    </ng-container>

    <!-- display teams when there are no scores for the sub match -->
    <ng-container *ngIf="this.match.subMatches[0].totalScoreLeaderboard.eventsList.length === 0;">

      <!-- display teams if available -->
      <ng-container *ngIf="this.match.subMatches[0].teams?.length > 0; else noteams">

        <ng-container *ngFor="let team of this.match.subMatches[0].teams;">

          <ion-row class="ion-no-padding ion-align-items-center">

            <ion-col class="ion-no-padding" size="1.2">-</ion-col>

            <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="4.8">{{team.name}}</ion-col>

            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">-</ion-text>
            </ion-col>

            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">
                <ion-text>0</ion-text>
              </ion-text>
            </ion-col>

            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">-</ion-text>
            </ion-col>

          </ion-row>

        </ng-container>

      </ng-container>

    </ng-container>

  </ion-grid>

</ion-item>

<!-- icon row -->
<ion-item class="iconRow" lines="full" style="--min-height: unset; font-size: larger; color: grey;">

  <ion-grid class="ion-no-padding">

    <ion-row class="ion-no-padding">

      <ion-col class="ion-no-padding" size="2" (click)="openScorecardMethod(match.players, match)">
        <ion-icon src="../../assets/icons/scoreboard3.svg"></ion-icon>
      </ion-col>

      <ion-col class="ion-no-padding" size="2" (click)="viewMatchFormat()">
        <ion-icon name="game-controller"></ion-icon>
      </ion-col>

      <!-- only show if logged in member created the match -->
      <ion-col class="ion-no-padding ion-text-right" *ngIf="match.organizerMemberId === accountService.member.id"
        (click)="editMatchFormat()">
        <ion-icon name="options"></ion-icon>
      </ion-col>

    </ion-row>

  </ion-grid>

</ion-item>

<!-- no team scores yet -->
<ng-template #noscores>
  <ion-row style="margin-left: 10px;">
    <ion-col size="12" class="ion-text-center" style="margin-top: 10px;">
      <ion-note style="font-size: smaller;">No scores have been posted for this match</ion-note>
    </ion-col>
  </ion-row>
</ng-template>

<!-- no teams yet -->
<ng-template #noteams>
  <ion-row style="margin-left: 10px;">
    <ion-col size="12" class="ion-text-center" style="margin-top: 10px;">
      <ion-note style="font-size: smaller;">Scoring hasn't started</ion-note>
    </ion-col>
  </ion-row>
</ng-template>