<ion-header class="ion-no-border">

  <ion-toolbar>

    <div slot="start" style="padding-left: 10px; font-weight: 500;">
      <ion-text>{{player.firstName}} {{player.lastName}}</ion-text>
    </div>

    <ion-buttons slot="primary">

      <ion-button fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>

    </ion-buttons>

  </ion-toolbar>

</ion-header>

<modal-content class="player-stats-content">

  <!-- fairway -->
  <ion-list>

    <ion-list-header>
      <ion-label>Fairway Hit</ion-label>
    </ion-list-header>

    <ion-item lines="none">

      <ion-grid class="counter ion-no-padding" style="padding-left: 0px; padding-top: 0px;">

        <ion-row class="ion-no-padding ion-align-items-center">

          <ion-col class="ion-text-center" size="auto">
            <div style="border-right: 1px solid var(--ion-color-primary); margin-right: 0px;">
              <ion-button shape="round" class="circle circleGreen"
                [ngClass]="{ circleOnGreen: this.player.nines[this.nineIndex].holes[this.holeIndex].fairway === FairwayHit.Yes }"
                (click)="setFairway(FairwayHit.Yes)" style="margin-top: 0px; margin-bottom: 0px;">
                <ion-icon slot="icon-only" name="checkmark"></ion-icon>
              </ion-button>
            </div>

          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].fairway === FairwayHit.Left }"
              (click)="setFairway(FairwayHit.Left)">
              <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].fairway === FairwayHit.Right }"
              (click)="setFairway(FairwayHit.Right)">
              <ion-icon slot="icon-only" name="arrow-forward"></ion-icon>
            </ion-button>
          </ion-col>

        </ion-row>

      </ion-grid>

    </ion-item>

  </ion-list>

  <!-- gir -->
  <ion-list>

    <ion-list-header>
      <ion-label>GIR</ion-label>
    </ion-list-header>

    <ion-item lines="none">

      <ion-grid class="counter ion-no-padding" style="padding-left: 0px; padding-top: 0px;">

        <ion-row class="ion-no-padding ion-align-items-center">

          <ion-col class="ion-text-center" size="auto">
            <div style="border-right: 1px solid var(--ion-color-primary); margin-right: 0px;">
              <ion-button shape="round" class="circle circleGreen"
                [ngClass]="{ circleOnGreen: this.player.nines[this.nineIndex].holes[this.holeIndex].gir === GreenInRegulation.Yes }"
                (click)="setGIR(GreenInRegulation.Yes)" style="margin-top: 0px; margin-bottom: 0px;">
                <ion-icon slot="icon-only" name="checkmark"></ion-icon>
              </ion-button>
            </div>

          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].gir === GreenInRegulation.Left }"
              (click)="setGIR(GreenInRegulation.Left)">
              <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].gir === GreenInRegulation.Long }"
              (click)="setGIR(GreenInRegulation.Long)">
              <ion-icon slot="icon-only" name="arrow-up"></ion-icon>
            </ion-button>
          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].gir === GreenInRegulation.Short }"
              (click)="setGIR(GreenInRegulation.Short)">
              <ion-icon slot="icon-only" name="arrow-down"></ion-icon>
            </ion-button>
          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].gir === GreenInRegulation.Right }"
              (click)="setGIR(GreenInRegulation.Right)">
              <ion-icon slot="icon-only" name="arrow-forward"></ion-icon>
            </ion-button>
          </ion-col>

        </ion-row>

      </ion-grid>
    </ion-item>

  </ion-list>

  <!-- putts -->
  <ion-list style="margin-bottom: 20px;">

    <ion-list-header>
      <ion-label>Putts</ion-label>
    </ion-list-header>

    <ion-item lines="none">

      <ion-grid class="counter ion-no-padding" style="padding-left: 0px; padding-top: 0px;">

        <ion-row class="ion-no-padding ion-align-items-center">

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].putts === 0 }"
              (click)="setPutts(0)">
              0
            </ion-button>
          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].putts === 1 }"
              (click)="setPutts(1)">
              1
            </ion-button>
          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].putts === 2 }"
              (click)="setPutts(2)">
              2
            </ion-button>
          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].putts === 3 }"
              (click)="setPutts(3)">
              3
            </ion-button>
          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].putts === 4 }"
              (click)="setPutts(4)">
              4
            </ion-button>
          </ion-col>

          <ion-col class="ion-text-center" size="auto">
            <ion-button shape="round" class="circle"
              [ngClass]="{ circleOn: this.player.nines[this.nineIndex].holes[this.holeIndex].putts === 5 }"
              (click)="setPutts(5)">
              5+
            </ion-button>
          </ion-col>

        </ion-row>

      </ion-grid>
    </ion-item>

  </ion-list>

</modal-content>