<ion-header class="preferences-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="secondary">
      <ion-button (click)="back()">
        <ion-icon name="chevron-back"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>

    <ion-title>{{title}}</ion-title>

  </ion-toolbar>

</ion-header>

<ion-content class="preferences-content">

  <ion-list>

    <ion-radio-group [value]="value">

      <ion-item *ngFor="let preference of preferences">
        <ion-label class="ion-text-wrap">{{ preference.description }}</ion-label>
        <ion-radio slot="start" [value]="preference.value" (click)="done(preference.value)"></ion-radio>
      </ion-item>

    </ion-radio-group>

  </ion-list>

</ion-content>