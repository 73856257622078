<ion-header class="app-update-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="primary" *ngIf="type===appUpdateTypeOptional">

      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Got it
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content class="app-update-content">

  <ion-list lines="none">

    <ion-item>
      <img class="auth-image" src="../../../assets/images/logowhitebackground.png" draggable="false">
    </ion-item>

    <ion-item>
      <h3 class="auth-title">Double Ace Golf Update</h3>
    </ion-item>

    <ion-item>
      <h6 class="auth-instructions" *ngIf="type===appUpdateTypeMandatory">Double Ace Golf has a new version that must be
        downloaded.</h6>
      <h6 class="auth-instructions" *ngIf="type===appUpdateTypeOptional">Double Ace Golf has a new version. Why not
        download it now!</h6>
    </ion-item>

    <ion-item>
      <div [innerHTML]="message" *ngIf="message" style="margin-bottom: 5px;"></div>
    </ion-item>

    <ion-item>
      <ion-button expand="block" shape="round" (click)="openAppStore()"
        style="width: 100%; height: 100%; font-size: 16px;">Update Double Ace Golf</ion-button>
    </ion-item>

  </ion-list>

</ion-content>