import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'validation-hero',
  templateUrl: './validation-hero.component.html',
  styleUrls: ['./validation-hero.component.scss'],
  animations: [
    trigger('validationHeroDisplay', [

      //"hide" is the "resting" state of the element when it is visible.
      state('hide', style({ height: 0, opacity: 0 })),

      transition('hide => show', [
        animate(300, style({ height: '100%', opacity: 1 }))
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition('show => hide',
        animate(300, style({ height: 0, opacity: 0 })))
    ])
  ]
})
export class ValidationHeroComponent implements OnInit {

  private _display: boolean = false;
  public displayState: string = 'hide';
  public _messages: string[] = [];
  public _header: string = 'Please correct the errors below.';

  constructor() { }

  ngOnInit() { }

  @Input() set display(value: boolean) {
    this.displayState = value ? 'show' : 'hide';
    this._display = value;
  }

  @Input() set header(value: string) {
    this._header = value;
  }

  @Input() set messages(value: string[]) {
    this._messages = value;
  }

  get display(): boolean {
    return this._display;
  }
}
