export const environment = {
  production: true,
  ALGOLIA_CONFIG: {
    appId: 'YAPIALTS11',
    writeAPIKey: 'ae6a7ed848b8c189d992fc2b30c336b3',
    searchAPIKey: '70791b13f09f73a86a44ce451dd10fa3',
    clubIndex: 'clubs-prod',
    groupIndex: 'groups-prod'
  },
  POSTHOG_CONFIG: {
    writeAPIKey: 'phc_r3k64XCgVG33pQGlZ1oAJZjjk9Y3N4X0cSke1nkUteP',
    projectId: 77043,
    environment: 'prod'
  },
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyAiVlfeIF9_C4i3Ij-YO_2BiYHqgHqkBf0",
    authDomain: "double-ace-golf.firebaseapp.com",
    projectId: "double-ace-golf",
    storageBucket: "double-ace-golf.appspot.com",
    messagingSenderId: "299535024097",
    appId: "1:299535024097:web:df1a4382fe1db3737e5ca0",
    measurementId: "G-GFB02KEZ7M",
  },
  noAvatarUriEmail: 'https://firebasestorage.googleapis.com/v0/b/double-ace-golf.appspot.com/o/app%2Favatar.png?alt=media&token=a0ae6a90-ea0a-4167-b583-21a321e7c723',
  cloudfunctions: 'us-central1-double-ace-golf',
  hosting: {
    app: "app.doubleacegolf.com",
    join: "join.doubleacegolf.com",
  },
  actionCodeSettings: {
    url: "https://app.doubleacegolf.com/email-link",
    handleCodeInApp: true,
    iOS: {
      bundleId: "com.doubleacegolf.app",
    },
    android: {
      packageName: 'com.doubleacegolf.app',
      installApp: true,
      minimumVersion: '1'
    },
    dynamicLinkDomain: 'doubleacegolfapp.page.link',
    dynamicLinkUri: 'https://double-ace-golf-app.firebaseapp.com'
  },
  passwordResetContinueUrl: "https://app.doubleacegolf.com/login"
};
