import { Component, Input } from '@angular/core';
import { AppFunction } from 'src/app/app.function';
import { AccountService } from 'src/app/app.account';
import { AppConfig } from 'src/app/app.config';
import { AppEvent, EventActionCd, EventType } from 'src/app/app.event';
import { EventDetailPage } from 'src/app/pages/event-detail/event-detail.page';
import { EventViewPage } from 'src/app/pages/event-view/event-view.page';
import { EventScoringPage } from 'src/app/pages/event-scoring/event-scoring.page';
import { MatchPlayerI } from 'src/app/app.match';

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent {

  @Input() event: AppEvent;
  AppConfig: typeof AppConfig = AppConfig;
  EventType: typeof EventType = EventType;

  constructor(public appFunction: AppFunction,
    public accountService: AccountService) {
  }

  async updateEvent(click: Event, event: AppEvent) {

    //prevent the list item click
    click.stopPropagation();

    //open event details page
    this.appFunction
      .modalCtrl
      .create({
        component: EventDetailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        backdropDismiss: false,
        componentProps: {
          event: event,
          group: event.group,
          editMode: AppConfig.EDIT_MODE.update
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('event-card.component.ts updateEvent modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-card.component.ts updateEvent modal create error', err);
      });

  }

  async viewEvent(click: Event, event: AppEvent, actionCd: EventActionCd = undefined) {

    //prevent the list item click
    click.stopPropagation();

    this.appFunction
      .modalCtrl
      .create({
        component: EventViewPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        backdropDismiss: false,
        canDismiss: true,
        componentProps: {
          event: event,
          actionCd: actionCd
        }
      })
      .then((modal) => {

        //pass in modal instance
        modal.componentProps.modal = modal;

        modal
          .present()
          .catch((err) => {
            console.log('home.ts viewEvent modal present error', err);
          });

      })
      .catch((err) => {
        console.log('home.ts viewEvent modal create error', err);
      });

  }

  joinEvent(click: Event, event: AppEvent) {

    //prevent the list item click
    click.stopPropagation();

    this.appFunction
      .loadingCtrl
      .create({ message: 'Joining event...' })
      .then((loading) => {

        loading.present();

        event
          .players
          .join(this.accountService.member.id)
          .then(() => {
            this.viewEvent(click, event, EventActionCd.Joined);
          })
          .finally(() => {
            loading.dismiss();
          });
      });

  }

  leaveEventConfirm(click: Event, player: MatchPlayerI) {

    //prevent the list item click
    click.stopPropagation();

    this.appFunction
      .alertCtrl
      .create({
        header: "Please Confirm!",
        message:
          "You are going to be removed from this Event. Are you sure you want to leave?",
        backdropDismiss: false,
        buttons: [
          {
            text: "No, stay",
            role: 'cancel',
            handler: () => {
              //no action
            },
          },
          {
            text: "Yes, leave",
            role: 'confirm',
            handler: () => {
              //popup dialog to enable permission
              this.leaveEvent(player);
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });

  }

  leaveEvent(player: MatchPlayerI) {

    this.appFunction
      .loadingCtrl
      .create({ message: 'Leaving event...' })
      .then((loading) => {

        loading.present();

        //remove player from event
        this.event
          .players
          .remove(player)
          .then(() => {
            loading.dismiss();
          });

      });

  }

  get numberOfButtons(): number {

    //get the number of ion-col elements in html
    const buttonRow = document.getElementById('buttonRow' + this.event.id);
    const ionColElements = buttonRow.getElementsByTagName('ion-col');

    //return 
    return 12 / ionColElements.length; //12 is the ion-grid layout max

  }

  async eventScoring(click: Event, event: AppEvent) {

    //prevent the list item click
    click.stopPropagation();

    this.appFunction
      .modalCtrl
      .create({
        component: EventScoringPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        backdropDismiss: false,
        canDismiss: true,
        componentProps: {
          event: event
        }
      })
      .then((modal) => {

        //pass in modal instance
        modal.componentProps.modal = modal;

        //open modal 
        modal
          .present()
          .catch((err) => {
            console.log('event-card.component.ts eventScoring modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-card.component.ts eventScoring modal create error', err);
      });

    //prevent the list item click
    click.stopPropagation();

  }

}
