import { Directive, Input, HostBinding } from '@angular/core';
import { AppConfig } from '../../app.config';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[imgLoader]'
})
export class ImgPreloadDirective {

  constructor(public domSanitizer: DomSanitizer) {
  }

  @HostBinding('attr.src') finalImage: any;

  //set an input so the directive can set a default image.
  @Input() defaultImage: string = AppConfig.SPINNER;

  @Input('imgLoader') set imgLoader(newImage: any) {

    try {

      //if no avatar then...
      if (newImage === AppConfig.NO_AVATAR_URI) {
        this.finalImage = this.domSanitizer.bypassSecurityTrustResourceUrl(AppConfig.NO_AVATAR_URI);
      } else {

        //else default image and get requested image
        this.finalImage = this.domSanitizer.bypassSecurityTrustResourceUrl(this.defaultImage);

        //create image object that will download requested image
        let downloadingImage: HTMLImageElement = new Image();

        //listen for the image download complete event
        downloadingImage.onload = () => {

          try {

            //set downloaded img
            this.finalImage = this.domSanitizer.bypassSecurityTrustResourceUrl(downloadingImage.src);

            //clean up
            downloadingImage.onload = undefined;
            downloadingImage = undefined;

          } catch (err) {
            console.log('img-preload.directive.ts imgLoader onload err', downloadingImage.src, err);
          }

        }

        //assign the src to that of some_remote_image_url. Once it is downloaded the "onload" will fire
        downloadingImage.src = newImage;

      }

    } catch (err) {
      console.log('img-preload.directive.ts imgLoader err', newImage, err);
    }

  }

}