import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, NavParams } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AppFunction, enterFromRightAnimation, leaveToRightAnimation } from '../../app.function';
import { ClubService, AppClubSearchI } from '../../app.club';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { ClubDetailPage } from '../club-detail/club-detail.page';

@Component({
  selector: 'club-search',
  templateUrl: './club-search.page.html',
  styleUrls: ['./club-search.page.scss'],
})
export class ClubSearchPage implements OnInit {

  clubs: AppClubSearchI[] = undefined;
  message: string;
  showDetail: boolean;
  private _clubsGeo: AppClubSearchI[] = undefined;

  constructor(
    public router: Router,
    public clubService: ClubService,
    public geolocation: Geolocation,
    public appFunction: AppFunction,
    public diagnostic: Diagnostic,
    public navParams: NavParams,
    public navCtrl: NavController) {

  }

  ngOnInit() {

    //what should happen when cluib is selected
    this.showDetail = this.navParams.get('showDetail') || false;

    //get location permissions
    this.appFunction
      .getLocationStatus()
      .then((status) => {

        //if status granted then search for courses
        if (this.appFunction.platform.is('capacitor') && (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE || status === 'not_determined')) {
          this.searchNearByClubs();
        }

      });

  }

  private searchNearByClubs() {

    this.appFunction
      .loadingCtrl
      .create({ message: 'Searching for near by clubs...' })
      .then((loading) => {

        loading.present();

        this.clubService
          .searchClubGeo()
          .then((clubsGeo) => {

            //save geo courses found, geo courses act as the default list when user is searching for a club
            this._clubsGeo = clubsGeo;

            //set geo club to the view
            this.clubs = clubsGeo;

            //set message in the event there are no results
            this.message = 'No clubs were found in your area. Try searching for a club name.';

            loading.dismiss();

          })
          .catch((err) => {
            console.log('club-search.page.ts searchNearByClubs searchCourseGeo error', err);
            loading.dismiss();
          });

      });

  }

  searchCriteriaChange(event: any) {

    //only perform a search if the user has entered criteria
    if (event.target.value && event.target.value.length > 0) {

      this.clubService
        .searchClub(event.target.value)
        .then((clubsFound) => {

          this.clubs = clubsFound;

          //set message in the event there are no results
          this.message = 'No clubs were found. Try changing the search terms.';

        })
        .catch((err) => {
          console.log('club-search.page.ts searchCriteriaChange search error', JSON.stringify(err));
        });

    } else {
      //if no criteria then restore geo search
      this.clubs = this._clubsGeo;
    }

  }

  searchCriteriaClear() {

    //if no criteria then restore geo search
    this.clubs = this._clubsGeo;

  }

  selectClub(club: AppClubSearchI) {

    try {

      this.clubService
        .getClub(club.clubId)
        .toPromise()
        .then(async (club) => {

          //
          if (this.showDetail) {

            this.appFunction
              .modalCtrl
              .create({
                component: ClubDetailPage,
                presentingElement: await this.appFunction.routerOutlet(),
                enterAnimation: enterFromRightAnimation,
                leaveAnimation: leaveToRightAnimation,
                cssClass: 'custom-modal', //for md
                componentProps: {
                  club: club,
                  closeLabel: 'Close'
                }
              })
              .then((modal) => {

                modal
                  .present()
                  .catch((err) => {
                    console.log('club-search.page.ts selectClub modal present error', err);
                  });

              })
              .catch((err) => {
                console.log('club-search.page.ts selectClub modal create error', err);
              });

          } else { //close

            this.appFunction
              .modalCtrl
              .dismiss({ club: club }, 'select')
              .catch((err) => {
                console.log('club-search.page.ts selectClub modal dismiss error', err);
              });

          }

        });

    } catch (err) {
      console.log('club-search.page.ts selectClub error', err);
    }

  }

  back() {
    this.appFunction.modalCtrl.dismiss(undefined, 'back');
  }

}
