<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>
    </ion-buttons>
    <ion-title>Comments</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- header -->
  <ion-item lines="none" style="--background: white; padding-top: 10px;">

    <!-- member avatar -->
    <avatar [member]="post.member" slot="start" *ngIf="post.member"></avatar>

    <!-- group avatar -->
    <ion-avatar slot="start" class="avatar-top" *ngIf="post.group">
      <img [imgLoader]="post.group.avatar.URI" draggable="false">
    </ion-avatar>

    <ion-label>
      <h2 *ngIf="post.member">{{post.member.firstName}} {{post.member.lastName}}</h2>
      <h2 *ngIf="post.group">{{post.group.name}}</h2>
      <p>{{post.getFromNow}}</p>
    </ion-label>

  </ion-item>

  <ion-card-content>
    <!-- be sure to keep this on one line. otherwise spaces will be added to the beginning of the post.text -->
    <div class="preserveFormat">{{post.text}}</div>
  </ion-card-content>

  <!-- footer -->
  <div class="ion-nowrap" style="padding-bottom: 5px;">

    <ion-grid>

      <ion-row>

        <ion-col size="3" style="padding-bottom: 0px;">
          <ion-note (click)="post.likePost()" class="iconTextSingleLine">
            <animated-like [liked]="post.memberLike"></animated-like>
            <ion-text>{{post.totalLikes}}&nbsp;like</ion-text>
            <ion-text *ngIf="post.totalLikes !== 1">s</ion-text>
          </ion-note>
        </ion-col>

        <ion-col size="6" style="padding-bottom: 0px;">
          <ion-note class="iconTextSingleLine">
            <ion-icon name="chatbubbles"></ion-icon>
            <ion-text>{{post.totalComments}}&nbsp;comment</ion-text>
            <ion-text *ngIf="post.totalComments !== 1">s</ion-text>
          </ion-note>
        </ion-col>

        <ion-col style="margin-right: 10px; padding-bottom: 0px;">
          <ion-icon name="ellipsis-horizontal" *ngIf="post.createdMemberId === accountService.member.id"
            (click)="deletePostConfirm(post)" style="font-size: 20px; float: right;"></ion-icon>
        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

  <!-- comments -->
  <ion-list
    style="border-top-style: solid; border-top-width: thin; border-top-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-250,#c8c7cc)))">

    <ion-item *ngFor="let comment of post.comments;">

      <avatar [member]="comment.member" slot="start"></avatar>

      <ion-label>
        <h2>{{comment.member.firstName}} {{comment.member.lastName}}</h2>
        <p>{{comment.getFromNow}}</p>
        <div class="preserveFormat" style="margin-top: 10px;">{{comment.text}}</div>
      </ion-label>

      <ion-icon name="ellipsis-horizontal" *ngIf="comment.memberId === accountService.member.id" slot="end"
        (click)="deleteCommentConfirm(comment)" color="dark" style="font-size: 20px; align-self: end;"></ion-icon>

    </ion-item>

  </ion-list>

</ion-content>

<ion-footer style="border-top-style: solid; border-top-width: thin; border-top-color: lightgray;">

  <form class="post-comment-form" [formGroup]="postCommentForm">

    <ion-item lines="none">
      <ion-label position="stacked">Comment</ion-label>
      <ion-textarea formControlName="text" [(ngModel)]="comment" auto-grow="true" autocomplete="on" autocorrect="on"
        autocapitalize="on" maxlength="200" placeholder="What's your comment?">
      </ion-textarea>
      <ion-button slot="end" shape="round" (click)="postComment()">Post</ion-button>
    </ion-item>
    <ion-item lines="none" class="validation" style="margin-bottom: 10px;">
      <ion-label
        *ngIf="postCommentForm.controls.text.hasError('required') && !postCommentForm.controls.text.valid && postCommentForm.controls.text.touched">
        Please enter your comment.</ion-label>
    </ion-item>

  </form>

</ion-footer>