import { Component, ViewChild, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AccountService, AppMember } from '../../app.account';
import { MediaService } from '../../app.media';
import { AppFunction, HelpService } from '../../app.function';
import { AppPost } from '../../app.social';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGroupI } from 'src/app/app.group';
import { CameraSource, CameraDirection } from '@capacitor/camera';

@Component({
  selector: 'post-new',
  templateUrl: './post-new.page.html',
  styleUrls: ['./post-new.page.scss']
})
export class PostNewPage implements OnInit {

  @ViewChild('myInput', { static: true }) text: any;
  @ViewChild('videoPlayer', { static: false }) videoPlayer: any;
  postForm: FormGroup;
  post: AppPost;
  poster: AppMember | AppGroupI;
  maxPostLength: number = 280;
  textLength: number = 0;
  viewEntered: boolean = false; //this fixes a bug with the slider where it's loading before the page
  muteIcon: string = 'volume-mute';
  isFormSubmitted: boolean = false;
  screenKey: string = 'post-new';

  // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
  slideOpts = {
    slidesPerView: 1.1,
    centeredSlides: true,
    spaceBetween: 10,
    setWrapperSize: true,
  };

  constructor(
    public builder: FormBuilder,
    public appFunction: AppFunction,
    public accountService: AccountService,
    public mediaService: MediaService,
    public navParams: NavParams,
    public helpService: HelpService) {

    //console.log('post-new.ts constructor');

    helpService.screenWhatsNew(this.screenKey);

  }

  ngOnInit() {

    //console.log('post-new.ts ngOnInit');

    //build form group
    this.postForm = this.builder.group({
      text: ['', Validators.required]
    });

    //create and init...
    this.post = new AppPost();
    this.post
      .initialize()
      .then(() => {

        //get member or group id
        this.poster = this.navParams.get('poster');
        if (this.poster instanceof AppMember) {

          this.post.memberId = (<AppMember>this.poster).id; //this.navParams.get('memberId');
          this.post.member = <AppMember>this.poster;

        } else if (this.poster as AppGroupI) {

          this.post.groupId = (<AppGroupI>this.poster).id; //this.navParams.get('groupId');
          this.post.group = <AppGroupI>this.poster;

        } else {
          console.error('post-new.ts ngOnInit invalid poster');
        }

        //set who's creating the post
        this.post.createdMemberId = this.accountService.member.id;

      });


  }

  ionViewDidLoad() {

    //console.log('post-new.ts ionViewDidLoad');
    this.text.setFocus();
  }

  ionViewDidEnter() {

    //this fixes a bug with the slider where it's loading before the page
    this.viewEntered = true;

  }

  openCamera() {

    console.log('post-new.ts openCamera');

    this.mediaService
      .getPicture(CameraSource.Camera, CameraDirection.Rear)
      .then(
        (fileURI) => {

          console.log('post-new.ts openCamera getPicture', fileURI);

          this.post.media.push(fileURI);

          //mark form as dirty
          this.postForm.markAsDirty();

        },
        (err) => {
          this.setFocusPostText();
        });

  }

  openVideo() {

    console.log('post-new.ts openVideo');

    this.mediaService
      .recordVideo()
      .then((fileURI) => {

        console.log('post-new.ts openCamera recordVideo', fileURI);

        if (fileURI) {

          //add to list of media objects
          this.post.media.push(fileURI);

          //mark form as dirty
          this.postForm.markAsDirty();

        }

      })
      .catch((err) => {
        console.log('post-new.ts openCamera recordVideo error', JSON.stringify(err));
        this.setFocusPostText();
      });

  }

  openGallery() {

    //console.log('post-new.ts openGallery');

    this.mediaService
      .getPicture(CameraSource.Photos)
      .then(
        (fileURI) => {

          console.log('post-new.ts openGallery getPicture', fileURI);

          this.post.media.push(fileURI);

          //mark form as dirty
          this.postForm.markAsDirty();

        })
      .catch((err) => {
        console.log('post-new.ts openGallery getPicture error', err);
        //this.setFocusPostText();
      });

  }

  removeMedia(index: number) {

    //console.log('post-new.ts removeMedia');
    this.post.media.splice(index, 1);

  }

  setFocusPostText() {

    //console.log('post-new.ts setFocusPostText');

    //set focus and shoe keyboard
    setTimeout(this.text.setFocus(), 1);

  }

  editImage() {

    //console.log('post-new.ts editImage');

  }

  playVideo(player: HTMLVideoElement) {

    //console.log('post-new.ts playVideo', player);

    try {

      player
        .play()
        .then(() => {
          console.log('post-new.ts playVideo play success');
        })
        .catch((err) => {
          console.log('post-new.ts playVideo play error', err);
        });

    } catch (err) {
      console.log('post-new.ts playVideo error', err);
    }

  }

  mute(player: HTMLVideoElement) {

    console.log('mute', player.muted);

    player.muted = !player.muted;

    if (player.muted) {
      this.muteIcon = 'volume-mute';
    } else {
      this.muteIcon = 'volume-high';
    }

  }

  postCount() {
    this.textLength = this.text.value.length;
  }

  done() {

    //console.log('post-new.ts done');

    //save the form if dirty
    if (this.postForm.dirty) {

      //signal validation toast that form has been submitted
      this.isFormSubmitted = true;

      //save the form if valid 
      if (this.postForm.valid) {

        //console.log('post-new.ts done valid');

        this.appFunction
          .loadingCtrl
          .create({ message: 'Saving post...' })
          .then((loading) => {

            loading.present();

            //get post text...
            this.post.text = this.postForm.controls.text.value;

            //...then save 
            this.post
              .save()
              .finally(() => {
                loading.dismiss();
                this.appFunction.modalCtrl.dismiss();
              });

          });

      } else {
        //show any untouched errors
        this.appFunction.setDirtyControlAsTouched(this.postForm);
      }

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

  cancel() {

    //console.log('post-new.ts cancel');

    //confirm that user wants to discard changes
    if (this.postForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your post. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
                /* this.setFocusPostText(); */
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.appFunction.modalCtrl.dismiss();
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

}
