<ion-header class="network-header ion-no-border">

  <ion-toolbar>

  </ion-toolbar>

</ion-header>

<ion-content class="network-content">

  <img class="auth-image" src="../../../assets/images/logowhitebackground.png" draggable="false">

  <h3 class="auth-title">Double Ace Golf has an issue</h3>

  <h6 class="auth-instructions">No Internet connection found. Please check your network settings.</h6>

  <img src="assets/images/noconnection.png" class="center" height="100" style="margin-top: 50px" draggable="false">

</ion-content>