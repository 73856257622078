import { Component, Input, OnInit } from '@angular/core';
import { AppEventPlayer } from 'src/app/app.event';
import { MatchPlayerI } from 'src/app/app.match';

@Component({
  selector: 'event-player-score',
  templateUrl: './event-player-score.component.html',
  styleUrls: ['./event-player-score.component.scss']
})
export class EventPlayerScoreComponent {

  //get from the host html
  @Input() player: MatchPlayerI;
  @Input() nineIndex: number;
  @Input() holeIndex: number;
  private _startingScore: number[] = [];

  constructor() {
    //console.log('event-player-score.page.ts constructor');
  }

  /* ngOnInit() {
    //console.log('event-player-score.page.ts ngOnInit', this.player.id);
  } */

  get startingScore(): number {
    return this._startingScore[(this.nineIndex * 9) + (this.holeIndex + 1)];
  }

  get endingScore(): number {
    return this.startingScore + 4;
  }

  set startingScore(startingScore: number) {
    this._startingScore[(this.nineIndex * 9) + (this.holeIndex + 1)] = startingScore;
  }

  get scoresToDisplay(): number[] {

    try {

      const scoresToDispay: number[] = [];
      const birdieScore: number = this.player.nines[this.nineIndex].holes[this.holeIndex].par - 1; //by default the birdie score should be the first score to display

      //determine the first score to display
      const startingScore: number = Math.min(this.startingScore || birdieScore);

      //store startingScore in player data (this will be use when scrolling the score slider)
      this.startingScore = startingScore;

      //now build array of score numbers to display
      for (var scoreToDisplay = (startingScore); scoreToDisplay < startingScore + 5; scoreToDisplay++) {
        scoresToDispay.push(scoreToDisplay);
      }

      return scoresToDispay;

    } catch (err) {
      console.log('event-player-score.page.ts scoresToDisplay error', err);
    }

  }

  scrollScore(offset: number) {
    this.startingScore = (this.startingScore || this.player.event.nines[this.nineIndex].holes[this.holeIndex].par - 1) + offset;
  }

}
