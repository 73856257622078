import { Component, OnInit } from '@angular/core';
import { AppMember, AccountService } from '../../app.account';
import { NavParams } from '@ionic/angular';
import { AppFunction } from '../../app.function';
import { MemberDetailPage } from '../member-detail/member-detail.page';

@Component({
  selector: 'member-search',
  templateUrl: './member-search.page.html',
  styleUrls: ['./member-search.page.scss'],
})
export class MemberSearchPage implements OnInit {

  private _searchMembers: AppMember[] = [];
  private _returnSelectedMember: boolean;
  firstSearch: boolean = false;

  constructor(
    public appFunction: AppFunction,
    public accountService: AccountService,
    public navParams: NavParams) {

  }

  ngOnInit() {
    this._returnSelectedMember = this.navParams.get('returnSelectedMember') || false;
  }

  searchCriteriaChange(event: any) {

    this.accountService
      .memberSearch(event)
      .then((membersFound) => {

        this._searchMembers = membersFound;

        //indicate that at least the fiorst search has occurred
        this.firstSearch = true;

      });

  }

  searchCriteriaClear() {
    this._searchMembers = [];
  }

  async selectMember(member: AppMember) {

    if (this._returnSelectedMember) {
      this.appFunction.modalCtrl.dismiss({ member: member });
    } else {

      this.appFunction
        .modalCtrl
        .create({
          component: MemberDetailPage,
          presentingElement: await this.appFunction.routerOutlet(),
          cssClass: 'custom-modal', //for md
          backdropDismiss: false,
          componentProps: {
            member: member
          }
        })
        .then((modal) => {

          modal
            .present()
            .catch((err) => {
              console.log('member-search.ts selectMember modal present error', err);
            });

        })
        .catch((err) => {
          console.log('member-search.ts selectMember modal create error', err);
        });

    }

  }

  get searchMembers(): AppMember[] {

    //don't show deleted members
    return this._searchMembers.filter((member) => {
      return member.exists;
    });

  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}