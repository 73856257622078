import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { AppFunction, AppHelpTopic, HelpService } from 'src/app/app.function';
import { HelpDetailPage } from '../help-detail/help-detail.page';

@Component({
  selector: 'help-list',
  templateUrl: './help-list.page.html',
  styleUrls: ['./help-list.page.scss'],
})
export class HelpListPage implements OnInit {

  helpTopics: AppHelpTopic[] = [];

  constructor(
    public _appFunction: AppFunction,
    public helpService: HelpService) {

  }

  ngOnInit() {

    //get help topics
    this.helpService
      .getHelpTopics()
      .then((helpTopics) => {
        this.helpTopics = helpTopics;
      })

  }

  async newHelpTopic() {

    this._appFunction
      .modalCtrl
      .create({
        component: HelpDetailPage,
        presentingElement: await this._appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        componentProps: {
          editMode: AppConfig.EDIT_MODE.new
        }
      })
      .then((modal) => {
        modal
          .present()
          .catch((err) => {
            console.log('help-list.ts newHelpTopic modal present error', err);
          });
      })
      .catch((err) => {
        console.log('help-list.ts newHelpTopic modal create error', err);
      });

  }

  async helpTopicDetail(helpTopic: AppHelpTopic) {

    this._appFunction
      .modalCtrl
      .create({
        component: HelpDetailPage,
        presentingElement: await this._appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        componentProps: {
          helpTopic: helpTopic,
          editMode: AppConfig.EDIT_MODE.update
        }
      })
      .then((modal) => {
        modal
          .present()
          .catch((err) => {
            console.log('help-list.ts helpTopicDetail modal present error', err);
          });
      })
      .catch((err) => {
        console.log('help-list.ts helpTopicDetail modal create error', err);
      });

  }

  done() {
    this._appFunction.modalCtrl.dismiss();
  }

}
