<div class="main">

  <!-- chatbot conversation -->
  <div id="chatbotConversation" class="chatbotConversation">

    <!-- this is the scrollable region for the chat bot conversation -->
    <div id="chatbotContainer" class="chatbotContainer">

      <ng-container *ngFor="let chat of chats">

        <div *ngFor="let prompts of chat.prompts" class="chat-bubble chat-bubble-left" @animateBubbleLeft
          (@animateBubbleLeft.done)="chat.getNextPrompt()">{{prompts}}
        </div>

        <!-- display all the answer options of a chat -->
        <div class="mainAnswerContainer" [id]="chat.id" *ngIf="chat.chatConfig.answers?.length > 0">
          <div [id]="'answerContainer-' + chat.id" class="answerContainer">
            <div [id]="answer.id + '-' + chat.id" *ngFor="let answer of chat.answers"
              class="answerButton hideAnswerButton" (click)="chat.answerSelected(answer);">
              {{answer.display}}
            </div>
          </div>
        </div>

        <!-- display the user input of a chat -->
        <div class="enteredInput" [id]="'userInputContainer-' + chat.id" *ngIf="chat.chatConfig.input">
          <div class="chat-bubble chat-bubble-right confetti-button" style="position: absolute;" [id]="'userInputChat-' + chat.id">
            {{chat.enteredInput}}
          </div>
        </div>

        <!-- display the ai response of a chat -->
        <div class="aiResponse chat-bubble chat-bubble-left" [id]="'aiResultsContainer-' + chat.id"
          *ngIf="chat.chatConfig.openAiResponse?.id">
          <div>
            <div style="height: 20px;" *ngIf="!chat.chatConfig.openAiResponse.results">
              <div loader class="loader"></div>
            </div>

            <div *ngIf="chat.chatConfig.openAiResponse.results">{{chat.chatConfig.openAiResponse.results}}</div>
          </div>
        </div>

      </ng-container>

    </div>

  </div>

  <!-- ion-textarea -->
  <div id="chatbotInput" class="chatbotInput">
    <ion-item lines="none">
      <ion-textarea #chatbotTextarea autoGrow="true" autocapitalize="sentences" enterkeyhint="go"></ion-textarea>
      <div (click)="sendChatInput(chatbotTextarea)" slot="end"><i class="fa-solid fa-paper-plane"></i></div>
    </ion-item>
  </div>

</div>