import { Directive, HostListener, Input, Renderer2, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[fabClose]'
})
export class FABCloseDirective {

  @Input() fabClose;
  private listernerHandle: any
  private backdrop: HTMLDivElement;
  private parent: HTMLElement = undefined;

  constructor(private viewContainerRef: ViewContainerRef, private renderer: Renderer2) {
    //create the backdrop div and set the style
    this.backdrop = this.renderer.createElement('div');
  }

  @HostListener('click', ['$event']) onClick() {

    //if parent is not set then create the backdrop and listen for outside click
    if (this.parent === undefined) {

      //get the z-index of the host fab element
      const hostElementzIndex = window.getComputedStyle(this.fabClose.el).getPropertyValue('z-index');

      //grandparent of the fabClose element (should be ion-page element)
      this.parent = this.fabClose.el.parentElement.parentElement;

      //set the backdrop style
      this.renderer.setStyle(this.backdrop, 'position', 'fixed');
      this.renderer.setStyle(this.backdrop, 'top', '0');
      this.renderer.setStyle(this.backdrop, 'left', '0');
      this.renderer.setStyle(this.backdrop, 'width', '100%');
      this.renderer.setStyle(this.backdrop, 'height', '100%');
      this.renderer.setStyle(this.backdrop, 'z-index', (parseInt(hostElementzIndex) - 1).toString());

      //listen for click event on the backdrop
      this.listernerHandle = this.renderer.listen(this.backdrop, 'click', () => {

        //remove the backdrop and remove the listener
        this.listernerHandle();
        this.renderer.removeChild(this.parent, this.backdrop);
        this.parent = undefined;

        //close the fab
        this.viewContainerRef.element.nativeElement.close();

      });

      //append the backdrop to the parent
      this.renderer.appendChild(this.parent, this.backdrop);

    } else {
      //if parent is set then remove the backdrop and remove the listener
      this.listernerHandle();
      this.renderer.removeChild(this.parent, this.backdrop);
      this.parent = undefined;
    }



  }

}

