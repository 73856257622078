import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppFunction, DeepLinkParmsI } from 'src/app/app.function';

@Component({
  selector: 'deeplink-welcome-message',
  templateUrl: './deeplink-welcome-message.page.html',
  styleUrls: ['./deeplink-welcome-message.page.scss'],
})
export class DeeplinkWelcomeMessagePage implements OnInit {

  deepLinkParms: DeepLinkParmsI;
  message: string;

  constructor(public navParams: NavParams,
    public appFunction: AppFunction) {

    //console.log('deeplink-welcome-message.page.ts constructor');

  }

  ngOnInit() {

    //console.log('deeplink-welcome-message.page.ts ngOnInit');

    this.deepLinkParms = this.navParams.get('deepLinkParms');

    //update the message
    this.deepLinkParms.emailHasAccount ? this.message = 'login' : this.message = 'create an account';

  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}
