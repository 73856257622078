<div [ngClass]="{ 
    'chat-bubble-right': chatMessage.type === messageType.MSG_REQUEST, 
    'chat-bubble-left': chatMessage.type === messageType.MSG_RESPONSE}">
  <div class="chat-bubble tail" *ngIf="chatMessage.message">
    <div class="message">{{chatMessage.message}}</div>
  </div>
  <div class="chat-bubble chat-bubble-ellipsis tail" *ngIf="!chatMessage.message">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>