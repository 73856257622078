<ion-header class="post-new-header ion-no-border">
  <ion-toolbar>
    <ion-title>
      New Post
    </ion-title>
    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" [screenKey]="screenKey">
        <ion-icon name="help-circle-outline"></ion-icon>
      </ion-button>
      <ion-button *ngIf="postForm.dirty" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-buttons slot="primary">
      <ion-button *ngIf="postForm.dirty" fill="solid" shape="round" (click)="done()">Post</ion-button>
      <ion-button *ngIf="!postForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>

  <!-- validation hero -->
  <validation-hero [display]="isFormSubmitted && postForm.invalid"></validation-hero>
</ion-header>

<ion-content #content class="post-new-content" fullscreen="false">

  <form class="post-new-form" [formGroup]="postForm">

    <ion-list>

      <!-- member avatar and name -->
      <ion-item lines="none" *ngIf="post.member">

        <avatar [member]="post.member" slot="start" class="avatar-top"></avatar>

        <ion-label>
          <h2>{{post.member.firstName}} {{post.member.lastName}}</h2>
          <p>{{post.member.email}}</p>
        </ion-label>

      </ion-item>

      <!-- group avatar and name -->
      <ion-item lines="none" *ngIf="post.group">

        <ion-avatar slot="start" class="avatar-top">
          <img [imgLoader]="post.group.avatar.URI" draggable="false">
          <!-- <img [src]="post.group.avatar.URI" draggable="false"> -->
        </ion-avatar>

        <ion-label>
          <h2>{{post.group.name}}</h2>
          <p>by {{accountService.member.firstName}} {{accountService.member.lastName}}</p>
        </ion-label>

      </ion-item>

      <!-- text -->
      <ion-item lines="none">
        <ion-textarea #myInput (input)="postCount()" formControlName="text" placeholder="Tell a golf story!"
          autocomplete="on" autocorrect="on" autocapitalize="on" auto-grow="true" [maxlength]="maxPostLength" rows="5">
        </ion-textarea>
      </ion-item>
      <ion-item lines="none" class="validation" style="margin-bottom: 10px;">
        <ion-label
          *ngIf="postForm.controls.text.hasError('required') && !postForm.controls.text.valid && postForm.controls.text.touched">
          Please enter some text.</ion-label>
        <ion-label slot="end" class="remainingText ion-text-right" color="secondary">{{textLength}}/{{maxPostLength}}
        </ion-label>
      </ion-item>

      <!-- media -->
      <ion-item lines="none" style="--padding-start: 0px; --inner-padding-end: 0px;"
        *ngIf="viewEntered && appFunction.platform.is('capacitor')">

        <ion-slides #slider pager="false" [options]="slideOpts" style="width: 100%;">

          <ion-slide *ngFor="let fileURI of post.media; let i = index">

            <div class="container" *ngIf="mediaService.isExtension(fileURI, ['jpeg', 'jpg', 'png'])">

              <img [src]="fileURI | sanitizeUrl" draggable="false">

              <div class="bottom-right" (click)="removeMedia(i)">
                <ion-icon name="close-circle" style="color: white; font-size: 30px;"></ion-icon>
              </div>

            </div>

            <div class="container" *ngIf="mediaService.isExtension(fileURI, ['mov', 'mp4'])">

              <video #videoPlayer playsinline (click)="playVideo(videoPlayer)" canplay="videoPlayer.muted = true">
                <source [src]="fileURI + '#t=0.1' | sanitizeUrl" type="video/mp4">
              </video>

              <div class="bottom-left" (click)="mute(videoPlayer)">
                <ion-icon [name]="muteIcon" style="color: white; font-size: 30px;"></ion-icon>
              </div>

              <div class="bottom-right" (click)="removeMedia(i)">
                <ion-icon name="close-circle" style="color: white; font-size: 30px;"></ion-icon>
              </div>

            </div>

          </ion-slide>

        </ion-slides>

      </ion-item>

    </ion-list>

    <ion-fab #fab vertical="bottom" horizontal="end" slot="fixed" haptic *ngIf="appFunction.platform.is('capacitor')"
      [fabClose]="fab">
      <ion-fab-button>
        <ion-icon name="image"></ion-icon>
      </ion-fab-button>
      <ion-fab-list side="top">
        <ion-button shape="round" (click)="openVideo(); fab.close();">
          <ion-icon name="videocam" slot="start"></ion-icon>
          <ion-label>Take a video</ion-label>
        </ion-button>
        <ion-button shape="round" (click)="openGallery(); fab.close();">
          <ion-icon name="images" slot="start"></ion-icon>
          <ion-label>Select from gallery</ion-label>
        </ion-button>
        <ion-button shape="round" (click)="openCamera(); fab.close();">
          <ion-icon name="camera" slot="start"></ion-icon>
          <ion-label>Take a picture</ion-label>
        </ion-button>
      </ion-fab-list>
    </ion-fab>

  </form>

</ion-content>