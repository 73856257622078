<ion-header class="member-list-header ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>
    </ion-buttons>
    <ion-title>Group Members</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="member-list-content">

  <ion-searchbar (ionChange)="searchCriteriaChange($event)" (ionClear)=searchCriteriaClear($event)
    show-cancel-button="focus" inputmode="search"></ion-searchbar>

  <ion-list class="itemLines">

    <ion-item *ngFor="let groupMember of filteredMembers">

      <avatar [member]="groupMember.member" slot="start"></avatar>

      <ion-label>
        <h2>{{groupMember.member.firstName}} {{groupMember.member.lastName}}</h2>
        <h4>{{groupMember.member.email}}</h4>
      </ion-label>

      <ion-button class="listAddButton" fill="solid" shape="round" (click)="addMember(groupMember.member);" slot="end"
        *ngIf="!memberSelected(groupMember.member.id)">Add
      </ion-button>
      <ion-icon name="checkmark-circle" *ngIf="memberSelected(groupMember.member.id)" slot="end"
        style="font-size: 25px; color: var(--ion-color-yes);"></ion-icon>

    </ion-item>

  </ion-list>

  <ion-modal #modal class="selectedMembers" [initialBreakpoint]="0.25" [breakpoints]="[0.1, 0.25, 0.5, 0.75]"
    [isOpen]="showSelectedMembersModal" [backdropDismiss]="false" [backdropBreakpoint]="0.5">
    <ng-template>
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <ion-title>Selected Members</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list class="itemLines">

          <ion-item *ngFor="let member of selectedMembers">

            <avatar [member]="member" slot="start"></avatar>

            <ion-label>
              <h2>{{member.firstName}} {{member.lastName}}</h2>
              <h4>{{member.email}}</h4>
            </ion-label>

            <ion-button class="listRemoveButton" fill="solid" shape="round" (click)="removeSelectedMember(member);"
              slot="end" *ngIf="memberSelected(member.id)">Remove
            </ion-button>

          </ion-item>

        </ion-list>
      </ion-content>
    </ng-template>
  </ion-modal>

</ion-content>