import { Injectable } from '@angular/core';
import { AppConfig, EventJoinDropNotificationPreference, EventEmailPreference, EventNotificationPreference, EventJoinDropEmailPreference } from './app.config';
import { AccountService, AppMember } from './app.account';
import { AppFunction, enterFromRightAnimation, leaveToRightAnimation, SortByOrder, AppClassI, DeepLinkParmsI, DeepLinkCampaign, DeepLinkChannel, DeepLinkService, PublishSubscribeTopics } from './app.function'
import firebase from 'firebase/compat/app';
import { AppGroupEvent, AppGroupEventMember, AppGroupI, AppGroupTrip, AppGroupTripMember, GroupType, memberGroupPreferences } from './app.group';
import { AppClub, AppCourse, AppCourseHoleI, AppCourseNineI, AppTee, ClubService } from './app.club';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ContactSearchPage } from './../app/pages/contact-search/contact-search.page';
import { ContactPage } from './../app/pages/contact/contact.page';
import * as moment from 'moment';
import { EmailPage } from './pages/email/email.page';
import { ActivePlayersI, AppMatch, AppMatchParentI, MatchEventsI, MatchPlayerI, MatchesI, PlayersI } from './app.match';
import PublishSubscribe from 'publish-subscribe-js';

export enum ScoringMode {
    ScoringNotStarted = 0,
    ScoringActive = 1,
    ScoringComplete = 2,
    ValidationErrors = 3
}

export enum EventActionCd {
    AskToJoin = 0,
    Joined = 1,
    Leave = 2,
    MemberJoined = 3, //for admin when member joins
    MemberDropped = 4, //for admin when member drops
}

export enum EventType {
    GroupEvent = GroupType.Event,
    GroupTrip = GroupType.Trip,
    Personal = 3 //non-group event
}

export enum CommunicationType {
    GroupMembers = 1,
    EventPlayers = 2
}

export enum GreenInRegulation {
    Yes = 1,
    Left = 2,
    Long = 3,
    Right = 4,
    Short = 5,
}

export enum FairwayHit {
    Yes = 1,
    Left = 2,
    Right = 3
}

export interface PubSubMatchPlayerHoleI {
    event: AppEvent;
    player: MatchPlayerI
    playerHole?: AppEventPlayerHole;
}

export interface AppTeeTimeI {
    time: string,
    teeTime: Date,
    players: AppEventPlayer[],
    playerCount(): number
}

export interface AppEventPlayerHoleI {
    grossScore: number;
    gir: number;
    putts: number;
    fairway: number;
    strokes: number;
    number: number;
    par?: number;
    event?: AppEvent; //i made this optional because it's not needed when saving to the database
}

export class AppEventPlayerHole implements AppEventPlayerHoleI {

    private _nineIndex: number;
    private _nineHoleIndex: number;
    private _courseHoleIndex: number;
    private _grossScore: number;
    private _gir: number;
    private _putts: number;
    private _fairway: number;
    private _strokes: number;
    private _number: number;
    private _hdcp: number;
    private _par: number;

    constructor(public player: AppEventPlayer) {

    }

    initialize(nineIndex: number, nineHoleIndex: number, courseHole: AppCourseHoleI, playerHole: AppEventPlayerHoleI) {

        this._nineIndex = nineIndex;
        this._nineHoleIndex = nineHoleIndex;
        this._courseHoleIndex = courseHole.number - 1;
        this._gir = playerHole?.gir;
        this._grossScore = playerHole?.grossScore;
        this._putts = playerHole?.putts;
        this._fairway = playerHole?.fairway;
        this._strokes = playerHole?.strokes;
        this._hdcp = courseHole.hdcp;
        this._par = courseHole.par;
        this._number = courseHole.number;

    }

    get event(): AppEvent {
        return this.player.event;
    }

    get gir(): number {
        return this._gir;
    }

    set gir(gir: number) {

        if (this._gir !== gir) {
            this._gir = gir;
            this.player.markAsDirty();
        }

    }

    get netScore(): number {
        return (this._grossScore === undefined) ? undefined : this._grossScore - this.strokes;
    }

    get grossScore(): number {
        return this._grossScore;
    }

    set grossScore(grossScore: number) {

        //if score has changed then...
        if (grossScore !== null && this._grossScore !== grossScore) {

            //if this hole's score was previously null (but now it has a value) then set courseHoleThruIndex
            if ([null, undefined].includes(this._grossScore)) {
                this.player.courseHoleThruIndex = this._courseHoleIndex;
            }

            //set score
            this._grossScore = grossScore;

            //make sure player saves
            this.player.markAsDirty();

            //calc score for the nines
            this.player.calcNineHoleScore();

        }

    }

    get putts(): number {
        return this._putts;
    }

    set putts(putts: number) {

        if (this._putts !== putts) {
            this._putts = putts;
            this.player.markAsDirty();
        }

    }

    get fairway(): number {
        return this._fairway;
    }

    set fairway(fairway: number) {

        if (this._fairway !== fairway) {
            this._fairway = fairway;
            this.player.markAsDirty();
        }

    }

    get scoreEntered(): boolean {
        return Number.isInteger(this.grossScore);
    }

    get strokes(): number {
        return this._strokes;
    }

    set strokes(strokes: number) {

        if (this._strokes !== strokes) {
            this._strokes = strokes;
            this.player.markAsDirty();
        }

    }

    get toParGross(): number {
        return this.scoreEntered ? this.grossScore - this.par : 0;
    }

    get toParNet(): number {
        return this.scoreEntered ? this.grossScore - this.strokes - this.par : 0;
    }

    get displayNumber(): number {
        return this._courseHoleIndex + 1;
    }

    get hdcp(): number {
        return this._hdcp;
    }

    get par() {
        return this._par;
    }

    get nineIndex(): number {
        return this._nineIndex;
    }

    get number(): number {
        return this._number;
    }

    get nineHoleIndex(): number {
        //this is the index within the 9 holes (0 thru 8)
        return this._nineHoleIndex;
    }

    get courseHoleIndex(): number {
        //this is the index within the 18 holes (0 thru 17)
        return this._courseHoleIndex;
    }

    data(): AppEventPlayerHoleI {

        try {

            return {
                gir: this.gir || null,
                grossScore: this.grossScore || null,
                putts: this.putts || null,
                fairway: this.fairway || null,
                strokes: this.strokes || 0,
                number: this._number
            };

        }
        catch (err) {
            console.log('app.event.ts AppEventPlayerHole data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

export interface AppPlayerScoreNineI {
    holes: AppEventPlayerHole[],
    score: number
}

export interface AppEventPlayerI {
    memberId: string;
    eventId: string;
    note: string;
    position: number;
    teeTime: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
    updatedDt: firebase.firestore.Timestamp;
    status: boolean;
    parentPlayerId: string;
    infoConfirmed: boolean;
    scoreConfirmed: boolean;
    handicapIndex: number;
    teeId: string;
    teeHandicap: number;
    firstName: string;
    lastName: string;
    nines: AppPlayerScoreNineI[];
    courseHoleThruIndex: number;
    startingHoleIndex: number;
}

export class AppEventPlayer implements AppEventPlayerI, MatchPlayerI {

    id: string;
    member: AppMember;
    eventId: string;
    position: number = 1000;
    createdDt: firebase.firestore.Timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    updatedDt: firebase.firestore.Timestamp;
    parentPlayerId: string;
    event: AppEvent;
    dynamicData: any = {};
    private _memberId: string;
    private _saved: boolean = false;
    private _note: string;
    private _courseHoleThruIndex: number = -1;
    private _dirty: boolean = false;
    private _status: boolean = true;
    private _firstName: string;
    private _lastName: string;
    private _infoConfirmed: boolean = false;
    private _scoreConfirmed: boolean = false;
    private _playerScoreNines: AppPlayerScoreNineI[];
    private _handicapIndex: number;
    private _teeId: string;
    private _tee: AppTee;
    private _startingHoleIndex: number;
    private _teeTime: firebase.firestore.Timestamp = firebase.firestore.Timestamp.fromDate(AppConfig.WAIT_LIST_TEE_TIME);
    private _playerDoc: firebase.firestore.DocumentSnapshot;
    private _appFunction: AppFunction;
    private _accountService: AccountService;
    private _courseId: string;
    private _nineHolesOnlyIndex: number

    constructor() {

        //get services
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);
        this._accountService = AppFunction.serviceLocator.get(AccountService);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.event.ts AppEventPlayer shutdown', this.id);
            });

    }

    initialize(memberId: string, event: AppEvent, playerDoc: firebase.firestore.DocumentSnapshot = undefined): Promise<AppEventPlayer> {

        return new Promise<AppEventPlayer>((resolve) => {

            //set event and tee information
            this.event = event;

            //if player already exists...
            if (playerDoc) {

                //save doc, this will be used when saving
                this._playerDoc = playerDoc;
                this.id = playerDoc.id;

                //get member
                this.getMember(memberId)
                    .then(() => {
                        this.update(playerDoc, AppConfig.UPDATE_TYPE.Added);
                        resolve(this);
                    });

            } else { //else player is new created in this session...

                //create new player doc ref
                const playerDoc: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.EventPlayers).doc();

                //now get it, it should be empty as this is a new player object
                playerDoc
                    .get()
                    .then((newPlayerDoc) => {

                        //save doc, this will be used when saving
                        this._playerDoc = newPlayerDoc;
                        this.id = playerDoc.id;

                        //get member, doing it here because it shouldn't change once the player has been created
                        this.getMember(memberId)
                            .then(() => {
                                //set to dirty so that this player saves (do we really need this?)
                                this.markAsDirty();
                                resolve(this);
                            });

                    });

            }

        });

    }

    update(updatedPlayer: firebase.firestore.DocumentSnapshot, updateType: string) {

        try {

            this._appFunction
                .ngZone
                .run(() => {

                    if (updateType === AppConfig.UPDATE_TYPE.Added || updateType === AppConfig.UPDATE_TYPE.Modified) {

                        this.position = updatedPlayer.data().position || 1000;
                        this.note = updatedPlayer.data().note || undefined;
                        this.status = updatedPlayer.data().status;
                        this._infoConfirmed = updatedPlayer.data().infoConfirmed || false;
                        this.teeTime = updatedPlayer.data().teeTime ? updatedPlayer.data().teeTime : firebase.firestore.Timestamp.fromDate(AppConfig.WAIT_LIST_TEE_TIME);
                        this._memberId = updatedPlayer.data().memberId;
                        this.eventId = updatedPlayer.data().eventId;
                        this.createdDt = updatedPlayer.data().createdDt;
                        this.updatedDt = updatedPlayer.data().updatedDt;
                        this.parentPlayerId = updatedPlayer.data().parentPlayerId ? updatedPlayer.data().parentPlayerId : undefined;
                        this._firstName = updatedPlayer.data().firstName;
                        this._lastName = updatedPlayer.data().lastName;
                        this.scoreConfirmed = updatedPlayer.data().scoreConfirmed || false;
                        //this._courseHoleThruIndex = updatedPlayer.data().courseHoleThruIndex === -1 ? -1 : updatedPlayer.data().courseHoleThruIndex;
                        this._courseHoleThruIndex = [-1, null].includes(updatedPlayer.data().courseHoleThruIndex) ? -1 : updatedPlayer.data().courseHoleThruIndex;
                        this._startingHoleIndex = updatedPlayer.data().startingHoleIndex;
                        this._saved = true;

                        //had to do it this way as 0 handicaps were being recognized as false
                        if (this._appFunction.isNumeric(updatedPlayer.data().handicapIndex)) {
                            this.handicapIndex = updatedPlayer.data().handicapIndex;
                        } else if (this._appFunction.isNumeric(this.member?.handicapIndex)) {
                            this.handicapIndex = this.member?.handicapIndex;
                        } else {
                            this.handicapIndex = AppConfig.DEFAULT_HANDICAP_INDEX
                        }

                        //get and set club, course and tee data
                        this.teeId = updatedPlayer.data().teeId ? updatedPlayer.data().teeId : (this.event.tee ? this.event.tee.teeId : undefined);

                        //now setup the hole data for scoring
                        this.setupHoles(this.tee.nines, <AppPlayerScoreNineI[]>updatedPlayer.data().nines);

                        //clear drity flag
                        this.markAsNotDirty();

                    }

                });

        } catch (err) {
            console.log('app.event.ts AppEventPlayer update error', err, JSON.stringify(err));
            throw err;
        }

    }

    //#region properties

    get memberId(): string {
        return this._memberId;
    }

    get scoringPlayerId(): string {
        //if an actual member then use the memberId, else (and this would be a name only guest) use the player id
        return this._memberId || this.id;
    }

    get startingHoleIndex(): number {
        return this._startingHoleIndex;
    }

    set startingHoleIndex(startingHoleIndex: number) {
        this._startingHoleIndex = startingHoleIndex;
        this.markAsDirty()
    }

    get tee(): AppTee {
        return this._tee || this.event.tee;
    }

    set tee(tee: AppTee) {

        if (this._tee !== tee) {

            this._tee = tee;
            this._teeId = tee.teeId;

            //calc strokes
            this.calcStrokesForHoles();

            //make sure to save
            this.markAsDirty();
        }

    }

    get teeId(): string {
        return this._teeId || this.event.tee?.teeId;
    }

    set teeId(teeId: string) {

        //set new teeId
        if (this._teeId !== teeId) {

            this._teeId = teeId;

            //only get tee if undefined or selected tee is different from previsouly selected tee
            if (!this._tee || this._tee.id !== teeId) {
                this._tee = this.event.course.getTee(teeId);
            }

            //calc strokes
            this.calcStrokesForHoles();

            //make sure to save
            this.markAsDirty();

        }

    }

    get handicapIndex(): number {

        //had to do it this way as 0 handicaps were being recognised as false
        if (this._appFunction.isNumeric(this._handicapIndex)) {
            return this._handicapIndex;
        } else if (this._appFunction.isNumeric(this.member?.handicapIndex)) {
            return this.member?.handicapIndex;
        } else {
            return AppConfig.DEFAULT_HANDICAP_INDEX;
        }

    }

    set handicapIndex(handicapIndex: number) {

        if (this._handicapIndex !== handicapIndex) {

            //set the index to the player object
            this._handicapIndex = handicapIndex;
            this.markAsDirty();

            this.calcStrokesForHoles();

        }

    }

    get handicapIndexDt(): firebase.firestore.Timestamp {
        return this.member?.handicapIndexDt;
    }

    get teeHandicap(): number {
        return this.teeHandicapAdjusted();
    }

    teeHandicapAdjusted(match: AppMatch = undefined, matchStrokeAdjustment: number = 0): number {

        //this method calculates the players handicap based on the selected tee
        if (this._appFunction.isNumeric(this.handicapIndex) && this.tee?.slope && this.tee?.rating && this.tee?.par) {

            //figure out handicap allowance, default to 1 (100%)
            let matchHandicapAllowance: number = 1;
            if (match) {
                matchHandicapAllowance = match.getHandicapAllowance(match.handicapAllowance).calcValue;
            }

            //if undefined then there's no adjustment
            if (matchStrokeAdjustment === undefined) {
                matchStrokeAdjustment = 0
            }

            let courseHandicap: number;
            if (this.event.numberOfHoles === 18) {

                //An 18-hole Course Handicap is calculated as follows
                //Course Handicap = Handicap Index x (Slope Rating ÷ 113) + (Course Rating – par)

                //first calc the course handicap
                courseHandicap = Math.round((this._handicapIndex * (this.tee.slope / 113)) + (this.tee.rating - this.tee.par));

            } else if (this.event.numberOfHoles === 9) {

                //A 9-hole Course Handicap is calculated as follows
                //Course Handicap = (Handicap Index ÷ 2) x (9-hole Slope Rating ÷ 113) + (9-hole Course Rating – 9-hole par)

                //if the course is an 18 hole course then get the selected nine index...
                let nineIndex: number = 0;
                if (this.event.course.holes === 18) {
                    nineIndex = this.event.nineHolesOnlyIndex
                }

                //first calc the course handicap
                courseHandicap = Math.round(((this._handicapIndex / 2) * (this.tee.nines[nineIndex].slope / 113)) + (this.tee.nines[nineIndex].rating - this.tee.nines[nineIndex].par));

            }

            //second apply the match handicap allowance and round to the nearest whole number
            const courseHandicapAllowanceAdjusted: number = Math.round(courseHandicap * matchHandicapAllowance);

            //third apply the match stroke adjustment (this comes from the "full or low ball" match setting)
            const courseHandicapAdjustedWithStrokeAdjustment: number = courseHandicapAllowanceAdjusted - matchStrokeAdjustment;

            //then return the value
            return courseHandicapAdjustedWithStrokeAdjustment;

        } else {
            //console.log('app.event.ts AppEventPlayer teeHandicap get undefined');
            return undefined;
        }

    }

    teeHandicapDisplay(match: AppMatch = undefined): string {
        //this method formats the players handicap and specifically needed for plus handicaps 
        //const teeHandicap: number = this.teeHandicapAdjusted(match, match?.strokeAdjustment(this.event));
        const teeHandicap: number = this.teeHandicapAdjusted(match, match?.strokeAdjustment());
        return teeHandicap < 0 ? '+' + Math.abs(teeHandicap).toString() : Math.abs(teeHandicap).toString();
    }

    set teeHandicap(teeHandicap: number) {
        //noop
    }

    get nines(): AppPlayerScoreNineI[] {
        return this._playerScoreNines;
    }

    set nines(nines: AppPlayerScoreNineI[]) {
        //noop, use this.setNines to populate the nines array
    }

    get infoConfirmed(): boolean {
        return this._infoConfirmed;
    }

    set infoConfirmed(infoConfirmed: boolean) {

        //update info confirmed
        this._infoConfirmed = infoConfirmed;
        this.markAsDirty();

        //if confirmed then build the array with player selected tees
        if (infoConfirmed) {

            //build the nines array
            this.setupHoles(this.tee.nines, this.nines);

        }

    }

    get scoreConfirmed(): boolean {
        return this._scoreConfirmed;
    }

    set scoreConfirmed(scoreConfirmed: boolean) {

        if (this._scoreConfirmed !== scoreConfirmed) {
            this._scoreConfirmed = scoreConfirmed;
            this._dirty = true;
        }

    }

    set firstName(firstName: string) {
        this._firstName = firstName;
        this.markAsDirty()
    }

    get firstName(): string {
        return this.member ? this.member.firstName : this._firstName;
    }

    set lastName(lastName: string) {
        this._lastName = lastName;
        this.markAsDirty()
    }

    get lastName(): string {
        return this.member ? this.member.lastName : this._lastName;
    }

    get URI(): string {
        return this.member ? this.member.avatar.URI : AppConfig.NO_AVATAR_URI;
    }

    get email(): string {
        return this.member ? this.member.email : '';
    }

    set note(note: string) {

        if (this._note !== note) {
            this._note = note;
            this._dirty = true;
        }

    }

    get note(): string {
        return this._note;
    }

    set status(status: boolean) {

        if (this._status !== status) {
            this._status = status;
            this._dirty = true;
        }

    }

    get status(): boolean {
        return this._status;
    }

    get saved(): boolean {
        return this._saved;
    }

    set teeTime(teeTime: firebase.firestore.Timestamp) {

        if (this._teeTime !== teeTime) {
            this._teeTime = teeTime;
            this._dirty = true;
        }

    }

    get teeTime(): firebase.firestore.Timestamp {
        return this._teeTime;
    }

    get courseHoleThruIndex(): number {
        return this._courseHoleThruIndex;
    }

    set courseHoleThruIndex(courseHoleThruIndex: number) {
        this._courseHoleThruIndex = courseHoleThruIndex;
    }

    get holeThruDisplay(): string {
        return this._courseHoleThruIndex === -1 ? '' : (this._courseHoleThruIndex + 1).toString();
    }

    get matches(): AppMatch[] {

        return this.event
            .matches
            .active
            .filter((match) => {
                return match.isPlayerInMemberMatch(this.id);
            });

    }

    get strokes(): number {

        let strokes: number;

        this.nines?.forEach((nine) => {

            nine
                .holes
                .forEach((hole) => {
                    strokes = (strokes || 0) + hole.strokes;
                });

        });

        return strokes;

    }

    holes(): AppEventPlayerHole[] {

        //flaten out the nines nested array
        return this.nines?.flatMap((nine) => {
            return nine.holes.map((hole) => {
                return hole;
            });
        });

    }

    //#endregion properties

    markAsDirty() {
        this._dirty = true;
    }

    markAsNotDirty() {
        this._dirty = false;
    }

    setupHoles(courseNines: AppCourseNineI[], playerNines: AppPlayerScoreNineI[]) {

        //this setups up the nines/holes for the first time and then handles subsequent updates
        try {

            //courseId or nineHolesOnlyIndex has changed so reset the player nines
            if (this._courseId !== this.event.course.id || this._nineHolesOnlyIndex !== this.event.nineHolesOnlyIndex) {
                this._playerScoreNines = undefined;
            }

            //build the player score array
            if (!Array.isArray(this._playerScoreNines) || this._playerScoreNines.length === 0) {

                //save course id and nineHolesOnlyIndex, these will be used to determine if player nines need to be reset  
                this._courseId = this.event.course.id;
                this._nineHolesOnlyIndex = this.event.nineHolesOnlyIndex;

                //initialize player score nines array
                this._playerScoreNines = [];

                //playerNineIndex is the index of the player's nine
                let playerNineIndex: number = -1;

                //loop through each nine and init the holes
                courseNines
                    .forEach((courseNine, nineIndex) => {

                        //only include appropriate nine...this handles 9 or 18 holes
                        if (this.event.includeNine(nineIndex)) {

                            //increment player nine index
                            playerNineIndex++;

                            //create holes array
                            const playerHoles: AppEventPlayerHole[] = [];

                            //init nine hole score
                            let nineHoleScore: number = 0;

                            //loop through holes
                            courseNine
                                .holes
                                .forEach((courseHole, nineHoleIndex) => {

                                    //create player hole object
                                    const playerHole: AppEventPlayerHole = new AppEventPlayerHole(this);

                                    //if passed in player score array not initialized then default hole score info
                                    let playerHoleScore: AppEventPlayerHole;
                                    if (!playerNines) {

                                        playerHoleScore = <AppEventPlayerHole>{
                                            grossScore: null, gir: null, putts: null, fairway: null, scoreEntered: false
                                        }

                                    } else { //else player score array already exists

                                        //get player hole score data...
                                        playerHoleScore = playerNines[playerNineIndex]?.holes[nineHoleIndex];

                                        //update nine score
                                        nineHoleScore = nineHoleScore + playerHoleScore?.grossScore || 0;

                                    }

                                    //finish initializing player hole
                                    playerHole.initialize(playerNineIndex, nineHoleIndex, courseHole, playerHoleScore);

                                    //push on to player hole array
                                    playerHoles.push(playerHole);

                                });

                            //push initialize nine on to the player
                            this._playerScoreNines.push({ holes: playerHoles, score: nineHoleScore });

                            //set nine hole score
                            this._playerScoreNines[playerNineIndex].score = 0;

                        }

                    });

                //calc strokes
                this.calcStrokesForHoles();

            } else { //update nines

                //for each player score nine...
                playerNines
                    .forEach((playerNine, playerNineIndex) => {

                        //for each player score hole...
                        let nineHoleScore: number = 0;
                        playerNine
                            .holes
                            .forEach((playerHole, playerHoleIndex) => {

                                //update holes data...
                                this._playerScoreNines[playerNineIndex].holes[playerHoleIndex].grossScore = playerHole.grossScore;
                                this._playerScoreNines[playerNineIndex].holes[playerHoleIndex].fairway = playerHole.fairway;
                                this._playerScoreNines[playerNineIndex].holes[playerHoleIndex].gir = playerHole.gir;
                                this._playerScoreNines[playerNineIndex].holes[playerHoleIndex].putts = playerHole.putts;
                                nineHoleScore = nineHoleScore + playerHole.grossScore;

                                PublishSubscribe.publish(PublishSubscribeTopics.UpdateEventPlayerScore + this.scoringPlayerId, <PubSubMatchPlayerHoleI>{ event: this.event, player: this, playerHole: this._playerScoreNines[playerNineIndex].holes[playerHoleIndex] });

                            });

                        //update nine hole score
                        this._playerScoreNines[playerNineIndex].score = nineHoleScore;

                    });

                //recalc event leaderboard positions
                this.event.players.active.calcLeaderboardPosition();
                this.event.players.active.calcLeaderboardNetPosition();

            }

        } catch (err) {
            console.log('app.event.ts AppEventPlayer setupNines error', err);
        }

    }

    calcNineHoleScore() {

        //calc score for each nine
        this.nines
            .forEach((nine) => {

                //only sum up the holes that have been scoreEntered
                nine.score = nine
                    .holes
                    .filter((hole) => {
                        return hole.scoreEntered;
                    })
                    .reduce((scoreForCurrentNine, hole) => {
                        return scoreForCurrentNine + hole.grossScore;
                    }, 0);

            });

    }

    private calcStrokesForHoles() {

        try {

            if (this.nines?.length > 0) {

                //if plus handicap then...
                if (this.teeHandicap < 0) {

                    const quotient: number = Math.ceil(this.teeHandicap / this.event.numberOfHoles);
                    const remainder: number = Math.abs(this.teeHandicap % this.event.numberOfHoles);

                    //sort hole by hdcp - easiest to hardest
                    const holes: AppEventPlayerHole[] = this.holes();
                    holes.sortBy('hdcp', SortByOrder.DESC);

                    //set each hole's strokes
                    holes
                        .forEach((hole, holeIndex) => {

                            //if given hole is in the first x (remainder) of the flatened array then award stroke
                            const getsStroke: boolean = holeIndex < remainder;

                            //hole.strokes = -(quotient + (Math.abs(remainder) >= holeIndex + 1 ? 1 : 0));
                            hole.strokes = -(quotient + (getsStroke ? 1 : 0));

                        });

                } else { //for non-plus handicaps

                    const quotient: number = Math.floor(this.teeHandicap / this.event.numberOfHoles);
                    const remainder: number = this.teeHandicap % this.event.numberOfHoles;

                    //sort hole by hdcp - hardest to easiest
                    const holes: AppEventPlayerHole[] = this.holes();
                    holes.sortBy('hdcp', SortByOrder.ASC);

                    //set each hole's strokes
                    holes
                        .forEach((hole, holeIndex) => {

                            //if given hole is in the first x (remainder) of the flatened array then award stroke
                            const getsStroke: boolean = holeIndex < remainder;

                            //hole.strokes = quotient + (Math.abs(remainder) >= holeIndex + 1 ? 1 : 0);
                            hole.strokes = quotient + (getsStroke ? 1 : 0);

                        });

                }

            }

        } catch (err) {
            console.log('app.event.ts AppEventPlayer calcStrokesForHoles error', err);
            throw err;
        }

    }

    private getMember(memberId: string): Promise<void> {

        return new Promise<void>((resolve) => {

            //set memberId
            this._memberId = memberId;

            //if no member object yet then get it...
            if (memberId && !this.member) {

                this._accountService
                    .getMember(memberId)
                    .toPromise()
                    .then((member) => {

                        //set properties
                        this.member = member;
                        resolve();

                    });

            } else {
                //noop
                resolve();
            }

        });

    }

    get scoringMode(): ScoringMode {

        try {

            switch (true) {

                case !this.infoConfirmed || this.dynamicData.playerInfoOpen:
                    return ScoringMode.ScoringNotStarted;
                    break;

                case this.infoConfirmed && !this.scoreConfirmed && !this.dynamicData.playerInfoOpen:
                    return ScoringMode.ScoringActive;
                    break;

                case this.infoConfirmed && this.scoreConfirmed && !this.dynamicData.playerInfoOpen:
                    return ScoringMode.ScoringComplete;
                    break;

            }
        } catch (err) {
            console.log('app.event.ts scoringMode error', err);
            throw err;
        }

    }

    score = new class {

        position: number;
        tiedIndicator: string = '';
        positionNet: number;
        tiedIndicatorNet: string = '';

        constructor(private parentPlayer: AppEventPlayer) {
        }

        get outGross(): number {
            return this.parentPlayer.nines[0]?.score || 0;
        }

        get outNet(): number {
            //only count strokes if the hole has been scored (or confirmed)
            return (this.parentPlayer.nines[0]?.score - this.parentPlayer.nines[0]?.holes.reduce((sum: number, hole: AppEventPlayerHole) => hole.scoreEntered ? sum + hole.strokes : sum, 0)) || 0;
        }

        get inGross(): number {
            return this.parentPlayer.nines[1]?.score || 0;
        }

        get inNet(): number {
            //only count strokes if the hole has been scored (or confirmed)
            return (this.parentPlayer.nines[1]?.score - this.parentPlayer.nines[1]?.holes.reduce((sum: number, hole: AppEventPlayerHole) => hole.scoreEntered ? sum + hole.strokes : sum, 0)) || 0;
        }

        get totalGross(): number {
            return this.outGross + this.inGross;
        }

        get totalNet(): number {
            return this.outNet + this.inNet;
        }

        get toParGross(): number {

            let scoreParTo: number = 0;

            this.parentPlayer
                .nines
                .forEach((nine) => {

                    nine.holes
                        .forEach((hole) => {
                            scoreParTo = scoreParTo + hole.toParGross;
                        });

                });

            return scoreParTo;

        }

        get toParNet(): number {

            let scoreParTo: number = 0;

            this.parentPlayer
                .nines
                .forEach((nine) => {

                    nine.holes
                        .forEach((hole) => {
                            scoreParTo = scoreParTo + hole.toParNet;
                        });

                });

            return scoreParTo;

        }

    }(this);

    statistics = new class {

        constructor(private parentPlayer: AppEventPlayer) {
        }

        get fairwaysHit(): string {

            let fairwaysRecorded: number = 0;
            let fairwaysHit: number = 0;

            this.parentPlayer
                .nines
                .forEach((nine) => {

                    nine
                        .holes
                        .forEach((hole) => {

                            if (hole.fairway) {
                                fairwaysRecorded++;

                                if (hole.fairway === FairwayHit.Yes) {
                                    fairwaysHit++;
                                }
                            }

                        });

                });

            return fairwaysHit.toString() + '/' + fairwaysRecorded.toString();

        }

        get gir(): string {

            let greensRecorded: number = 0;
            let greensHit: number = 0;

            this.parentPlayer
                .nines
                .forEach((nine) => {

                    nine
                        .holes
                        .forEach((hole) => {

                            if (hole.gir) {
                                greensRecorded++;

                                if (hole.gir === GreenInRegulation.Yes) {
                                    greensHit++;
                                }
                            }

                        });

                });

            return greensHit.toString() + '/' + greensRecorded.toString();

        }

        get putts(): number {

            let putts: number = 0;

            this.parentPlayer
                .nines
                .forEach((nine) => {

                    nine
                        .holes
                        .forEach((hole) => {

                            if (hole.putts) {
                                putts = putts + hole.putts;
                            }

                        });

                });

            return putts;

        }

    }(this);

    guests = new class {

        constructor(private parentPlayer: AppEventPlayer) {
        }

        get all(): AppEventPlayer[] {

            //return players that are guest of this player
            return <AppEventPlayer[]>this.parentPlayer
                .event
                .players
                .active
                .all
                .filter((player) => {
                    return (<AppEventPlayer>player).parentPlayerId === this.parentPlayer.id;
                });

        }

        addConfirm(event: AppEvent): Promise<void> {

            return new Promise<void>((resolve) => {

                if (this.all.length === 3) {

                    //don't allow more than three guests
                    this.parentPlayer
                        ._appFunction
                        .alertCtrl
                        .create({
                            header: 'Notice',
                            message: 'You may not add more than three guests to this event.',
                            buttons: ['OK']
                        })
                        .then((alert) => {

                            alert.present();

                            alert
                                .onDidDismiss()
                                .then(() => {
                                    resolve();
                                })

                        });

                } else {

                    //confirmation
                    this.parentPlayer
                        ._appFunction
                        .actionCtrl
                        .create({
                            header: 'Add guest from',
                            buttons: [
                                {
                                    text: 'Contacts and Group members',
                                    handler: () => {

                                        this.addFromContacts(event)
                                            .then(() => {
                                                resolve();
                                            });

                                    }
                                },
                                {
                                    text: 'Name only',
                                    handler: () => {

                                        this.addJustName()
                                            .then(() => {
                                                resolve();
                                            });

                                    }
                                },
                                {
                                    text: 'Cancel',
                                    role: 'cancel'
                                }
                            ]
                        })
                        .then((action) => {
                            action.present();
                        });

                }

            });

        }

        private addFromContacts(event: AppEvent): Promise<void> {

            return new Promise<void>(async (resolve, reject) => {

                this.parentPlayer
                    ._appFunction
                    .modalCtrl
                    .create({
                        component: ContactSearchPage,
                        presentingElement: await this.parentPlayer._appFunction.routerOutlet(),
                        //enterAnimation: enterFromRightAnimation,
                        //leaveAnimation: leaveToRightAnimation,
                        cssClass: 'custom-modal', //for md
                        componentProps: {
                            member: this.parentPlayer._accountService.member
                        }
                    })
                    .then((modal) => {

                        modal
                            .onDidDismiss()
                            .then((results) => {

                                //if returned array has contacts then...
                                if (Array.isArray(results.data.contacts) && results.data.contacts.length > 0) {

                                    //save the array of promises
                                    const promiseArray: any[] = [];

                                    this.parentPlayer
                                        ._appFunction
                                        .loadingCtrl
                                        .create({ message: 'Adding guests...' })
                                        .then((loading) => {

                                            loading.present();

                                            //loop through returned array
                                            results.data
                                                .contacts
                                                .forEach((contact) => {

                                                    //only allow three guests
                                                    if (this.all.length < 3) {
                                                        const p = this.addMember(event, contact.givenName, contact.familyName, contact.email);
                                                        promiseArray.push(p);
                                                    }

                                                });

                                            Promise
                                                .all(promiseArray)
                                                .then(() => {
                                                    loading.dismiss();
                                                    resolve();
                                                })
                                                .catch((err) => {
                                                    loading.dismiss();
                                                    reject(err);
                                                });

                                        });

                                } else {
                                    resolve();
                                }

                            });

                        modal
                            .present()
                            .catch((err) => {
                                console.log('app.event.ts modal present error', err);
                            });


                    })
                    .catch((err) => {
                        console.log('app.event.ts modal create error', err);
                    });

            });

        }

        private addJustName(): Promise<void> {

            return new Promise<void>(async (resolve, reject) => {

                this.parentPlayer
                    ._appFunction
                    .modalCtrl
                    .create({
                        component: ContactPage,
                        presentingElement: await this.parentPlayer._appFunction.routerOutlet(),
                        cssClass: 'custom-modal' //for md
                    })
                    .then((modal) => {

                        //save contact on page close
                        modal
                            .onDidDismiss()
                            .then((result) => {

                                if (result.data) {

                                    this.parentPlayer
                                        ._appFunction
                                        .loadingCtrl
                                        .create({ message: 'Adding guest...' })
                                        .then((loading) => {

                                            loading.present();

                                            //now create new guest AppEventPlayer
                                            const player: AppEventPlayer = new AppEventPlayer();
                                            player
                                                .initialize(undefined, this.parentPlayer.event)
                                                .then(() => {

                                                    //set properties
                                                    player.parentPlayerId = this.parentPlayer.id;
                                                    player.eventId = this.parentPlayer.eventId;
                                                    player.teeTime = this.parentPlayer.teeTime;
                                                    player.firstName = result.data.contact.firstName;
                                                    player.lastName = result.data.contact.lastName;

                                                    //save player
                                                    player
                                                        .save()
                                                        .then(() => {
                                                            loading.dismiss();
                                                            resolve();
                                                        });

                                                })
                                                .catch((err) => {
                                                    console.log('app.event.ts AppEventPlayer addJustName initialize error', JSON.stringify(err));
                                                    reject(err);
                                                });

                                        });

                                }

                            });

                        modal
                            .present()
                            .catch((err) => {
                                console.log('app.event.ts modal present error', err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.event.ts modal create error', err);
                    });

            });
        }

        private addMember(event: AppEvent, firstName: string, lastName: string, email: string): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                //see if email already exists in guest list
                const emailFound: number = this.all.findIndex((guest) => {
                    return guest.member?.email.trim().toLowerCase() === email.trim().toLowerCase();
                });

                //if email not found then it's a new guest for this member
                if (emailFound === -1) {

                    //see if new guest is already a group member
                    const memberEmailFoundIndex: number = event.members.findIndex((member) => {
                        return member?.email.trim().toLowerCase() === email.trim().toLowerCase();
                    });

                    //if email not a group member then add
                    if (memberEmailFoundIndex === -1) {

                        //is new guest/email already a member...
                        this.parentPlayer
                            ._accountService
                            .getMemberByEmail(email)
                            .then((member) => {

                                //member not found so create it
                                if (member === undefined) {

                                    //if member not found then use passed in data to create the member
                                    const member: AppMember = new AppMember();

                                    member
                                        .initialize()
                                        .then(() => {

                                            member.email = email;
                                            member.firstName = firstName;
                                            member.lastName = lastName;

                                            //create guest player with new member
                                            this.addGuest(member)
                                                .then(() => {
                                                    resolve();
                                                })
                                                .catch((err) => {
                                                    console.log('app.event.ts addMember new member error', err, JSON.stringify(err));
                                                    reject(err);
                                                });

                                        });

                                } else {

                                    //create guest player with existing member 
                                    this.addGuest(member)
                                        .then(() => {
                                            resolve();
                                        })
                                        .catch((err) => {
                                            console.log('app.event.ts addMember existing member error', err, JSON.stringify(err));
                                            reject(err);
                                        });

                                }

                            })
                            .catch((err) => {
                                console.log('app.event.ts AppEventPlayer addMember getMemberByEmail error', err, JSON.stringify(err));
                                reject(err);
                            });

                    } else {

                        //guest is already a group member, don't allow to be added as a guest
                        this.parentPlayer
                            ._appFunction
                            .alertCtrl
                            .create({
                                header: 'Notice!',
                                message: email + ' is already a member of this group and can not be added as a guest.',
                                buttons: ['OK']
                            })
                            .then((alert) => {
                                alert.present()
                                    .then(() => {
                                        resolve();
                                    });
                            });

                    }

                } else {

                    //guest is already a guest, don't add twice
                    this.parentPlayer
                        ._appFunction
                        .alertCtrl
                        .create({
                            header: 'Notice!',
                            message: email + ' is already a guest and can not be re-added.',
                            buttons: ['OK']
                        })
                        .then((alert) => {
                            alert
                                .present()
                                .then(() => {
                                    resolve();
                                });
                        });

                }

            });

        }

        private addGuest(member: AppMember): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                //add member as guest
                this.addPlayer(member)
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        console.log('app.event.ts AppEventPlayer addGuest error', err, JSON.stringify(err));
                        reject(err);
                    });

            });

        }

        private addPlayer(guestMember: AppMember): Promise<void> {

            //console.log('app.event.ts AppEventPlayer addGuest');

            return new Promise<void>((resolve, reject) => {

                //create/save guest member record
                guestMember
                    .save()
                    .then((member) => {

                        //console.log('app.event.ts AppEventPlayer add save member success');

                        //now create new guest AppEventPlayer
                        const player: AppEventPlayer = new AppEventPlayer();
                        player
                            .initialize(member.id, this.parentPlayer.event)
                            .then(() => {

                                //set parent player id to guest player
                                player.parentPlayerId = this.parentPlayer.id;
                                player.eventId = this.parentPlayer.eventId;
                                player.teeTime = this.parentPlayer.teeTime;

                                //save player
                                player
                                    .save()
                                    .then(() => {
                                        resolve();
                                    });

                            })
                            .catch((err) => {
                                console.log('app.event.ts AppEventPlayer AppEventPlayer initialize error', JSON.stringify(err));
                                reject(err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.event.ts AppEventPlayer addGuest save member error', JSON.stringify(err));
                        reject(err);
                    });

            });

        }

    }(this);

    //soft delete a player so they still display on the dropped and timeline lists
    remove(): Promise<boolean> {

        //console.log('app.event.ts AppEventPlayer remove');

        return new Promise<boolean>((resolve, reject) => {

            //for non-group events...if this player is the creating member don't allow to be removed 
            if (!this.event.group && this.event.isMemberAdmin(this.member)) {

                //
                this._appFunction
                    .alertCtrl
                    .create({
                        header: 'Notice',
                        message: 'The organizer can not be removed from the Event.',
                        buttons: ['OK']
                    })
                    .then((alert) => {

                        alert.present();

                        alert.onDidDismiss()
                            .then(() => {
                                resolve(false);
                            });

                    });

            } else {

                const promiseArray: any[] = [];

                //first remove all guests from player that is being removed...
                this.guests
                    .all
                    .forEach((guest) => {
                        const p = guest.remove();
                        promiseArray.push(p);
                    });

                //...then once resolved then remove this player
                Promise
                    .all(promiseArray)
                    .then(() => {

                        //update player status to inactive
                        this.status = false;

                        //only call save if player previsouly saved
                        if (this._playerDoc.exists) {

                            //now save the player (which is soft deleting the member)
                            this
                                .save()
                                .then(() => {
                                    //send notification that player dropped
                                    this.event.sendPlayerStatusNotification(this, EventJoinDropNotificationPreference.Drop);
                                    resolve(true);
                                })
                                .catch((err) => {
                                    console.log('app.event.ts AppEventPlayer delete/inactive error', JSON.stringify(err));
                                    reject(err);
                                });

                        } else {
                            resolve(true);
                        }

                    });

            }

        });

    }

    //delete a player if the entire event is being deleted, this should only be called when deleting the event
    delete(batch: firebase.firestore.WriteBatch): Promise<void> {

        return new Promise<void>((resolve) => {

            //save the array of promises
            const promiseArray: any[] = [];

            //delete this player...
            batch.delete(this._playerDoc.ref);

            //...and any guests
            this.guests
                .all
                .forEach((guest) => {
                    const p = guest.delete(batch);
                    promiseArray.push(p);
                });

            Promise
                .all(promiseArray)
                .then(() => {
                    resolve();
                });

        });

    }

    save(): Promise<AppEventPlayer> {

        return new Promise<AppEventPlayer>((resolve, reject) => {

            //console.log('app.event.ts AppEventPlayer save');

            try {

                //only save if there has been a change
                if (this._dirty) {

                    this._playerDoc
                        .ref
                        .set(this.data(), { merge: true })
                        .then(() => {

                            //set dirty back
                            this.markAsNotDirty();

                            //mark as saved
                            this._saved = true;

                            //console.log('app.event.ts AppEventPlayer save set success');
                            resolve(this);

                        })
                        .catch((err) => {
                            console.log('app.event.ts AppEventPlayer save set error', JSON.stringify(err));
                            reject(err);
                        });

                } else {
                    //noop, player not dirty
                    resolve(this);
                }

            } catch (err) {
                console.log('app.event.ts AppEventPlayer save error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    private data(): AppEventPlayerI {

        try {

            //for each nine build the holes
            const playerScoreNines: AppPlayerScoreNineI[] = [];
            this.nines?.forEach((nine) => {

                //create holes array
                const holes = [];

                //loop through each nine and init the holes
                nine
                    .holes
                    .forEach((hole) => {
                        const data: AppEventPlayerHoleI = hole.data();
                        holes.push(data);
                    });

                //push initialize nine on to the player
                playerScoreNines.push({ holes: holes, score: nine.score || null });

            });

            return {
                memberId: this.memberId || null,
                eventId: this.event.id,
                note: this.note || null,
                position: this.position,
                teeTime: this.teeTime || null,
                status: this.status,
                parentPlayerId: this.parentPlayerId || null,
                infoConfirmed: this._infoConfirmed, //don't change this to this.infoConfirmed (see getter/setter)
                scoreConfirmed: this.scoreConfirmed,
                handicapIndex: this.handicapIndex,
                teeId: this.teeId || null,
                teeHandicap: this.teeHandicap || null,
                firstName: this._firstName || null,
                lastName: this._lastName || null,
                nines: playerScoreNines || null,
                courseHoleThruIndex: this._courseHoleThruIndex === undefined ? -1 : this._courseHoleThruIndex,
                startingHoleIndex: this.startingHoleIndex === undefined ? 0 : this.startingHoleIndex,
                updatedDt: firebase.firestore.Timestamp.fromDate(new Date()),
                createdDt: this.createdDt
            };

        }
        catch (err) {
            console.log('app.event.ts AppEventPlayer data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

export interface AppEventI {
    description: string;
    eventDt: firebase.firestore.Timestamp; //date;
    timezone: string;
    numberTeeTimes: number;
    groupId: string;
    clubId: string;
    courseId: string;
    teeId: string;
    teeTimeInterval: number;
    lastUpdatedMemberId: string;
    createdMemberId: string;
    type: EventType;
    startingHoleIndex: number;
    numberOfHoles: number; //9 or 18
    nineHolesOnlyIndex: number; //this specifies which nine holes which is being played on an 18 hole course
    memberIds: string[]; //i needed this for non group events because there was no group that normally links a member to an event
    matchIds: string[];
    deleted: boolean;
    updatedDt: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
}

export class AppEvent implements AppEventI, AppClassI, AppMatchParentI {

    id: string;
    description: string;
    private _eventDt: firebase.firestore.Timestamp;
    private _timezone: string;
    numberTeeTimes: number;
    groupId: string;
    group: AppGroupI;
    club: AppClub;
    course: AppCourse = null;
    tee: AppTee = null;
    teeTimeInterval: number;
    dynamicData: any = {};
    lastUpdatedMemberId: string;
    createdMember: AppMember;
    updateEvent: Subject<string> = new BehaviorSubject<string>(undefined);
    updateEventPlayerScore: Subject<string> = new BehaviorSubject<string>(undefined);
    exists: boolean = false;
    numberOfHoles: number = 18;
    startingHoleIndex: number = 0;
    nineHolesOnlyIndex: number; //if only nine holes are being played on an 18 hole course 
    memberIds: string[] = []; //this is used for non group events...some day we need to rearch this
    teetimes: AppTeeTimeI[];
    deleted: boolean = false;
    updatedDt: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
    matchIds: string[] = [];
    class: string = 'AppEvent';
    private _eventDoc: firebase.firestore.DocumentSnapshot;
    private _clubId: string;
    private _courseId: string;
    private _teeId: string;
    private _appFunction: AppFunction;
    private _accountService: AccountService;
    private _deepLinkService: DeepLinkService;
    private _clubService: ClubService;
    private _createdMemberId: string;

    constructor() {

        //get services
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);
        this._accountService = AppFunction.serviceLocator.get(AccountService);
        this._clubService = AppFunction.serviceLocator.get(ClubService);
        this._deepLinkService = AppFunction.serviceLocator.get(DeepLinkService);

        //clean up
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.event.ts AppEvent shutdown event', this.id);
            });

    }

    initialize(group: AppGroupI, eventDoc: firebase.firestore.DocumentSnapshot = undefined): Promise<AppEvent> {

        return new Promise<AppEvent>((resolve, reject) => {

            if (!this.createdMemberId) {
                console.log('app.event.ts AppEvent initialize error the createdMemberId must be set before initializing the event.');
                reject();
            } else if (eventDoc) {

                //set some properties
                this._eventDoc = eventDoc;
                this.id = eventDoc.id;
                this.group = group;
                this.groupId = group?.id;

                this.update(eventDoc, AppConfig.UPDATE_TYPE.Added)
                    .then(() => {
                        resolve(this);
                    });

            } else {

                //create new event doc ref
                const eventDoc: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.Events).doc();

                //set event properties
                this.group = group;
                this.groupId = group?.id;

                if (group as AppGroupEvent) {

                    this._clubId = (<AppGroupEvent>group)?.clubId;
                    this.club = (<AppGroupEvent>group)?.club;
                    this._courseId = (<AppGroupEvent>group)?.courseId;
                    this.course = (<AppGroupEvent>group)?.course;
                    this._teeId = (<AppGroupEvent>group)?.teeId;
                    this.tee = (<AppGroupEvent>group)?.tee;

                }

                //now get it, it should be empty as this is a new event object
                eventDoc
                    .get()
                    .then((eventDoc) => {

                        //save doc, this will be used when saving
                        this._eventDoc = eventDoc;
                        this.id = eventDoc.id;

                        resolve(this);

                    })
                    .catch((err) => {
                        console.log('app.event.ts AppEvent initialize error', err);
                        reject();
                    });

            }

        });

    }

    update(updatedEvent: firebase.firestore.DocumentSnapshot, type: string): Promise<void> {

        return new Promise<void>((resolve) => {

            try {

                this._appFunction
                    .ngZone
                    .run(async () => {

                        if (type === AppConfig.UPDATE_TYPE.Added || type === AppConfig.UPDATE_TYPE.Modified) {

                            this.description = updatedEvent.data().description || undefined;
                            this.eventDt = updatedEvent.data().eventDt ? updatedEvent.data().eventDt : undefined;
                            this.timezone = updatedEvent.data().timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
                            this.numberTeeTimes = updatedEvent.data().numberTeeTimes || undefined;
                            this.createdDt = updatedEvent.data().createdDt;
                            this.teeTimeInterval = updatedEvent.data().teeTimeInterval;
                            this.matchIds = updatedEvent.data().matchIds;
                            this.numberOfHoles = updatedEvent.data().numberOfHoles || 18;
                            this.startingHoleIndex = updatedEvent.data().startingHoleIndex || 0;
                            this.nineHolesOnlyIndex = updatedEvent.data().nineHolesOnlyIndex;
                            this.createdMemberId = updatedEvent.data().createdMemberId;
                            this.memberIds = updatedEvent.data().memberIds;
                            this.deleted = updatedEvent.data().deleted || false;
                            this.lastUpdatedMemberId = updatedEvent.data().lastUpdatedMemberId;
                            this.exists = true;

                            //get member who created the event
                            await this._accountService.getMember(this.createdMemberId)
                                .toPromise()
                                .then((member) => {
                                    this.createdMember = member;
                                });

                            await this.setClub(updatedEvent.data().clubId || (<AppGroupEvent>this.group)?.clubId, updatedEvent.data().courseId || (<AppGroupEvent>this.group)?.courseId, updatedEvent.data().teeId || (<AppGroupEvent>this.group)?.teeId);
                            await this.players.getPlayers();
                            await this.matches.getMatches();
                            await this._accountService.getMember(this.createdMemberId)
                                .toPromise()
                                .then((member) => {
                                    this.createdMember = member;
                                });

                            //if the event config is being updated then repub player scores so that matches are recalculated
                            if (type === AppConfig.UPDATE_TYPE.Modified) {
                                this.players.active.republishHoleScores();
                            }

                            //if the event is being deleted then...
                            if (type === AppConfig.UPDATE_TYPE.Removed) {

                                //if this event that is being deleted is part of a trip then...
                                if (this.group?.type === GroupType.Trip) {

                                    //iterate through all trip matches and reset so to remove this deleted event
                                    (<AppGroupTrip>this.group).matches.active.forEach((match) => {
                                        match.reset();
                                    });

                                    //iterate through remaining active trip events and republish scores
                                    this.group.events.all
                                        .forEach((event) => {
                                            //republish scores
                                            event.players.active.republishHoleScores();
                                        });

                                }

                            }

                            resolve();

                        } else {
                            this.exists = false;
                            resolve();
                        }

                    });

            } catch (err) {
                console.log('app.event.ts AppEvent update error', JSON.stringify(err));
                throw err;
            }
        });


    }

    //#region event methods

    set clubId(clubId: string) {

        //only retrieve if course id is new
        if (this._clubId !== clubId) {

            //set the new club id
            this._clubId = clubId;

            //with the new club the course is no longer valid
            this._courseId = undefined;
            this.course = undefined;

            //with the new club the tee id is no longer valid
            this._teeId = undefined;
            this.tee = undefined;

            //now get course
            this._clubService
                .getClub(clubId)
                .toPromise()
                .then((club) => {
                    this.club = club;
                });

        }

    }

    get clubId(): string {
        return this._clubId;
    }

    set courseId(courseId: string) {

        //only retrieve if course id is new
        if (this._courseId !== courseId) {

            //set courseId
            this._courseId = courseId;

            //reset tees
            this._teeId = undefined;
            this.tee = undefined;

        }

    }

    get courseId(): string {
        return this._courseId;
    }

    set teeId(teeId: string) {

        //console.log('app.event.ts set teeId');

        if (this.club) {

            //only retrieve if tee id is new
            if (this._teeId !== teeId) {

                //set teeId
                this._teeId = teeId;

                //now get the tee object from the course
                /* this.club
                    .tees
                    .forEach((tee) => {

                        if (tee.id === teeId) {
                            this.tee = tee;
                            return;
                        }

                    }); */

            }

        } else {
            console.log('app.event.ts set teeId course is undefined');
        }

    }

    get teeId(): string {
        return this._teeId;
    }

    set createdMemberId(createdMemberId: string) {

        this._createdMemberId = createdMemberId;

        this._accountService
            .getMember(createdMemberId)
            .toPromise()
            .then((member) => {
                this.createdMember = member;
            });

    }

    get createdMemberId(): string {
        return this._createdMemberId || this._accountService.member.id
    }

    get members(): AppMember[] {

        //return group members, players of a non-group event or empty array

        //if a group exists...
        if (this.group) {

            return (this.group.members).map((groupMember) => {
                return groupMember.member;
            }) || [];

        } else { //else non group event

            return this.players
                .active
                .all
                .map((player) => {
                    if (player.member) return player.member;
                }) || [];

        }

    }

    get type(): EventType {
        return this.group ? (this.group.type === GroupType.Event ? EventType.GroupEvent : EventType.GroupTrip) : EventType.Personal;
    }

    get startingHoleDescription(): string {
        return 'Hole ' + (this.startingHoleIndex + 1).toString();
    }

    get numberOfHolesDescription(): string {
        return this.numberOfHoles.toString() + ' holes';
    }

    get eventDt(): firebase.firestore.Timestamp {

        const options: Intl.DateTimeFormatOptions = {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZone: this.timezone
        };

        return firebase.firestore.Timestamp.fromDate(new Date(Intl.DateTimeFormat("en-US", options).format(this._eventDt?.toDate())));

    }

    set eventDt(eventDt: firebase.firestore.Timestamp) {
        this._eventDt = eventDt;
    }

    set timezone(timezone: string) {
        this._timezone = timezone;
    }

    get timezone(): string {
        return this._timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    //#endregion event methods

    players = <PlayersI>new class Players {

        private _players: AppEventPlayer[] = [];
        private _didGetPlayers: boolean = false;

        constructor(private parentEvent: AppEvent) {
        }

        add(player: AppEventPlayer) {

            this.parentEvent
                ._appFunction
                .ngZone
                .run(() => {

                    this._players.push(player);

                    //if a non-group event and not no-named player then...
                    if (this.parentEvent.group === undefined && player.member?.id) {
                        this.parentEvent.memberIds.push(player.member?.id);
                    }

                });

        }

        join(memberId: string): Promise<void> {

            return new Promise<void>((resolve) => {

                //create new player
                const player: AppEventPlayer = new AppEventPlayer();
                player
                    .initialize(memberId, this.parentEvent)
                    .then(() => {
                        player
                            .save()
                            .then(() => {

                                //send notification that player joined
                                this.parentEvent.sendPlayerStatusNotification(player, EventJoinDropNotificationPreference.Join);

                                //return
                                resolve();

                            });
                    });

            });

        }

        addPlayer(firstName: string, lastName: string, email: string): Promise<void> {

            //console.log('app.event.ts AppEvent players.addPlayer');

            return new Promise<void>((resolve, reject) => {

                //does player/email already exists in player list
                const emailFound: number = this.active.all.findIndex((player) => {
                    return player.member?.email.trim().toLowerCase() === email.trim().toLowerCase();
                });

                //if player/email not found then it's a new player
                if (emailFound === -1) {

                    //does email belong to an existing member...
                    this.parentEvent
                        ._accountService
                        .getMemberByEmail(email)
                        .then((member) => {

                            //email/member not found so create member
                            if (member === undefined) {

                                //create and initialize player
                                const member: AppMember = new AppMember();
                                member
                                    .initialize()
                                    .then(() => {

                                        member.email = email;
                                        member.firstName = firstName;
                                        member.lastName = lastName;

                                        //save new member
                                        member
                                            .save()
                                            .then(() => {

                                                //now create player
                                                const player: AppEventPlayer = new AppEventPlayer();
                                                player
                                                    .initialize(member.id, this.parentEvent)
                                                    .then(() => {

                                                        //add new player to event
                                                        this.add(player);

                                                        //return
                                                        resolve();

                                                    });

                                            })
                                            .catch((err) => {
                                                console.log('app.event.ts addMember new member error', err, JSON.stringify(err));
                                                reject(err);
                                            });

                                    });

                            } else {

                                //member found so just create player
                                const player: AppEventPlayer = new AppEventPlayer();
                                player
                                    .initialize(member.id, this.parentEvent)
                                    .then(() => {

                                        //add new player to event
                                        this.add(player);

                                        //return
                                        resolve();

                                    });

                            }

                        })
                        .catch((err) => {
                            console.log('app.event.ts AppEventPlayer addMember getMemberByEmail error', err, JSON.stringify(err));
                            reject(err);
                        });

                } else {
                    //member is already a player so noop, just return
                    resolve();
                }

            });

        }

        addFromContacts(): Promise<void> {

            return new Promise<void>(async (resolve, reject) => {

                this.parentEvent
                    ._appFunction
                    .modalCtrl
                    .create({
                        component: ContactSearchPage,
                        presentingElement: await this.parentEvent._appFunction.routerOutlet(),
                        //enterAnimation: enterFromRightAnimation,
                        //leaveAnimation: leaveToRightAnimation,
                        cssClass: 'custom-modal', //for md
                        componentProps: {
                            member: this.parentEvent._accountService.member
                        }
                    })
                    .then((modal) => {

                        modal
                            .onDidDismiss()
                            .then((results) => {

                                //if returned array has contacts then...
                                if (Array.isArray(results.data.contacts) && results.data.contacts.length > 0) {

                                    //save the array of promises
                                    const promiseArray: any[] = [];

                                    this.parentEvent
                                        ._appFunction
                                        .loadingCtrl
                                        .create({ message: 'Adding players...' })
                                        .then((loading) => {

                                            loading.present();

                                            //loop through returned array
                                            results.data
                                                .contacts
                                                .forEach((contact) => {
                                                    const p = this.addPlayer(contact.givenName, contact.familyName, contact.email);
                                                    promiseArray.push(p);
                                                });

                                            Promise
                                                .all(promiseArray)
                                                .then(() => {
                                                    loading.dismiss();
                                                    resolve();
                                                })
                                                .catch((err) => {
                                                    loading.dismiss();
                                                    reject(err);
                                                });

                                        });

                                } else {
                                    resolve();
                                }

                            });

                        modal
                            .present()
                            .catch((err) => {
                                console.log('app.event.ts AppEvent addFromContacts modal present error', err);
                            });


                    })
                    .catch((err) => {
                        console.log('app.event.ts AppEvent addFromContacts modal create error', err);
                    });

            });

        }

        addJustName(): Promise<void> {

            //console.log('app.event.ts addJustName');

            return new Promise<void>(async (resolve, reject) => {

                this.parentEvent
                    ._appFunction
                    .modalCtrl
                    .create({
                        component: ContactPage,
                        presentingElement: await this.parentEvent._appFunction.routerOutlet(),
                        cssClass: 'custom-modal' //for md
                    })
                    .then((modal) => {

                        //save contact on page close
                        modal
                            .onDidDismiss()
                            .then((result) => {

                                if (result.data) {

                                    this.parentEvent
                                        ._appFunction
                                        .loadingCtrl
                                        .create({ message: 'Adding player...' })
                                        .then((loading) => {

                                            loading.present();

                                            //now create new AppEventPlayer
                                            const player: AppEventPlayer = new AppEventPlayer();
                                            player
                                                .initialize(undefined, this.parentEvent)
                                                .then(() => {

                                                    //set properties
                                                    player.eventId = this.parentEvent.id;
                                                    player.firstName = result.data.contact.firstName;
                                                    player.lastName = result.data.contact.lastName;

                                                    //add player to event
                                                    this.add(player);

                                                    //
                                                    loading.dismiss();

                                                    //
                                                    resolve();

                                                })
                                                .catch((err) => {
                                                    console.log('app.event.ts AppEvent addJustName initialize error', JSON.stringify(err));
                                                    reject(err);
                                                });

                                        });

                                }

                            });

                        modal
                            .present()
                            .catch((err) => {
                                console.log('app.event.ts AppEvent addJustName modal present error', err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.event.ts AppEvent addJustName modal create error', err);
                    });

            });
        }

        active = <ActivePlayersI>new class {

            constructor(private parentPlayers: Players) {
            }

            get all(): AppEventPlayer[] {

                //return only active players
                const players: AppEventPlayer[] = this.parentPlayers._players?.filter((player) => {
                    return player.status;
                }) || [];

                //sort and return
                players?.sortBy('position', SortByOrder.DESC, 'createdDt', SortByOrder.ASC);
                return players || [];

            }

            get withTeeTime(): AppEventPlayer[] {

                //return only active players with tee times (i.e. not on the wait list)
                const players: AppEventPlayer[] = this.all.filter((player) => {
                    return !(moment(player.teeTime.toDate()).isSame(AppConfig.WAIT_LIST_TEE_TIME));
                }) || [];

                //sort and return
                players?.sortBy('position', SortByOrder.DESC, 'createdDt', SortByOrder.ASC);
                return players || [];

            }

            get groupMembersOnly(): AppEventPlayer[] {

                //return only active players that are group members (i.e. not guests)
                const players: AppEventPlayer[] = this.all.filter((player) => {
                    return player.parentPlayerId === undefined;
                }) || [];

                //sort and return
                players?.sortBy('position', SortByOrder.ASC, 'createdDt', SortByOrder.ASC);
                return players || [];

            }

            get teeTime(): AppEventPlayer[] {

                //get the logged in player OR if system admin then just get the first player
                const memberPlayer: AppEventPlayer = this.parentPlayers.memberPlayer;

                //fine all players in the logged in member's tee time
                return this.all
                    .filter((player) => {
                        //if the player "exists" and the teetimes are the same or a guest then return player...
                        return (moment(player.teeTime.toDate()).isSame(memberPlayer?.teeTime.toDate())) || (player.parentPlayerId === memberPlayer?.id);
                    });

            }

            get teeTimeScoringMode(): ScoringMode {

                const scoringNotStarted: boolean = this.teeTime
                    .some((player) => {
                        return !player.infoConfirmed;
                    });

                if (scoringNotStarted) {
                    return ScoringMode.ScoringNotStarted;
                }

                const scoringStarted: boolean = this.teeTime
                    .every((player) => {
                        return player.infoConfirmed && !player.scoreConfirmed;
                    });

                if (scoringStarted) {
                    return ScoringMode.ScoringActive;
                }

                const scoringCompleted: boolean = this.teeTime
                    .every((player) => {
                        return player.scoreConfirmed;
                    });

                if (scoringCompleted) {
                    return ScoringMode.ScoringComplete;
                }

            }

            get scoringMode(): ScoringMode {

                //as long as event player is active then scoring is active
                const scoringActive: boolean = this.all
                    .some((player) => {
                        return player.scoringMode === ScoringMode.ScoringActive;
                    });

                if (scoringActive) {
                    return ScoringMode.ScoringActive;
                }

                //if every player is complete then scoring is complete
                const scoringCompleted: boolean = this.all
                    .some((player) => {
                        return player.scoringMode === ScoringMode.ScoringComplete;
                    });

                if (scoringCompleted) {
                    return ScoringMode.ScoringComplete;
                } else {
                    return ScoringMode.ScoringNotStarted;
                }

            }

            get leaderboard(): AppEventPlayer[] {

                //return only active and confirmed players
                const players: AppEventPlayer[] = this.all.filter((player) => {
                    return player.infoConfirmed;
                }) || [];

                //sort players by gross score - lowest to highest
                players?.sortBy('score.toParGross', SortByOrder.ASC);
                return players || [];

            }

            get leaderboardNet(): AppEventPlayer[] {

                //return only active and confirmed players
                const players: AppEventPlayer[] = this.all.filter((player) => {
                    return player.infoConfirmed;
                }) || [];

                //sort players by net score - lowest to highest
                players?.sortBy('score.toParNet', SortByOrder.ASC);
                return players || [];

            }

            calcLeaderboardPosition() {

                this.parentPlayers
                    .active
                    .leaderboard
                    .forEach((player, playerIndex, leaderboard) => {

                        if (playerIndex === 0) {
                            (<AppEventPlayer>player).score.position = 1;
                            (<AppEventPlayer>player).score.tiedIndicator = '';
                        } else {

                            //if current player score the same as previous player score
                            if ((<AppEventPlayer>player).score.toParGross === (<AppEventPlayer>leaderboard[playerIndex - 1]).score.toParGross) {

                                //update previous player to be tied with this player
                                (<AppEventPlayer>leaderboard[playerIndex - 1]).score.tiedIndicator = 'T';

                                //update current player's position to be the same as the previous player
                                (<AppEventPlayer>player).score.position = (<AppEventPlayer>leaderboard[playerIndex - 1]).score.position;
                                (<AppEventPlayer>player).score.tiedIndicator = 'T';

                            } else {

                                //update current player to be one position past previous player
                                (<AppEventPlayer>player).score.position = playerIndex + 1;
                                (<AppEventPlayer>player).score.tiedIndicator = '';

                            }

                        }

                    });

            }

            calcLeaderboardNetPosition() {

                this.parentPlayers
                    .active
                    .leaderboardNet
                    .forEach((player, playerIndex, leaderboard) => {

                        if (playerIndex === 0) {
                            (<AppEventPlayer>player).score.positionNet = 1;
                            (<AppEventPlayer>player).score.tiedIndicatorNet = '';
                        } else {

                            //if current player score the same as previous player score
                            if ((<AppEventPlayer>player).score.toParNet === (<AppEventPlayer>leaderboard[playerIndex - 1]).score.toParNet) {

                                //update previous player to be tied with this player
                                (<AppEventPlayer>leaderboard[playerIndex - 1]).score.tiedIndicatorNet = 'T';

                                //update current player's position to be the same as the previous player
                                (<AppEventPlayer>player).score.positionNet = (<AppEventPlayer>leaderboard[playerIndex - 1]).score.positionNet;
                                (<AppEventPlayer>player).score.tiedIndicatorNet = 'T';

                            } else {

                                //update current player to be one position past previous player
                                (<AppEventPlayer>player).score.positionNet = playerIndex + 1; //leaderboard[playerIndex - 1].score.positionNet + 1;
                                (<AppEventPlayer>player).score.tiedIndicatorNet = '';

                            }

                        }

                    });

            }

            //return all AppMembers for event players
            get members(): AppMember[] {
                return this.all.map((player) => {
                    if (player.member) return player.member;
                });
            }

            republishHoleScores() {
                //republish hole scores for all active players (only use this when republishing hole scores)
                this.all.forEach((player) => {
                    player.setupHoles(this.parentPlayers.parentEvent.tee.nines, player.nines);
                });
            }

        }(this);

        get groupMembersOnly(): AppEventPlayer[] {

            //return only (active, and deleted) players that are group members (i.e. not guests)
            const players: AppEventPlayer[] = this._players?.filter((player) => {
                return player.parentPlayerId === undefined;
            }) || [];

            //sort and return
            players?.sortBy('position', SortByOrder.ASC, 'createdDt', SortByOrder.ASC);
            return players || [];

        }

        is(member: AppMember): boolean {
            return (<AppEventPlayer[]>this.active.all).some((player) => {
                return player.memberId === member.id;
            });
        }

        getPlayer(playerId: string): AppEventPlayer {

            //return player object based on player id
            return <AppEventPlayer>this.active.all.find((player) => {
                return (<AppEventPlayer>player).id === playerId;
            });

        }

        getPlayerByMemberId(memberId: string): AppEventPlayer {

            //return player object based on member id
            return <AppEventPlayer>this.active.all.find((player) => {
                return player.member?.id === memberId;
            });

        }

        get dropped(): AppEventPlayer[] {

            let droppedPlayers: AppEventPlayer[] = [];

            //return only inactive players that have been saved
            droppedPlayers = this._players.filter((player) => {
                return !player.status && player.saved;
            });

            //sort the dropped players (most recent to oldest)
            droppedPlayers?.sortBy('updatedDt', SortByOrder.ASC);

            return droppedPlayers || [];

        }

        //this returns the logged in member player object
        get memberPlayer(): AppEventPlayer {

            return <AppEventPlayer>this.active.all.find((player) => {
                return player.member?.id === this.parentEvent._accountService.member.id;
            });

        }

        getPlayers(): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                try {

                    //only call once
                    if (!this._didGetPlayers) {

                        //initialize array
                        this._didGetPlayers = true;

                        const promiseArray: any[] = [];

                        //get all players (active and inactive, including guests) for the given event
                        const playersSnapshotUnsubscribe = this.parentEvent._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.EventPlayers)
                            .where('eventId', '==', this.parentEvent.id) //this.parent.id = eventId
                            .onSnapshot((foundPlayers) => {

                                //for each found player...
                                foundPlayers
                                    .docChanges()
                                    .forEach((returnedPlayer) => {

                                        //try to find player...
                                        const localPlayerFound: AppEventPlayer = this.getPlayer(returnedPlayer.doc.id);

                                        //for new players
                                        if (returnedPlayer.type === AppConfig.UPDATE_TYPE.Added) {

                                            //...and if not found then create new player. this would happen when the app is first loading event and players
                                            if (!localPlayerFound) {

                                                //create player and push on to players array
                                                const player: AppEventPlayer = new AppEventPlayer();
                                                const p = player
                                                    .initialize(returnedPlayer.doc.data().memberId, this.parentEvent, returnedPlayer.doc)
                                                    .then(() => {

                                                        //ngZone is to fix an issue where player count wasn't displaying appropriately
                                                        this.parentEvent
                                                            ._appFunction
                                                            .ngZone
                                                            .run(() => {
                                                                this._players.push(player);
                                                            });

                                                    });

                                                promiseArray.push(p);

                                            } else { //this would happen if the player was created during this session and and saved for the first time 
                                                localPlayerFound.update(returnedPlayer.doc, AppConfig.UPDATE_TYPE.Modified);
                                            }

                                        } else { //for updated or removed players
                                            localPlayerFound?.update(returnedPlayer.doc, returnedPlayer.type);
                                        }

                                    });

                                //wait for all promises to return
                                Promise
                                    .all(promiseArray)
                                    .then(() => {
                                        //after the players list has changed signal event update
                                        this.parentEvent.updateEvent.next(this.parentEvent.lastUpdatedMemberId);
                                        resolve();
                                    });

                            }, (err) => {
                                console.log('app.event.ts getPlayers onSnapshot error', err);
                                reject(err);
                            });

                        this.parentEvent._appFunction.registerUnsubscribe(playersSnapshotUnsubscribe);

                    } else {
                        //noop
                        resolve();
                    }

                } catch (err) {
                    console.log('app.event.ts AppEvent players', err, JSON.stringify(err));
                    resolve();
                }

            });

        }

        remove(player: AppEventPlayer): Promise<boolean> {

            return new Promise<boolean>((resolve) => {

                //remove player from event
                player
                    .remove()
                    .then((removed) => {

                        //if non group event...
                        if (this.parentEvent.type === EventType.Personal) {

                            //...find player in memberIds array
                            const playerIndex = this.parentEvent.memberIds.indexOf(player.memberId);

                            //if player is found then...
                            if (playerIndex > -1) {

                                //...remove player from memberIds array
                                this.parentEvent.memberIds.splice(playerIndex, 1);

                                //...then save event and resolve promise
                                this.parentEvent
                                    .save()
                                    .then(() => {
                                        resolve(removed);
                                    });

                            }
                        } else {
                            resolve(removed);
                        }


                    });

            });

        }

        playerInfoConfirmed(players: AppEventPlayer[]): ScoringMode {

            const scoringNotStarted: boolean = players.some((player) => {
                return !player.infoConfirmed;
            });

            if (scoringNotStarted) {
                return ScoringMode.ScoringNotStarted;
            }

            const scoringStarted: boolean = players.every((player) => {
                return player.infoConfirmed && !player.scoreConfirmed;
            });

            if (scoringStarted) {
                return ScoringMode.ScoringActive;
            }

            const scoringCompleted: boolean = players.every((player) => {
                return player.scoreConfirmed;
            });

            if (scoringCompleted) {
                return ScoringMode.ScoringComplete;
            }

        }

    }(this);

    matches = <MatchesI>new class {

        private _matches: AppMatch[] = [];
        private _runOnceGetMatches: boolean = false;

        constructor(private parentEvent: AppEvent) {
        }

        //return all non deleted event and member matches
        get active(): AppMatch[] {

            return this._matches?.filter((match) => {
                return match.exists;
            }) || [];

        }

        //return just event matches...matches created as event matches
        get parent(): AppMatch[] {

            return this.active
                .filter((match) => {
                    return this.parentEvent.matchIds?.includes(match.id);
                }) || [];

        }

        createMatch(): AppMatch {
            const match: AppMatch = new AppMatch();
            return match;
        }

        getMatch(matchId: string): AppMatch {

            //turn team
            return this._matches.find((match) => {
                return match.id === matchId;
            });

        }

        getMatches(): Promise<void> {

            return new Promise<void>((resolve) => {

                try {

                    //only run once
                    if (!this._runOnceGetMatches) {

                        //setup
                        const promiseArray: any[] = [];
                        this._runOnceGetMatches = true;

                        //get all matched (event and member) for given event
                        const getMatchesUnsubscribe = this.parentEvent._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Matches)
                            .where('parentId', '==', this.parentEvent.id)
                            .onSnapshot((foundMatches) => {

                                //for each found match...
                                foundMatches
                                    .docChanges()
                                    .forEach((foundMatch) => {

                                        //try to find match...
                                        const localMatchFound: AppMatch = this.getMatch(foundMatch.doc.id);

                                        //for new matches that aren't already in array
                                        if (foundMatch.type === AppConfig.UPDATE_TYPE.Added) {

                                            //...if not found then create
                                            if (!localMatchFound) {

                                                //create new match and init...
                                                const match: AppMatch = this.parentEvent.matches.createMatch();
                                                const p = match.initialize(this.parentEvent, foundMatch.doc)
                                                    .then(() => {
                                                        //add to event matches array 
                                                        this._matches.push(match);
                                                    });

                                                promiseArray.push(p);

                                            } else {
                                                //this would happen if the match was created during this session and and saved for the first time 
                                                localMatchFound.update(foundMatch.doc, AppConfig.UPDATE_TYPE.Modified);
                                            }

                                        } else {
                                            //for updated or removed matches
                                            localMatchFound?.update(foundMatch.doc, foundMatch.type);
                                        }

                                    });

                                Promise.all(promiseArray)
                                    .then(() => {
                                        resolve();
                                    });

                            }, (err) => {
                                console.log('app.event.ts AppEvent getMatches onSnapshot error', err);
                            });

                        //store snapshot for shutdown
                        this.parentEvent._appFunction.registerUnsubscribe(getMatchesUnsubscribe);

                    } else {
                        //noop
                        resolve();
                    }

                } catch (err) {
                    console.log('app.event.ts AppEvent getMatches', err, JSON.stringify(err));
                    resolve();
                }

            });

        }

        getPlayerMatches(player: AppEventPlayer): AppMatch[] {
            return [...this.parent, ...this.getPlayerMemberMatches(player)];
        }

        //this will return all matches that the player is in except for the event match
        getPlayerMemberMatches(player: AppEventPlayer): AppMatch[] {

            return this.active
                .filter((match) => {
                    //return the match if... 
                    //1) player is in the match OR
                    //2) player is the organizer of the match and the match is not the event match
                    return match.isPlayerInMemberMatch(player.id) || (match.organizerMemberId === player.member?.id && match.isMemberMatch);
                }) || [];

        }

        //add match that is not already added
        addMatch(match: AppMatch, isMatch: boolean): boolean {

            const foundMatch: AppMatch = this.getMatch(match.id);
            if (!foundMatch) {

                //add match to event matches array
                this._matches.push(match);

                //if is event match then set event match
                if (isMatch) {
                    this.parentEvent.matchIds.push(match.id);
                }

                return true;
            } else {
                return false;
            }

        }

        async createTeams(currentCourseHoleIndex: number) {

            //different approach
            //1) interate through players and get all member matches
            //2) get all event matches
            //3) dedup matches
            //4) call createTeam for each match

            const matches: AppMatch[] = [];

            //1) interate through players and get all member matches
            const teeTimePlayers: AppEventPlayer[] = <AppEventPlayer[]>this.parentEvent.players.active.teeTime;
            teeTimePlayers.forEach((player) => {

                //do each match (getPlayerMemberMatches only seems to get member matches...maybe change that later) for current player
                this.parentEvent
                    .matches
                    .getPlayerMemberMatches(player)
                    .forEach((match) => {
                        matches.push(match);
                    });

            });

            //2) get all event matches
            this.parentEvent.matches.parent.forEach((match) => {
                matches.push(match);
            });

            //3) dedup matches
            const uniqueMatches: AppMatch[] = matches.filter((match, index, self) => {
                return index === self.findIndex((t) => {
                    return t.id === match.id;
                });
            });

            //4) call createTeam for each match
            await uniqueMatches.reduce(async (previousMatch, currentMatch) => {

                //wait for previsous player to finish
                await previousMatch;

                //do each match
                const p = currentMatch.createTeam(currentMatch.players, this.parentEvent, currentCourseHoleIndex);

                //wait for all matches to return for current player
                await Promise
                    .all([p])
                    .then(() => {
                        return;
                    });

            }, Promise.resolve());

        }

    }(this);

    events = <MatchEventsI>new class {

        constructor(private parentEvent: AppEvent) {
        }

        get all(): AppEvent[] {
            return this.parentEvent.exists ? [this.parentEvent] : [];
        }

        in(event: AppEvent): boolean {
            return this.all.some((allEvent) => {
                return allEvent.id === event.id;
            });
        }

    }(this)

    getNotificationDistributionList(editMode: number, communicationType: CommunicationType): Promise<AppMember[]> {

        return new Promise<any[]>((resolve) => {

            const members: AppMember[] = [];
            const promiseArray: any[] = [];

            //if this is a group event
            if (this.group && communicationType === CommunicationType.GroupMembers) {

                //send to each member of the group
                this.group
                    .members
                    .forEach((groupMember) => {

                        //cast to get member
                        const member: AppMember = (<AppGroupEventMember | AppGroupTripMember>groupMember).member;

                        //get member preferences for this group
                        const p = member
                            .getPreference(this.group.id)
                            .then((preference: memberGroupPreferences) => {

                                //determine if this user wants this email
                                const sendNotification: boolean =
                                    (editMode === AppConfig.EDIT_MODE.new && preference.n === EventNotificationPreference.First) ||
                                    (preference.n === EventNotificationPreference.All || preference.n === undefined) //member wants all notifications or member hasn't set preferences

                                //if new event (push) notification (n) preference is set or is undefined (will then assume true)
                                if (sendNotification) {
                                    members.push(member);
                                }

                            });

                        promiseArray.push(p);

                    });

            } else { //this is a non group event or CommunicationType.eventPlayers

                this.players
                    .active
                    .members
                    .forEach((member) => {

                        //only send to players with a member object
                        if (member) {
                            members.push(member);
                        }

                    });

            }

            Promise
                .all(promiseArray)
                .then(() => {
                    resolve(members);
                });

        });

    }

    //send notification that player either joined or left the event
    sendPlayerStatusNotification(player: AppEventPlayer, currentRequest: EventJoinDropNotificationPreference) {

        //if event is from a group (not personal or trip events)
        if ([EventType.GroupEvent].includes(this.type)) {

            //get list of group admins 
            const adminMembers: AppMember[] = this.group.getAdmins();

            //iterate through all group admins and send email/notifications
            adminMembers.forEach((adminMember) => {

                //get the admin's communication preferences for this group
                adminMember
                    .getPreference(this.group?.id)
                    .then(async (preference: memberGroupPreferences) => {

                        if (preference) {

                            //campaign
                            const campaign: DeepLinkCampaign = (currentRequest === EventJoinDropNotificationPreference.Join ? DeepLinkCampaign.EventMemberJoined : DeepLinkCampaign.EventMemberDropped);

                            //setup title and message
                            const action: string = (currentRequest === EventJoinDropNotificationPreference.Join) ? 'joined' : 'dropped from';
                            const title: string = this.name + ' notification';

                            //create deep link parms object
                            const deepLinkParms: DeepLinkParmsI = {
                                route: '/main/home',
                                page: AppConfig.PAGE.EventDetail,
                                id: this.id,
                                segment: null,
                                actionCd: currentRequest === EventJoinDropNotificationPreference.Join ? EventActionCd.MemberJoined : EventActionCd.MemberDropped,
                                actionCdMessage: player.firstName + ' ' + player.lastName + ' has ' + action + ' the event scheduled for ' + moment(this.eventDt.toDate()).format('dddd MMMM D, YYYY').toString() + '.',
                                email: adminMember.email,
                                welcomeMessage: 'Hi ' + adminMember.firstName + ', there is join/dropped notification for <b>' + this.name + '</b> scheduled for ' + moment(this.eventDt.toDate()).format('dddd MMMM D, YYYY').toString() + '.',
                                emailHasAccount: null,
                                additionalData: { groupId: this.group?.id || null }
                            }

                            //#region send email

                            //determine if this admin member wants an email notification
                            const sendEmail: boolean =
                                preference.eventEmailJoinDrop === currentRequest || //join (1) or drop (2) 
                                preference.eventEmailJoinDrop === EventJoinDropEmailPreference.JoinDrop ||
                                preference.eventEmailJoinDrop === undefined; //admin member wants all emails or admin member hasn't set preferences

                            //send email if preference is set
                            if (sendEmail) {

                                //create deep link
                                let deeplink: string;
                                await this._deepLinkService
                                    .createDeepLink(campaign, DeepLinkChannel.email, deepLinkParms, { title: 'Welcome to Double Ace Golf', description: 'For the best experience please leave the checkbox below selected.' })
                                    .then((eventInviteDeepLink) => {
                                        deeplink = eventInviteDeepLink;
                                    });

                                //configure email
                                const personalizations = [];
                                personalizations.push({
                                    "subject": 'Event Notification',
                                    "templateId": AppConfig.SENDGRID_TEMPLATES.EventMemberJoinedDropped,
                                    "to": {
                                        "name": adminMember.firstName + ' ' + adminMember.lastName,
                                        "email": adminMember.email
                                    },
                                    "from": {
                                        "name": adminMember.firstName + ' ' + adminMember.lastName,
                                        "email": AppConfig.NOREPLY_EMAIL
                                    },
                                    "replyTo": {
                                        "name": adminMember.firstName + ' ' + adminMember.lastName,
                                        "email": adminMember.email
                                    },
                                    "dynamic_template_data": {
                                        "subject": 'Event Join/Drop Notification',
                                        "adminFirstName": adminMember.firstName,
                                        "adminLastName": adminMember.lastName,
                                        "groupName": this.name,
                                        "groupAvatarURI": this.avatarEmail,
                                        "title": title,
                                        "message": deepLinkParms.actionCdMessage,
                                        "deepLink": deeplink
                                    },
                                    "hideWarnings": true
                                });

                                //send email
                                this._appFunction.sendEmail(personalizations);

                            }

                            //#endregion send email

                            //#region send push notification

                            //determine if this admin member wants a notification
                            const sendNotification: boolean =
                                preference.eventNotificationJoinDrop === currentRequest || //join (1) or drop (2) 
                                preference.eventNotificationJoinDrop === EventJoinDropNotificationPreference.JoinDrop ||
                                preference.eventNotificationJoinDrop === undefined; //member wants all emails or member hasn't set preferences

                            //send email if preference is set
                            if (sendNotification) {

                                //create deep link
                                let deeplink: string;
                                await this._deepLinkService
                                    .createDeepLink(campaign, DeepLinkChannel.push, deepLinkParms, { title: 'Welcome to Double Ace Golf', description: 'For the best experience please leave the checkbox below selected.' })
                                    .then((eventInviteDeepLink) => {
                                        deeplink = eventInviteDeepLink;
                                    });

                                //send push notification
                                const memberIds = [];
                                memberIds.push({ memberId: adminMember.id });
                                this._appFunction.sendNotification(this._accountService.member.id, memberIds, title, deepLinkParms.actionCdMessage, this.avatar, { eventId: this.id }, deeplink);

                            }

                            //#endregion send push notification

                        }

                    });

            });
        }

    }

    setClub(clubId: string, courseId: string, teeId: string): Promise<void> {

        return new Promise<void>((resolve) => {

            //set the new course id
            this._clubId = clubId;

            //with the new club the course no longer valid
            this._courseId = undefined;
            this.courseId = undefined;

            //now get club
            this._clubService
                .getClub(clubId)
                .toPromise()
                .then((club) => {

                    //set course and...
                    this.club = club;

                    //...if only one course then auto select course
                    if (this.club.courses.length === 1) {
                        this.course = this.club.courses[0];
                        this.courseId = this.course.courseId;
                    } else {
                        //...else get selected course (if any)...
                        this.course = this.club.getCourse(courseId);
                        this.courseId = this.course?.courseId;
                    }

                    //...then get tee
                    if (this.courseId) {
                        this.tee = this.course.getTee(teeId);
                        this.teeId = this.tee?.teeId;
                    }

                    resolve();

                });

        });

    }

    composeEmailConfirm() {

        const actionCtrlItems: any[] = [];

        //only present this option for group events
        if (this.group) {

            actionCtrlItems.push({
                text: 'All group members',
                handler: async () => {

                    this._appFunction
                        .modalCtrl
                        .create({
                            component: EmailPage,
                            presentingElement: await this._appFunction.routerOutlet(),
                            cssClass: 'custom-modal' //for md
                        })
                        .then((modal) => {

                            modal
                                .present()
                                .catch((err) => {
                                    console.log('app.event.ts composeEmailConfirm modal present error', err);
                                });

                            modal
                                .onDidDismiss()
                                .then((results) => {
                                    console.log('app.event.ts modal present results', results);

                                    if (results.data.action === 'send') {
                                        this.group.sendGroupMessageEmail(AppConfig.EDIT_MODE.new, results.data.subject, results.data.message);
                                    }

                                })
                                .catch((err) => {
                                    console.log('app.event.ts composeEmailConfirm modal onDidDismiss error', err);
                                });

                        })
                        .catch((err) => {
                            console.log('app.event.ts composeEmailConfirm modal create error', err);
                        });

                }
            });

        }

        actionCtrlItems.push({
            text: 'Event players only',
            handler: async () => {

                this._appFunction
                    .modalCtrl
                    .create({
                        component: EmailPage,
                        presentingElement: await this._appFunction.routerOutlet(),
                        cssClass: 'custom-modal' //for md
                    })
                    .then((modal) => {

                        modal
                            .present()
                            .catch((err) => {
                                console.log('app.event.ts modal present error', err);
                            });

                        modal
                            .onDidDismiss()
                            .then((results) => {
                                console.log('app.event.ts modal present results', results);

                                if (results.data.action === 'send') {
                                    this.sendEventMessageEmail(AppConfig.EDIT_MODE.new, results.data.subject, results.data.message);
                                }

                            })
                            .catch((err) => {
                                console.log('app.event.ts modal onDidDismiss error', err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.event.ts modal create error', err);
                    });

            }
        });

        actionCtrlItems.push({
            text: 'Cancel',
            role: 'cancel'
        });

        //confirmation
        this._appFunction
            .actionCtrl
            .create({
                header: 'Who should recieve this email?',
                buttons: actionCtrlItems
            })
            .then((action) => {
                action.present();
            });

    }

    sendEventMessageEmail(editMode, subject: string, message: string): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                const personalizations = [];
                let showGroupAvatar: string = 'none';
                const promiseArray: any[] = [];

                //if the group has an avatar then show
                if (this.avatar.length > 0) {
                    showGroupAvatar = 'table-row';
                }

                //send to each player of the event
                this.players
                    .active
                    .all
                    .filter((player) => {
                        return player.member; //only return players that are also members
                    })
                    .forEach((player) => {

                        //get email preferences
                        const q = player
                            .member
                            .getPreference(this.id)
                            .then((preference: memberGroupPreferences) => {

                                //determine if this user wants this email
                                const sendEmail: boolean =
                                    (editMode === AppConfig.EDIT_MODE.new && preference.e === EventEmailPreference.First) || //member only wants new event emails
                                    (preference.e === EventEmailPreference.All || preference.e === undefined) //member wants all emails or member hasn't set preferences


                                //if event update email (e) preference is set or is undefined (will then assume true)
                                if (sendEmail) {

                                    personalizations.push({
                                        "subject": subject,
                                        "templateId": AppConfig.SENDGRID_TEMPLATES.GroupMessage,
                                        "to": {
                                            "name": player.member.firstName + ' ' + player.member.lastName,
                                            "email": player.member.email
                                        },
                                        "from": {
                                            "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                            "email": AppConfig.NOREPLY_EMAIL
                                        },
                                        "replyTo": {
                                            "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                            "email": this._accountService.member.email
                                        },
                                        "dynamic_template_data": {
                                            "subject": subject,
                                            "message": message,
                                            "firstName": player.member.firstName,
                                            "lastName": player.member.lastName,
                                            "groupName": this.name,
                                            "groupAvatarURI": this.avatarEmail,
                                            "showGroupAvatar": showGroupAvatar,
                                            "memberId": player.member.id,
                                            "groupId": this.id
                                        },
                                        "hideWarnings": true
                                    });

                                }

                            });

                        promiseArray.push(q);

                    });

                Promise
                    .all(promiseArray)
                    .then(() => {

                        this._appFunction
                            .sendEmail(personalizations)
                            .then(() => {

                                this._appFunction
                                    .queueToast({
                                        message: 'The Event email has been sent.',
                                        position: 'top',
                                        duration: 4000,
                                        color: 'secondary',
                                        closeButtonText: 'Ok'
                                    });

                                resolve();
                            })
                            .catch((err) => {
                                reject(err);
                            });

                    });

            } catch (err) {
                console.log('app.event.ts sendEventMessageEmail promise.all error', err, JSON.stringify(err));
                reject();
            }

        });

    }

    get nines(): AppCourseNineI[] {

        return this.tee
            .nines
            .filter((nine, nineIndex) => {
                return this.includeNine(nineIndex);
            });

    }

    get holes(): number[] {

        //flaten out the nines nested array
        return this.nines?.flatMap((nine) => {
            return nine.holes.map((hole) => {
                return hole.number - 1; //retunr the index and not the display number
            });
        });

    }

    includeNine(nineIndex: number): boolean {
        //return this nine if we're playing all 18 holes or course is only 9 holes or if this is the nine holes selected 
        return this.numberOfHoles === 18 || this.course.holes === 9 || nineIndex === this.nineHolesOnlyIndex;
    }

    nextHoleIndex(currentHoleIndex: number): number {
        const firstHoleIndex: number = this.nines[0].holes[0].number - 1;
        return ((currentHoleIndex + 1) % this.numberOfHoles) + firstHoleIndex;
    }

    previousHoleIndex(currentHoleIndex: number): number {
        const firstHoleIndex: number = this.nines[0].holes[0].number - 1;
        return this._appFunction.mod(currentHoleIndex - 1, this.numberOfHoles) + firstHoleIndex;
    }

    get isEventTodayOrPast(): boolean {
        //if logged in memeber is a sys admin or the event is today
        return this._accountService.member.admin || moment(moment(new Date()).startOf('day').toDate()).isSameOrAfter(moment(this.eventDt.toDate()).startOf('day').toDate());
    }

    get avatar(): string {
        return this.group?.avatar.URI || this.createdMember.avatar.URI;
    }

    get avatarEmail(): string {
        return this.group?.avatar.URIEmail || this.createdMember.avatar.URIEmail;
    }

    get cover(): string {
        return this.group?.cover.URI || AppConfig.NO_COVER_URI;
    }

    get name(): string {

        //if event belongs to a trip
        if (this.group?.type === GroupType.Trip) {

            //find the this events index in the trip events array
            const tripEventIndex: number = (<AppGroupTrip>this.group).events.all.findIndex((tripEvent) => {
                return tripEvent.id === this.id;
            });

            //if event is not found then return the group name
            if (tripEventIndex === -1) {
                return this.group.name;
            } else {
                //return the trip event name
                return 'Round ' + (tripEventIndex + 1).toString();
            }

        } else {
            return this.group?.name || this.createdMember.firstName + ' ' + this.createdMember.lastName;
        }

    }

    isMemberAdmin(member: AppMember): boolean {
        //either a group admin or ad-hoc event owner
        return this.group?.isMemberAdmin(member) || member?.id === this.createdMemberId;
    }

    get numberOfAvailableSpots(): number {
        return (this.numberTeeTimes * 4) - this.players.active.all.length;
    }

    /* this is use to sort events (and group trips) on the home screen */
    get sortByDt(): Date {
        return this.eventDt.toDate();
    }

    save(): Promise<AppEvent> {

        return new Promise<AppEvent>((resolve, reject) => {

            try {

                //create batch transaction
                const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();

                //save the event data
                batch.set(this._eventDoc.ref, this.data(), { merge: true });

                //then save the event matches (if there is a configured match for this event)
                const promiseArray: any[] = [];

                //iterate through all event matches and save
                this.matches
                    .parent
                    .forEach((match) => {
                        const p = match.save(batch);
                        promiseArray.push(p)
                    });

                //once everything is done, commit the save
                Promise
                    .all(promiseArray)
                    .then(() => {

                        batch
                            .commit()
                            .then(() => {
                                resolve(this);
                            })
                            .catch((err) => {
                                console.log('app.event.ts AppEvent save error', JSON.stringify(err));
                                reject(err);
                            });

                    });

            } catch (err) {
                console.log('app.event.ts AppEvent save error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    delete(): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //set status
                this.deleted = true;

                //save event
                this.save()
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        console.log('app.event.ts AppEvent delete doc error', JSON.stringify(err));
                        reject(err);
                    });

            } catch (err) {
                console.log('app.event.ts AppEvent delete error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    private data(): AppEventI {

        try {

            //get event match ids
            const matchIds: string[] = [];
            this.matches
                .parent
                .forEach((match) => {
                    matchIds.push(match.id);
                });

            return {
                description: this.description ? this.description.trim() : null,
                eventDt: this.eventDt ? this.eventDt : null,
                timezone: this.timezone,
                numberTeeTimes: this.numberTeeTimes,
                groupId: this.groupId || null,
                lastUpdatedMemberId: this._accountService.member.id,
                createdMemberId: this.createdMemberId,
                teeTimeInterval: this.teeTimeInterval,
                clubId: this.clubId,
                teeId: this.teeId,
                courseId: this.courseId,
                numberOfHoles: this.numberOfHoles || 18,
                startingHoleIndex: this.startingHoleIndex || 0,
                memberIds: this.memberIds,
                matchIds: matchIds,
                deleted: this.deleted,
                type: this.type,
                nineHolesOnlyIndex: this.numberOfHoles === 18 ? null : this.nineHolesOnlyIndex === undefined ? null : this.nineHolesOnlyIndex,
                createdDt: this.createdDt || firebase.firestore.Timestamp.fromDate(new Date()),
                updatedDt: firebase.firestore.Timestamp.fromDate(new Date())
            };

        }
        catch (err) {
            console.log('app.event.ts AppEvent data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

@Injectable({
    providedIn: 'root'
})
export class EventService {

    events: AppEvent[] = [];
    newEvent: Subject<AppEvent> = new Subject<AppEvent>();

    constructor(
        private _appFunction: AppFunction) {

        //clean up
        this._appFunction
            .shutDown
            .subscribe(() => {

                //clear event cache
                this.events = [];

            });

    }

    createEvent(): AppEvent {
        //create, push and return...
        const event: AppEvent = new AppEvent();
        this.events.push(event);
        return event;
    }

    getEvent(group: AppGroupI, eventId: string, eventDoc: firebase.firestore.DocumentSnapshot = undefined): Promise<AppEvent> {

        return new Promise<AppEvent>((resolve) => {

            //look for cached event
            const index: number = this.events.findIndex((event) => {
                return event.id === eventId;
            });

            //if found in cache then return
            if (index > -1) {
                resolve(this.events[index]);
            } else if (eventDoc) {

                //if event doc then create...
                const event: AppEvent = this.createEvent();

                //..then initialize
                event
                    .initialize(group, eventDoc)
                    .then(() => {
                        //...and then return
                        resolve(event);
                    });

            } else {
                console.log('app.event.ts EventService getEvent error, event not returned');
                resolve(undefined);
            }

        });

    }

}