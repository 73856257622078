import { Component, Input } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { TripAttendanceStatus } from 'src/app/app.group';

@Component({
  selector: 'trip-status',
  templateUrl: './trip-status.component.html',
  styleUrls: ['./trip-status.component.scss']
})
export class TripStatusComponent {

  @Input() tripStatus: TripAttendanceStatus;
  @Input() isMemberOnWaitlist: boolean;
  TripAttendanceStatus: typeof TripAttendanceStatus = TripAttendanceStatus;
  faTicketAlt = AppConfig.faTicketAlt;
  faThumbsUp = AppConfig.faThumbsUp;
  faThumbsDown = AppConfig.faThumbsDown;

  constructor() {

  }

}
