<div class="iconWrapper">

  <div id="{{name + 'bg'}}" class="bg"></div>

  <ng-container *ngFor="let button of buttons;">

    <ion-label>
      <ion-icon id="{{name + button.value}}" (click)="setActive($event, button.value)" name="{{button.iconName}}"
        size="large" class="item">
      </ion-icon>
    </ion-label>

  </ng-container>

</div>

<div class="labelWrapper">
  <ng-container *ngFor="let button of buttons;">

    <ion-label>
      {{button.label}}
    </ion-label>

  </ng-container>
</div>