import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ContactSearchPage } from './contact-search.page';
import { PipesModule } from './../../pipes/pipes.module';

const routes: Routes = [
  {
    path: '',
    component: ContactSearchPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    PipesModule
  ],
  declarations: [ContactSearchPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ContactSearchPage]
})
export class ContactSearchPageModule {}
