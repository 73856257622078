import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from '../../app.config';
import { IonModal, NavParams } from '@ionic/angular';
import { AppFunction } from '../../app.function';
import { AppGroupEventMember, AppGroupTripMember } from 'src/app/app.group';
import { AppMember } from 'src/app/app.account';

@Component({
  selector: 'member-list',
  templateUrl: './member-list.page.html',
  styleUrls: ['./member-list.page.scss'],
})
export class MemberListPage implements OnInit {

  currentMemberObjectIds: any = [];
  members: (AppGroupEventMember | AppGroupTripMember)[] = [];
  filteredMembers: (AppGroupEventMember | AppGroupTripMember)[] = [];
  selectedMembers: AppMember[] = [];
  avatarDirectory: string;
  showSelectedMembersModal: boolean = false;
  @ViewChild(IonModal) modal: IonModal;

  constructor(
    public appFunction: AppFunction,
    public navParams: NavParams) {

    //get avatar directory
    this.avatarDirectory = AppConfig.MEDIA_STORAGE.AVATAR;

  }

  ngOnInit() {

    //get passed in data
    this.currentMemberObjectIds = this.navParams.get('currentMemberObjectIds');
    this.members = this.navParams.get('members');
    this.filteredMembers = this.members;

  }

  addMember(member: AppMember) {

    this.selectedMembers.push(member);
    this.currentMemberObjectIds.push(member.id);

    //show the selected members modal
    this.showSelectedMembersModal = true;

  }

  removeSelectedMember(member: AppMember) {

    let memberIndex = this.selectedMembers.indexOf(member);
    this.selectedMembers.splice(memberIndex, 1);

    let currentMemberObjectIdIndex = this.currentMemberObjectIds.indexOf(member.id);
    this.currentMemberObjectIds.splice(currentMemberObjectIdIndex, 1);

    //hide the selected members modal if no members are selected
    this.showSelectedMembersModal = this.selectedMembers.length > 0 ? true : false;

  }

  memberSelected(memberObjectId) {

    return this.currentMemberObjectIds.some(function (currentMemberObjectId) {
      return currentMemberObjectId === memberObjectId;
    });

  }

  searchCriteriaChange(event: any) {

    //only perform a search if the user has entered criteria
    if (event.target.value && event.target.value.length > 0) {
      this.filteredMembers = this.members.filter((groupMember) => {
        return (groupMember.member.firstName.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0 || groupMember.member.lastName.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0);
      });
    } else {
      this.filteredMembers = this.members;
    }

  }

  searchCriteriaClear(event: any) {
    this.filteredMembers = this.members;
  }

  done() {

    //dismiss the modal
    this.modal.dismiss()
      .then(() => {
        //dismiss the modal
        this.appFunction.modalCtrl.dismiss({
          selectedMembers: this.selectedMembers
        });
      });

  }

}
