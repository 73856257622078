<ng-container *ngIf="tripStatus === TripAttendanceStatus.Invited">
  <fa-icon [icon]="faTicketAlt"></fa-icon>
  <ion-text>You've been invited</ion-text>
</ng-container>
<ng-container *ngIf="tripStatus === TripAttendanceStatus.In">
  <fa-icon [icon]="faThumbsUp"></fa-icon>
  <ion-text>Yes, I'm in</ion-text>
  <ion-text *ngIf="isMemberOnWaitlist" style="font-size: small;">&nbsp;(you are currently on the waitlist)</ion-text>
</ng-container>
<ng-container *ngIf="tripStatus === TripAttendanceStatus.Out">
  <fa-icon [icon]="faThumbsDown"></fa-icon>
  <ion-text>No, I'm out</ion-text>
</ng-container>