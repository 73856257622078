import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppEventPlayer, GreenInRegulation, FairwayHit } from 'src/app/app.event';
import { AppFunction } from 'src/app/app.function';

@Component({
  selector: 'player-stats',
  templateUrl: './player-stats.page.html',
  styleUrls: ['./player-stats.page.scss'],
})
export class PlayerStatsPage implements OnInit {

  player: AppEventPlayer;
  nineIndex: number;
  holeIndex: number;
  GreenInRegulation: typeof GreenInRegulation = GreenInRegulation;
  FairwayHit: typeof FairwayHit = FairwayHit;

  constructor(
    private navParams: NavParams,
    public appFunction: AppFunction) {

  }

  ngOnInit() {
    this.player = <AppEventPlayer>this.navParams.get('player');
    this.nineIndex = <number>this.navParams.get('nineIndex');
    this.holeIndex = <number>this.navParams.get('holeIndex');
  }

  setPutts(putts: number) {
    this.player.nines[this.nineIndex].holes[this.holeIndex].putts = putts;
  }

  setGIR(gir: number) {
    this.player.nines[this.nineIndex].holes[this.holeIndex].gir = gir;
  }

  setFairway(fairway: number) {
    this.player.nines[this.nineIndex].holes[this.holeIndex].fairway = fairway;
  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}
