<ion-header class="ion-no-border">

  <ion-toolbar>

    <div slot="start" style="margin-left: 5px; font-weight: 500;">{{title}}</div>

    <ion-buttons slot="primary">

      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Got it
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

</ion-header>

<modal-content class="help-view-content">

  <div [innerHTML]="subject" class="ion-padding helpWhatsNew" style="margin-bottom: 5px;"></div>

</modal-content>