<!-- validation hero -->
<validation-hero [display]="match.validationErrors.length > 0"
  [header]="'This match has some setup problems. Please open match configuration for more information.'">
</validation-hero>

<!-- scoring has started!!! -->
<!-- <ng-container *ngIf="match.status !== ScoringMode.ScoringNotStarted"> -->

<!-- match -->
<ion-item class="ion-no-padding" style="--inner-padding-end: 0px" lines="none">

  <!-- *********** STROKE PLAY *********** -->
  <ion-grid class="ion-no-padding game" *ngIf="match.game === GameType.StrokePlay">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- <ng-container
      *ngIf="!match.subMatches[0].teamCreatePrompted; else showCreateTeam; context: { subMatch: match.subMatches[0] }"> -->

    <!-- header row -->
    <ion-row class="ion-align-items-center" style="font-size: 14px">
      <ion-col class="ion-no-padding" size="1.2">
        <ion-text class="ion-float-left"> Pos </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4.8">
        <ion-text class="ion-float-left"> Team </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4">
        <ion-text class="ion-float-right"> To Par </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right"> Thru </ion-text>
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container *ngIf="match.subMatches[0].totalScoreLeaderboard.eventsList.length > 0;">

      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of totalScoreLeaderboardEventTeamList;">

        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="6.5">{{teamScoreCard.team.name}}</ion-col>

        <ion-col class="ion-no-padding" size="2.25">
          <ion-chip *ngIf="teamScoreCard.won" class="won">
            <ion-label>Won</ion-label>
          </ion-chip>
          <ion-text class="ion-float-right">
            <ion-text>{{teamScoreCard.teamToPar <= 0 ? '' : '+' }}</ion-text>
                <ion-text>{{teamScoreCard.teamToPar === 0 ? 'E' :
                  teamScoreCard.teamToPar}}</ion-text>
            </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            <!-- if event is starting on the back nine then show * -->
            {{(teamScoreCard.holeThruMatchIndex + 1).toString() + (event.startingHoleIndex === 9 ? '*' : '')}}
          </ion-text>
        </ion-col>

      </ion-row>

      <!-- display message if all teams haven't started scoring -->
      <ion-row class="ion-no-padding teamMessage"
        *ngIf="totalScoreLeaderboardEventTeamList.length < match.subMatches[0].calcNumberOfTeams()">

        <ion-col class="ion-no-padding">
          <ion-note>Teams/players will appear as they start their scoring</ion-note>
        </ion-col>

      </ion-row>

    </ng-container>

    <!-- display teams when there are no scores for the sub match -->
    <ng-container *ngIf="match.subMatches[0].totalScoreLeaderboard.eventsList.length === 0;">

      <!-- display teams if available -->
      <ng-container *ngIf="this.match.subMatches[0].teams?.length > 0; else noteams">

        <ng-container *ngFor="let team of this.match.subMatches[0].teams;">
          <ion-row class="ion-no-padding ion-align-items-center">
            <ion-col class="ion-no-padding" size="1.2">-</ion-col>
            <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="7.75">{{team.name}}</ion-col>
            <ion-col class="ion-no-padding" size="1">
              <ion-text class="ion-float-right">
                <ion-text>0</ion-text>
              </ion-text>
            </ion-col>
            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">-</ion-text>
            </ion-col>

          </ion-row>

        </ng-container>

      </ng-container>

    </ng-container>

    <!-- </ng-container> -->

  </ion-grid>

  <!-- *********** STABLEFORD *********** -->
  <ion-grid class="ion-no-padding game" *ngIf="match.game === GameType.Stableford">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- header row -->
    <ion-row class="ion-align-items-center" style="font-size: 14px">
      <ion-col class="ion-no-padding" size="1.2">
        <ion-text class="ion-float-left"> Pos </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4.8">
        <ion-text class="ion-float-left"> Team </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4">
        <ion-text class="ion-float-right"> Points </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right"> Thru </ion-text>
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container *ngIf="match.subMatches[0].totalScoreLeaderboard.eventsList.length > 0;">

      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of totalScoreLeaderboardEventTeamList;">

        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="6.5">{{teamScoreCard.team.name}}</ion-col>

        <ion-col class="ion-no-padding" size="2.25">
          <ion-chip *ngIf="teamScoreCard.won" class="won">
            <ion-label>Won</ion-label>
          </ion-chip>
          <ion-text class="ion-float-right">
            <ion-text>{{teamScoreCard.teamScore}}</ion-text>
          </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            {{teamScoreCard.holeThruMatchIndex + 1}}
          </ion-text>
        </ion-col>

      </ion-row>

      <!-- display message if all teams haven't started scoring -->
      <ion-row class="ion-no-padding teamMessage"
        *ngIf="totalScoreLeaderboardEventTeamList.length < match.subMatches[0].calcNumberOfTeams()">

        <ion-col class="ion-no-padding">
          <ion-note>Teams/players will appear as they start their scoring</ion-note>
        </ion-col>

      </ion-row>

    </ng-container>

    <!-- display teams when there are no scores for the sub match -->
    <ng-container *ngIf="match.subMatches[0].totalScoreLeaderboard.eventsList.length === 0;">

      <!-- display teams if available -->
      <ng-container *ngIf="this.match.subMatches[0].teams?.length > 0; else noteams">

        <ng-container *ngFor="let team of this.match.subMatches[0].teams;">

          <ion-row class="ion-no-padding ion-align-items-center">

            <ion-col class="ion-no-padding" size="1.2">-</ion-col>

            <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="7.75">{{team.name}}</ion-col>

            <ion-col class="ion-no-padding" size="1">
              <ion-text class="ion-float-right">
                <ion-text>0</ion-text>
              </ion-text>
            </ion-col>

            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">-</ion-text>
            </ion-col>

          </ion-row>

        </ng-container>

      </ng-container>

    </ng-container>

  </ion-grid>

  <!-- *********** MATCH PLAY or SKINS *********** -->
  <ion-grid class="ion-no-padding game" *ngIf="[GameType.MatchPlay, GameType.Skins].includes(this.match.game)">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container *ngIf="totalHoleByHoleLeaderboardEventList.length > 0;">

      <!-- build the list of horizontal hole numbers for Match play-->
      <ion-row class="ion-no-padding" *ngIf="match.game === GameType.MatchPlay">
        <ion-col class="ion-no-padding" style="display: flex">
          <div class="hole" *ngFor="let holeIndex of match.subMatches[0].getAdjustMatchHoleIndexes(event)"
            [ngStyle]="{ 'background-color': holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam ? holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.backgroundcolor : (holeByHoleLeaderboard.eventsList[0].holes[holeIndex]===undefined ? 'initial' : 'var(--ion-color-white-d)'), 
                    'border-color': holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam ? holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.backgroundcolor : 'var(--ion-color-black-b)',
                    'color': holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam ? holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.fontcolor: 'initial'}">
            <div>{{holeIndex + 1}}</div>
          </div>
        </ion-col>
      </ion-row>

      <!-- build the list of horizontal hole numbers for Skins-->
      <ion-row class="ion-no-padding" *ngIf="match.game === GameType.Skins">
        <ion-col class="ion-no-padding" style="display: flex">
          <div class="hole" *ngFor="let holeIndex of match.subMatches[0].getAdjustMatchHoleIndexes(event)"
            [ngStyle]="{ 
                      'background-color': (holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam && !holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeCarryOverByTeam) ? holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.backgroundcolor : (holeByHoleLeaderboard.eventsList[0].holes[holeIndex]===undefined ? 'initial' : 'var(--ion-color-white-d)'), 
                      'border-color': (holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam) ? holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.backgroundcolor : 
                            (holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam===null && holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeCarryOverByTeam===null ? 'var(--ion-color-black-b)' : 
                            (holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeCarryOverByTeam) ? holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeCarryOverByTeam.teamColor.backgroundcolor : 'initial'), 
                      'color': (holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam) ? holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.fontcolor: 'initial',
                      'border-width': (!holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam && holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeCarryOverByTeam) ? '1.5px': '0.5px'}">
            <div>{{holeIndex + 1}}</div>
          </div>
        </ion-col>
      </ion-row>

      <!-- header row -->
      <ion-row class="ion-align-items-center" style="margin-top: 10px; font-size: 14px">

        <ion-col class="ion-no-padding" size="1.2">
          <ion-text class="ion-float-left"> Pos </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="4.8">
          <ion-text class="ion-float-left"> Team </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="4">
          <ion-text class="ion-float-right"> Holes won </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right"> Thru </ion-text>
        </ion-col>

      </ion-row>

      <!-- build the list of teams winning team first -->
      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of totalHoleByHoleLeaderboardEventTeamList;">

        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <ion-col class="ion-no-padding ion-text-nowrap fadeText" style="display: flex" size="6.75">
          <div class="teamMarker" [ngStyle]="{ 'background-color': teamScoreCard.team.teamColor.backgroundcolor }">
          </div>
          <ion-text>{{teamScoreCard.team.name}}</ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="2">
          <ion-chip *ngIf="teamScoreCard.won" class="won">
            <ion-label>Won</ion-label>
          </ion-chip>
          <ion-text class="ion-float-right">{{teamScoreCard.holesWon}}</ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            {{teamScoreCard.holeThruMatchIndex + 1}}
          </ion-text>
        </ion-col>

      </ion-row>

      <!-- display message if all teams haven't started scoring -->
      <ion-row class="ion-no-padding teamMessage"
        *ngIf="totalHoleByHoleLeaderboardEventTeamList.length < match.subMatches[0].calcNumberOfTeams()">

        <ion-col class="ion-no-padding">
          <ion-note>Teams/players will appear as they start their scoring</ion-note>
        </ion-col>

      </ion-row>

    </ng-container>

    <!-- display teams when there are no scores for the sub match -->
    <ng-container *ngIf="holeByHoleLeaderboard.eventsList.length === 0;">

      <!-- build the list of horizontal hole numbers -->
      <ion-row class="ion-no-padding holeByHoleNumbers">
        <ion-col class="ion-no-padding" style="display: flex">
          <div class="hole" *ngFor="let holeIndex of match.subMatches[0].getAdjustMatchHoleIndexes(event)">
            <div>{{holeIndex + 1}}</div>
          </div>
        </ion-col>
      </ion-row>

      <!-- display teams if available -->
      <ng-container *ngIf="match.subMatches[0].teams?.length > 0; else noteams">

        <!-- header row -->
        <ion-row class="ion-align-items-center" style="font-size: 14px">

          <ion-col class="ion-no-padding" size="1.2">
            <ion-text class="ion-float-left"> Pos </ion-text>
          </ion-col>

          <ion-col class="ion-no-padding" size="4.8">
            <ion-text class="ion-float-left"> Team </ion-text>
          </ion-col>

          <ion-col class="ion-no-padding" size="4">
            <ion-text class="ion-float-right"> Holes won </ion-text>
          </ion-col>

          <ion-col class="ion-no-padding" size="2">
            <ion-text class="ion-float-right"> Thru </ion-text>
          </ion-col>

        </ion-row>

        <!-- build the list of teams -->
        <ng-container *ngFor="let team of match.subMatches[0].teams;">

          <ion-row class="ion-no-padding ion-align-items-center">

            <ion-col class="ion-no-padding" size="1.2">-</ion-col>

            <ion-col class="ion-no-padding ion-text-nowrap fadeText" style="display: flex" size="7.75">
              <div class="teamMarker" [ngStyle]="{'background-color': team.teamColor.backgroundcolor }"></div>
              <ion-text>{{team.name}}</ion-text>
            </ion-col>

            <ion-col class="ion-no-padding" size="1">
              <ion-text class="ion-float-right">
                <ion-text>0</ion-text>
              </ion-text>
            </ion-col>

            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">-</ion-text>
            </ion-col>

          </ion-row>

        </ng-container>

      </ng-container>

    </ng-container>

  </ion-grid>

  <!-- *********** SPINS or NASSAU *********** -->
  <ng-container *ngIf="[GameType.Spins9, GameType.Spins18, GameType.Nassau].includes(this.match.game)">

    <ng-container *ngIf="!showInScorecard">
      <ion-slides #matchSlides pager="true" effect="slide" [options]="slideOpts" class="matchSlides">
        <ion-slide *ngFor="let subMatch of match.subMatches;">
          <ng-container
            *ngTemplateOutlet="spins; context: { subMatch: subMatch, showInScorecard: showInScorecard }"></ng-container>
        </ion-slide>
      </ion-slides>
    </ng-container>

    <div *ngIf="showInScorecard" style="width: 100%;">
      <div *ngFor="let subMatch of match.subMatches;" class="subMatchContainer"
        style="display: flex; overflow-y: scroll;">
        <ng-container
          *ngTemplateOutlet="spins; context: { subMatch: subMatch, showInScorecard: showInScorecard }"></ng-container>
      </div>
    </div>

  </ng-container>

  <!-- *********** NINES *********** -->
  <ion-grid class="ion-no-padding game" *ngIf="match.game === GameType.Nines">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- header row -->
    <ion-row class="ion-align-items-center" style="font-size: 14px">
      <ion-col class="ion-no-padding" size="1.2">
        <ion-text class="ion-float-left"> Pos </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4.8">
        <ion-text class="ion-float-left"> Team </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4">
        <ion-text class="ion-float-right"> Points </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right"> Thru </ion-text>
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container *ngIf="match.subMatches[0].totalScoreLeaderboard.eventsList.length > 0;">

      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of totalScoreLeaderboardEventTeamList;">

        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="6.5">{{teamScoreCard.team.name}}</ion-col>

        <ion-col class="ion-no-padding" size="2.25">
          <ion-chip *ngIf="teamScoreCard.won" class="won">
            <ion-label>Won</ion-label>
          </ion-chip>
          <ion-text class="ion-float-right">
            <ion-text>{{teamScoreCard.teamScore}}</ion-text>
          </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            {{teamScoreCard.holeThruMatchIndex + 1}}
          </ion-text>
        </ion-col>

      </ion-row>

      <!-- display message if all teams haven't started scoring -->
      <ion-row class="ion-no-padding teamMessage"
        *ngIf="totalScoreLeaderboardEventTeamList.length < match.subMatches[0].calcNumberOfTeams()">

        <ion-col class="ion-no-padding">
          <ion-note>Teams/players will appear as they start their scoring</ion-note>
        </ion-col>

      </ion-row>

    </ng-container>

    <!-- display teams when there are no scores for the sub match -->
    <ng-container *ngIf="this.match.subMatches[0].totalScoreLeaderboard.eventsList.length === 0;">

      <!-- display teams if available -->
      <ng-container *ngIf="this.match.subMatches[0].teams?.length > 0; else noteams">

        <ng-container *ngFor="let team of this.match.subMatches[0].teams;">

          <ion-row class="ion-no-padding ion-align-items-center">

            <ion-col class="ion-no-padding" size="1.2">-</ion-col>

            <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="7.75">{{team.name}}</ion-col>

            <ion-col class="ion-no-padding" size="1">
              <ion-text class="ion-float-right">
                <ion-text>0</ion-text>
              </ion-text>
            </ion-col>

            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">-</ion-text>
            </ion-col>

          </ion-row>

        </ng-container>

      </ng-container>

    </ng-container>

  </ion-grid>

</ion-item>

<!-- icon row -->
<ion-item *ngIf="!showInScorecard" class="iconRow" lines="full"
  style="--min-height: unset; font-size: larger; color: grey;">

  <ion-grid class="ion-no-padding">

    <ion-row class="ion-no-padding">

      <ion-col class="ion-no-padding" size="2" (click)="openScorecardMethod(match.players, match)">
        <ion-icon src="../../assets/icons/scoreboard3.svg"></ion-icon>
      </ion-col>

      <ion-col class="ion-no-padding" size="2" (click)="viewMatchFormat()">
        <ion-icon name="game-controller"></ion-icon>
      </ion-col>

      <!-- only show if logged in member created the match -->
      <ion-col class="ion-no-padding ion-text-right" *ngIf="match.organizerMemberId === accountService.member.id"
        (click)="editMatchFormat()">
        <ion-icon name="options"></ion-icon>
      </ion-col>

    </ion-row>

  </ion-grid>

</ion-item>

<!-- </ng-container> -->

<!-- DELETE??? no teams in the event match have start scoring -->
<ng-container *ngIf="match.status === ScoringMode.ScoringNotStarted && false">

  <ion-grid class="ion-no-padding game">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- if submatch is requiring a team (true) then show button -->
    <ion-row *ngIf="match.subMatches[0].teamCreatePrompted">
      <ion-col class="ion-no-padding">
        <ion-button shape="round" (click)="createTeam(match.subMatches[0])" expand="block" color="primary">
          Create Teams
        </ion-button>
      </ion-col>
    </ion-row>

    <!-- if submatch is requiring a team (true) then show button -->
    <ng-container *ngIf="!match.subMatches[0].teamCreatePrompted">
      <match-view [match]="match" [formatLabel]="'Format'">
      </match-view>
    </ng-container>

    <!-- icon row -->
    <ion-row class="ion-no-padding iconRow" *ngIf="match.organizerMemberId===accountService.member.id" lines="full"
      style="font-size: larger; color: grey;">

      <ion-col class="ion-no-padding">

        <ion-grid class="ion-no-padding">

          <ion-row class="ion-no-padding">

            <!-- only show if logged in member created the match -->
            <ion-col class="ion-no-padding ion-text-right" (click)="editMatchFormat()">
              <ion-icon name="options"></ion-icon>
            </ion-col>

          </ion-row>

        </ion-grid>

      </ion-col>

    </ion-row>

  </ion-grid>

</ng-container>

<!-- no team scores yet -->
<ng-template #noscores>
  <ion-row style="margin-left: 10px;">
    <ion-col size="12" class="ion-text-center" style="margin-top: 10px;">
      <ion-note style="font-size: smaller;">No scores have been posted for this match</ion-note>
    </ion-col>
  </ion-row>
</ng-template>

<!-- no teams yet -->
<ng-template #noteams>
  <ion-row style="margin-left: 10px;">
    <ion-col size="12" class="ion-text-center" style="margin-top: 10px;">
      <ion-note style="font-size: smaller;">Scoring hasn't started</ion-note>
    </ion-col>
  </ion-row>
</ng-template>

<!-- spins -->
<ng-template #spins let-subMatch="subMatch" let-showInScorecard="showInScorecard">

  <ion-grid class="ion-no-padding game" [ngStyle]="{'min-width': showInScorecard ? '350px' : 'unset'}">

    <!-- sub match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{subMatch.subMatchName()}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container *ngIf="subMatch.holeByHoleLeaderboard.eventsList.length > 0;">

      <!-- build the list of horizontal hole numbers for Match Play -->
      <ion-row class="ion-no-padding">
        <ion-col class="ion-no-padding" style="display: flex">
          <div class="hole" *ngFor="let index of subMatch.getAdjustMatchHoleIndexes(event)"
            [ngStyle]="{ 'background-color': subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[index]?.holeWonByTeam ? subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[index]?.holeWonByTeam.teamColor.backgroundcolor : (subMatch.holeByHoleLeaderboard.eventsList[0].holes[index]===undefined ? 'initial' : 'var(--ion-color-white-d)'), 
                          'border-color': subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[index]?.holeWonByTeam ? subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[index]?.holeWonByTeam.teamColor.backgroundcolor : 'var(--ion-color-black-b)',
                          'color': subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[index]?.holeWonByTeam ? subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[index]?.holeWonByTeam.teamColor.fontcolor: 'initial'}">
            <div>{{index + 1}}</div>
          </div>
        </ion-col>
      </ion-row>

      <!-- header row -->
      <ion-row class="ion-align-items-center" style="margin-top: 10px; font-size: 14px">

        <ion-col class="ion-no-padding" size="1.2">
          <ion-text class="ion-float-left"> Pos </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="4.8">
          <ion-text class="ion-float-left"> Team </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="4">
          <ion-text class="ion-float-right"> Holes won </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right"> Thru </ion-text>
        </ion-col>

      </ion-row>

      <!-- build the list of teams winning team first -->
      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of subMatch.holeByHoleLeaderboard.eventsList[0].teamsList;">

        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <ion-col class="ion-no-padding ion-text-nowrap fadeText" style="display: flex" size="6.75">
          <div class="teamMarker" [ngStyle]="{ 'background-color': teamScoreCard.team.teamColor.backgroundcolor }">
          </div>
          <ion-text>{{teamScoreCard.team.name}}</ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="2">
          <ion-chip *ngIf="teamScoreCard.won" class="won">
            <ion-label>Won</ion-label>
          </ion-chip>
          <ion-text class="ion-float-right">{{teamScoreCard.holesWon}}</ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            {{teamScoreCard.holeThruMatchIndex + 1}}
          </ion-text>
        </ion-col>

      </ion-row>

      <!-- display message if all teams haven't started scoring -->
      <ion-row class="ion-no-padding ion-align-items-center"
        style="font-size: 14px; padding-top: 10px; padding-bottom: 10px;"
        *ngIf="subMatch.holeByHoleLeaderboard.eventsList[0].teamsList.length < subMatch.calcNumberOfTeams()">

        <ion-col class="ion-no-padding">
          <ion-note>Teams/players will appear as they start their scoring</ion-note>
        </ion-col>

      </ion-row>

    </ng-container>

    <!-- display teams when there are no scores for the sub match -->
    <ng-container *ngIf="subMatch.holeByHoleLeaderboard.eventsList.length === 0;">

      <!-- build the list of horizontal hole numbers -->
      <ion-row class="ion-no-padding holeByHoleNumbers">
        <ion-col class="ion-no-padding" style="display: flex">
          <div class="hole" *ngFor="let index of subMatch.getAdjustMatchHoleIndexes(event)">
            <div>{{index + 1}}</div>
          </div>
        </ion-col>
      </ion-row>

      <!-- display teams if available -->
      <ng-container *ngIf="subMatch.teams?.length > 0; else noteams">

        <!-- header row -->
        <ion-row class="ion-align-items-center" style="font-size: 14px">

          <ion-col class="ion-no-padding" size="1.2">
            <ion-text class="ion-float-left"> Pos </ion-text>
          </ion-col>

          <ion-col class="ion-no-padding" size="4.8">
            <ion-text class="ion-float-left"> Team </ion-text>
          </ion-col>

          <ion-col class="ion-no-padding" size="4">
            <ion-text class="ion-float-right"> Holes won </ion-text>
          </ion-col>

          <ion-col class="ion-no-padding" size="2">
            <ion-text class="ion-float-right"> Thru </ion-text>
          </ion-col>

        </ion-row>

        <!-- build the list of teams -->
        <ng-container *ngFor="let team of subMatch.teams;">

          <ion-row class="ion-no-padding ion-align-items-center">

            <ion-col class="ion-no-padding" size="1.2">-</ion-col>

            <ion-col class="ion-no-padding ion-text-nowrap fadeText" style="display: flex" size="7.75">
              <div class="teamMarker" [ngStyle]="{'background-color': team.teamColor.backgroundcolor }"></div>
              <ion-text>{{team.name}}</ion-text>
            </ion-col>

            <ion-col class="ion-no-padding" size="1">
              <ion-text class="ion-float-right">
                <ion-text>0</ion-text>
              </ion-text>
            </ion-col>

            <ion-col class="ion-no-padding" size="2">
              <ion-text class="ion-float-right">-</ion-text>
            </ion-col>

          </ion-row>

        </ng-container>

      </ng-container>

    </ng-container>

  </ion-grid>

  <!-- show the press -->
  <ng-container *ngIf="subMatch.press">
    <ng-container
      *ngTemplateOutlet="spins; context: { subMatch: subMatch.press, showInScorecard: showInScorecard }"></ng-container>
  </ng-container>

</ng-template>

<!-- create team -->
<ng-template #showCreateTeam let-subMatch="subMatch">
  <ion-row class="ion-no-padding buttonBar">
    <ion-col size="12" style="margin-top: 10px; text-align: center;">
      <ion-button shape="round" color="primary" size="default" (click)="createTeam(subMatch)">
        Create Teams
      </ion-button>
    </ion-col>
  </ion-row>
</ng-template>