import { Directive, ElementRef, HostListener } from '@angular/core';
import tippy, { Instance, Props, sticky } from 'tippy.js';
import { Storage } from "@ionic/storage";

export interface DisplayOptions {
  name: string; //used for saving display info in local storage 
  show: boolean; //whether or not to show the popover
  numberOfDisplays: number; //total number of displays controlled by frequencyOfDisplay, undefined is infinite
  frequencyOfDisplay: number; //how often to display, 1 or undefined is every time, 2 is every other time, etc. 
  closeAfterAnimation: boolean; //close after animation ends
}

const attempt: string = 'Attempt';

@Directive({
  selector: '[popoverHelp]',
  exportAs: 'popoverHelp'
})
export class PopoverDirective {

  private defaultConfigOptions: Partial<Props> = {
    placement: 'left',
    trigger: 'manual',
    plugins: [sticky],
    theme: 'tippyCustom',
    hideOnClick: true,
    animation: 'bounce-down',
  };

  private defaultDisplayOptions: Partial<DisplayOptions> = {
    name: undefined,
    show: true,
    numberOfDisplays: -1,  //-1 is infinite
    frequencyOfDisplay: 1, //1 is every time
    closeAfterAnimation: false,
  };
  private instance: Instance;

  constructor(public elRef: ElementRef,
    public storage: Storage,) {
  }

  ngAfterViewInit() {
    //create the tippy instance
    this.instance = tippy(this.elRef.nativeElement as Element, {});
  }

  public async show(instanceConfigOptions: Partial<Props> = {}, instanceDisplayOptions: Partial<DisplayOptions> = {}) {

    //merge display option objects
    const popoverDisplayOptions: Partial<DisplayOptions> = { ...this.defaultDisplayOptions, ...instanceDisplayOptions };

    //display popover???
    if (popoverDisplayOptions.show) {

      //get the stored display count from local storage
      const storedDisplayCount = await this.getCount(popoverDisplayOptions.name);

      //get the stored attampted display count from local storage
      const storedDisplayAttemptCount = await this.getCount(popoverDisplayOptions.name + attempt);

      //calculate configured display count
      //const configuredDisplayCount = popoverDisplayOptions.numberOfDisplays || storedDisplayCount;
      const configuredDisplayCount = popoverDisplayOptions.numberOfDisplays;

      //calculate frequency display (if 1 then always display, else calculate based on display count)
      const frequencyOfDisplay: number = popoverDisplayOptions.frequencyOfDisplay || 1; //make sure frequency is at least 1
      const frequencyShouldDisplay: boolean = frequencyOfDisplay === 1 || (storedDisplayAttemptCount % frequencyOfDisplay) === 0;

      //display if...
      //1. the number of times displayed is less than or equal to the configured display count (-1 mean infinite) AND
      //2. the frequency of display is met
      if ((configuredDisplayCount === -1 || storedDisplayCount < configuredDisplayCount) && frequencyShouldDisplay) {

        //merge config objects
        const popoverConfig: Partial<Props> = { ...this.defaultConfigOptions, ...instanceConfigOptions };

        //set props
        this.instance.setProps(popoverConfig);

        //add event listener for animation end
        this.instance.popper.addEventListener('animationend', () => {
          if (popoverDisplayOptions.closeAfterAnimation) {
            this.instance.hide();
          }
        });

        //show popover
        this.instance.show();

        //increment display count (this is really the number of attempts to display)
        this.setCount(popoverDisplayOptions.name, storedDisplayCount + 1);

      }

      //increment display attempt count (this is really the number of attempts to display)
      this.setCount(popoverDisplayOptions.name + attempt, storedDisplayAttemptCount + 1);

    }

  }

  private getCount(name): Promise<number> {


    return this.storage
      .get(name)
      .then((count) => {
        return count || 0;
      })
      .catch((err) => {
        console.log('popover.directive.ts getStorage() error: ', err);
      });

  }

  private setCount(name, value) {
    this.storage.set(name, value);
  }

  @HostListener('click', ['$event']) onClick() {
    this.instance.hide();
  }

}
