import { Injectable } from '@angular/core';
import { MediaCapture, MediaFile, CaptureError, CaptureVideoOptions } from '@ionic-native/media-capture/ngx';
import { AppFunction } from './app.function';
import { Filesystem, ReadFileOptions, Directory } from '@capacitor/filesystem';
import { Camera, ImageOptions, CameraResultType, CameraSource, CameraDirection, CameraPermissionType } from '@capacitor/camera';

export interface imageManagementI {
    delete: (save: boolean) => Promise<void>;
    save: (fileURI: string) => Promise<void>;
}

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    constructor(
        private mediaCapture: MediaCapture,
        private appFunction: AppFunction) {

        console.log('app.media.ts constructor');

    }

    getPicture(source: CameraSource, direction: CameraDirection = CameraDirection.Rear): Promise<string> {

        return new Promise<string>((resolve, reject) => {

            //create options for the Camera Dialog
            const options = <ImageOptions>{
                quality: 80,
                resultType: CameraResultType.Base64,
                width: this.appFunction.platform.width(),
                height: this.appFunction.platform.width(),
                source: source,
                correctOrientation: true,
                allowEditing: true,
                direction: direction,
                webUseInput: true
            };

            Camera
                .getPhoto(options)
                .then((imagePath) => {

                    //console.log('app.media.ts getPicture success', imagePath.format);

                    //
                    this.copyFileToLocalDir(imagePath.base64String, imagePath.format)
                        .then((newFileName) => {
                            //console.log('app.media.ts getPicture copyFileToLocalDir', newFileName);
                            resolve(newFileName);
                        })
                        .catch((err) => {
                            reject(err);
                        });

                })
                .catch((err) => {
                    console.log('app.media.ts getPicture getPhoto error', err);
                    reject(err);
                });

        });

    }

    recordVideo(): Promise<string> {

        return new Promise<string>((resolve, reject) => {

            const options: CaptureVideoOptions = {
                duration: 30,
                limit: 1,

            };

            this.mediaCapture
                .captureVideo(options)
                .then(
                    (videoData: MediaFile[]) => {

                        console.log('app.media.ts recordVideo success', videoData[0]);

                        if (videoData.length > 0) {

                            resolve('file://' + videoData[0].fullPath);

                        } else {
                            console.log('app.media.ts recordVideo captureVideo return no videos');
                            resolve(undefined);
                        }

                    },
                    (err: CaptureError) => {
                        console.log('app.media.ts recordVideo error', err);
                        reject(err);
                    }
                );

        });

    }

    private copyFileToLocalDir(imageData: any, extension: string): Promise<string> {

        //console.log('app.media.ts copyFileToLocalDir');

        return new Promise<string>(async (resolve) => {

            //console.log('app.media.ts copyFileToLocalDir extension', extension);
            //console.log('app.media.ts copyFileToLocalDir imageData', imageData);

            //create new file name
            const date = new Date(),
                time = date.getTime(),
                fileName = time + '.' + extension;

            //write file
            await Filesystem.writeFile({
                data: imageData,
                path: fileName,
                directory: Directory.Data
            });

            //get new path
            const finalPhotoUri = await Filesystem.getUri({
                directory: Directory.Data,
                path: fileName
            });

            console.log('app.media.ts copyFileToLocalDir finalPhotoUri', finalPhotoUri);

            //return
            resolve(finalPhotoUri.uri);

        });

    }

    private getExtension(fileURI: string): string {

        const regexp = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        const extension = fileURI.match(regexp);

        //console.log('app.media.ts getExtension fileURI', fileURI);
        //console.log('app.media.ts getExtension extension', extension, (extension && extension[1]).toLowerCase());

        return (extension && extension[1]).toLowerCase();

    }

    isExtension(fileURI: string, lookForExtensions: string[]): boolean {

        const fileURIExtension: string = this.getExtension(fileURI);

        return lookForExtensions
            .some((extension) => {
                return extension.toLowerCase() === fileURIExtension.toLowerCase();
            });

    }

    selectAvatarAction(click, avatarElement: HTMLImageElement, avatarImageObject: imageManagementI): Promise<string> {

        //console.log('app.media.ts selectAvatarAction');

        return new Promise<string>((resolve) => {

            //open action sheet
            this.appFunction
                .actionCtrl
                .create({
                    header: 'What would you like to do?',
                    buttons: [
                        {
                            text: 'Choose Photo',
                            handler: () => {

                                this.checkPermissions('photos')
                                    .then((allow) => {

                                        //console.log('app.media.ts selectAvatarAction photos checkPermissions', allow);

                                        //if enabled then allow access to camera roll
                                        if (allow) {

                                            this.getPicture(CameraSource.Photos)
                                                .then((fileURI) => {
                                                    //console.log('app.media.ts selectAvatarAction getPicture photos', fileURI);
                                                    resolve(fileURI);
                                                })
                                                .catch((err) => {
                                                    console.log('app.media.ts selectAvatarAction getPicture photos error', JSON.stringify(err));
                                                });

                                        }

                                    })
                                    .catch((err) => {
                                        console.log('app.media.ts selectAvatarAction PHOTOLIBRARY getCameraStatus error', JSON.stringify(err));
                                    });

                            }
                        },
                        {
                            text: 'Take Photo',
                            handler: () => {

                                this.checkPermissions('camera')
                                    .then((allow) => {

                                        console.log('app.media.ts selectAvatarAction camera checkPermissions', allow);

                                        //if enabled then allow access to camera
                                        if (allow) {

                                            this.getPicture(CameraSource.Camera, CameraDirection.Front)
                                                .then((fileURI) => {
                                                    console.log('app.media.ts selectAvatarAction getPicture camera', fileURI);
                                                    resolve(fileURI);
                                                })
                                                .catch((err) => {
                                                    console.log('app.media.ts selectAvatarAction getPicture camera error', JSON.stringify(err));
                                                });

                                        }

                                    })
                                    .catch((err) => {
                                        console.log('app.media.ts selectAvatarAction camera checkPermissions error', JSON.stringify(err));
                                    });

                            }
                        },
                        {
                            text: 'View Photo',
                            handler: () => {
                                this.appFunction.showAvatarView(click, avatarElement);
                                resolve('');
                            }
                        },
                        {
                            text: 'Remove Photo',
                            handler: () => {

                                avatarImageObject
                                    .delete(true)
                                    .then(() => {
                                        resolve('');
                                    })
                                    .catch((err) => {
                                        console.log('app.media.ts selectAvatarAction deleteAvatar error', JSON.stringify(err));
                                    });

                            }
                        },
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                            }
                        }
                    ]
                })
                .then((action) => {
                    action.present();
                })

        });

    }

    selectCoverAction(coverImageObject: imageManagementI): Promise<string> {

        console.log('app.media.ts selectCoverAction');

        return new Promise<string>((resolve) => {

            //open action sheet
            this.appFunction
                .actionCtrl
                .create({
                    header: 'What would you like to do?',
                    buttons: [
                        {
                            text: 'Choose Photo',
                            handler: () => {

                                this.checkPermissions('photos')
                                    .then((allow) => {

                                        console.log('app.media.ts selectCoverAction photos checkPermissions', allow);

                                        //if enabled then allow access to camera roll
                                        if (allow) {

                                            this.getPicture(CameraSource.Photos)
                                                .then((fileURI) => {
                                                    console.log('app.media.ts selectCoverAction getPicture photos', fileURI);
                                                    resolve(fileURI);
                                                })
                                                .catch((err) => {
                                                    console.log('app.media.ts selectCoverAction getPicture photos error', JSON.stringify(err));
                                                });

                                        }

                                    })
                                    .catch((err) => {
                                        console.log('app.media.ts selectCoverAction PHOTOLIBRARY getCameraStatus error', JSON.stringify(err));
                                    });

                            }
                        },
                        {
                            text: 'Take Photo',
                            handler: () => {

                                this.checkPermissions('camera')
                                    .then((allow) => {

                                        console.log('app.media.ts selectAvatarAction camera checkPermissions', allow);

                                        //if enabled then allow access to camera
                                        if (allow) {

                                            this.getPicture(CameraSource.Camera, CameraDirection.Front)
                                                .then((fileURI) => {
                                                    console.log('app.media.ts selectAvatarAction getPicture camera', fileURI);
                                                    resolve(fileURI);
                                                })
                                                .catch((err) => {
                                                    console.log('app.media.ts selectAvatarAction getPicture camera error', JSON.stringify(err));
                                                });

                                        }

                                    })
                                    .catch((err) => {
                                        console.log('app.media.ts selectCoverAction camera checkPermissions error', JSON.stringify(err));
                                    });

                            }
                        },
                        {
                            text: 'Remove Photo',
                            handler: () => {

                                coverImageObject
                                    .delete(true)
                                    .then(() => {
                                        resolve('');
                                    })
                                    .catch((err) => {
                                        console.log('app.media.ts selectCoverAction deleteAvatar error', JSON.stringify(err));
                                    });

                            }
                        },
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                resolve(undefined)
                            }
                        }
                    ]
                })
                .then((action) => {
                    action.present();
                })

        });

    }

    saveMedia(fileName: string, newfileName: string, firebaseStorageFolder: string, fileDirectory?: Directory): Promise<string> {

        return new Promise<string>((resolve, reject) => {

            try {

                const currentExtension = fileName.substring(fileName.lastIndexOf('.'));

                console.log('app.media.ts saveMedia fileName', fileName);
                console.log('app.media.ts saveMedia fileDirectory', fileDirectory);
                console.log('app.media.ts saveMedia firebaseStorageFolder', firebaseStorageFolder);
                console.log('app.media.ts saveMedia newfileName', newfileName);
                console.log('app.media.ts saveMedia currentExtension', currentExtension);
                console.log('app.media.ts saveMedia fullPath', firebaseStorageFolder + newfileName + currentExtension);

                const opts: ReadFileOptions = { path: fileName };
                if (fileDirectory) {
                    opts.directory = fileDirectory;
                }

                Filesystem
                    .readFile(opts)
                    .then((result) => {

                        console.log('app.media.ts saveMedia readAsArrayBuffer success');

                        //metadata
                        const metadata = {
                            cacheControl: 'public, max-age=3000, s-maxage=6000'
                        };

                        this.appFunction
                            .firestorage
                            .ref(firebaseStorageFolder + newfileName + currentExtension)
                            .putString(<string>result.data, 'base64', metadata)
                            .then((snapshot) => {

                                //console.log('app.media.ts saveMedia saveFile success', snapshot);

                                snapshot.ref
                                    .getDownloadURL()
                                    .then((downloadUrl) => {
                                        console.log('app.media.ts saveMedia saveFile getDownloadURL success', downloadUrl);
                                        resolve(downloadUrl);
                                    });

                            })
                            .catch((err) => {
                                console.log('app.media.ts saveMedia save to firebase error', JSON.stringify(err));
                                reject(err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.media.ts saveMedia readFile error', JSON.stringify(err));
                        reject(err);
                    });

            } catch (err) {

                console.log('app.media.ts saveMedia readAsArrayBuffer error', JSON.stringify(err));
                reject(err);

            }

        });

    }

    deleteMedia(fileName: string): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            //only delete if there is an avatar
            if (fileName) {

                this.appFunction
                    .firestorage
                    .refFromURL(fileName)
                    .delete()
                    .then(() => {
                        //console.log('app.media.ts deleteMedia success');
                        resolve();
                    })
                    .catch((err) => {

                        if (err.code === 'storage/object-not-found') {
                            console.log('app.function.ts deleteMedia file not found', JSON.stringify(err));
                            resolve();
                        } else {
                            console.log('app.function.ts deleteMedia error', JSON.stringify(err));

                            // TODO: #5 review this with Dan
                            // we couldn't delete the file, potentially because the file is located under a different storage account
                            // let's resolve and allow the process to continu
                            resolve();
                        }

                    });

            } else {
                resolve();
            }

        });

    }

    private checkPermissions(type: CameraPermissionType): Promise<boolean> {

        console.log('app.media.ts checkPermissions', type);

        return new Promise<boolean>((resolve, reject) => {

            Camera
                .checkPermissions()
                .then((permission) => {

                    console.log('app.media.ts checkPermissions checkPermissions', permission, permission.camera, permission.photos);

                    //get the status for the appropriate device
                    const cameraPhotoStatus = (type === 'camera') ? permission.camera : permission.photos;
                    const cameraPhotoPrompt: string = (type === 'camera') ? 'camera' : 'photo gallery';

                    //camera have been enabled on the phone\or maybe it is for this app
                    if (cameraPhotoStatus === 'granted') {
                        resolve(true);
                    } else if (cameraPhotoStatus === 'prompt') {

                        //if "prompt" then ask user
                        Camera
                            .requestPermissions()
                            .then((permission) => {

                                console.log('app.media.ts checkPermissions requestPermissions', permission);

                                //which status
                                const cameraPhotoStatus = (type === 'camera') ? permission.camera : permission.photos;

                                //retuen status boolean
                                resolve(cameraPhotoStatus === 'granted');

                            })
                            .catch((err) => {

                                //console.log('app.media.ts checkPermissions requestPermissions error', err);

                                if (err.code === 'UNIMPLEMENTED') {

                                    this.appFunction
                                        .alertCtrl
                                        .create({
                                            header: 'Not available',
                                            subHeader: "The camera is not available on the web.",
                                            buttons: [
                                                {
                                                    text: 'OK',
                                                    handler: () => {
                                                    }
                                                }
                                            ]
                                        })
                                        .then((alert) => {
                                            alert.present();
                                        });
                                }

                                reject(err);

                            });

                    } else {

                        //permission is denied (could be prompt-with-rationale but don't know what that does) so ask...
                        this.appFunction
                            .alertCtrl
                            .create({
                                header: 'Please Confirm!',
                                message: 'Double Ace Golf needs your permission to access the ' + cameraPhotoPrompt + '. Would you like to grant access?',
                                buttons: [
                                    {
                                        text: 'Not now',
                                        handler: () => {
                                            resolve(false);
                                        }
                                    },
                                    {
                                        text: 'Yes',
                                        handler: () => {

                                            //popup dialog to enable camera
                                            this.appFunction
                                                .diagnostic
                                                .switchToSettings()
                                                .then(() => {
                                                    resolve(false);
                                                })
                                                .catch((err) => {
                                                    console.log('app.media.ts checkPermissions switchToSettings error', err);
                                                    reject(err);
                                                });

                                        }
                                    }
                                ]
                            })
                            .then((alert) => {
                                alert.present();
                            });

                    }

                })
                .catch((err) => {
                    console.log('app.media.ts checkPermissions getCameraRollAuthorizationStatus error', err);
                    reject();
                });

        });

    }

}

