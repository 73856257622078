import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'scroll-auto-play',
  templateUrl: './scroll-auto-play.component.html',
  styleUrls: ['./scroll-auto-play.component.scss'],
})
export class ScrollAutoPlayComponent implements OnInit {

  //get host controls
  @Input() src: string;
  @Input() content: IonContent;

  //get componenet html
  @ViewChild('videoPlayer', { static: true }) videoPlayer: any;
  @ViewChild('videoEndWrapper', { static: true }) videoEndWrapper: any;
  @ViewChild('videoPlayWrapper', { static: true }) videoPlayWrapper: any;
  @ViewChild('videoPauseWrapper', { static: true }) videoPauseWrapper: any;
  
  muteIcon: string = 'volume-mute';
  private _player: HTMLVideoElement;
  private _endWrapper: HTMLDivElement;
  private _playWrapper: HTMLDivElement;
  private _pauseWrapper: HTMLDivElement;
  private _scrollView: HTMLElement;
  private _played: boolean = false;

  constructor(public element: ElementRef) {
    //console.log('scroll-auto-play.ts constructor', element);
  }

  ngOnInit() {

    //get video element reference
    this._player = this.videoPlayer.nativeElement;

    //does this help on ht
    //this._player.load();

    //mute video
    this._player.muted = true;

    //get reference to end wrapper element
    this._endWrapper = this.videoEndWrapper.nativeElement;

    //get reference to pause wrapper element
    this._pauseWrapper = this.videoPauseWrapper.nativeElement;

    //start component with this layer on
    this._pauseWrapper.setAttribute('style', 'display: inline');

    //get reference to play wrapper element
    this._playWrapper = this.videoPlayWrapper.nativeElement;

    //capture when video is done playing
    this._player.onended = () => { this.ended(); }

    //capture when video is playing
    this._player.onplay = () => { this.playing(); }

    //capture when video is paused
    this._player.onpause = () => { this.paused(); }

    //capture when video is buffering
    this._player.onwaiting = () => { this.waiting(); }

    //get main scroll view
    this.content
      .getScrollElement()
      .then((content) => {

        this._scrollView = content;
        const contentClientHeight = content.clientHeight;

        this._scrollView.addEventListener('scroll', () => {

          const { top, bottom } = this.element.nativeElement.getBoundingClientRect();

          //calculate "this" position (video must bi in full view...how doe this work on small devices)
          const isInWindow: boolean = (top > 0 && bottom < contentClientHeight);
          if (isInWindow) {

            //only play if it isn't currently being played
            if (!this._played) {
              this.play();
            }

          } else {
            this.pause();

            //clear the "played" flag so the video plays once back in view
            this._played = false;
          }

        });

      });

  }

  play() {

    //if user clicks play while already playing then open in viewer
    this._endWrapper.setAttribute('style', 'display: none');
    this._pauseWrapper.setAttribute('style', 'display: none');
    this._playWrapper.setAttribute('style', 'display: flex');
    this._player.play();

  }

  mute() {

    console.log('mute', this._player.muted);
    this._player.muted = !this._player.muted;

    if (this._player.muted) {
      this.muteIcon = 'volume-mute';
    } else {
      this.muteIcon = 'volume-high';
    }

  }

  private pause() {
    this._player.pause();
  }

  private ended() {

    console.log('ended');
    this._endWrapper.setAttribute('style', 'display: flex');
    this._playWrapper.setAttribute('style', 'display: none');
    this._pauseWrapper.setAttribute('style', 'display: none');
    this._played = true;

  }

  private paused() {

    console.log('paused');
    this._endWrapper.setAttribute('style', 'display: none');
    this._playWrapper.setAttribute('style', 'display: none');
    this._pauseWrapper.setAttribute('style', 'display: inline');

  }

  private playing() {

    //console.log('playing');

  }

  private waiting() {

    console.log('buffering');

  }

}
