import { Component, OnInit } from '@angular/core';
import { ActionSheetButton, NavParams } from '@ionic/angular';
import { AccountService, AppMember } from '../../app.account';
import { AppFollowing, AppPost } from '../../app.social';
import { AppConfig } from '../../app.config';
import { AvatarBorder } from '../../app.function';
import { AppFunction } from '../../app.function';
import { MediaService } from '../../app.media';
import { MemberSearchPage } from '../member-search/member-search.page';
import { PostCommentPage } from '../post-comment/post-comment.page';

@Component({
  selector: 'profile-summary',
  templateUrl: './profile-summary.page.html',
  styleUrls: ['./profile-summary.page.scss'],
  animations: [
    AvatarBorder
  ]
})
export class ProfileSummaryPage implements OnInit {

  member: AppMember;
  memberFollower: AppFollowing;
  followButton: boolean = true;
  profileSummarySegment: string;
  socialStatus: typeof AppConfig.SOCIAL_STATUS = AppConfig.SOCIAL_STATUS;
  noMorePosts: boolean = false;
  private _posts: AppPost[] = [];

  constructor(
    public accountService: AccountService,
    public appFunction: AppFunction,
    public navParams: NavParams,
    public mediaService: MediaService) {

    //console.log('profile-summary.ts constructor');

  }

  ngOnInit() {

    //console.log('profile-summary.ts ngOnInit');

    //get the member that was selected
    this.member = this.navParams.get('member');

    //set the active tab
    this.profileSummarySegment = this.navParams.get('tab') || 'posts';

    //get members that follow "this.member" 
    this.member
      .social
      .getFollowers()
      .then(() => {

        //set the "following status"
        this.setFollowingStatus();

      });

    //get list of members that "this.member" is following
    this.member
      .social
      .getFollowing()
      .then(() => {

        //see if logged in member (accountService.member) is following this.member 
        this.memberFollower = this.member.social.isFollower(this.accountService.member);

      });

    //get "this.member" posts
    this.member
      .social
      .getPosts()
      .then((posts) => {
        this._posts = posts;
      });

    //increment counters
    AppFunction.openProfiles++;

  }

  ionViewDidLeave() {
    //decrement counters
    AppFunction.openProfiles--;
  }

  get following(): AppFollowing[] {
    return this.member.social.following;
  }

  get blockedFollowers(): AppFollowing[] {

    return this.member
      .social
      .followers
      .filter((follower) => {
        return follower.status === AppConfig.SOCIAL_STATUS.Blocked;
      });

  }

  get requestedFollowers(): AppFollowing[] {

    return this.member
      .social
      .followers
      .filter((follower) => {
        return follower.status === AppConfig.SOCIAL_STATUS.Requested;
      });

  }

  get followers(): AppFollowing[] {

    return this.member
      .social
      .followers
      .filter((follower) => {
        return follower.status === AppConfig.SOCIAL_STATUS.Following;
      });

  }

  private setFollowingStatus() {

    try {

      //if logged in member isn't "this.member" then...
      if (this.member.id !== this.accountService.member.id) {

        //is the logged in member a follower of selected/current member...
        this.memberFollower = this.member.social.isFollower(this.accountService.member);

        //...if yes, then...
        if (this.memberFollower) {

          //set follow button state
          switch (this.memberFollower.status) {

            case AppConfig.SOCIAL_STATUS.Following:
            case AppConfig.SOCIAL_STATUS.Blocked:
            case AppConfig.SOCIAL_STATUS.Requested: {

              //show the unfollow button
              this.followButton = false;

              break;
            }

            default: {

              //show the follow button
              this.followButton = true;

              break;
            }

          }

        } else {

          //show the follow button
          this.followButton = true;

        }

      }

    } catch (err) {
      console.log('profile-summmary.page.ts setFollowingStatus error', err);
    }

  }

  requestToFollowMember() {

    //console.log('profile-summary.ts requestToFollowMember');

    //signal work
    this.appFunction
      .loadingCtrl
      .create({ message: 'Follow...' })
      .then((loading) => {

        loading.present();

        //console.log('profile-summary.page.ts requestToFollowMember', this.member, this.accountService.member);

        this.member
          .social
          .requestToFollow(this.accountService.member)
          .finally(() => {

            this.setFollowingStatus();
            loading.dismiss();

          });

      });

  }

  unfollowMemberAction(follower: AppFollowing) {

    let buttons: ActionSheetButton[] = [];

    buttons.push({
      text: 'Unfollow Member',
      handler: () => {
        this.unfollowMember(follower);
      }
    });

    buttons.push({
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
      }
    });

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: buttons
      })
      .then((action) => {
        action.present();
      });

  }

  private unfollowMember(follower: AppFollowing) {

    //console.log('profile-summary.page.ts unfollowMember');

    this.appFunction
      .loadingCtrl
      .create({ message: 'Unfollow...' })
      .then((loading) => {

        loading.present();

        //unfollow
        follower
          .delete()
          .finally(() => {

            this.setFollowingStatus();
            loading.dismiss();

          });

      });

  }

  private acceptRequest(follow: AppFollowing) {

    //console.log('profile-summary.ts acceptRequest');

    //signal work
    this.appFunction
      .loadingCtrl
      .create({ message: 'Accepting request...' })
      .then((loading) => {

        loading.present();

        //set and save
        follow.status = AppConfig.SOCIAL_STATUS.Following;
        follow
          .save()
          .finally(() => {
            loading.dismiss();
          });

      });

  }

  private blockMember(follow: AppFollowing) {

    //console.log('profile-summmary.page.ts blockMember');

    //signal work
    this.appFunction
      .loadingCtrl
      .create({ message: 'Blocking request...' })
      .then((loading) => {

        loading.present();

        //set and save
        follow.status = AppConfig.SOCIAL_STATUS.Blocked;
        follow
          .save()
          .finally(() => {
            loading.dismiss();
          });

      });

  }

  getNextPosts(event) {

    //get posts
    this.member
      .social
      .getNextPosts()
      .then((nextPosts) => {

        nextPosts
          .forEach((nextPost) => {
            this._posts.push(nextPost);
          });

        event.target.complete();

        //when no more posts to display then stop trying to fetch
        if (nextPosts.length === 0) {
          event.target.disabled = true;
          this.noMorePosts = true;
        }

      });

  }

  deletePostConfirm(post: AppPost) {

    //console.log('profile-summmary.page deletePostConfirm');

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Delete Post',
            role: 'destructive',
            handler: () => {
              post.delete();
            }
          },
          {
            text: 'No',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  get posts(): AppPost[] {

    return this._posts.filter((post) => {
      return post.exists;
    })

  }

  async memberSearch() {

    this.appFunction
      .modalCtrl
      .create({
        component: MemberSearchPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        componentProps: {
          returnSelectedMember: true
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('profile-summmary.page.ts modal present error', err);
          });

        modal
          .onDidDismiss()
          .then((results) => {

            if (results && results.data.member) {
              const memberToFollow: AppMember = <AppMember>results.data.member;
              memberToFollow.social.requestToFollow(this.accountService.member);
            }

          });

      })
      .catch((err) => {
        console.log('profile-summmary.page.ts modal create error', err);
      });

  }

  async comments(post: AppPost) {

    //open event details page
    this.appFunction
      .modalCtrl
      .create({
        component: PostCommentPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        componentProps: {
          post: post
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('profile-summary.page.ts postComments comments modal present error', err);
          });

      })
      .catch((err) => {
        console.log('profile-summary.page.ts postComments comments modal create error', err);
      });

  }

  requestedFollowerAction(follower: AppFollowing) {

    let buttons: ActionSheetButton[] = [];

    if (follower.status === this.socialStatus.Following || follower.status === this.socialStatus.Requested) {
      buttons.push({
        text: 'Block Member',
        handler: () => {
          this.blockMember(follower);
        }
      });
    }

    if (follower.status === this.socialStatus.Requested || follower.status === this.socialStatus.Blocked) {
      buttons.push({
        text: 'Accept Request',
        handler: () => {
          this.acceptRequest(follower);
        }
      });
    }

    buttons.push({
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
      }
    });

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: buttons
      })
      .then((action) => {
        action.present();
      });

  }

  blockedFollowerAction(follower: AppFollowing) {

    let buttons: ActionSheetButton[] = [];

    if (follower.status === this.socialStatus.Requested || follower.status === this.socialStatus.Blocked) {
      buttons.push({
        text: 'Accept Request',
        handler: () => {
          this.acceptRequest(follower);
        }
      });
    }

    buttons.push({
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
      }
    });

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: buttons
      })
      .then((action) => {
        action.present();
      });

  }

  followerAction(follower: AppFollowing) {

    let buttons: ActionSheetButton[] = [];

    if (follower.status === this.socialStatus.Following || follower.status === this.socialStatus.Requested) {
      buttons.push({
        text: 'Block Member',
        handler: () => {
          this.blockMember(follower);
        }
      });
    }

    buttons.push({
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
      }
    });

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: buttons
      })
      .then((action) => {
        action.present();
      });

  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}
