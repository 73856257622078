<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>
    </ion-buttons>
    <ion-title>Invite Players</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="match-player-search-content">

  <ion-searchbar #searchBar (ionChange)="searchCriteriaChange($event)" (ionClear)="searchCriteriaClear()"
    show-cancel-button="focus" placeholder="Search on name or email" inputmode="search"></ion-searchbar>

  <ion-list class="itemLines">

    <ion-item *ngFor="let player of filteredPlayers">

      <avatar [member]="player.member" slot="start"></avatar>

      <ion-label>
        <h2>{{player.firstName}} {{player.lastName}}</h2>
        <h4>{{player.email}}</h4>
      </ion-label>

      <ion-button class="listAddButton" fill="solid" shape="round" (click)="addPlayerToMatch(player);" slot="end"
        *ngIf="!isPlayerInMatch(player)">Add
      </ion-button>
      <ion-icon name="checkmark-circle" *ngIf="isPlayerInMatch(player)"
        style="font-size: 25px; color: var(--ion-color-yes);" slot="end"></ion-icon>

    </ion-item>

  </ion-list>

</ion-content>