import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.page.html',
  styleUrls: ['./maintenance.page.scss'],
})
export class MaintenancePage implements OnInit {

  message: string = undefined;

  constructor(public navParams: NavParams) { }

  ngOnInit() {
    this.message = this.navParams.get('message');
  }

}
