import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage {

  constructor(
    public modalCtrl: ModalController) {

  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
