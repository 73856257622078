<ion-header class="course-scorecard-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="secondary">
      <ion-button (click)="back()">
        <ion-icon name="chevron-back"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button *ngIf="courseForm.dirty" fill="solid" shape="round" (click)="done()">Save</ion-button>
      <ion-button *ngIf="!courseForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>

    <ion-title>{{course.name}} Scorecard</ion-title>

  </ion-toolbar>

</ion-header>

<ion-content class="course-scorecard-content">

  <ion-grid class="ion-no-padding mainGrid">

    <!-- hole numbers -->
    <ion-row class="ion-no-padding hole" style="line-height: 1.75; font-weight: 600; border: 0px;">

      <ion-col size="1.5" class="ion-no-padding border" style="padding-left: 5px;">
        Hole
      </ion-col>

      <ng-container *ngFor="let nine of course.tees[0].nines;">

        <ion-col class="ion-no-padding border ion-text-center" *ngFor="let hole of nine.holes;">
          {{hole.number}}
        </ion-col>

      </ng-container>

      <ion-col class="ion-no-padding border ion-text-center">Total</ion-col>

    </ion-row>

    <!-- tee -->
    <ion-row class="ion-no-padding" *ngFor="let tee of course.tees;">

      <ion-col class="ion-no-padding">

        <ion-grid class="ion-no-padding">

          <!-- distance -->
          <ion-row class="ion-no-padding">

            <ion-col size="1.5" class="ion-no-padding border" style="padding-top: 5px; padding-left: 5px;">
              {{tee.name}}
            </ion-col>

            <ng-container *ngFor="let nine of tee.nines;">

              <ion-col class="ion-no-padding border" *ngFor="let hole of nine.holes;">
                <ion-input type="number" [(ngModel)]="hole.distance" (ionChange)="updateDistance(tee)"></ion-input>
              </ion-col>

            </ng-container>

            <ion-col class="ion-no-padding border ion-align-items-center ion-justify-content-center"
              style="display: flex;">
              <ion-label>{{tee.distance}}</ion-label>
            </ion-col>

          </ion-row>

          <!-- hdcp -->
          <ion-row class="ion-no-padding hdcp">

            <ion-col size="1.5" class="ion-no-padding border ion-text-right"
              style="font-size: smaller; padding-right: 5px; padding-top: 5px;">
              hdcp <ion-icon name="copy-outline" (click)="copyHdcpPar(tee)"></ion-icon>
              <ion-icon *ngIf="copyTee" name="clipboard-outline" (click)="pasteHdcpPar(tee)"></ion-icon>
            </ion-col>

            <ng-container *ngFor="let nine of tee.nines;">

              <ion-col class="ion-no-padding border" *ngFor="let hole of nine.holes;">
                <ion-input type="number" [(ngModel)]="hole.hdcp" (ionChange)="update()"></ion-input>
              </ion-col>

            </ng-container>

            <ion-col class="ion-no-padding border" style="background-color: black;">&nbsp;</ion-col>

          </ion-row>

          <!-- par -->
          <ion-row class="ion-no-padding par">

            <ion-col size="1.5" class="ion-no-padding border ion-text-right ion-align-items-center"
              style="font-size: smaller; padding-right: 5px; padding-top: 5px;">
              par
            </ion-col>

            <ng-container *ngFor="let nine of tee.nines;">

              <ion-col class="ion-no-padding border" *ngFor="let hole of nine.holes;">
                <ion-input type="number" [(ngModel)]="hole.par" (ionChange)="updatePar(tee)"></ion-input>
              </ion-col>

            </ng-container>

            <ion-col class="ion-no-padding border ion-align-items-center ion-justify-content-center"
              style="display: flex;">
              <ion-label>{{tee.par}}</ion-label>
            </ion-col>

          </ion-row>

        </ion-grid>

      </ion-col>

    </ion-row>

  </ion-grid>

</ion-content>