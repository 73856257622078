import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AccountService } from '../../app.account';
import firebase from 'firebase/compat/app';
import { AppFunction, HelpService } from '../../app.function'

@Component({
  selector: 'profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {

  profileForm: FormGroup;
  isFormSubmitted: boolean = false;
  updatehandicapIndexSubscription: Subscription;
  passwordType: string = "password";
  showHide: string = "eye-off-outline";

  constructor(
    public builder: FormBuilder,
    public accountService: AccountService,
    public appFunction: AppFunction,
    public helpService: HelpService) {

  }

  ngOnInit() {

    this.profileForm = this.builder.group({
      firstName: [this.accountService.member.firstName, Validators.required],
      lastName: [this.accountService.member.lastName, Validators.required],
      email: [this.accountService.member.email, Validators.required],
      password: [''],
      /* ghinNumber: [this.accountService.member.ghinNumber, [
        Validators.minLength(7)]
      ], */
      handicapIndex: this.accountService.member.handicapIndex,
      privateAccount: this.accountService.member.privateAccount
    });

    /* this.accountService
      .member
      .getHandicapIndex()
      .then(() => {
        this.profileForm.controls['handicapIndex'].setValue(this.accountService.member.handicapIndex);
      }); */

    //subscribe to handicap index updates
    this.updatehandicapIndexSubscription = this.accountService
      .member
      .updatehandicapIndex
      .subscribe((updatedHandicapIndex: number) => {
        this.profileForm.controls.handicapIndex.setValue(updatedHandicapIndex);
      });

  }

  get isEmailNew(): boolean {

    //determine if email has changed
    const isNew: boolean = this.accountService.member.email !== this.profileForm.controls.email.value;

    //if email is new then add required validator, else remove required validator
    if (isNew) {
      this.appFunction.setValidators(<FormGroup>(this.profileForm), 'password', Validators.required);
    } else {
      this.appFunction.setValidators(<FormGroup>(this.profileForm), 'password');
    }

    return isNew;

  }

  /* updateGhin() {
    this.accountService.member.ghinNumber = this.profileForm.controls.ghinNumber.value;
  } */

  private closeScreen(): Promise<void> {

    return new Promise<void>((resolve) => {
      this.updatehandicapIndexSubscription.unsubscribe();
      this.appFunction
        .modalCtrl
        .dismiss()
        .then(() => {
          resolve();
        });
    });

  }

  deleteProfileConfirmation() {

    this.appFunction
      .alertCtrl
      .create({
        header: 'Delete Profile?',
        message: "By selecting 'Yes, delete' your Double Ace Golf profile will be permanently deleted and you will be logged off. Do you want to continue?",
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              //noop
            }
          }, {
            text: 'Yes, delete',
            handler: () => {
              this.appFunction
                .memberSelfDelete(this.accountService.member)
                .then(() => {

                  //make sure page closes before logging off...otherwise several errors are generated
                  this.closeScreen()
                    .then(() => {
                      this.accountService.logout();
                    });

                });
            }
          }
        ]
      })
      .then((alert) => {
        alert.present();
      });

  }

  showPassword() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
      this.showHide = 'eye-outline';
    } else {
      this.passwordType = 'password';
      this.showHide = 'eye-off-outline';
    }
  }

  cancel() {

    //confirm that user wants to discard changes
    if (this.profileForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your profile. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.closeScreen();
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.closeScreen();
    }

  }

  done() {

    //save the form if dirty 
    if (this.profileForm.dirty) {

      //signal validation toast that form has been submitted
      this.isFormSubmitted = true;

      //save the form if valid 
      if (this.profileForm.valid) {

        this.appFunction
          .loadingCtrl
          .create({ message: 'Saving profile...' })
          .then((loading) => {

            loading.present();

            const promiseArray: any[] = [];

            //if email has changed then...
            if (this.isEmailNew) {

              const p = new Promise<boolean>((resolve) => {

                //first check to be sure the new email is not already in use in the Members collection
                this.accountService.getMemberByEmail(this.profileForm.controls.email.value)
                  .then((member) => {

                    //if email is in use then show error message
                    if (member) {

                      loading.dismiss();

                      this.appFunction
                        .alertCtrl
                        .create({
                          header: 'Email In Use!',
                          message: "The new email, " + this.profileForm.controls.email.value + ", is already in use by another account. If this is your email address logout and then re-login or register with the new email address.",
                          buttons: [
                            {
                              text: 'OK',
                              handler: () => {
                                resolve(false);
                              }
                            }
                          ]
                        })
                        .then((alert) => {
                          alert.present();
                        });

                    } else { //email is not in use so update member email and authentication account

                      //get crendentials with old email address
                      const credential = firebase.auth.EmailAuthProvider.credential(
                        this.accountService.member.email,
                        this.profileForm.controls.password.value
                      );

                      //re-authenticate
                      this.accountService
                        .user
                        .reauthenticateWithCredential(credential)
                        .then(() => {

                          //save new email on authentication account
                          this.accountService
                            .user
                            .updateEmail(this.profileForm.controls.email.value)
                            .then(() => {

                              //save email on member collection
                              this.accountService.member.email = this.profileForm.controls.email.value;
                              this.accountService
                                .member
                                .save()
                                .then(() => {
                                  resolve(true);
                                })
                                .catch((err) => {
                                  console.log('profile.page.ts done member email save error', JSON.stringify(err));
                                });

                            })
                            .catch((err) => {

                              loading.dismiss();
                              if (err.code === 'auth/email-already-in-use') {

                                this.appFunction
                                  .alertCtrl
                                  .create({
                                    header: 'Email In Use!',
                                    message: "The new email, " + this.profileForm.controls.email.value + ", is already in use by another account. If this is your email address logout and then re-login or register with the new email address.",
                                    buttons: [
                                      {
                                        text: 'OK',
                                        handler: () => {
                                          resolve(false);
                                        }
                                      }
                                    ]
                                  })
                                  .then((alert) => {
                                    alert.present();
                                  });

                              }

                            });

                        })
                        .catch((err) => {

                          loading.dismiss();
                          if (err.code === 'auth/wrong-password') {

                            //now ask if they want to press
                            this.appFunction
                              .alertCtrl
                              .create({
                                header: 'Incorrect Password!',
                                message: "The password for the current email " + this.accountService.member.email + " is incorrect.",
                                buttons: [
                                  {
                                    text: 'OK',
                                    handler: () => {
                                      resolve(false);
                                    }
                                  }
                                ]
                              })
                              .then((alert) => {
                                alert.present();
                              });

                          } else {

                            //catch all error handling
                            this.appFunction
                              .alertCtrl
                              .create({
                                header: 'Error!',
                                message: err.message,
                                buttons: [
                                  {
                                    text: 'OK',
                                    handler: () => {
                                      resolve(false);
                                    }
                                  }
                                ]
                              })
                              .then((alert) => {
                                alert.present();
                              });

                          }

                        });
                    }

                  });

              });

              promiseArray.push(p);

            }

            Promise
              .all(promiseArray)
              .then((promise) => {

                if (!promise.includes(false)) {

                  //save first/last names
                  this.accountService.member.firstName = this.profileForm.controls.firstName.value;
                  this.accountService.member.lastName = this.profileForm.controls.lastName.value;
                  this.accountService.member.privateAccount = this.profileForm.controls.privateAccount.value;
                  this.accountService.member.handicapIndex = this.profileForm.controls.handicapIndex.value;
                  this.accountService
                    .member
                    .save()
                    .then(() => {

                      loading.dismiss();
                      this.closeScreen();

                    })
                    .catch((err) => {
                      console.log('profile.page.ts done first/last name save error', JSON.stringify(err));
                    });

                }

              });

          });

      } else {
        //show any untouched errors
        this.appFunction.setDirtyControlAsTouched(this.profileForm);
      }

    } else {
      this.closeScreen();
    }

  }

}
