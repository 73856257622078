<ion-header class="club-search-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="secondary">
      <ion-button (click)="back()">
        <ion-icon name="chevron-back"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>

    <ion-title>Find a golf club</ion-title>

  </ion-toolbar>

</ion-header>

<ion-content class="club-search-content">

  <ion-searchbar (ionChange)="searchCriteriaChange($event)" (ionClear)="searchCriteriaClear()"
    show-cancel-button="focus" inputmode="search" debounce="500"></ion-searchbar>

  <div *ngIf="clubs?.length > 0">

    <ion-list>

      <ion-item *ngFor="let club of clubs" (click)="selectClub(club)">

        <ion-label>
          <h2>{{ club.name }}</h2>
          <p>{{ club.fullAddress }}</p>
        </ion-label>

      </ion-item>

    </ion-list>

  </div>

  <div *ngIf="clubs?.length === 0">

    <ion-note class="instructional">
      {{message}}
    </ion-note>

  </div>

</ion-content>