<ion-header class="contact-header ion-no-border">

  <ion-toolbar>
    <ion-title>Contact</ion-title>

    <ion-buttons slot="secondary" *ngIf="contactForm.dirty">
      <ion-button (click)="cancel()">
        Cancel
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button *ngIf="contactForm.dirty" fill="solid" shape="round" (click)="done()">Save</ion-button>
      <ion-button *ngIf="!contactForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- validation hero -->
  <validation-hero [display]="isFormSubmitted && contactForm.invalid"></validation-hero>

</ion-header>

<ion-content class="contact-content">

  <form class="contact-form" [formGroup]="contactForm">

    <ion-list class="itemLines">

      <ion-item lines="inset">
        <ion-label position="stacked">First Name</ion-label>
        <ion-input type="text" formControlName="firstName" autocapitalize="on"></ion-input>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="contactForm.controls.firstName.hasError('required') && !contactForm.controls.firstName.valid && contactForm.controls.firstName.touched">
        <ion-label>Please enter a first name.</ion-label>
      </ion-item>

      <ion-item lines="inset">
        <ion-label position="stacked">Last Name</ion-label>
        <ion-input type="text" formControlName="lastName" autocapitalize="on"></ion-input>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="contactForm.controls.lastName.hasError('required') && !contactForm.controls.lastName.valid && contactForm.controls.lastName.touched">
        <ion-label>Please enter a last name.</ion-label>
      </ion-item>

      <ng-container *ngIf="showEmail">
        <ion-item lines="inset">
          <ion-label position="stacked">Email</ion-label>
          <ion-input type="email" formControlName="email" autocapitalize="none" autocomplete="email"
            inputmode="email">
          </ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="contactForm.controls.email.hasError('required') && !contactForm.controls.email.valid && contactForm.controls.email.touched">
          <ion-label>Please enter an email address.</ion-label>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="contactForm.controls.email.hasError('pattern') && !contactForm.controls.email.valid && contactForm.controls.email.touched">
          <ion-label>Please enter a valid email address.</ion-label>
        </ion-item>
      </ng-container>

    </ion-list>

  </form>

</ion-content>