import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppFunction } from '../../app.function';
import { ScoringMode } from '../../app.event';
import { AppMatch, MatchPlayerI, TotalScoreLeaderboardEventTeamI } from '../../app.match';
import { GameType, HandicapAllowance, HandicapType } from 'src/app/app.config';

@Component({
  selector: 'group-trip-scorecard',
  templateUrl: './group-trip-scorecard.page.html',
  styleUrls: ['./group-trip-scorecard.page.scss']
})
export class GroupTripScorecardPage implements OnInit {

  match: AppMatch;
  HandicapType: typeof HandicapType = HandicapType;
  HandicapAllowance: typeof HandicapAllowance = HandicapAllowance;
  GameType: typeof GameType = GameType;
  selectedScorecard = 'matchScorecard';
  private _currentRoundIndex: number = -1;
  private _currentRoundId: string;
  players: MatchPlayerI[] = [];

  constructor(
    public appFunction: AppFunction,
    public navParams: NavParams
  ) {

  }

  ngOnInit() {

    //get passed in data
    this.match = <AppMatch>this.navParams.get('match');

    //get players
    this.players = this.match.parent.players.active.all;

  }

  currentEventIndex(): number {

    this._currentRoundIndex = -1;

    //interate through the trip events and determine which event is/was most recent
    this.match.parent.events.all.forEach((event) => {
      //if the event is active or complete then it is the current round
      if ([ScoringMode.ScoringActive, ScoringMode.ScoringComplete].includes(event.players.active.scoringMode)) {
        this._currentRoundIndex++;
        this._currentRoundId = event.id;
      }
    });

    //if current index is -1 then set to 0, we do this when the trip hasn't started yet
    if (this._currentRoundIndex === -1) {
      this._currentRoundIndex = 0;
      this._currentRoundId = this.match.parent.events.all[0]?.id;
    }

    return this._currentRoundIndex;

  }

  currentEventTeam(teamId: string): TotalScoreLeaderboardEventTeamI {
    return this.match.subMatches[0].totalScoreLeaderboard.events[this._currentRoundId]?.teams[teamId];
  }

  aggregateEventIndex(): number {
    return this.match.parent.events.all.length
  }

  aggregateEventId(): string {
    //which is the submatch id
    return this.match.subMatches[0].id;
  }

  done() {
    //close screen
    this.appFunction.modalCtrl.dismiss();
  }

}
