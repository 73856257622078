import { Directive, HostListener, Input, HostBinding, ElementRef } from '@angular/core';
import { AppFunction } from '../../app.function';
import { DragulaService } from 'ng2-dragula';

@Directive({
  selector: '[delayDragLift]'
})
export class DelayDragLiftDirective {

  private touchTimeout: any;
  private el: HTMLElement;

  @Input('delayDrag')
  dragDelay: number = 150; // milliseconds

  @HostBinding('class.delay-drag-lifted')
  private draggable: boolean = false;

  @HostListener('touchstart', ['$event'])
  private onTouchStart(e: Event): void {
    this.touchTimeout = setTimeout(() => {

      //console.log('touch start', e);

      //allow the touch move event
      this.draggable = true;

      //signal user
      this.appFunction.buzz();

    }, this.dragDelay);
  }

  @HostListener('touchmove', ['$event'])
  private onTouchMove(e: Event): void {

    if (!this.draggable) {
      e.stopPropagation();
      clearTimeout(this.touchTimeout);
      //console.log('touch move cancel');
    } else {
      //console.log('touch move');
    }

  }

  @HostListener('touchend', ['$event'])
  private onTouchEnd(e: Event): void {

    clearTimeout(this.touchTimeout);
    this.draggable = false;
    //console.log('touch end');

  }

  constructor(el: ElementRef, public appFunction: AppFunction, public dragulaService: DragulaService) {
    this.el = el.nativeElement;
    //console.log('delay-drag-lift constructor', this.el);
  }

}