<ion-header class="email-header ion-no-border">

  <ion-toolbar>

    <ion-title>
      Email
    </ion-title>

    <ion-buttons slot="secondary">
      <ion-button (click)="cancel()">
        Cancel
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" (click)="done()" icon-right>
        Send
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content class="email-content">

  <form class="email-form" [formGroup]="emailForm">

    <!-- email details -->
    <ion-list class="itemLines">

      <ion-list-header>
        <ion-label>Email</ion-label>
      </ion-list-header>

      <!-- Subject -->
      <ion-item [class.invalid]="!emailForm.controls.subject.valid && emailForm.controls.subject.touched" lines="inset">
        <ion-label position="stacked">Subject</ion-label>
        <ion-input formControlName="subject"></ion-input>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="emailForm.controls.subject.hasError('required') && !emailForm.controls.subject.valid && emailForm.controls.subject.touched">
        <ion-label autocapitalize="on">Please enter an email subject.</ion-label>
      </ion-item>

      <!-- Message -->
      <ion-item lines="inset">
        <ion-label position="stacked">Message</ion-label>
        <ion-textarea formControlName="message" autocomplete="on" autocorrect="on" autocapitalize="on" maxlength="1200" auto-grow="true">
        </ion-textarea>
      </ion-item>

    </ion-list>

  </form>

</ion-content>