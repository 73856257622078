import { Component, Input } from '@angular/core';
import { AppTee } from 'src/app/app.club';
import { AppFunction } from 'src/app/app.function';

@Component({
  selector: 'tee',
  templateUrl: './tee.component.html',
  styleUrls: ['./tee.component.scss']
})
export class TeeComponent {

  @Input() tee: AppTee;
  @Input() nineHolesOnlyIndex: number;
  @Input() cirleClass: string = 'circle-small';

  constructor(public appFunction: AppFunction) {
  }

  get teeDistance(): number {

    //if nines were passed then use that to determine distance
    if (this.tee.nines) {

      return this.tee.nines
        .filter((nine, index) => {

          //console.log(!this.appFunction.isNumeric(this.nineHolesOnlyIndex) || this.nineHolesOnlyIndex === index, this.nineHolesOnlyIndex, index);

          //not numberic means all 18 holes
          return !this.appFunction.isNumeric(this.nineHolesOnlyIndex) || this.nineHolesOnlyIndex === index;
        })
        .reduce((sum, nine) => {

          return sum + nine.holes.reduce((sum, hole) => {
            return sum + hole.distance;
          }, 0);

        }, 0);

    }

  }

  get teeSlope(): number {

    //if nines were passed then use that to determine distance
    if (this.tee.nines && this.nineHolesOnlyIndex) {
      return this.tee.nines[this.nineHolesOnlyIndex]?.slope;
    } else {
      return this.tee.slope;
    }

  }

  get teeRating(): number {

    //if nines were passed then use that to determine distance
    if (this.tee.nines && this.nineHolesOnlyIndex) {
      return this.tee.nines[this.nineHolesOnlyIndex]?.rating;
    } else {
      return this.tee.rating;
    }

  }

}
