<ion-header class="ion-no-border">
  <ion-toolbar>
    <div slot="start" style="margin-left: 10px; font-weight: 500;">Match Format</div>
    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Got it
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<modal-content class="match-view-content" class="ion-padding">
  <match-view [match]="match">
  </match-view>
</modal-content>