import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'terms-of-service',
  templateUrl: './terms-of-service.page.html',
  styleUrls: ['./terms-of-service.page.scss'],
})
export class TermsOfServicePage {

  constructor(
    public modalCtrl: ModalController) {

  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
