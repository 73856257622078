import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IonAccordionGroup, NavController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../app.account';
import { AppFunction } from '../../app.function';
import { AppConfig, GameType, NetGrossType, PressOptions } from '../../app.config';
import { AppMatch, AppMatchParentI, StablefordPointsI } from 'src/app/app.match';

@Component({
  selector: 'match-edit',
  templateUrl: './match-edit.component.html',
  styleUrls: ['./match-edit.component.scss']
})
export class MatchEditComponent implements OnInit {

  //get from the host html
  @Input() match: AppMatch;
  @Input() matchOptions: any;
  @Output() matchChange = new EventEmitter<AppMatch>();
  @Output() matchFormChange = new EventEmitter<FormGroup>();
  matchForm: FormGroup;
  handicapAllowanceOptions = AppConfig.HANDICAP_ALLOWANCE.values;
  handicapTypeOptions = AppConfig.HANDICAP_TYPE.values;
  netGrossTypeOptions = AppConfig.NETGROSS_TYPE.values;
  pressOptions = [
    {
      iconName: 'ban',
      value: PressOptions.Off,
      label: 'Off'
    },
    {
      iconName: 'help',
      value: PressOptions.Prompt,
      label: 'Prompt'
    },
    {
      iconName: 'checkmark',
      value: PressOptions.Auto,
      label: 'Auto'
    }
  ];
  NetGrossType: typeof NetGrossType = NetGrossType;
  PressOptions: typeof PressOptions = PressOptions;
  GameType: typeof GameType = GameType;
  isFormSubmitted: boolean = false;
  allowPress: boolean = false;
  accordianName: string;

  constructor(
    public builder: FormBuilder,
    public accountService: AccountService,
    public appFunction: AppFunction,
    public navParams: NavParams,
    public navCtrl: NavController) {

  }

  ngOnInit() {

    try {

      //build form group
      this.matchForm = this.builder.group({
        teamSize: [null, Validators.required],
        game: [null, Validators.required],
        grossNetType: [null, Validators.required],
        scoringType: [null, Validators.required],
        handicapType: [null, Validators.required],
        handicapAllowance: [null, Validators.required],
        pressOption: [null],
        pressStokesDown: [null],
        stablefordPoints: [null],
        notes: ''
      });

      //send back form reference to the calling page
      this.matchFormChange.emit(this.matchForm);

      //does match exist...then update
      if (this.match) {

        //set form
        this.matchForm.controls['teamSize'].setValue(this.match.teamSize !== undefined ? this.match.teamSize : undefined);
        this.matchForm.controls['game'].setValue(this.match.game !== undefined ? this.match.game : undefined);
        this.matchForm.controls['grossNetType'].setValue(this.match.grossNetType !== undefined ? this.match.grossNetType : undefined);
        this.matchForm.controls['scoringType'].setValue(this.match.scoringType !== undefined ? this.match.scoringType : undefined);
        this.matchForm.controls['handicapType'].setValue(this.match.handicapType !== undefined ? this.match.handicapType : undefined);
        this.matchForm.controls['handicapAllowance'].setValue(this.match.handicapAllowance !== undefined ? this.match.handicapAllowance : undefined);
        this.matchForm.controls['pressOption'].setValue(this.match.pressOption !== undefined ? this.match.pressOption : undefined);
        this.matchForm.controls['pressStokesDown'].setValue(this.match.pressStokesDown !== undefined ? this.match.pressStokesDown : undefined);
        this.matchForm.controls['notes'].setValue(this.match.notes !== undefined ? this.match.notes : '');
        this.matchForm.controls['stablefordPoints'].setValue(this.match.stablefordPoints);

        //be sure to turn the press section when appropriate
        this.allowPress = this.match.getGame(this.match.game)?.allowPress;

      } else { //else match is 'new'

        //get parent (event or grouptrip)
        const parent: AppMatchParentI = <AppMatchParentI>this.navParams.get('parent');

        //create match object...
        this.match = parent.matches.createMatch();

        //...then init match
        this.match
          .initialize(parent)
          .then(() => {
            //set some defaults
            this.matchForm.controls['handicapType'].setValue(this.match.handicapType);
            this.matchForm.controls['grossNetType'].setValue(this.match.grossNetType);
            this.matchForm.controls['handicapAllowance'].setValue(this.match.handicapAllowance);
            this.matchForm.controls['pressOption'].setValue(this.match.pressOption);
            this.matchForm.controls['pressStokesDown'].setValue(this.match.pressStokesDown);
            this.matchForm.controls['stablefordPoints'].setValue(this.match.stablefordPoints);
          });

      }

      //open game accordian if no game is selected
      if ([undefined, null].includes(this.matchForm.controls['game'].value)) {
        this.accordianName = 'game';
      }

    } catch (err) {
      console.log('match-edit.component.ts ngOnInit error', err);
    }

  }

  private signalChange() {

    this.matchFormChange.emit(this.matchForm);
    this.matchChange.emit(this.match);

  }

  async selectHandicapAllowance(value: number) {

    this.matchForm.controls['handicapAllowance'].setValue(value);
    this.matchForm.markAsDirty();
    this.signalChange();

  }

  /* score type - net or gross */
  async selectScoreType(value: number) {

    this.matchForm.controls['grossNetType'].setValue(value);
    this.matchForm.markAsDirty();
    this.signalChange();

  }

  /* handicap type - full or low ball */
  async selectHandicapType(value: number) {

    this.matchForm.controls['handicapType'].setValue(value);
    this.matchForm.markAsDirty();
    this.signalChange();

  }

  async selectGame(game: any) {

    this.matchForm.controls['game'].setValue(game.value);
    this.matchForm.markAsDirty();
    this.signalChange();

    //get allow press config value
    this.allowPress = game.allowPress;

    //clear press info if game doesn't allow presses??

    //clear team size and scoring type
    this.matchForm.controls['teamSize'].setValue(null);
    this.matchForm.controls['scoringType'].setValue(null);

    //if there is only one team size then auto select
    if (game.teamSize.length === 1) {
      this.matchForm.controls['teamSize'].setValue(game.teamSize[0].value);

      //if there is only one scoring type then auto select
      if (game.teamSize[0].scoringType.length === 1) {
        this.matchForm.controls['scoringType'].setValue(game.teamSize[0].scoringType[0].value);
      } else {
        //auto open accordian
        this.accordianName = 'scoringType';
      }
    } else {
      //auto open accordian
      this.accordianName = 'teamSize';
    }

  }

  async selectTeamSize(value: number) {

    this.matchForm.controls['teamSize'].setValue(value);
    this.matchForm.markAsDirty();
    this.signalChange();

    //if team size is one (value: 0) then set team scoring type
    if (this.matchForm.controls.teamSize.value === 0) {

      this.matchForm.controls['scoringType'].setValue(0);

      //auto open accordian
      if (this.allowPress) {
        this.accordianName = 'press';
      }

    } else {

      //clear game and scoring selections
      this.matchForm.controls['scoringType'].setValue(null);

      //auto open accordian
      this.accordianName = 'scoringType';

    }

  }

  /* team scoring type - x better balls */
  async selectTeamScoringType(value: number) {

    this.matchForm.controls['scoringType'].setValue(value);
    this.matchForm.markAsDirty();
    this.signalChange();

    //auto open accordian
    if (this.allowPress) {
      this.accordianName = 'press';
    }

  }

  async selectPressOption(value) {

    this.matchForm.controls['pressOption'].setValue(value);
    this.matchForm.markAsDirty();
    this.signalChange();

  }

  async selectPressStokesDown(incrementValue: number) {

    const pressStokesDown = this.matchForm.controls.pressStokesDown.value + incrementValue;
    this.matchForm.controls['pressStokesDown'].setValue(pressStokesDown);
    this.matchForm.markAsDirty();
    this.signalChange();

  }

  async selectStablefordPoints(scoreName: string, incrementValue: number) {

    const stablefordPoints: StablefordPointsI = this.matchForm.controls['stablefordPoints'].value;

    switch (scoreName) {

      case 'albatross': //3 under, albatross
        stablefordPoints.albatross = stablefordPoints.albatross + incrementValue;
        break;

      case 'eagle': //two under, eagle
        stablefordPoints.eagle = stablefordPoints.eagle + incrementValue;
        break;

      case 'birdie': //one under, birdie
        stablefordPoints.birdie = stablefordPoints.birdie + incrementValue;
        break;

      case 'par': //par
        stablefordPoints.par = stablefordPoints.par + incrementValue;
        break;

      case 'bogey': //one over, bogey
        stablefordPoints.bogey = stablefordPoints.bogey + incrementValue;
        break;

      default: //two over or more, double bogey
        stablefordPoints.doubleBogey = stablefordPoints.doubleBogey + incrementValue;
        break;

    }

    this.matchForm.controls['stablefordPoints'].setValue(stablefordPoints);
    this.matchForm.markAsDirty();
    this.signalChange();

  }

  get matchGames() {

    return this.matchOptions
      .game
      .filter((game) => {

        //if at least one rule exists then evaluate...
        if (game.displayRules?.length > 0) {

          return game
            .displayRules
            .some((rule) => {

              if (rule.numberOfHoles) {

                if (rule.numberOfHoles.expression === '=') {
                  return this.match.parent.numberOfHoles === rule.numberOfHoles.holes;
                }

              }

            });

        } else { //...otherwise allow to be displayed
          return true;
        }

      });

  }

  accordianClick(name: string, accordianGroup: IonAccordionGroup) {
    this.accordianName = <string>accordianGroup.value;
  }

}