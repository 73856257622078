import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from '../../app.config';
import { AppFunction } from '../../app.function';

@Component({
  selector: 'contact',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss'],
})
export class ContactPage implements OnInit {

  contactForm: FormGroup;
  contact: any;
  isFormSubmitted: boolean = false;
  showEmail: boolean = false;

  constructor(
    public builder: FormBuilder,
    public appFunction: AppFunction,
    public navParams: NavParams) {

    //console.log('contact.ts constructor');

  }

  ngOnInit() {

    //console.log('contact.ts ngOnInit');

    this.showEmail = this.navParams.get('showEmail')

    this.contactForm = this.builder.group({
      firstName: [this.navParams.get('firstName') || '', Validators.required],
      lastName: [this.navParams.get('lastName') || '', Validators.required],
      email: ['']
    });

    //add validator if email is required
    if (this.showEmail) {

      this.appFunction
        .setValidators(
          <FormGroup>(
            this.contactForm
          ),
          'email',
          [Validators.required,
          Validators.pattern(AppConfig.REGEX.email)]
        );

    }

  }

  done() {

    //save the form if dirty
    if (this.contactForm.dirty) {

      //signal validation toast that form has been submitted
      this.isFormSubmitted = true;

      //save the form if valid 
      if (this.contactForm.valid) {

        this.appFunction
          .modalCtrl
          .dismiss({
            contact: {
              firstName: this.contactForm.controls.firstName.value,
              lastName: this.contactForm.controls.lastName.value,
              email: this.contactForm.controls.email.value
            }
          });

      } else {
        //show any untouched errors
        this.appFunction.setDirtyControlAsTouched(this.contactForm);
      }

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

  cancel() {

    //confirm that user wants to discard changes
    if (this.contactForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have entered guest information. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.appFunction.modalCtrl.dismiss();
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

}
