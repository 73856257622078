import { Component, OnInit } from "@angular/core";
import { NavParams } from "@ionic/angular";
import { AppFunction } from "src/app/app.function";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "help-view",
  templateUrl: "./help-view.page.html",
  styleUrls: ["./help-view.page.scss"],
})
export class HelpViewPage implements OnInit {
  title: string = "";
  subject;
  private _appFunction: AppFunction;

  constructor(private navParams: NavParams, private dom: DomSanitizer) {
    this._appFunction = AppFunction.serviceLocator.get(AppFunction);
  }

  ngOnInit() {
    const rawSubject = this.navParams.get("subject");
    this.subject = this.dom.bypassSecurityTrustHtml(rawSubject);
    this.title = this.navParams.get("title");
  }

  done() {
    this._appFunction.modalCtrl.dismiss();
  }
}
