import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Platform } from '@ionic/angular';

@Pipe({
    name: 'sanitizeUrl'
})
export class sanitizeUrl implements PipeTransform {
    constructor(
        private domSanitizer: DomSanitizer,
        private webView: WebView,
        private platform: Platform) {

    }

    transform(value: string): any {

        try {

            if (this.platform.is('capacitor'))
                return this.domSanitizer.bypassSecurityTrustUrl(this.webView.convertFileSrc(value));
            else {
                return value;
            }

        } catch (err) {
            console.log('sanitize.pipe.ts sanitizeUrl transform error', err);

        }

    }
}