<ion-header class="group-event-view-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>
    </ion-buttons>

    <ion-title>Group</ion-title>

    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" [screenKey]="screenKey">
        <ion-icon name="help-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content #content class="group-event-view-content">

  <!-- segment -->
  <div class="ion-padding">

    <ion-segment [(ngModel)]="groupViewSegment" (ionChange)="groupSegmentChange($event)">

      <ion-segment-button [value]="GroupTripSegment.Information">
        <ion-label>Details</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="GroupTripSegment.Members">
        <ion-label>Members</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="GroupTripSegment.Posts">
        <ion-label>Posts</ion-label>
      </ion-segment-button>

    </ion-segment>

  </div>

  <div *ngIf="groupViewSegment === GroupTripSegment.Information">

    <!-- cover and avatar -->
    <div class="coverAvatar" style="margin-top: -10px">

      <!-- cover -->
      <div class="cover" style="padding: 16px;">
        <img [src]="group.cover.URI" style="border-radius: 16px;" draggable="false">
      </div>

      <!-- avatar -->
      <div class="avatar" style="margin-top: -30px;">
        <ion-avatar class="avatar-large avatarImageOverlay">
          <img #imgAvatar [src]="group.avatar.URI" (click)="appFunction.showAvatarView($event, imgAvatar)"
            draggable="false">
        </ion-avatar>
      </div>

    </div>

    <!-- 'Ask to Join' action card -->
    <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.AskToJoin">

      <ion-card-content>
        <ion-card-title>
          Join Now!
        </ion-card-title>

        <p class="message">Tap <b>Join Group</b> to start playing with the <b>{{group.name}}</b> group.</p>

      </ion-card-content>

      <ion-grid class="ion-no-padding ion-nowrap" class="buttonBar">
        <ion-row>
          <ion-col class="ion-text-center ion-no-padding" [size]="12">
            <ion-button expand="block" shape="round" class="primary-action" (click)="joinGroup(group)">Join Group
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-card>

    <!-- 'Joined' action card -->
    <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.Joined">

      <ion-card-content>
        <ion-card-title>
          Welcome!
        </ion-card-title>

        <p class="message">Welcome to the <b>{{group.name}}</b> group. Set your communication preferences below.</p>

      </ion-card-content>

    </ion-card>

    <!-- 'Leave' action card -->
    <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.Leave">

      <ion-card-content>
        <ion-card-title>
          Sorry to see you go
        </ion-card-title>

        <p class="message">You can alway rejoin this group in the future.</p>

      </ion-card-content>

    </ion-card>

    <!-- 'AddedToGroupByOrganizer' action card -->
    <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.AddedToGroupByOrganizer">

      <ion-card-content>
        <ion-card-title>
          Welcome!
        </ion-card-title>

        <p class="message">You have been added you to the <b>{{group.name}}</b>. Set your communication preferences
          below.</p>

      </ion-card-content>

    </ion-card>

    <!-- group details -->
    <ion-list class="itemLines">

      <ion-list-header>
        <ion-label>Group Details</ion-label>
      </ion-list-header>

      <ion-item>
        <ion-label>
          <ion-text class="labelPositionStacked">Name</ion-text>
          <h2 class="readOnly ion-text-wrap">{{group.name}}</h2>
        </ion-label>
      </ion-item>

      <ion-item *ngIf="group.description">
        <ion-label>
          <ion-text class="labelPositionStacked">Description</ion-text>
          <h2 class="readOnly ion-text-wrap">{{group.description}}</h2>
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <ion-text class="labelPositionStacked">Course</ion-text>
          <h2 class="readOnly ion-text-wrap">{{group.club.name}}</h2>
        </ion-label>
      </ion-item>

    </ion-list>

    <!-- event ereferences -->
    <ion-list *ngIf="isMember" class="itemLines">

      <ion-list-header>
        <ion-label>Event Preferences</ion-label>
      </ion-list-header>
      <ion-list-header class="help">
        <ion-text>Customize event notifications.</ion-text>
        <ion-text (click)="helpService.showFeatureHelp('eventCommunicationPreferences')">Learn more.</ion-text>
      </ion-list-header>

      <ion-item>
        <ion-label>
          <ion-text class="labelPositionStacked">Email</ion-text>
          <round-segment (change)="updateEmailPreferences($event)" [value]="this.memberGroupPreferences?.e"
            [buttons]="emailPreference"></round-segment>
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <ion-text class="labelPositionStacked">Notification</ion-text>
          <round-segment (change)="updateNotificationPreferences($event)" [value]="this.memberGroupPreferences?.n"
            [buttons]="emailPreference">
          </round-segment>
        </ion-label>
      </ion-item>

    </ion-list>

    <!-- group post preferences -->
    <ion-list *ngIf="isMember" class="itemLines">

      <ion-list-header>
        <ion-label>Post Preferences</ion-label>
      </ion-list-header>
      <ion-list-header class="help">
        <ion-text>Customize post notifications.</ion-text>
        <ion-text (click)="helpService.showFeatureHelp('postCommunicationPreferences')">Learn more.</ion-text>
      </ion-list-header>

      <ion-item>
        <ion-label>
          <ion-text class="labelPositionStacked">Notification</ion-text>
          <round-segment (change)="updatePostNotificationPreferences($event)" [value]="this.memberGroupPreferences?.p"
            [buttons]="postPreference"></round-segment>
        </ion-label>
      </ion-item>

    </ion-list>

  </div>

  <div *ngIf="groupViewSegment === GroupTripSegment.Members">

    <ion-searchbar (ionChange)="searchCriteriaChange($event)" (ionClear)="searchCriteriaClear()"
      show-cancel-button="focus" inputmode="search"></ion-searchbar>

    <ng-container *ngIf="filteredGroupEventMembers.length > 0, else noMembers">

      <ion-list class="itemLines">

        <ion-item *ngFor="let groupMember of filteredGroupEventMembers">

          <avatar #avatar [member]="groupMember.member" slot="start"></avatar>

          <ion-label (click)="avatar.displayProfileSummary($event)">

            <h2>{{groupMember.member.firstName}} {{groupMember.member.lastName}}</h2>
            <p [ngStyle]="{'color': group.isMemberAdmin(groupMember.member) ? 'red': 'initial' }">
              {{(groupMember.member.id === group.ownerMemberId) ? 'organizer' : group.isMemberAdmin(groupMember.member)
              ? 'administrator' :
              groupMember.member.email}}
            </p>

          </ion-label>

        </ion-item>

      </ion-list>

    </ng-container>

  </div>

  <div *ngIf="groupViewSegment === GroupTripSegment.Posts" class="greyBackgroundForSegment">

    <ng-container *ngIf="posts?.length > 0, else noPosts">

      <ion-list>
        <ng-container *ngFor="let post of posts;">
          <post-card [post]="post" [content]="content"></post-card>
        </ng-container>
      </ion-list>

      <ion-infinite-scroll (ionInfinite)="getPosts($event)" threshold="1px">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more posts...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>

      <ion-note *ngIf="noMorePosts" class="instructional"
        style="height: 85px; margin-bottom: 10px; background-color: var(--ion-color-white-b);">No more posts
      </ion-note>

    </ng-container>

  </div>

  <ion-fab #fab vertical="bottom" horizontal="end" slot="fixed" haptic *ngIf="isMember" [fabClose]="fab">
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-button shape="round" (click)="leaveGroupConfirm(group); fab.close();">
        <ion-icon name="exit" slot="start"></ion-icon>
        <ion-label>Leave Group</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="newPost(); fab.close();">
        <ion-icon name="chatbubbles" slot="start"></ion-icon>
        <ion-label>Post Message</ion-label>
      </ion-button>
    </ion-fab-list>
  </ion-fab>

</ion-content>

<ng-template #noPosts>
  <div style="text-align: center; font-size: 70px; margin-top: 50px;">
    <ion-icon name="chatbubbles"></ion-icon>
  </div>
  <ion-note class="message" style="margin-top: 12px; margin-bottom: 12px; line-height: 25px;">
    There are no posts.
  </ion-note>
</ng-template>

<ng-template #noMembers>
  <ion-note class="instructional" style="margin-top: 10px;">There are no members</ion-note>
</ng-template>