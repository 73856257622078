<ion-label style="margin: auto;">

  <ion-grid class="counter ion-no-padding">

    <ion-row class="ion-no-padding ion-text-nowrap ion-align-items-center">

      <!-- scroll left -->
      <ion-col class="ion-text-center ion-no-padding" size="auto">
        <ion-button shape="round" fill="clear" [disabled]="startingScore===1"
          (click)="$event.stopPropagation(); scrollScore(-1);">
          <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
        </ion-button>
      </ion-col>

      <!-- score -->
      <ng-container *ngFor="let scoreDisplay of scoresToDisplay; let i = index;">

        <ng-container [ngSwitch]="true">

          <!-- first position -->
          <ion-col class="ion-no-padding ion-align-items-center" size="auto"
            *ngSwitchCase="i===0 && player.nines[nineIndex].holes[holeIndex].grossScore && scoreDisplay > player.nines[nineIndex].holes[holeIndex].grossScore">
            <ion-button shape="round" class="score" [ngClass]="{ scoreSelected: true, stickyScoreLeft: true }">
              <ion-label>
                {{player.nines[nineIndex].holes[holeIndex].grossScore}}
                <h6
                  *ngIf="player.nines[nineIndex].holes[holeIndex].par === player.nines[nineIndex].holes[holeIndex].grossScore"
                  style="font-size: 10px;">par
                </h6>
              </ion-label>
            </ion-button>
          </ion-col>

          <!-- last position -->
          <ion-col class="ion-no-padding ion-align-items-center" size="auto"
            *ngSwitchCase="i===4 && player.nines[nineIndex].holes[holeIndex].grossScore && scoreDisplay < player.nines[nineIndex].holes[holeIndex].grossScore">
            <ion-button shape="round" class="score" [ngClass]="{ scoreSelected: true, stickyScoreRight: true }">
              <ion-label>
                {{player.nines[nineIndex].holes[holeIndex].grossScore}}
                <h6
                  *ngIf="player.nines[nineIndex].holes[holeIndex].par === player.nines[nineIndex].holes[holeIndex].grossScore"
                  style="font-size: 10px;">par
                </h6>
              </ion-label>
            </ion-button>
          </ion-col>

          <ion-col class="ion-no-padding ion-align-items-center" size="auto" *ngSwitchDefault>
            <ion-button shape="round" class="score"
              [ngClass]="{ scoreSelected: player.nines[nineIndex].holes[holeIndex].grossScore === scoreDisplay }"
              (click)="player.nines[nineIndex].holes[holeIndex].grossScore = scoreDisplay;">
              <!-- (click)="$event.stopPropagation(); player.nines[nineIndex].holes[holeIndex].grossScore = scoreDisplay;"> -->
              <ion-label>
                <ion-text>{{scoreDisplay}}</ion-text>
                <h6 *ngIf="player.nines[nineIndex].holes[holeIndex].par === scoreDisplay" style="font-size: 10px;">
                  par
                </h6>
              </ion-label>
            </ion-button>
          </ion-col>

        </ng-container>

      </ng-container>

      <!-- scroll right -->
      <ion-col class="ion-text-center ion-no-padding" size="auto">
        <ion-button shape="round" fill="clear" [disabled]="endingScore===15"
          (click)="$event.stopPropagation(); scrollScore(1);">
          <ion-icon slot="icon-only" name="chevron-forward"></ion-icon>
        </ion-button>
      </ion-col>

    </ion-row>

  </ion-grid>

</ion-label>