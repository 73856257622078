import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { AppFunction } from 'src/app/app.function';

interface roundSegmentButtonI {
  value: string | number;
  iconName: string;
  label: string;
}

@Component({
  selector: 'round-segment',
  templateUrl: './round-segment.component.html',
  styleUrls: ['./round-segment.component.scss']
})
export class RoundSegmentComponent implements AfterViewInit {

  @Input() value: number;
  @Input() buttons: roundSegmentButtonI[] = [];
  @Output() change = new EventEmitter<string | number>();
  private bg: HTMLElement;
  emitValue: boolean = true;
  name: string;

  constructor(public appFunction: AppFunction) {
    this.name = appFunction.newGuid();
  }

  ngAfterViewInit() {
    //at initialization, the click event is not fired, so we need to do it manually
    this.click();
  }

  click() {

    setTimeout(() => {

      //find the control for the initial (passed in) value
      const activeControl = document.getElementById(this.name + this.value);

      //don't return value when initializing
      this.emitValue = false;

      //now click it so that the highlight is placed over the appropriate icon (i wish there was a better way to do this)
      activeControl?.click();

    }, 250);

  }

  setActive(event, value: string | number) {

    //this is the element that highlights the selected icon
    this.bg = document.getElementById(this.name + 'bg');

    //set position and show
    const left: number = Math.max(event.target.offsetLeft - 5, 3); //this was a hack to fix an issue in match edit
    this.bg.style.left = left.toString() + 'px';

    //publish value change
    if (this.emitValue) {
      this.change.emit(value);
    }

    //all subsequent clicks should emit the value
    this.emitValue = true;

  }

}
