<ion-header class="chat-bot-header ion-no-border">

  <ion-toolbar>

    <ion-title (click)="restartConversation()">Chat Bot</ion-title>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content class="chat-bot-content">

  <chat-bot #chatBotConversation></chat-bot>

</ion-content>
