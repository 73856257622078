<ion-header class="match-format-detail-header ion-no-border">

  <ion-toolbar>

    <ion-title>Match</ion-title>

    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" [screenKey]="screenKey">
        <ion-icon name="help-circle-outline"></ion-icon>
      </ion-button>
      <ion-button (click)="cancel()" *ngIf="matchForm?.dirty">
        Cancel
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button *ngIf="matchForm?.dirty" fill="solid" shape="round" (click)="done()">Save</ion-button>
      <ion-button *ngIf="!matchForm?.dirty" fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>

  </ion-toolbar>

  <!-- validation hero -->
  <validation-hero [display]="isFormSubmitted && matchForm.invalid"></validation-hero>

</ion-header>

<ion-content class="match-format-detail-content">

  <match-edit [match]="match" [parent]="parent" [matchOptions]="matchOptions" (matchChange)="matchChange($event);"
    (matchFormChange)="matchFormChange($event);">
  </match-edit>

</ion-content>