import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService, AppMember } from '../../app.account';
import { AppFunction, HelpService, leaveToRightAnimation, enterFromRightAnimation } from '../../app.function';
import { GroupService, AppGroupEvent, memberGroupPreferences, AppGroupEventMember, MemberGroupRole, GroupEventActionCd } from '../../app.group';
import { AppConfig } from '../../app.config';
import { ContactSearchPage } from '../contact-search/contact-search.page';
import { ContactPage } from '../contact/contact.page';
import { EventDetailPage } from '../event-detail/event-detail.page';
import { ClubSearchPage } from '../club-search/club-search.page';
import { EmailPage } from '../email/email.page';
import { PostNewPage } from '../post-new/post-new.page';
import { ClubDetailPage } from '../club-detail/club-detail.page';
import { MediaService } from '../../app.media';
import { AppPost } from 'src/app/app.social';
import { Subscription } from 'rxjs';
import { AppCourse, ClubService } from 'src/app/app.club';
import { AppEvent, EventService } from 'src/app/app.event';
import { EventScoringPage } from '../event-scoring/event-scoring.page';
import { DisplayOptions, PopoverDirective } from 'src/app/directives/popover/popover.directive';
import { Props } from 'tippy.js';

export enum GroupEventSegment {
  Information = 'information',
  Members = 'members',
  Events = 'events',
  Posts = 'posts'
}

@Component({
  selector: AppConfig.PAGE.GroupEventDetail,
  templateUrl: './group-event-detail.page.html',
  styleUrls: ['./group-event-detail.page.scss']
})
export class GroupEventDetailPage implements OnInit {

  group: AppGroupEvent;
  groupForm: FormGroup;
  formEditMode: typeof AppConfig.EDIT_MODE = AppConfig.EDIT_MODE;
  editMode: any;
  groupDetailSegment: string = GroupEventSegment.Information;
  filteredMembersCriteria: string = '';
  memberGroupPreferences: memberGroupPreferences;
  emailPreference = AppConfig.GROUP_PREFERENCES.EVENT_EMAIL_PREFERENCE.values;
  notificationPreference = AppConfig.GROUP_PREFERENCES.EVENT_NOTIFICATION_PREFERENCE.values;
  postPreference = AppConfig.GROUP_PREFERENCES.POST_NOTIFICATION_PREFERENCE.values;
  eventJoinDropPreference = AppConfig.GROUP_PREFERENCES.EVENT_JOINDROP_NOTIFICATION_PREFERENCE.values;
  isFormSubmitted: boolean = false;
  noMorePosts: boolean = false;
  localAvatarURL: string;
  localCoverURL: string;
  private _events: AppEvent[];
  private _posts: AppPost[];
  private _futurePostSubscription: Subscription;
  screenKey: string = AppConfig.PAGE.GroupEventDetail;
  @ViewChild('nines', { read: ElementRef }) ninesItem: ElementRef;
  @ViewChild(PopoverDirective) popOver: PopoverDirective;
  GroupEventSegment: typeof GroupEventSegment = GroupEventSegment;
  actionCd: GroupEventActionCd;
  ActionCd: typeof GroupEventActionCd = GroupEventActionCd;

  //set up popover help config
  createPopupConfig: Partial<Props> = {
    placement: 'top-end'
  }

  //set up popover help config
  createPopupDisplayConfig: Partial<DisplayOptions> = {
    name: 'groupEventCreatePopup',
    frequencyOfDisplay: 1
  }

  constructor(
    public builder: FormBuilder,
    public accountService: AccountService,
    public navParams: NavParams,
    public groupService: GroupService,
    public appFunction: AppFunction,
    public eventService: EventService,
    public mediaService: MediaService,
    public helpService: HelpService,
    public navCtrl: NavController,
    public clubService: ClubService) {

    helpService.screenWhatsNew(AppConfig.PAGE.GroupEventDetail);

  }

  ngOnInit() {

    try {

      //build form group
      this.groupForm = this.builder.group({
        name: ['', Validators.required],
        description: '',
        club: [undefined, Validators.required],
        course: [undefined],
        tee: [undefined],
        public: true,
        numberOfHoles: [undefined],
        numberTeeTimes: AppConfig.NUMBER_TEE_TIMES,
        teeTimeInterval: AppConfig.TEE_TIME_INTERVAL,
        nineHolesOnlyIndex: [undefined],
        avatarFileURI: '', //only use this to indicate that avatar has been updated
        coverFileURI: '' //only use this to indicate that avatar has been updated
      });

      //see if actionCd was passed in
      this.actionCd = <GroupEventActionCd>this.navParams.get('actionCd');

      //get passed in edit mode
      this.editMode = this.navParams.get('editMode');
      if (this.editMode === AppConfig.EDIT_MODE.update) {

        //get group
        this.group = <AppGroupEvent>this.navParams.get('group');

        //set local image
        this.localAvatarURL = this.group.avatar.URI;
        this.localCoverURL = this.group.cover.URI;

        //populate form 
        this.groupForm.controls['name'].setValue(this.group.name, { emitEvent: false });
        this.groupForm.controls['description'].setValue(this.group.description, { emitEvent: false });
        this.groupForm.controls['numberTeeTimes'].setValue(this.group.numberTeeTimes, { emitEvent: false });
        this.groupForm.controls['teeTimeInterval'].setValue(this.group.teeTimeInterval, { emitEvent: false });
        this.groupForm.controls['public'].setValue(this.group.public, { emitEvent: false });
        this.groupForm.controls['club'].setValue(this.group.club, { emitEvent: false });
        this.groupForm.controls['course'].setValue(this.group.course, { emitEvent: false });
        this.groupForm.controls['tee'].setValue(this.group.tee, { emitEvent: false });
        this.groupForm.controls['numberOfHoles'].setValue(this.group.numberOfHoles, { emitEvent: false });
        this.groupForm.controls['nineHolesOnlyIndex'].setValue(this.group.nineHolesOnlyIndex, { emitEvent: false });

        //get member preferences (for either group owner or group admin)
        this.accountService
          .member
          .getPreference(this.group.id)
          .then((preferences) => {
            this.memberGroupPreferences = new memberGroupPreferences(this.group.id, preferences);
          });

      } else { //edit mode is 'new'

        //create group class...
        this.group = new AppGroupEvent();

        //...then setup
        this.group
          .initialize()
          .then(() => {

            //set local image
            this.localAvatarURL = this.group.avatar.URI;
            this.localCoverURL = this.group.cover.URI;

            //set organizer to logged in member
            this.group.ownerMemberId = this.accountService.member.id;

            //push organizer onto main members array
            this.group.addMemberToGroup(this.accountService.member, MemberGroupRole.Owner);

            //default member preferences
            this.memberGroupPreferences = new memberGroupPreferences(undefined, {});

            //set form validation
            this.groupForm.controls['numberOfHoles'].setValue(this.group.numberOfHoles, { emitEvent: false });

          });

      }

      //subscribe to nineHolesOnlyIndex changes
      this.groupForm.controls['nineHolesOnlyIndex'].valueChanges.subscribe(() => {
        this.groupForm.markAsDirty();
      });

    } catch (err) {
      console.log('group-event-detail.page.ts ngOnInit error', err);
    }

  }

  ionViewDidLeave() {
    if (this._futurePostSubscription) {
      this._futurePostSubscription.unsubscribe();
    }
  }

  addMemberConfirm() {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Add member from',
        buttons: [
          {
            text: 'Contacts and Group members',
            handler: () => {
              this.addMemberFromContacts();
            }
          }, {
            text: 'Email address',
            handler: () => {
              this.addMemberFromEmail();
            }
          }, {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  async addMemberFromContacts() {

    this.appFunction
      .modalCtrl
      .create({
        component: ContactSearchPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        componentProps: {
          member: this.accountService.member
        }
      })
      .then((modal) => {

        modal
          .onDidDismiss()
          .then((results) => {

            try {

              //if returned array has contacts then...
              if (Array.isArray(results.data.contacts) && results.data.contacts.length > 0) {

                //loop through returned array
                results.data
                  .contacts
                  .forEach((contact) => {

                    //see if contact email already exists in group member list
                    const emailFound: boolean = this.group.members.some((groupMember) => {
                      return (<AppGroupEventMember>groupMember).member.email.trim().toLowerCase() === contact.email.trim().toLowerCase();
                    });

                    //if email not found then it's new to this group 
                    if (!emailFound) {

                      //does member.email already exist?
                      this.accountService
                        .getMemberByEmail(contact.email)
                        .then((foundMember) => {

                          //if found then... 
                          if (foundMember) {

                            //...add member
                            this.group
                              .addMemberToGroup(foundMember, MemberGroupRole.Member)
                              .then(() => {
                                //force groupForm to dirty so new members are saved
                                this.groupForm.markAsDirty();
                              });

                          } else {

                            //otherwise create new member
                            const member: AppMember = new AppMember();
                            member
                              .initialize()
                              .then(() => {

                                member.email = contact.email;
                                member.firstName = contact.givenName;
                                member.lastName = contact.familyName;

                                //push new member on the array that is bound to the view
                                this.group
                                  .addMemberToGroup(member, MemberGroupRole.Member)
                                  .then(() => {
                                    //force groupForm to dirty so new members are saved
                                    this.groupForm.markAsDirty();
                                  });

                              });

                          }

                        });

                    }

                  });

              }
            } catch (err) {
              console.log('group-event-detail.page.ts addMemberFromContacts error processing new contact', err)
            }

          });

        modal
          .present()
          .then(() => {
            //make the members segment active
            this.groupDetailSegment = GroupEventSegment.Members;
          })
          .catch((err) => {
            console.log('group-event-detail.page.ts addMemberFromContacts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-event-detail.page.ts addMemberFromContacts modal create error', err);
      });

  }

  async addMemberFromEmail() {

    this.appFunction
      .modalCtrl
      .create({
        component: ContactPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md 
        componentProps: {
          showEmail: true
        }
      })
      .then((modal) => {

        modal
          .onDidDismiss()
          .then((results) => {

            //if the returned object contains an email field then...
            if (results.data && results.data.contact && results.data.contact.email) {

              //see if contact email already exists in group member list
              const emailFound: boolean = this.group.members.some((groupMember) => {
                return (<AppGroupEventMember>groupMember).member.email.trim().toLowerCase() === results.data.contact.email.trim().toLowerCase();
              });

              //if email not found then it's new and add to array 
              if (!emailFound) {

                //does member.email already exist?
                this.accountService
                  .getMemberByEmail(results.data.contact.email)
                  .then((foundMember) => {

                    //if found then... 
                    if (foundMember) {

                      //...add member
                      this.group
                        .addMemberToGroup(foundMember, MemberGroupRole.Member)
                        .then(() => {
                          //force groupForm to dirty so new members are saved
                          this.groupForm.markAsDirty();
                        });

                    } else {

                      //otherwise create new member
                      const member: AppMember = new AppMember();
                      member
                        .initialize()
                        .then(() => {

                          member.email = results.data.contact.email;
                          member.firstName = results.data.contact.firstName;
                          member.lastName = results.data.contact.lastName;

                          //push new member on the array that is bound to the view
                          this.group
                            .addMemberToGroup(member, MemberGroupRole.Member)
                            .then(() => {
                              //force groupForm to dirty so new members are saved
                              this.groupForm.markAsDirty();
                            });

                        });

                    }

                  });

              }

            }

          });

        modal
          .present()
          .then(() => {
            //make the members segment active
            this.groupDetailSegment = GroupEventSegment.Members;
          })
          .catch((err) => {
            console.log('group-event-detail.page.ts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-event-detail.page.ts modal create error', err);
      });

  }

  private removeMember(member: AppMember) {

    //remove member
    this.group
      .removeMemberFromGroup(member)
      .then(() => {

        //force groupForm to dirty
        this.groupForm.markAsDirty();

      });

  }

  makeAdmin(member: AppMember) {

    //add and mark dirty so it saves
    this.group.makeAdmin(member);
    this.groupForm.markAsDirty();

  }

  removeAdmin(member: AppMember) {

    //remove and mark dirty so it saves
    this.group.removeAdmin(member);
    this.groupForm.markAsDirty();

  }

  private removeMemberConfirm(member: AppMember) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Remove Member',
            role: 'destructive',
            handler: () => {
              this.removeMember(member);
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  get filteredMembers(): AppGroupEventMember[] {

    //if there is search criteria then filter
    if (this.filteredMembersCriteria.length > 0) {

      return <AppGroupEventMember[]>this.group.members.filter((groupMember) => {

        //cast to get member object
        const member: AppMember = (<AppGroupEventMember>groupMember).member;

        //combine first, last and email together lower case
        const searchIn: string = member.firstName.toLowerCase() + member.lastName.toLowerCase() + member.email.toLowerCase();

        //perform search
        return (searchIn.indexOf(this.filteredMembersCriteria.toLowerCase().replace(' ', '')) >= 0);

      });

    } else {
      return <AppGroupEventMember[]>this.group.members;
    }

  }

  searchCriteriaChange(event: any) {

    //only perform a search if the user has entered criteria
    if (event.target.value && event.target.value.length > 0) {
      this.filteredMembersCriteria = event.target.value;
    } else {
      this.filteredMembersCriteria = '';
    }

  }

  searchCriteriaClear() {
    this.filteredMembersCriteria = '';
  }

  updateAvatar(click, avatarElement: HTMLImageElement) {

    this.mediaService
      .selectAvatarAction(click, avatarElement, this.group.avatar)
      .then((fileURI) => {

        //if avatar updated then display locally and make dirty
        if (fileURI !== undefined) {

          //set local image
          this.localAvatarURL = fileURI || AppConfig.NO_AVATAR_URI;

          //make form dirty
          this.groupForm.controls.avatarFileURI.markAsDirty();

        }

      })
      .catch((err) => {
        console.log('group-event-detail.page.ts updateAvatar selectAvatarAction error', err, JSON.stringify(err));
      });

  }

  updateCover() {

    this.mediaService
      .selectCoverAction(this.group.cover)
      .then((fileURI) => {

        console.log('group-event-detail.page.ts updateCover selectCoverAction', fileURI);

        //if avatar updated then display locally and make dirty
        if (fileURI !== undefined) {

          //set local image
          this.localCoverURL = fileURI || AppConfig.NO_COVER_URI;

          //make form dirty
          this.groupForm.controls.coverFileURI.markAsDirty();

        }

      })
      .catch((err) => {
        console.log('group-event-detail.page.ts updateCover selectPictureSource error', err, JSON.stringify(err));
      });

  }

  async newEvent(group: AppGroupEvent) {

    this.appFunction
      .modalCtrl
      .create({
        component: EventDetailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        componentProps: {
          group: group,
          editMode: AppConfig.EDIT_MODE.new,
          numberOfTeeTimes: this.groupForm.controls.numberTeeTimes.value
        }
      })
      .then((modal) => {
        modal
          .present()
          .catch((err) => {
            console.log('group-event-detail.page.ts modal present error', err);
          });
      })
      .catch((err) => {
        console.log('group-event-detail.page.ts modal create error', err);
      });

  }

  private deleteGroup(group: AppGroupEvent) {

    //delete group
    group
      .delete()
      .then(() => {
        this.appFunction.modalCtrl.dismiss();
      });

  }

  deleteGroupConfirmation(group: AppGroupEvent) {

    //confirmation
    this.appFunction
      .alertCtrl
      .create({
        header: 'Please confirm!',
        message: "Once deleted, this Group (including Members, past Events and Posts) will no longer be available. Are you sure you want to delete this Group?",
        backdropDismiss: false,
        buttons: [
          {
            text: "No",
            role: 'cancel',
            handler: () => {
              //no action
            }
          },
          {
            text: 'Yes, delete',
            role: 'destructive',
            handler: () => {
              this.deleteGroup(group);
            }
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  confirmClubAction() {

    //if club selected then ask if new search or club edit
    if (this.group.clubId) {

      //confirmation
      this.appFunction
        .actionCtrl
        .create({
          header: 'Please confirm',
          buttons: [
            {
              text: 'View/edit club',
              handler: () => {
                this.viewClub();
              }
            },
            {
              text: 'Change club',
              handler: () => {
                this.searchClub();
              }
            },
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
              }
            }
          ]
        })
        .then((action) => {
          action.present();
        });

    } else { //else just search
      this.searchClub();
    }

  }

  private async searchClub() {

    this.appFunction
      .modalCtrl
      .create({
        component: ClubSearchPage,
        presentingElement: await this.appFunction.routerOutlet(),
        enterAnimation: enterFromRightAnimation,
        leaveAnimation: leaveToRightAnimation,
        cssClass: 'custom-modal' //for md
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('group-event-detail.page.ts modal present error', err);
          });

        modal
          .onDidDismiss()
          .then((result) => {

            //set club 
            if (result.role === 'select') {

              //set local value
              this.groupForm.controls['club'].setValue(result.data.club);

              //only set this valitator if live scoring (this will be temporary until all courses support live scoring)
              /* if (this.groupForm.controls.club.value.scoring) {
                this.groupForm.get('tee').setValidators(Validators.required);
              } else {
                this.groupForm.get('tee').clearValidators();
              } */

              //must update
              //this.groupForm.get('tee').updateValueAndValidity();

              //...if only one course then auto select course
              if (this.groupForm.controls.club.value.courses.length === 1) {
                this.groupForm.controls['course'].setValue(this.groupForm.controls.club.value.courses[0]);
              } else {
                //...else get selected course (if any)...
                this.groupForm.controls['course'].setValue(undefined);
              }

              //when changing course we must reset tee
              this.groupForm.controls['tee'].setValue(undefined);
              this.groupForm.controls['nineHolesOnlyIndex'].setValue(undefined);
              this.groupForm.controls['numberOfHoles'].setValue(18);

              //mark sure it gets saved to the group
              this.groupForm.markAsDirty();

              //send a support email if any data is missing from selected course
              this.clubService.validateClubData(result.data.club);

            }

          });

      })
      .catch((err) => {
        console.log('group-event-detail.page.ts modal create error', err);
      });

  }

  private async viewClub() {

    this.appFunction
      .modalCtrl
      .create({
        component: ClubDetailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        enterAnimation: enterFromRightAnimation,
        leaveAnimation: leaveToRightAnimation,
        cssClass: 'custom-modal', //for md
        componentProps: {
          club: this.group.club,
          closeLabel: 'Close'
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('group-event-detail.page.ts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-event-detail.page.ts modal create error', err);
      });

  }

  //async selectCourse(course: string) {
  async selectCourse(course: AppCourse) {

    //set new value to form
    this.groupForm.controls['course'].setValue(course);

    //clear tee values
    this.groupForm.controls['tee'].setValue(undefined);

    //set the number of holes. This really only matters if the course is an 18 hole course.
    this.groupForm.controls['numberOfHoles'].setValue(this.groupForm.controls.course.value.holes);

    //make dirty so it saves
    this.groupForm.markAsDirty();

  }

  async selectTee(tee: string) {

    this.groupForm.controls['tee'].setValue(tee);

    //make dirty so it saves
    this.groupForm.markAsDirty();

  }

  private turnOnNineHolesOnlyIndexValidator(on: boolean) {

    if (on) {
      this.groupForm.get('nineHolesOnlyIndex').setValidators(Validators.required);
    } else {
      this.groupForm.get('nineHolesOnlyIndex').clearValidators();
    }

    this.groupForm.get('nineHolesOnlyIndex').updateValueAndValidity();

  }

  async selectHoles(numberOfHoles: number) {

    //turn on/off nine hole validation
    this.turnOnNineHolesOnlyIndexValidator(numberOfHoles === 9);

    //set the number of holes
    this.groupForm.controls.numberOfHoles.setValue(numberOfHoles);

    //
    if (numberOfHoles === 18) {
      this.groupForm.controls['nineHolesOnlyIndex'].setValue(undefined);
      this.group.nineHolesOnlyIndex = undefined;
    }

    //mark as dirty
    this.groupForm.markAsDirty();

  }

  updateEmailPreferences(value) {
    this.memberGroupPreferences.e = value;
    this.groupForm.markAsDirty();
  }

  updateNotificationPreferences(value) {
    this.memberGroupPreferences.n = value;
    this.groupForm.markAsDirty();
  }

  updatePostNotificationPreferences(value) {
    this.memberGroupPreferences.p = value;
    this.groupForm.markAsDirty();
  }

  updateEmailEventNotificationPreferences(value) {
    this.memberGroupPreferences.eventEmailJoinDrop = value;
    this.groupForm.markAsDirty();
  }

  updateNotificationEventNotificationPreferences(value) {
    this.memberGroupPreferences.eventNotificationJoinDrop = value;
    this.groupForm.markAsDirty();
  }

  decrementCounter(controlName: string) {
    this.groupForm.controls[controlName].setValue(this.groupForm.controls[controlName].value - 1);
    this.groupForm.controls[controlName].markAsDirty();
  }

  incrementCounter(controlName: string) {
    this.groupForm.controls[controlName].setValue(this.groupForm.controls[controlName].value + 1);
    this.groupForm.controls[controlName].markAsDirty();
  }

  //#region social

  get posts(): AppPost[] {

    if (Array.isArray(this._posts)) {

      return this._posts.filter((post) => {
        return post.exists;
      });

    } else {
      return [];
    }

  }

  async newPost() {

    this.appFunction
      .modalCtrl
      .create({
        component: PostNewPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        keyboardClose: false,
        componentProps: {
          poster: this.group
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('group-event-detail.page.ts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-event-detail.page.ts modal create error', err);
      });

  }

  getPosts(event: any = undefined): Promise<void> {

    return new Promise<void>((resolve) => {

      //if first time get posts
      if (!Array.isArray(this._posts)) {

        this.appFunction
          .loadingCtrl
          .create({ message: 'Getting posts...' })
          .then((loading) => {

            loading.present();

            //get this groups posts
            this.group
              .social
              .getPosts()
              .then((posts) => {

                //set posts
                this._posts = posts;

                //listen for new posts
                this._futurePostSubscription = this.group
                  .social
                  .getFuturePosts()
                  .subscribe((futurePost) => {

                    //push all new posts on the front of the array
                    this._posts.unshift(futurePost);

                  });

                loading.dismiss();
                resolve();

              });

          });

      } else if (event) { //only get next set of posts on infinite scroll 

        //get next batch of (older) posts
        this.group
          .social
          .getNextPosts()
          .then((nextPosts) => {

            nextPosts
              .forEach((nextPost) => {
                this._posts.push(nextPost);
              });

            event.target.complete();

            //when no more posts to display then stop trying to fetch
            if (nextPosts.length === 0) {
              event.target.disabled = true;
              this.noMorePosts = true;
            }

            resolve();

          });

      } else {
        resolve();
      }

    });

  }

  //#endregion social

  async composeEmail() {

    this.appFunction
      .modalCtrl
      .create({
        component: EmailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal' //for md
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('group-event-detail.page.ts modal present error', err);
          });

        modal
          .onDidDismiss()
          .then((results) => {
            console.log('group-event-detail.page.ts modal present results', results);

            if (results.data.action === 'send') {

              this.group.sendGroupMessageEmail(this.editMode, results.data.subject, results.data.message);

            }

          })
          .catch((err) => {
            console.log('group-event-detail.page.ts modal onDidDismiss error', err);
          });

      })
      .catch((err) => {
        console.log('group-event-detail.page.ts modal create error', err);
      });

  }

  /* groupSegmentChange(event: any) {

    //console.log('group-event-detail.page.ts groupSegmentChange', event);

    if (event.detail.value === 'posts') {
      this.getPosts();
    }

    if (event.detail.value === 'events') {
      this.getPastEvents();
    }

    

  } */

  groupSegmentChange(event: any) {

    if (event.detail.value === GroupEventSegment.Posts) {

      this.getPosts()
        .then(() => {
          //set and show up popover config
          this.createPopupConfig.content = 'Create Posts here';
          this.createPopupDisplayConfig.show = this.posts.length === 0; //only show if no posts
          this.popOver.show(this.createPopupConfig, this.createPopupDisplayConfig);
        });

    } else if (event.detail.value === GroupEventSegment.Events) {

      this.getPastEvents();

      //set and show up popover config
      this.createPopupConfig.content = 'Create Events here';
      this.createPopupDisplayConfig.show = this.group.events.all.length === 0; //only show if no events
      this.popOver.show(this.createPopupConfig, this.createPopupDisplayConfig);

    }

  }

  private updateMemberPreference(): Promise<void> {

    return new Promise<void>((resolve) => {

      //update member preferences for group
      this.accountService
        .member
        .updatePreference(this.memberGroupPreferences)
        .then(() => {
          resolve();
        });

    });

  }

  memberAction(member: AppMember) {

    //console.log('group-event-detail.page.ts memberAction', member);

    const options: any[] = [];

    //remove member from group
    options.push({
      text: 'Remove from group',
      role: 'destructive',
      handler: () => {
        this.removeMemberConfirm(member);
      }
    });

    //either make or remove admin
    if (this.group.isMemberAdmin(member)) {

      options.push({
        text: 'Remove administrator status',
        handler: () => {
          this.removeAdmin(member);
        }
      });

    } else {

      options.push({
        text: 'Make group administrator',
        handler: () => {
          this.makeAdmin(member);
        }
      });

    }

    options.push({
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
      }
    });

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'What would you like to do?',
        buttons: options
      })
      .then((action) => {
        action.present();
      });

  }

  async eventScoring(click: Event, event: AppEvent) {

    //console.log('group-event-detail.page.ts eventScoring');

    //prevent the list item click
    click.stopPropagation();

    this.appFunction
      .modalCtrl
      .create({
        component: EventScoringPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        backdropDismiss: false,
        canDismiss: true,
        componentProps: {
          event: event
        }
      })
      .then((modal) => {

        //pass in modal instance
        modal.componentProps.modal = modal;

        //open modal 
        modal
          .present()
          .catch((err) => {
            console.log('group-event-detail.page.ts eventScoring modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-event-detail.page.ts eventScoring modal create error', err);
      });

    //prevent the list item click
    click.stopPropagation();

  }

  getPastEvents() {

    try {

      //if first time get events
      if (!Array.isArray(this._events)) {

        //init array
        this._events = [];

        this.appFunction
          .loadingCtrl
          .create({ message: 'Getting events...' })
          .then((loading) => {

            loading.present();

            //get current and past events for given group
            this.appFunction
              .firestore
              .collection(AppConfig.COLLECTION.Events)
              .where('groupId', '==', this.group.id)
              .where('deleted', '==', false)
              .orderBy('eventDt', 'desc')
              .limit(20)
              .get({ source: 'server' })
              .then((foundEvents) => {

                //for each found event...
                foundEvents
                  .docChanges()
                  .forEach((foundEvent) => {

                    //try to find event in local storage...
                    const foundLocalEvent: AppEvent = this._events.find((event) => {
                      return event.id === foundEvent.doc.id;
                    })

                    //...if not found then create
                    if (!foundLocalEvent) {

                      this.eventService
                        .getEvent(this.group, foundEvent.doc.id, foundEvent.doc)
                        .then((event) => {

                          //store locally
                          this._events.push(event);

                        });

                    }

                  });

                loading.dismiss();

              }, (err) => {
                console.log('group-event-detail.page.ts getEvents get error', err);
              });

          });

      }

    } catch (err) {
      console.log('group-event-detail.page.ts getPastEvents error', err);
    }

  }

  get events(): AppEvent[] {
    //return non deleted events
    return this._events?.filter((event) => {
      return !event.deleted;
    });
  }

  private save(): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      try {

        //save the form if dirty or avatar has been updated
        if (this.groupForm.dirty) {

          //signal validation toast that form has been submitted
          this.isFormSubmitted = true;

          //save the form if valid 
          if (this.groupForm.valid) {

            this.appFunction
              .loadingCtrl
              .create({ message: 'Saving group...' })
              .then((loading) => {

                loading.present();

                //update values
                this.group.name = this.groupForm.controls.name.value;
                this.group.description = this.groupForm.controls.description.value;
                this.group.numberTeeTimes = this.groupForm.controls.numberTeeTimes.value;
                this.group.teeTimeInterval = this.groupForm.controls.teeTimeInterval.value;
                this.group.public = this.groupForm.controls.public.value;
                this.group.numberOfHoles = this.groupForm.controls.numberOfHoles.value;
                this.group.nineHolesOnlyIndex = this.groupForm.controls.nineHolesOnlyIndex?.value;

                this.group
                  .setClub(this.groupForm.controls.club.value.clubId, this.groupForm.controls.course.value?.courseId, this.groupForm.controls.tee.value?.teeId)
                  .then(() => {

                    //create or save group
                    this.group
                      .save()
                      .then((group) => {

                        //make sure member preference has the group id
                        this.memberGroupPreferences.key = group.id;

                        //save the array of promises
                        const promiseArray: any[] = [];

                        //save avatar if it has been updated
                        if (this.groupForm.controls['avatarFileURI'].dirty) {

                          const p = this.group
                            .avatar
                            .save(this.localAvatarURL)
                            .catch((err) => {
                              console.log('group-event-detail.page.ts save saveAvatar error', err, JSON.stringify(err));
                            });

                          promiseArray.push(p);

                        }

                        //save cover if it has been updated
                        if (this.groupForm.controls['coverFileURI'].dirty) {

                          const p = this.group
                            .cover
                            .save(this.localCoverURL)

                            .catch((err) => {
                              console.log('group-event-detail.page.ts save cover error', err, JSON.stringify(err));
                            });

                          promiseArray.push(p);

                        }

                        //if group is new then...
                        if (this.editMode === AppConfig.EDIT_MODE.new) {
                          this.group.sendNewGroupEmail(this.accountService.member);
                        }

                        //wait for all promises to return
                        Promise
                          .all(promiseArray)
                          .then(() => {
                            loading.dismiss();
                            resolve();
                          })
                          .catch((err) => {
                            console.log('group-event-detail.page.ts done promise.all error', err, JSON.stringify(err));
                            reject();
                          });

                      })
                      .catch((err) => {
                        loading.dismiss();
                        console.log('group-event-detail.page.ts group save error', err, JSON.stringify(err));
                        reject();
                      });
                  });

              });

          } else {
            //show any untouched errors
            this.appFunction.setDirtyControlAsTouched(this.groupForm);
            reject();
          }

        } else {
          //no updates
          resolve();
        }

      } catch (err) {
        console.log('group-event-detail.page.ts save error', err);
        reject();
      }

    });


  }

  done() {

    //save group
    this
      .save()
      .then(() => {

        //save organizer's preferences
        if (this.group.exists && this.memberGroupPreferences.dirty) {
          this.updateMemberPreference();
        }

        this.appFunction.modalCtrl.dismiss();

      });

  }

  cancel() {

    //confirm that user wants to discard changes
    if (this.groupForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your Group. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.appFunction.modalCtrl.dismiss();
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

}