import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AccountService, AppMember } from '../../app.account';
import { AppFunction, AvatarBorder } from '../../app.function';
import { ProfileSummaryPage } from '../../pages/profile-summary/profile-summary.page';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  animations: [
    AvatarBorder
  ]
})
export class AvatarComponent implements OnInit {

  //get from the host html
  @Input() member: AppMember;
  @Input() disable: boolean = false;

  //get from the component html
  @ViewChild('avatarImg', { static: true }) avatarImg: ElementRef;

  constructor(
    public accountService: AccountService,
    public appFunction: AppFunction) {
  }

  ngOnInit() {

    //console.log('avatar.component.ts ngOnInit', this.avatarImg);

    //set avatar...
    //if (!this.member || this.member.avatar.URI === AppConfig.NO_AVATAR_URI) {
    if (this.member) {

      //else default image and get requested image
      this.avatarImg.nativeElement.src = AppConfig.SPINNER;

      //create image object that will download requested image
      let downloadingImage: HTMLImageElement = new Image();

      //listen for the image download complete event
      downloadingImage.onload = () => {
        this.avatarImg.nativeElement.src = downloadingImage.src;

        //clean up
        downloadingImage.onload = undefined;
        downloadingImage = undefined;
      }

      //assign the src to that of some_remote_image_url. once it is downloaded the "onload" will fire
      downloadingImage.src = this.member.avatar.URI;

    } else {
      this.avatarImg.nativeElement.src = AppConfig.NO_AVATAR_URI;
    }

  }

  get followingStatus(): string {

    const index: number = this.accountService
      .member?.social
      .following
      .findIndex((followingMember) => {
        //console.log('avatar.component.ts followingStatus', followingMember.followedMember.id, this.member.id);
        return followingMember.followedMember.id === this.member?.id;
      });

    return (index > -1 ? 'on' : 'off');

  }

  async displayProfileSummary(click: Event) {

    //prevent the list item click
    click.stopPropagation();

    //only allow if there's a member object
    if (this.member) {

      //only allow a few profile summary pages to be open at one time.
      if (AppFunction.openProfiles <= 2) {

        this.appFunction
          .modalCtrl
          .create({
            component: ProfileSummaryPage,
            presentingElement: await this.appFunction.routerOutlet(),
            cssClass: 'custom-modal', //for md
            showBackdrop: false,
            componentProps: {
              member: this.member
            }
          })
          .then((modal) => {

            modal
              .present()
              .catch((err) => {
                console.log('avatar.component.ts displayProfileSummary modal present error', err);
              });

          })
          .catch((err) => {
            console.log('avatar.component.ts displayProfileSummary modal create error', err);
          });

      } else {

        this.appFunction
          .alertCtrl
          .create({
            header: 'Stop!',
            subHeader: 'The maximum number of member profiles are open. You must close one before opening another.',
            buttons: [
              {
                text: 'OK',
                handler: () => {
                }
              }
            ]
          })
          .then((alert) => {
            alert.present();
          });

      }

    }

  }

  showAvatarView(click: Event, element: HTMLImageElement) {

    //prevent the list item click
    click.stopPropagation();

    if (!this.disable) {
      this.appFunction.showAvatarView(click, element);
    }

  }

}
