<ion-label style="margin-top: 10px;">

  <ion-label position="stacked">{{formatLabel}}</ion-label>

  <ng-container *ngIf="match">

    <ion-grid class="ion-no-padding">

      <!-- handicap allowance -->
      <ion-row>
        <ion-col size="auto">
          <div class="circle-small descriptionHeader">
            {{match.getHandicapAllowance(match.handicapAllowance).descriptionHeader}}
          </div>
        </ion-col>
        <ion-col>
          <div class="description">{{match.getHandicapAllowance(match.handicapAllowance).description}} handicap
            allowance</div>
        </ion-col>
      </ion-row>

      <!-- handicap type -->
      <ion-row>
        <ion-col size="auto">
          <div class="circle-small descriptionHeader">
            {{match.getHandicapType(match.handicapType).descriptionHeader}}
          </div>
        </ion-col>
        <ion-col>
          <div class="description">{{match.getHandicapType(match.handicapType).name}}</div>
        </ion-col>
      </ion-row>

      <!-- gross/net type -->
      <ion-row>
        <ion-col size="auto">
          <div class="circle-small descriptionHeader">
            {{match?.getGrossNetValueType(match.grossNetType).descriptionHeader}}
          </div>
        </ion-col>
        <ion-col>
          <div class="description">{{match.getGrossNetValueType(match.grossNetType).description}}
          </div>
        </ion-col>
      </ion-row>

      <!-- game -->
      <ion-row>
        <ion-col size="auto">
          <div class="circle-small descriptionHeader">
            {{match.getGame(match.game)?.nameHeader}}
          </div>
        </ion-col>
        <ion-col>
          <div class="description">{{match.getGame(match.game)?.name}}</div>
        </ion-col>
      </ion-row>

      <!-- team size -->
      <ion-row>
        <ion-col size="auto">
          <div class="circle-small descriptionHeader">
            {{match.getTeamSize(match.game, match.teamSize)?.descriptionHeader}}
          </div>
        </ion-col>
        <ion-col>
          <div class="description">{{match.getTeamSize(match.game, match.teamSize)?.description}}</div>
        </ion-col>
      </ion-row>

      <!-- scoring type -->
      <ion-row>
        <ion-col size="auto">
          <div class="circle-small descriptionHeader">
            {{match.getTeamScoring(match.game, match.teamSize, match.scoringType)?.descriptionHeader}}
          </div>
        </ion-col>
        <ion-col>
          <div class="description">
            {{match.getTeamScoring(match.game, match.teamSize, match.scoringType)?.description}}
          </div>
        </ion-col>
      </ion-row>

      <!-- stableford points -->
      <ng-container *ngIf="match.game===GameType.Stableford">

        <!-- header row -->
        <ion-row *ngIf="match.game===GameType.Stableford">
          <ion-col size="auto">
            <div class="circle-small descriptionHeader">
              P
            </div>
          </ion-col>
          <ion-col>
            <div class="description">
              Stableford points
            </div>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="match.game===GameType.Stableford">
          <ul style="margin-top: 0; margin-bottom: 0;">
            <li>Albatross: {{match.stablefordPoints.albatross}}</li>
            <li>Eagle: {{match.stablefordPoints.eagle}}</li>
            <li>Birdie: {{match.stablefordPoints.birdie}}</li>
            <li>Par: {{match.stablefordPoints.par}}</li>
            <li>Bogey: {{match.stablefordPoints.bogey}}</li>
            <li>Double Bogey+: {{match.stablefordPoints.doubleBogey}}</li>
          </ul>
        </ion-row>

      </ng-container>

      <!-- press -->
      <ion-row *ngIf="match.getAllowPress(match.game)">
        <ion-col size="auto">
          <div class="circle-small descriptionHeader">
            P
          </div>
        </ion-col>
        <ion-col>
          <div class="description">
            {{match.getPressOption()}}
          </div>
        </ion-col>
      </ion-row>

    </ion-grid>

  </ng-container>

  <ng-container *ngIf="!match">
    <!-- <ion-label style="margin-bottom: 10px;">Setup the event match</ion-label> -->
    <h2 style="margin-bottom: 10px;" class="readOnly">Setup the event match</h2>
  </ng-container>

</ion-label>

<ion-label *ngIf="match" style="margin: 10px 0;">
  <div class="preserveFormat">{{match.notes}}</div>
</ion-label>