<ion-header class="event-detail-header ion-no-border">

  <ion-toolbar>

    <ion-title><span *ngIf="editMode===formEditMode.new">New&nbsp;</span>Event</ion-title>

    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" [screenKey]="screenKey">
        <ion-icon name="help-circle-outline"></ion-icon>
      </ion-button>
      <ion-button (click)="cancel()" *ngIf="eventForm.dirty">Cancel</ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button *ngIf="eventForm.dirty" fill="solid" shape="round" (click)="done()">Save</ion-button>
      <ion-button *ngIf="!eventForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>

  </ion-toolbar>

  <!-- validation hero -->
  <validation-hero [display]="isFormSubmitted && eventForm.invalid"></validation-hero>

</ion-header>

<ion-content class="event-detail-content" forceOverscroll="false">

  <!-- segment -->
  <div class="ion-padding">

    <ion-segment [(ngModel)]="eventDetailSegment" (ionChange)="eventDetailSegmentChange($event)">

      <ion-segment-button [value]="EventDetailSegment.Information">
        <ion-label>Details</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="EventDetailSegment.TeeTimes">
        <ion-label>Tee Times</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="EventDetailSegment.TimeLine" *ngIf="event.exists">
        <ion-label>Timeline</ion-label>
      </ion-segment-button>

    </ion-segment>

  </div>

  <!-- main tab with event details -->
  <div *ngIf="eventDetailSegment === EventDetailSegment.Information">

    <!-- 'Player dropped' action card -->
    <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.MemberDropped">

      <ion-card-content>
        <ion-card-title>
          Player Dropped
        </ion-card-title>

        <p class="message">{{actionCdMessage}}</p>

      </ion-card-content>

    </ion-card>

    <!-- 'Player joined' action card -->
    <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.MemberJoined">

      <ion-card-content>
        <ion-card-title>
          Player Joined
        </ion-card-title>

        <p class="message">{{actionCdMessage}}</p>

      </ion-card-content>

    </ion-card>

    <form class="event-detail-form" [formGroup]="eventForm">

      <!-- event details -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Event</ion-label>
        </ion-list-header>

        <!-- group name -->
        <ion-item *ngIf="isGroupEvent">
          <ion-label>{{event.name}}</ion-label>
        </ion-item>

        <!-- number of confirmed players -->
        <ion-item *ngIf="event.exists">

          <ion-label>

            <ion-text>Number of players</ion-text>
            <ion-badge color="secondary" style="margin-left: 2px;">{{event.players.active.all.length}}</ion-badge><br />

            <ng-container *ngIf="(eventForm.controls.numberTeeTimes.value * 4) - event.players.active.all.length >= 0">
              <ion-text>Number of spots available</ion-text>
              <ion-badge color="secondary" style="margin-left: 2px;">{{(eventForm.controls.numberTeeTimes.value * 4) -
                event.players.active.all.length}}</ion-badge>
              <ng-container *ngIf="event.players.active.all.length >= (eventForm.controls.numberTeeTimes.value * 4)">
                <ion-badge color="secondary" style="margin-left: 2px;">Full</ion-badge>
              </ng-container><br />

            </ng-container>

            <ng-container *ngIf="waitListCount > 0">
              <ion-text>Number of players on the wait list</ion-text>
              <ion-badge color="danger" style="margin-left: 2px;">{{waitListCount}}</ion-badge>
            </ng-container>

          </ion-label>

        </ion-item>

        <!-- message -->
        <ion-item>
          <ion-label position="stacked">Message</ion-label>
          <ion-textarea formControlName="description" autocomplete="on" autocorrect="on" autocapitalize="on"
            auto-grow="true" rows="3">
          </ion-textarea>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="eventForm.controls.description.hasError('required') && !eventForm.controls.description.valid && eventForm.controls.description.touched">
          <ion-label>Please enter an event message.</ion-label>
        </ion-item>

        <!-- event date -->
        <ion-item class="accordionItem">
          <ion-label position="stacked">Date</ion-label>
          <ion-accordion-group #eventDtAccordian>
            <ion-accordion>
              <ion-item slot="header">
                <ion-label>{{ eventForm.controls.eventDt.value | amUtc | amLocal | amDateFormat:'dddd, MMMM Do' }}
                </ion-label>
              </ion-item>
              <div slot="content">
                <ion-datetime #eventDt presentation="date" formControlName="eventDt" [min]="minEventDate"
                  [max]="maxEventDate"></ion-datetime>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-item>
        <!--  <ion-item lines="none" class="validation" *ngIf="eventForm.controls.eventDt.hasError('groupTripEventDate')">
          <ion-label class="ion-text-wrap">The Event date is outside of the trip departure and return
            dates.</ion-label>
        </ion-item> -->

        <!-- first tee time -->
        <ion-item class="accordionItem">
          <ion-label position="stacked">First Tee Time</ion-label>
          <ion-accordion-group #firstTeeTimeAccordian>
            <ion-accordion value="firstTeeTime">
              <ion-item slot="header">
                <ion-label>{{eventForm.controls.firstTeeTime.value | amDateFormat:'h:mm A'}}</ion-label>
              </ion-item>
              <div slot="content">
                <ion-datetime presentation="time" formControlName="firstTeeTime"></ion-datetime>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-item>

        <!-- Number of Tee Times -->
        <ion-item>

          <ion-label>
            <ion-text class="labelPositionStacked">Number of Tee Times</ion-text>

            <ion-grid class="counter ion-no-padding">
              <ion-row class="ion-no-padding">
                <ion-col class="ion-text-center ion-no-padding">
                  <ion-button shape="round" color="secondary" haptic
                    [disabled]="eventForm.controls.numberTeeTimes.value===1"
                    (click)="decrementCounter('numberTeeTimes')">
                    <ion-icon slot="icon-only" name="remove"></ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col class="ion-text-center ion-no-padding">
                  <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                    {{eventForm.controls.numberTeeTimes.value}}</ion-label>
                </ion-col>
                <ion-col class="ion-text-center ion-no-padding">
                  <ion-button shape="round" color="secondary" haptic
                    [disabled]="eventForm.controls.numberTeeTimes.value===15"
                    (click)="incrementCounter('numberTeeTimes')">
                    <ion-icon slot="icon-only" name="add"></ion-icon>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>

          </ion-label>

        </ion-item>

      </ion-list>

      <!-- match details -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Matches</ion-label>
          <ion-button style="height: auto;" (click)="matchEdit();">
            <ion-icon name="add-circle" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-list-header>

        <!-- get event matches (vs member matches) -->
        <ng-container *ngIf="event.matches.parent.length > 0, else noEventMatches">

          <ng-container *ngFor="let match of event.matches.parent">

            <!-- match format -->
            <ion-item (click)="matchEdit(match)" detail="true" lines="full">

              <match-view [match]="match" [formatLabel]="'Format'">
              </match-view>

            </ion-item>

          </ng-container>

        </ng-container>

        <!-- if no event matches then show this messsage -->
        <ng-template #noEventMatches>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <p>There are no matches for this Event. Tap <ion-icon name="add-circle" slot="icon-only"
                  style="color: var(--ion-color-primary); font-size: large; vertical-align: text-bottom;"></ion-icon>
                above to create the first Event match.</p>
            </ion-label>
          </ion-item>
        </ng-template>

      </ion-list>

      <!-- club details -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Club Details</ion-label>
        </ion-list-header>

        <!-- club -->
        <ion-item (click)="confirmClubAction()" detail="true">

          <ion-label>

            <ion-text class="labelPositionStacked">
              Golf Club
            </ion-text>

            <h2 *ngIf="!eventForm.controls.club.value" class="readOnly">Add golf club</h2>
            <h2 *ngIf="eventForm.controls.club.value" style="overflow: visible;">
              {{eventForm.controls.club.value.name}}</h2>

          </ion-label>

        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="eventForm.controls.club.hasError('required') && !eventForm.controls.club.valid && eventForm.controls.club.touched">
          <ion-label>Please select a club.</ion-label>
        </ion-item>

        <!-- courses, only show if club has more than one course -->
        <ion-item class="accordionItem" *ngIf="eventForm.controls.club.value?.courses?.length > 1">
          <ion-label position="stacked">Courses</ion-label>
          <ion-accordion-group #courseAccordion>
            <ion-accordion value="course">
              <ion-item slot="header">
                <ion-label>
                  <!-- selected course -->
                  <h2 *ngIf="!eventForm.controls.course.value" class="readOnly">Select a course</h2>
                  <h2 *ngIf="eventForm.controls.course.value" style="overflow: visible;">
                    {{eventForm.controls.course.value.name}}
                  </h2>
                </ion-label>
              </ion-item>
              <div slot="content" class="lineSeparatorTop">
                <ion-grid class="ion-no-padding">
                  <ion-row class="ion-align-items-center" *ngFor="let course of eventForm.controls.club.value.courses"
                    (click)="selectCourse(course); courseAccordion.value = undefined;" style="height: 35px;">

                    <ion-col size="auto">
                      <div class="circle-small descriptionHeader">{{course.name.charAt(0)}}</div>
                    </ion-col>
                    <ion-col>
                      <div>{{course.name}}</div>
                    </ion-col>

                  </ion-row>
                </ion-grid>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="eventForm.controls.course.hasError('required') && !eventForm.controls.course.valid && eventForm.controls.course.touched">
          <ion-label>Please select a course.</ion-label>
        </ion-item>

        <!-- tees -->
        <ion-item class="accordionItem" *ngIf="eventForm.controls.course.value">
          <ion-label position="stacked">Tees</ion-label>
          <ion-accordion-group #teeAccordion>
            <ion-accordion value="tee">
              <ion-item slot="header">
                <ion-label>
                  <h2 *ngIf="!eventForm.controls.tee.value" class="readOnly">Select a tee</h2>
                  <h2 *ngIf="eventForm.controls.tee.value" style="overflow: visible;">

                    <tee [tee]="eventForm.controls.tee.value"
                      [firstTeeColor]="eventForm.controls.tee.value.firstTeeColor"
                      [secondTeeColor]="eventForm.controls.tee.value.secondTeeColor"
                      [teeName]="eventForm.controls.tee.value.name" [distance]="eventForm.controls.tee.value.distance"
                      [slope]="eventForm.controls.tee.value.slope" [rating]="eventForm.controls.tee.value.rating"
                      [nines]="eventForm.controls.tee.value.nines"
                      [nineHolesOnlyIndex]="eventForm.controls.nineHolesOnlyIndex.value"></tee>

                  </h2>
                </ion-label>
              </ion-item>
              <div slot="content" class="lineSeparatorTop">
                <tee [tee]="tee" [firstTeeColor]="tee.firstTeeColor" [secondTeeColor]="tee.secondTeeColor"
                  [teeName]="tee.name" [distance]="tee.distance" [nines]="tee.nines" [slope]="tee.slope"
                  [rating]="tee.rating" [nineHolesOnlyIndex]="eventForm.controls.nineHolesOnlyIndex.value"
                  *ngFor="let tee of eventForm.controls.course.value.tees"
                  (click)="selectTee(tee); teeAccordion.value = undefined;">
                </tee>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="eventForm.controls.tee.hasError('required') && !eventForm.controls.tee.valid && eventForm.controls.tee.touched">
          <ion-label>Please select a tee.</ion-label>
        </ion-item>

        <!-- number of holes -->
        <ion-item *ngIf="eventForm.controls.tee.value && eventForm.controls.course.value?.holes===18">

          <ion-label>

            <ion-text class="labelPositionStacked">
              Number of holes
            </ion-text>

            <ion-grid class="holesPlayed ion-no-padding">

              <ion-row class="ion-align-items-center">

                <ion-col size="4" (click)="selectHoles(18);">
                  <ion-button shape="round" class="circle"
                    [ngClass]="{ circleOn: eventForm.controls.numberOfHoles.value===18 }"
                    style="margin-top: 0px; margin-bottom: 0px; font-size: 16px;">18</ion-button>holes
                </ion-col>

                <ion-col size="auto" (click)="selectHoles(9);">
                  <ion-button shape="round" class="circle"
                    [ngClass]="{ circleOn: eventForm.controls.numberOfHoles.value===9 }"
                    style="margin-top: 0px; margin-bottom: 0px; font-size: 16px;">9</ion-button>holes
                </ion-col>

              </ion-row>

            </ion-grid>

          </ion-label>

        </ion-item>

        <!-- course nines, if the tee was selected and the course is an 18 hole course and we're only playing 9 holes -->
        <ion-item #nines class="item-expand-closed"
          style="--max-height-expand: {{this.ninesItem?.nativeElement?.scrollHeight + 1}}px;"
          [ngClass]="{'item-expand-open' : eventForm.controls.tee.value && eventForm.controls.course.value?.holes===18 && eventForm.controls.numberOfHoles.value===9}">

          <ion-label>

            <ion-text class="labelPositionStacked">
              Nines
            </ion-text>

            <ion-radio-group formControlName="nineHolesOnlyIndex">
              <ion-item lines="none" *ngFor="let nine of eventForm.controls.tee.value?.nines; let i = index;"
                style="--padding-start: 0px;">
                <ion-radio [value]="i" mode="md" slot="start" color="secondary" style="height: 30px;">
                </ion-radio>
                <ion-label>{{nine.name}}</ion-label>
              </ion-item>
            </ion-radio-group>

          </ion-label>

        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="eventForm.controls.nineHolesOnlyIndex.hasError('required') && !eventForm.controls.nineHolesOnlyIndex.valid && eventForm.controls.nineHolesOnlyIndex.touched">
          <ion-label>Please select which nine holes to play.</ion-label>
        </ion-item>

        <!-- starting hole, if 18 holes what hjole are you starting on 1 or 10 -->
        <ion-item #startingHole class="item-expand-closed"
          style="--max-height-expand: {{this.startingHoleItem?.nativeElement?.scrollHeight + 1}}px;"
          [ngClass]="{'item-expand-open' : eventForm.controls.numberOfHoles.value===18}">

          <ion-label>

            <ion-text class="labelPositionStacked">
              Starting Hole
            </ion-text>

            <ion-radio-group formControlName="startingHoleIndex">
              <ion-item lines="none" style="--padding-start: 0px;">
                <ion-radio [value]="0" mode="md" slot="start" color="secondary" style="height: 30px;">
                </ion-radio>
                <ion-label>Hole 1</ion-label>
              </ion-item>
              <ion-item lines="none" style="--padding-start: 0px;">
                <ion-radio [value]="9" mode="md" slot="start" color="secondary" style="height: 30px;">
                </ion-radio>
                <ion-label>Hole 10</ion-label>
              </ion-item>
            </ion-radio-group>

          </ion-label>

        </ion-item>
        <!-- <ion-item lines="none" class="validation"
          *ngIf="eventForm.controls.nineHolesOnlyIndex.hasError('required') && !eventForm.controls.nineHolesOnlyIndex.valid && eventForm.controls.nineHolesOnlyIndex.touched">
          <ion-label>Please select which nine holes to play.</ion-label>
        </ion-item> -->

        <!-- Default Tee Time Interval (minutes) -->
        <ion-item>

          <ion-label>

            <ion-text class="labelPositionStacked">Tee Time Interval (minutes)</ion-text>

            <ion-grid class="counter ion-no-padding">

              <ion-row class="ion-no-padding">

                <ion-col class="ion-text-center ion-no-padding">
                  <ion-button shape="round" color="secondary" [disabled]="eventForm.controls.teeTimeInterval.value===1"
                    (click)="decrementCounter('teeTimeInterval')">
                    <ion-icon slot="icon-only" name="remove"></ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col class="ion-text-center ion-no-padding">
                  <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                    {{eventForm.controls.teeTimeInterval.value}}</ion-label>
                </ion-col>
                <ion-col class="ion-text-center ion-no-padding">
                  <ion-button shape="round" color="secondary" [disabled]="eventForm.controls.teeTimeInterval.value===15"
                    (click)="incrementCounter('teeTimeInterval')">
                    <ion-icon slot="icon-only" name="add"></ion-icon>
                  </ion-button>
                </ion-col>

              </ion-row>

            </ion-grid>

          </ion-label>

        </ion-item>

      </ion-list>

    </form>

  </div>

  <!-- tee times -->
  <div *ngIf="eventDetailSegment === EventDetailSegment.TeeTimes">

    <!-- this creates all of the players. css slides this div on top of the headers div -->
    <div #dragulaScroll id="dragulaScroll">

      <!-- this creates all of the headers -->
      <div *ngFor="let teeTime of teeTimes" class="teeTimesContainer">

        <div class="header doNotDrag">
          <ion-label>
            {{teeTime.time}}
          </ion-label>
        </div>

        <!-- if regular tee time -->
        <div *ngIf="teeTime.teeTime !== WAIT_LIST_TEE_TIME" class="teeTimes" [dragula]="'teetime-bag'"
          [dragulaModel]="teeTime.players">

          <ng-container *ngFor="let player of teeTime.players">

            <!-- only display if not dropped -->
            <ng-container *ngIf="player.status">

              <div delayDragLift class="doDragEventPlayer {{'doDrag' + (player.guests.all.length + 1).toString()}}">

                <!-- players -->
                <div class="teeTimePlayer">

                  <ion-grid class="ion-no-padding">

                    <ion-row class="ion-no-padding ion-nowrap">

                      <ion-col class="ion-no-padding">
                        <ion-icon name="chatbubbles" [hidden]="!player.note" (click)="displayNote(player)"
                          style="color: var(--ion-color-primary)"></ion-icon>
                      </ion-col>

                      <ion-col class="ion-no-padding">
                        <ion-avatar item-left class="avatar-small container">
                          <img [imgLoader]="player.URI" draggable="false">
                        </ion-avatar>
                      </ion-col>

                      <ion-col class="ion-no-padding">
                        <ion-icon name="ellipsis-vertical" (click)="confirmPlayerAction(player)"
                          style="color: var(--ion-color-primary)"></ion-icon>
                      </ion-col>

                    </ion-row>

                    <ion-row class="ion-no-padding">

                      <ion-col class="ion-no-padding" style="overflow: hidden;">
                        <ion-text class="name ion-text-nowrap">{{player.firstName}} {{player.lastName}}
                        </ion-text>
                      </ion-col>

                    </ion-row>

                  </ion-grid>

                </div>

                <!-- guests -->
                <ng-container *ngFor="let guest of player.guests.all;">

                  <!-- guest -->
                  <div class="teeTimePlayer">

                    <ion-grid class="ion-no-padding">

                      <ion-row class="ion-no-padding ion-justify-content-center">

                        <ion-col class="ion-no-padding">
                          <!-- do not remove -->
                        </ion-col>

                        <ion-col class="ion-no-padding">
                          <ion-avatar item-left class="avatar-small">
                            <img [imgLoader]="guest.URI" draggable="false">
                          </ion-avatar>
                        </ion-col>

                        <ion-col class="ion-no-padding">
                          <ion-icon name="ellipsis-vertical" (click)="guestRemoveConfirm(guest)"
                            style="color: var(--ion-color-primary)"></ion-icon>
                        </ion-col>

                      </ion-row>

                      <ion-row class="ion-no-padding ion-justify-content-center">
                        <ion-col class="ion-no-padding">
                          <ion-label class="name">{{guest.firstName}} {{guest.lastName}}</ion-label>
                        </ion-col>
                      </ion-row>

                    </ion-grid>

                  </div>

                </ng-container>

              </div>

            </ng-container>

          </ng-container>

        </div>

        <!-- if wait listed tee time -->
        <div *ngIf="teeTime.teeTime === WAIT_LIST_TEE_TIME" class="teeTimes" [dragula]="'teetime-bag'"
          [dragulaModel]="teeTime.players" style="min-height: 61px !important">

          <ng-container *ngFor="let player of teeTime.players">

            <!-- only display if not dropped -->
            <ng-container *ngIf="player.status">

              <div delayDragLift class="doDragEventPlayer waitList">

                <!-- member -->
                <ion-item style="height: 100%; width: 100%;">

                  <avatar #avatar [member]="player.member" slot="start"></avatar>

                  <ion-label (click)="avatar.displayProfileSummary($event)">
                    <h2>{{player.firstName}} {{player.lastName}}</h2>
                    <p>{{player.email}}</p>
                    <p>joined on {{player.createdDt.toDate() | amUtc | amLocal | amDateFormat: 'ddd, MMMM Do h:mm A'}}
                    </p>
                    <ion-note [hidden]="!player.note">
                      <ion-icon name="chatbubbles" style="margin-left: 0px; margin-right: 5px;"></ion-icon>
                      {{player.note}}
                    </ion-note>
                  </ion-label>

                  <ion-icon name="ellipsis-vertical" (click)="confirmPlayerAction(player)" slot="end"
                    style="align-self: end; margin-right: 0px;" color="primary"></ion-icon>

                </ion-item>

                <!-- guests -->
                <ion-item *ngFor="let guest of player.guests.all; let i = index" style="height: 100%; width: 100%;">

                  <avatar [member]="guest.member" slot="start"></avatar>

                  <ion-label>

                    <h3>{{guest.firstName}} {{guest.lastName}}</h3>
                    <p>guest</p>

                  </ion-label>

                  <ion-icon name="ellipsis-vertical" (click)="guestRemoveConfirm(player);"
                    style="color: var(--ion-color-primary); align-self: end; margin-right: 0px;" slot="end"></ion-icon>

                </ion-item>

              </div>

            </ng-container>

          </ng-container>

        </div>

      </div>

      <!-- show dropped players -->
      <div *ngIf="event.players.dropped.length > 0">

        <div class="header doNotDrag">

          <ion-label>
            Dropped
          </ion-label>

        </div>

        <ion-list style="margin-bottom: 0px;" class="itemLines">

          <ng-container *ngFor="let player of event.players.dropped">

            <!-- member -->
            <ion-item>

              <avatar #avatar [member]="player.member" slot="start"></avatar>

              <ion-label (click)="avatar.displayProfileSummary($event)">
                <h2>{{player.firstName}} {{player.lastName}}</h2>
                <p>dropped on {{player.updatedDt?.toDate() | amUtc | amLocal | amDateFormat: 'ddd, MMMM Do h:mm A'}}
              </ion-label>

            </ion-item>

            <!-- guests -->
            <ion-item *ngFor="let guest of player.guests.all">

              <avatar [member]="guest.member" slot="start"></avatar>

              <ion-label>

                <h3>{{guest.firstName}} {{guest.lastName}}</h3>
                <p>guest</p>

              </ion-label>

            </ion-item>

          </ng-container>

        </ion-list>

      </div>

      <!-- info messages -->
      <div style="margin-top: 10px; margin-bottom: 10px;">

        <div *ngIf="teeTimes.length > 0, else noTeeTimes">

          <ion-note class="instructional">
            Tap
            <ion-icon name="chatbubbles" class="primary" style="margin-left: 0px; margin-right: 5px;"></ion-icon>
            to view a player note
          </ion-note>

          <ion-note class="instructional">
            Press and drag player to desired tee time
          </ion-note>

        </div>

        <ion-note class="instructional">
          Tap
          <ion-icon name="add-circle" class="primary"></ion-icon> below to add players to this event
        </ion-note>

      </div>

    </div>

  </div>

  <!-- timeline -->
  <div *ngIf="eventDetailSegment=== EventDetailSegment.TimeLine">

    <ion-list class="itemLines" *ngIf="event.players.groupMembersOnly.length > 0, else noTeeTimes">

      <ng-container *ngFor="let player of event.players.groupMembersOnly">

        <ion-item>

          <avatar #avatar [member]="player.member" slot="start"></avatar>

          <ion-label (click)="avatar.displayProfileSummary($event)">

            <h2>{{player.firstName}} {{player.lastName}}</h2>
            <p *ngIf="player.status">joined on
              {{player.createdDt?.toDate() | amUtc | amLocal | amDateFormat: 'ddd, MMMM Do h:mm A'}}
            <p *ngIf="!player.status">dropped on
              {{player.updatedDt?.toDate() | amUtc | amLocal | amDateFormat: 'ddd, MMMM Do h:mm A'}}

          </ion-label>

        </ion-item>

        <ng-container *ngFor="let guest of player.guests.all">

          <ion-item>

            <avatar [member]="guest.member" slot="start"></avatar>

            <ion-label>

              <h3>{{guest.firstName}} {{guest.lastName}}</h3>
              <p>guest</p>

            </ion-label>

          </ion-item>

        </ng-container>

      </ng-container>

    </ion-list>

  </div>

  <ion-fab #fab #addPlayers vertical="bottom" horizontal="end" slot="fixed" [fabClose]="fab" haptic popoverHelp>
    <ion-fab-button id="eventDetailFab">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-button shape="round" (click)="deleteEventConfirm(); fab.close();" *ngIf="event.exists">
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label>Cancel Event</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="sendAdhocEventUpdate(); fab.close();" *ngIf="event.exists">
        <ion-icon name="send" slot="start"></ion-icon>
        <ion-label>Send Event Update</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="event.composeEmailConfirm(); fab.close();" *ngIf="event.exists">
        <ion-icon name="mail" slot="start"></ion-icon>
        <ion-label>Send Email</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="newPost(); fab.close();" *ngIf="event.exists">
        <ion-icon name="chatbubbles" slot="start"></ion-icon>
        <ion-label>Post Message</ion-label>
      </ion-button>

      <!-- only show this if the event has a group -->
      <ion-button shape="round" (click)="addMemberFromGroup(); fab.close();" *ngIf="event.group">
        <ion-icon name="person-add" slot="start"></ion-icon>
        <ion-label>Add Group Member</ion-label>
      </ion-button>

      <!-- show this if the event doesn't have a group -->
      <ion-button shape="round" (click)="addPlayerConfirm(); fab.close();" *ngIf="!event.group">
        <ion-icon name="person-add" slot="start"></ion-icon>
        <ion-label>Add Player</ion-label>
      </ion-button>

    </ion-fab-list>
  </ion-fab>

</ion-content>

<ng-template #noTeeTimes>
  <ion-note class="instructional">
    No players have joined this event
  </ion-note>
</ng-template>