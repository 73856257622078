import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Routes, RouterModule } from "@angular/router";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { NativeGeocoder } from "@ionic-native/native-geocoder/ngx";
import { IonicModule } from "@ionic/angular";
import { RuleHelperPage } from "./rule-helper.page";
import { ComponentsModule } from "../../components/components.module";
import { DirectivesModule } from "../../directives/directives.module";
import { PipesModule } from "../../pipes/pipes.module";

const routes: Routes = [
  {
    path: "",
    component: RuleHelperPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    DirectivesModule,
    PipesModule,
  ],
  declarations: [RuleHelperPage],
  providers: [NativeGeocoder, Geolocation],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RuleHelperPageModule {}
