import { Component, OnInit } from '@angular/core';
import { AppFunction, ColorService, IonColor } from '../../app.function';
import firebase from 'firebase/compat/app';
import { AppConfig } from 'src/app/app.config';
import { GeoFirestore } from 'geofirestore';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { AppGroupEventMember, AppGroupEventMemberI, GroupType, MemberGroupRole } from 'src/app/app.group';
import { EventType } from 'src/app/app.event';

@Component({
  selector: 'system-utilities',
  templateUrl: './system-utilities.page.html',
  styleUrls: ['./system-utilities.page.scss'],
})
export class SystemUtilitiesPage implements OnInit {

  loadFile: string;

  constructor(
    public appFunction: AppFunction,
    public colorService: ColorService) {

  }

  ngOnInit() {
  }

  onClubFileSelected(event) {

    try {

      //console.log(event.srcElement.files);

      //get file, parse and load
      var file = event.srcElement.files[0];
      if (file.name.match(/\.(csv)$/)) {

        //console.log(event.srcElement.files);

        let clubCount: number = 0;
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {

          const csv: any = reader.result;
          const rawClubs = csv.split(/\r\n/);

          //console.log('system-utilities.page.ts onFileSelected file read', e, rawClubs);

          console.log('system-utilities.page.ts onFileSelected pre count count: ', rawClubs.length);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment 
          // @ts-ignore
          const geoFirestore = new GeoFirestore(this.appFunction.firestore);
          const geoClubs = geoFirestore.collection(AppConfig.COLLECTION.Clubs)

          rawClubs
            .forEach((clubRawData, index) => {

              //skip the header row which is index 0
              if (index > 0) {

                //splt row
                const clubProperties: string[] = clubRawData.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

                /* 
                0: "Club ID"
                1: "Club Name"
                4: "Address"
                5: "City"
                6: "State"
                8: "Zipcode"
                9: "Phone"
                11: "Url"
                12: "Longitude"
                13: "Latitude" 
                */

                const latitude: number = Number(clubProperties[13]);
                const longitude: number = Number(clubProperties[12]);
                const clubObject = {
                  "clubId": clubProperties[0],
                  "name": clubProperties[1].replace('"', ''),
                  "searchName": clubProperties[1].replace('"', '').toUpperCase(),
                  "address": clubProperties[4].replace('"', ''),
                  "city": clubProperties[5],
                  "state": clubProperties[6],
                  "zipcode": clubProperties[8],
                  "phone": clubProperties[9],
                  "latitude": latitude,
                  "longitude": longitude,
                  "url": clubProperties[11].replace('"', ''),
                }

                try {

                  const p = new Promise<DocumentReference>((resolve) => {

                    //get document with clubId
                    this.appFunction
                      .firestore
                      .collection(AppConfig.COLLECTION.Clubs)
                      .where('clubId', '==', clubProperties[0])
                      .get()
                      .then((club) => {

                        //if empty then it is a new club so add it
                        if (club.empty) {

                          this.appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Clubs)
                            .add(clubObject)
                            .then((club) => {
                              console.log('club added/saved', club.id, 'count: ', clubCount++);
                              resolve(club);
                            })
                            .catch((err) => {
                              console.log('Error during club collection add', err, club);
                            });

                        } else { //update

                          const clubRef = this.appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Clubs)
                            .doc(club.docs[0].id);

                          clubRef
                            .set(clubObject)
                            .then(() => {
                              console.log('club updated', club.docs[0].id, 'count: ', clubCount++);
                              resolve(clubRef);
                            })
                            .catch((err) => {
                              console.log('Error during club update', err, club);
                            });

                        }

                      });

                  });

                  Promise
                    .all([p])
                    .then((clubRef) => {

                      try {

                        clubRef[0]
                          .get()
                          .then((clubData) => {

                            //set coord
                            const data = clubData.data();
                            data.coordinates = new firebase.firestore.GeoPoint(clubData.data().latitude, clubData.data().longitude);

                            console.log('clubData: ', clubData.data());

                            //save the coordinate
                            geoClubs
                              .doc(clubData.id)
                              .set(data)
                              .then(() => {
                                //console.log('Provided key has been added to GeoFirestore', doc.id);
                                console.log('Provided key has been added to GeoFirestore');
                              })
                              .catch((error) => {
                                console.log('Error: ' + error, clubData.id);
                              });

                          });

                      } catch (err) {
                        console.log('Geocode error: ' + err);
                      }

                    });

                } catch (err) {
                  console.log('Error during club add', err, clubObject);
                }

              }


            });

          console.log('club load complete');

        };

      } else {
        alert('File not supported, .txt .json .csv files only');
      }

    } catch (err) {
      console.log('system-utilities.page.ts onFileSelected error', err);
    }

  }

  onCourseFileSelected(event) {

    try {

      //console.log(event.srcElement.files);

      //get file, parse and load
      var file = event.srcElement.files[0];
      if (file.name.match(/\.(csv)$/)) {

        //console.log(event.srcElement.files);

        let courseCount: number = 0;
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {

          const csv: any = reader.result;
          const rawCourses = csv.split(/\r\n/);

          //console.log('system-utilities.page.ts onFileSelected file read', e, rawCourses);

          rawCourses
            .forEach((courseRawData, index) => {

              //skip the header row
              if (index > 0) {

                //splt row
                const courseProperties: string[] = courseRawData.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
                //console.log('Course properties', courseProperties, courseCount++);

                /* 
                0: CourseID "USA-AR-00056-01"
                1: ClubID "USA-AR-00056"
                2: Course Name "Greystone"
                3: Holes "Holes"
                6: Designer "Kevin Tucker"
                7: Year "1998"
                */

                const courseObject = {
                  "courseId": courseProperties[0],
                  "clubId": courseProperties[1],
                  "name": courseProperties[2].replace('"', ''),
                  "holes": this.returnNumber(courseProperties[3]),
                  "designer": courseProperties[6].replace('"', ''),
                  "year": courseProperties[7].toString().replace('"', '')
                };

                //get document with clubId
                this.appFunction
                  .firestore
                  .collection(AppConfig.COLLECTION.Courses)
                  .where('courseId', '==', courseProperties[0])
                  .get()
                  .then((course) => {

                    //if empty then it is a new club and add
                    if (course.empty) {

                      this.appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Courses)
                        .add(courseObject)
                        .then((course) => {
                          console.log('course added/saved', course.id, 'count: ', courseCount++);
                        }).
                        catch((err) => {
                          console.log('Error during course add', err, courseProperties[0], course);
                        });

                    } else { //update

                      const courseRef = this.appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Courses)
                        .doc(course.docs[0].id);

                      courseRef
                        .set(courseObject)
                        .then(() => {
                          console.log('course updated', course.docs[0].id, 'count: ', courseCount++);
                        })
                        .catch((err) => {
                          console.log('Error during course update', err, course);
                        });

                    }

                  });

              }

            });

          console.log('course load complete');

        };

      } else {
        alert('File not supported, .txt .json .csv files only');
      }

    } catch (err) {
      console.log('system-utilities.page.ts onCourseFileSelected error', err);
    }

  }

  onTeeFileSelected(event) {

    const teeColors = this.colorService.colorList;

    try {

      //console.log(event.srcElement.files);

      //get file, parse and load
      var file = event.srcElement.files[0];
      if (file.name.match(/\.(csv)$/)) {

        //console.log(event.srcElement.files);

        let teeCount: number = 0;
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {

          const csv: any = reader.result;
          const rawTees = csv.split(/\r\n/);

          console.log('Number of Tees', rawTees.length);

          rawTees
            .forEach((teeRawData, index) => {

              //skip the header row
              if (index > 0) {

                //splt row
                const teeProperties: string[] = teeRawData.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
                //console.log('Tee properties', teeProperties);

                /* 
                0: "Tee ID"
                1: "Course ID"
                2: "Tee Name"
                3: "Tee Color 1"
                4: "Course Par For Tee"
                5: "Rating"
                6: "Slope"
                7: "Hole1"
                8: "Hole2"
                9: "Hole3"
                10: "Hole4"
                11: "Hole5"
                12: "Hole6"
                13: "Hole7"
                14: "Hole8"
                15: "Hole9"
                16: "Hole10"
                17: "Hole11"
                18: "Hole12"
                19: "Hole13"
                20: "Hole14"
                21: "Hole15"
                22: "Hole16"
                23: "Hole17"
                24: "Hole18"
                25: "Total Distance"
                26: "Hole1 Par"
                27: "Hole2 Par"
                28: "Hole3 Par"
                29: "Hole4 Par"
                30: "Hole5 Par"
                31: "Hole6 Par"
                32: "Hole7 Par"
                33: "Hole8 Par"
                34: "Hole9 Par"
                35: "Hole10 Par"
                36: "Hole11 Par"
                37: "Hole12 Par"
                38: "Hole13 Par"
                39: "Hole14 Par"
                40: "Hole15 Par"
                41: "Hole16 Par"
                42: "Hole17 Par"
                43: "Hole18 Par"
                44: "Hole1 Handicap"
                45: "Hole2 Handicap"
                46: "Hole3 Handicap"
                47: "Hole4 Handicap"
                48: "Hole5 Handicap"
                49: "Hole6 Handicap"
                50: "Hole7 Handicap"
                51: "Hole8 Handicap"
                52: "Hole9 Handicap"
                53: "Hole10 Handicap"
                54: "Hole11 Handicap"
                55: "Hole12 Handicap"
                56: "Hole13 Handicap"
                57: "Hole14 Handicap"
                58: "Hole15 Handicap"
                59: "Hole16 Handicap"
                60: "Hole17 Handicap"
                61: "Hole17 Handicap"
                */

                let firstTeeIonColor: IonColor;
                let secondTeeIonColor: IonColor;

                //get full tee name
                const teeName: string = teeProperties[2].toLowerCase();

                //get index of slash (in the event the tee name is two colors)
                const slashIndex: number = teeName.indexOf('/');

                //does tee name have a slash in it?
                if (slashIndex > -1) {

                  const firstTeeColor: string = teeName.substr(0, slashIndex);
                  const secondTeeColor: string = teeName.substr(slashIndex + 1);

                  //get the first tee color
                  firstTeeIonColor = teeColors.find((teeColor) => {
                    return teeColor.friendlyName.toLowerCase() === firstTeeColor;
                  });

                  //if tee name not found try tee color
                  if (firstTeeIonColor === undefined) {
                    console.log('tee color not found', teeProperties[0], teeName);
                  }

                  //get the second tee color
                  secondTeeIonColor = teeColors.find((teeColor) => {
                    return teeColor.friendlyName.toLowerCase() === secondTeeColor;
                  });

                  //if tee name not found try tee color
                  if (secondTeeIonColor === undefined) {
                    console.log('tee color not found', teeProperties[0], teeName);
                  }

                } else { //no slash

                  //get the tee name
                  firstTeeIonColor = teeColors.find((teeColor) => {
                    return teeColor.friendlyName.toLowerCase() === teeName;
                  });

                  //if tee name not found try tee color
                  if (firstTeeIonColor === undefined) {

                    //console.log('tee color not found', teeName, 'trying tee color', teeProperties[3].toLowerCase());

                    //get the tee color
                    firstTeeIonColor = teeColors.find((teeColor) => {
                      return teeColor.friendlyName.toLowerCase() === teeProperties[3].toLowerCase();
                    });

                    //still not found
                    if (firstTeeIonColor === undefined) {
                      console.log('tee color not found again', teeProperties[0], teeProperties[3].toLowerCase());
                    }

                  }

                  //no second color
                  secondTeeIonColor = null;

                }

                //this is used when determining par for each nine
                let numberOfNines: number = 1;
                if (!Number.isNaN(Number(teeProperties[16]))) {
                  numberOfNines = 2;
                }

                //push the first nine holes
                const nines: any[] = [];
                nines.push({
                  "name": "Front",
                  "par": this.returnNumber(teeProperties[4]) / numberOfNines,
                  "holes": [
                    {
                      number: 1,
                      distance: this.returnNumber(teeProperties[7]),
                      par: this.returnNumber(teeProperties[7 + 19]),
                      hdcp: this.returnNumber(teeProperties[7 + 37])
                    },
                    {
                      number: 2,
                      distance: this.returnNumber(teeProperties[8]),
                      par: this.returnNumber(teeProperties[8 + 19]),
                      hdcp: this.returnNumber(teeProperties[8 + 37])
                    },
                    {
                      number: 3,
                      distance: this.returnNumber(teeProperties[9]),
                      par: this.returnNumber(teeProperties[9 + 19]),
                      hdcp: this.returnNumber(teeProperties[9 + 37])
                    },
                    {
                      number: 4,
                      distance: this.returnNumber(teeProperties[10]),
                      par: this.returnNumber(teeProperties[10 + 19]),
                      hdcp: this.returnNumber(teeProperties[10 + 37])
                    },
                    {
                      number: 5,
                      distance: this.returnNumber(teeProperties[11]),
                      par: this.returnNumber(teeProperties[11 + 19]),
                      hdcp: this.returnNumber(teeProperties[11 + 37])
                    },
                    {
                      number: 6,
                      distance: this.returnNumber(teeProperties[12]),
                      par: this.returnNumber(teeProperties[12 + 19]),
                      hdcp: this.returnNumber(teeProperties[12 + 37])
                    },
                    {
                      number: 7,
                      distance: this.returnNumber(teeProperties[13]),
                      par: this.returnNumber(teeProperties[13 + 19]),
                      hdcp: this.returnNumber(teeProperties[13 + 37])
                    },
                    {
                      number: 8,
                      distance: this.returnNumber(teeProperties[14]),
                      par: this.returnNumber(teeProperties[14 + 19]),
                      hdcp: this.returnNumber(teeProperties[14 + 37])
                    },
                    {
                      number: 9,
                      distance: this.returnNumber(teeProperties[15]),
                      par: this.returnNumber(teeProperties[15 + 19]),
                      hdcp: this.returnNumber(teeProperties[15 + 37])
                    }
                  ]
                });

                //determine if there is a second nine holes
                if (!Number.isNaN(Number(teeProperties[16]))) {

                  nines.push({
                    "name": "Back",
                    "par": this.returnNumber(teeProperties[4]) / numberOfNines,
                    "holes": [
                      {
                        number: 10,
                        distance: this.returnNumber(teeProperties[16]),
                        par: this.returnNumber(teeProperties[16 + 19]),
                        hdcp: this.returnNumber(teeProperties[16 + 37])
                      },
                      {
                        number: 11,
                        distance: this.returnNumber(teeProperties[17]),
                        par: this.returnNumber(teeProperties[17 + 19]),
                        hdcp: this.returnNumber(teeProperties[17 + 37])
                      },
                      {
                        number: 12,
                        distance: this.returnNumber(teeProperties[18]),
                        par: this.returnNumber(teeProperties[18 + 19]),
                        hdcp: this.returnNumber(teeProperties[18 + 37])
                      },
                      {
                        number: 13,
                        distance: this.returnNumber(teeProperties[19]),
                        par: this.returnNumber(teeProperties[19 + 19]),
                        hdcp: this.returnNumber(teeProperties[19 + 37])
                      },
                      {
                        number: 14,
                        distance: this.returnNumber(teeProperties[20]),
                        par: this.returnNumber(teeProperties[20 + 19]),
                        hdcp: this.returnNumber(teeProperties[20 + 37])
                      },
                      {
                        number: 15,
                        distance: this.returnNumber(teeProperties[21]),
                        par: this.returnNumber(teeProperties[21 + 19]),
                        hdcp: this.returnNumber(teeProperties[21 + 37])
                      },
                      {
                        number: 16,
                        distance: this.returnNumber(teeProperties[22]),
                        par: this.returnNumber(teeProperties[22 + 19]),
                        hdcp: this.returnNumber(teeProperties[22 + 37])
                      },
                      {
                        number: 17,
                        distance: this.returnNumber(teeProperties[23]),
                        par: this.returnNumber(teeProperties[23 + 19]),
                        hdcp: this.returnNumber(teeProperties[23 + 37])
                      },
                      {
                        number: 18,
                        distance: this.returnNumber(teeProperties[24]),
                        par: this.returnNumber(teeProperties[24 + 19]),
                        hdcp: this.returnNumber(teeProperties[24 + 37])
                      }
                    ]
                  });

                }

                const teeObject = {
                  "teeId": teeProperties[0],
                  "courseId": teeProperties[1],
                  "name": teeProperties[2],
                  "firstTeeColor": firstTeeIonColor || null,
                  "secondTeeColor": secondTeeIonColor || null,
                  "par": this.returnNumber(teeProperties[4]),
                  "rating": this.returnNumber(teeProperties[5]),
                  "slope": this.returnNumber(teeProperties[6]),
                  "nines": nines,
                  "distance": this.returnNumber(teeProperties[25])
                };

                //get document with clubId
                this.appFunction
                  .firestore
                  .collection(AppConfig.COLLECTION.Tees)
                  .where('teeId', '==', teeProperties[0])
                  .get()
                  .then((tee) => {

                    //if empty then it is a new tee and add
                    if (tee.empty) {

                      this.appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Tees)
                        .add(teeObject)
                        .then((tee) => {
                          console.log('tee added/saved', tee.id, 'count: ', teeCount++);
                        }).
                        catch((err) => {
                          console.log('Error during tee add', err, teeProperties[0], tee);
                        });

                    } else { //update

                      const teeRef = this.appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Tees)
                        .doc(tee.docs[0].id);

                      teeRef
                        .set(teeObject)
                        .then(() => {
                          console.log('tee updated', 'count: ', teeCount++, teeProperties[0]);
                        })
                        .catch((err) => {
                          console.log('Error during tee update', tee.docs[0].id, err, tee);
                        });

                    }

                  });

              }

            });

          console.log('tee load complete');

        };

      } else {
        alert('File not supported, .txt .json .csv files only');
      }

    } catch (err) {
      console.log('system-utilities.page.ts onCourseFileSelected error', err);
    }

  }

  geohash() {

    console.log('system-utilities.page.ts geohash');

    this.appFunction
      .firestore
      .collection(AppConfig.COLLECTION.Clubs)
      .get()
      .then((clubs) => {

        console.log('system-utilities.page.ts geohash. length: ', clubs.size);

        let count: number = 0;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment 
        // @ts-ignore
        const geoFirestore = new GeoFirestore(this.appFunction.firestore);
        const geoClubs = geoFirestore.collection(AppConfig.COLLECTION.Clubs)

        clubs
          .forEach((club) => {

            //console.log('system-utilities.page.ts geohash', doc.data());

            try {

              let data = club.data();
              let latitude: number = club.data().latitude || 0;
              let longitude: number = club.data().longitude || 0;

              if (data.g) {

                console.log('Already had g', club.id);

              } else if (isNaN(latitude) || isNaN(longitude)) {

                console.log('Invalid lat or long', club.id);

              } else {

                data.coordinates = new firebase.firestore.GeoPoint(latitude, longitude);
                delete data.location;

                geoClubs
                  .doc(club.id)
                  .set(data)
                  .then(() => {
                    //console.log('Provided key has been added to GeoFirestore', doc.id);
                    console.log('Provided key has been added to GeoFirestore', count++);
                  })
                  .catch((error) => {
                    console.log('Error: ' + error, data, club.id);
                  });

              }

            } catch (err) {
              console.log('geahash error', err);
            }

          });

        console.log('done');

      })
      .catch((err) => {
        console.log(err, JSON.stringify(err));
      });

  }

  updateMemberData() {

    console.log('system-utilities.page.ts updateMemberData');

    this.appFunction
      .firestore
      .collection(AppConfig.COLLECTION.Members)
      //.limit(1)
      .get()
      .then((members) => {

        console.log('system-utilities.page.ts updateMemberData length: ', members.size);

        let count: number = 0;

        members
          .forEach((member) => {

            //console.log('system-utilities.page.ts updateMemberData', doc.data());

            try {

              member
                .ref
                .set(
                  {
                    appVersion: '0.0.0',
                    postCount: 0,
                    firstNameSearch: member.data().firstName.trim().toUpperCase(),
                    lastNameSearch: member.data().lastName.trim().toUpperCase(),
                    compositeSearch: firebase.firestore.FieldValue.delete()
                  },
                  { merge: true })
                .then((updatedMember) => {

                  count++;
                  console.log('system-utilities.page.ts updateMemberData member set data count', count);

                })
                .catch((err) => {
                  console.log('system-utilities.page.ts updateMemberData member set data error', err);
                });

            } catch (err) {
              console.log('system-utilities.page.ts updateMemberData try error', err);
            }

          });

      });

    console.log('done');

  }

  fixClubZipCodes() {

    console.log('system-utilities.page.ts fixClubZipCodes');

    this.appFunction
      .firestore
      .collection(AppConfig.COLLECTION.Clubs)
      //.limit(1)
      .get()
      .then((clubs) => {

        console.log('system-utilities.page.ts fixClubZipCodes length: ', clubs.size);

        let count: number = 0;

        clubs
          .forEach((club) => {

            //console.log('system-utilities.page.ts fixClubZipCodes');

            try {

              if (club.data().zipcode?.trim().length === 4) {

                console.log('system-utilities.page.ts fixClubZipCodes zipcode', club.data().zipcode);

                club
                  .ref
                  .set(
                    {
                      zipcode: '0' + club.data().zipcode
                    },
                    { merge: true })
                  .catch((err) => {
                    console.log('system-utilities.page.ts fixClubZipCodes member set data error', err);
                  });

              }

            } catch (err) {
              console.log('system-utilities.page.ts fixClubZipCodes try error', err);
            }

          });

      });

    console.log('done');

  }

  fixGroupEventData() {

    this.appFunction
      .firestore
      .collection(AppConfig.COLLECTION.Groups)
      //.limit(10)
      .get()
      .then((groups) => {

        console.log('system-utilities.page.ts fixGroupEventData number of groups: ', groups.size);

        let count: number = 0;

        groups
          .forEach((group) => {

            console.log('system-utilities.page.ts fixGroupEventData groups: ', group.data().name);

            try {

              const groupMembers: AppGroupEventMemberI[] = [];
              const promiseArray: any[] = [];

              //breakdown old members object
              const members = this.appFunction.decomposeObjectToArray(group.data().memberIds);

              //now create member array
              members
                .forEach((member) => {

                  let role: MemberGroupRole;

                  if (member.key === group.data().ownerMemberId) {
                    role = MemberGroupRole.Owner;
                  } else if (group.data().adminMemberIds?.includes(member.key)) {
                    role = MemberGroupRole.Administrator;
                  } else {
                    role = MemberGroupRole.Member;
                  }

                  const groupEventMember: AppGroupEventMember = new AppGroupEventMember();
                  const p = groupEventMember.initialize({ memberId: member.key, role: role });
                  groupMembers.push(groupEventMember);
                  promiseArray.push(p);

                });

              Promise
                .all(promiseArray)
                .then(() => {

                  const members: AppGroupEventMemberI[] = [];
                  const searchMemberIds: string[] = [];
                  groupMembers.forEach((groupMember) => {
                    members.push((<AppGroupEventMember>groupMember).data());
                    searchMemberIds.push(groupMember.memberId);
                  });

                  console.log('system-utilities.page.ts fixGroupTripData members: ', members, searchMemberIds);

                  group
                    .ref
                    .set(
                      {
                        type: GroupType.Event,
                        deleted: false,
                        searchMemberIds: searchMemberIds,
                        members: members
                      },
                      { merge: true })
                    .then(() => {
                      count++;
                      console.log('Count', count);
                    })
                    .catch((err) => {
                      console.log('system-utilities.page.ts fixGroupEventData group set data error', group.data().name, err);
                    });

                });

            } catch (err) {
              console.log('system-utilities.page.ts fixGroupEventData try error', group.data().name, err);
            }

          });

      });

    console.log('done');

  }

  fixEventData() {

    this.appFunction
      .firestore
      .collection(AppConfig.COLLECTION.Events)
      //.limit(1)
      .get()
      .then((events) => {

        console.log('system-utilities.page.ts fixEventData number of events: ', events.size);

        let count: number = 0;

        events
          .forEach((event) => {

            try {

              //console.log('system-utilities.page.ts fixEventData groupId?: ', event.data().description, event.data().groupId, event.data().groupId ? EventType.Event : EventType.Peronal);

              event
                .ref
                .set(
                  {
                    type: event.data().groupId ? EventType.GroupEvent : EventType.Personal,
                    deleted: false
                  },
                  { merge: true })
                .then(() => {
                  count++;
                  console.log('Count', count);
                })
                .catch((err) => {
                  console.log('system-utilities.page.ts fixEventData event set data error', event.data().description, err);
                });

            } catch (err) {
              console.log('system-utilities.page.ts fixEventData try error', event.data().description, err);
            }

          });

      });

  }

  updateClubDataForAlgolia() {

    this.appFunction
      .firestore
      .collection(AppConfig.COLLECTION.Clubs)
      .get()
      .then((clubs) => {

        console.log('system-utilities.page.ts updateClubDataForAlgolia number of clubs: ', clubs.size);

        let count: number = 0;

        clubs
          .forEach((club) => {

            try {

              club
                .ref
                .set(
                  {
                    name: club.data().name,
                  },
                  { merge: true })
                .then(() => {
                  count++;
                  //console.log('Count', count);
                })
                .catch((err) => {
                  console.log('system-utilities.page.ts updateClubDataForAlgolia set data error', club.data().description, err);
                });

            } catch (err) {
              console.log('system-utilities.page.ts updateClubDataForAlgolia try error', club.data().description, err);
            }

          });

      });

  }

  returnNumber(value: string): number {
    return Number.isNaN(Number(value)) ? null : Number(value);
  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}
