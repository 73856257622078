<ion-card class="customCard">

  <ion-card-header>

    <!-- cover and avatar -->
    <div class="coverAvatar">

      <!-- cover -->
      <div class="cover">
        <img #imgCover [src]="event.cover" draggable="false">
      </div>

      <!-- avatar -->
      <div class="avatar">
        <ion-avatar class="avatar-large avatarImageOverlay">
          <img #imgAvatar [imgLoader]="event.avatar" (click)="appFunction.showAvatarView($event, imgAvatar)"
            draggable="false">
        </ion-avatar>
      </div>

      <ion-item lines="none" class="infoActionBar">

        <ion-note slot="start" style="font-size: 15px;">
          <div *ngIf="event.numberOfAvailableSpots > 0">{{event.numberOfAvailableSpots}}&nbsp;opening<span
              *ngIf="event.numberOfAvailableSpots !== 1">s</span></div>
          <div *ngIf="event.numberOfAvailableSpots <= 0">All full</div>
        </ion-note>

        <ion-note slot="end" style="font-size: 15px;">
          <div *ngIf="event.groupId">Group Event</div>
          <div *ngIf="!event.groupId">Personal Event</div>
        </ion-note>

      </ion-item>

    </div>

  </ion-card-header>

  <ion-card-content>

    <ion-grid class="ion-no-padding">

      <!-- group name -->
      <ion-row style="padding-left: 10px; padding-right: 10px">
        <ion-col size="auto" style="text-align: center; margin: auto">
          <h1>{{event.name}}</h1>
        </ion-col>
      </ion-row>

      <!-- display course and which nine if applicable -->
      <ion-row style="padding: 10px 5px 0px 10px;">
        <ion-col class="eventInfo ion-no-padding iconTextSingleLine">
          <ion-icon name="golf-solid" style="font-size: larger; color: var(--ion-color-black-d);"></ion-icon>
          <ion-text>{{(event.course)?.name + ', ' + event.startingHoleDescription + ', ' +
            event.numberOfHolesDescription}}</ion-text>
        </ion-col>
      </ion-row>

      <!-- event date -->
      <ion-row style="padding: 10px 5px 0px 10px;">
        <ion-col class="eventInfo ion-no-padding iconTextSingleLine">
          <ion-icon name="calendar" style="font-size: larger; color: var(--ion-color-black-d);"></ion-icon>
          <ion-text>Scheduled for {{event.eventDt.toDate() | amUtc | amLocal | amDateFormat:'dddd, MMM Do'}}
          </ion-text>
        </ion-col>
      </ion-row>

      <!-- event first tee time or player tee time -->
      <ion-row style="padding: 10px 5px 0px 10px;">

        <ion-col class="eventInfo ion-no-padding iconTextSingleLine">

          <ion-icon name="time" style="font-size: larger; color: var(--ion-color-black-d);"></ion-icon>

          <ng-container
            *ngIf="event.players.memberPlayer && event.players.memberPlayer.teeTime.toDate() < AppConfig.WAIT_LIST_TEE_TIME">
            <ion-text>Your tee time is at
              {{event.players.memberPlayer.teeTime.toDate() | amUtc | amLocal | amDateFormat: 'h:mm A'}}
            </ion-text>
          </ng-container>

          <ng-container
            *ngIf="event.players.memberPlayer && event.players.memberPlayer.teeTime.toDate() >= AppConfig.WAIT_LIST_TEE_TIME">
            <ion-text>Your tee time has not yet been posted</ion-text>
          </ng-container>

          <ng-container *ngIf="!event.players.memberPlayer">
            <ion-text>Tee times starting at {{event.eventDt.toDate() | amUtc | amLocal | amDateFormat: 'h:mmA'}}
            </ion-text>
          </ng-container>

        </ion-col>

      </ion-row>

      <!-- guests -->
      <ion-row style="padding: 10px 5px 0px 10px;"
        *ngIf="event.players.memberPlayer && event.players.memberPlayer.guests.all.length > 0">

        <ion-col class="eventInfo ion-no-padding iconTextSingleLine">

          <ion-icon name="people" style="font-size: larger; color: var(--ion-color-black-d);"></ion-icon>

          <ion-text>You've invited
            {{event.players.memberPlayer.guests.all.length}} guest<span
              *ngIf="event.players.memberPlayer.guests.all.length !== 1">s</span></ion-text>

        </ion-col>

      </ion-row>

    </ion-grid>

  </ion-card-content>

  <!-- footer -->
  <ion-grid class="ion-no-padding" class="buttonBar">

    <ion-row [id]="'buttonRow' + event.id" class="ion-nowrap ion-no-padding">

      <!-- show event details button -->
      <ion-col class="ion-text-center ion-no-padding" [size]="numberOfButtons"
        *ngIf="!event.isMemberAdmin(accountService.member)">

        <ion-button shape="round" color="light" (click)="viewEvent($event, event)" mode="ios">
          <ion-icon name="information-circle" slot="start" color="secondary"></ion-icon>Details
        </ion-button>

      </ion-col>

      <!-- show edit event button -->
      <ion-col class="ion-text-center ion-no-padding" [size]="numberOfButtons"
        *ngIf="event.isMemberAdmin(accountService.member)">

        <ion-button shape="round" color="light" (click)="updateEvent($event, event)" mode="ios">
          <ion-icon name="options" slot="start" color="primary"></ion-icon>Edit
        </ion-button>

      </ion-col>

      <!-- show join button if... -->
      <!-- event belongs to a event group -->
      <!-- and logged in member is not a player of event -->
      <ion-col class="ion-text-center ion-no-padding" [size]="numberOfButtons"
        *ngIf="event.type === EventType.GroupEvent && !event.players.memberPlayer" (click)="joinEvent($event, event)">

        <ion-button shape="round" color="light" mode="ios">
          <ion-icon name="enter" slot="start" color="primary"></ion-icon>Join
        </ion-button>

      </ion-col>

      <!-- show leave button if... -->
      <!-- logged in member has already joined the event -->
      <!-- and event belongs to a event group -->
      <!-- or personal event and not event creator -->
      <ion-col class="ion-text-center ion-no-padding" [size]="numberOfButtons"
        *ngIf="event.players.memberPlayer && (event.type === EventType.GroupEvent || (event.type === EventType.Personal && !event.isMemberAdmin(event.players.memberPlayer.member)))"
        (click)="leaveEventConfirm($event, event.players.memberPlayer)">

        <ion-button shape="round" color="light" mode="ios">
          <ion-icon name="exit" slot="start" color="primary"></ion-icon>Leave
        </ion-button>

      </ion-col>

      <!-- show scoring button -->
      <ion-col class="ion-text-center ion-no-padding" [size]="numberOfButtons" (click)="eventScoring($event, event)"
        *ngIf="event.players.memberPlayer">

        <ion-button shape="round" color="light" mode="ios" style="--padding-start: 20px; --padding-end: 20px;">
          <ion-icon name="play" slot="start" color="primary"></ion-icon>Scoring
        </ion-button>

      </ion-col>

    </ion-row>

  </ion-grid>

</ion-card>