<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>
    </ion-buttons>
    <ion-title>Member Search</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-searchbar #searchBar (ionChange)="searchCriteriaChange($event)" (ionClear)="searchCriteriaClear()"
    show-cancel-button="focus" placeholder="Search on email or name" inputmode="search"></ion-searchbar>

  <ion-list class="itemLines">

    <ion-item *ngFor="let member of searchMembers" (click)="selectMember(member)">

      <avatar [member]="member" slot="start"></avatar>

      <ion-label>
        <h2>{{member.firstName}} {{member.lastName}}</h2>
        <h4>{{member.email}}</h4>
      </ion-label>

    </ion-item>

  </ion-list>

  <ion-note class="instructional" *ngIf="firstSearch && searchBar.value !== '' && searchMembers.length === 0">
    No members were found
  </ion-note>

</ion-content>