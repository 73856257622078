import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NavParams } from "@ionic/angular";
import { AppFunction } from "src/app/app.function";
import { DomSanitizer } from "@angular/platform-browser";
import { AppConfig } from "src/app/app.config";

@Component({
  selector: "whats-new",
  templateUrl: "./whats-new.page.html",
  styleUrls: ["./whats-new.page.scss"],
  encapsulation: ViewEncapsulation.None
})
export class WhatsNewPage implements OnInit {
  title: string = '';
  subject;
  private _appFunction: AppFunction;
  faTimes = AppConfig.faTimes;
  faCircle = AppConfig.faCircle;
  faCalendar = AppConfig.faCalendar;

  constructor(private navParams: NavParams, private dom: DomSanitizer) {
    this._appFunction = AppFunction.serviceLocator.get(AppFunction);
  }

  ngOnInit() {
    const rawSubject = this.navParams.get('subject');
    this.subject = this.dom.bypassSecurityTrustHtml(rawSubject);
    this.title = this.navParams.get('title');
  }

  done() {
    this._appFunction.modalCtrl.dismiss();
  }
}
