import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'network',
  templateUrl: './network.page.html',
  styleUrls: ['./network.page.scss'],
})
export class NetworkPage implements OnInit {

  constructor() {

    //console.log('network.ts constructor');

  }

  ngOnInit() {

    console.log('network.ts ngOnInit');

  }

}
