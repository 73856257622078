<ion-header class="profile-header ion-no-border">
  <ion-toolbar>
    <ion-title>My Profile</ion-title>
    <ion-buttons slot="secondary" *ngIf="profileForm.dirty">
      <ion-button (click)="cancel()">
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="primary">
      <ion-button *ngIf="profileForm.dirty" fill="solid" shape="round" (click)="done()">Save</ion-button>
      <ion-button *ngIf="!profileForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <!-- validation hero -->
  <validation-hero [display]="isFormSubmitted && profileForm.invalid"></validation-hero>
</ion-header>

<ion-content class="profile-content">

  <form [formGroup]="profileForm">

    <ion-list class="itemLines">

      <!-- first name -->
      <ion-item [class.invalid]="!profileForm.controls.firstName.valid && profileForm.controls.firstName.touched">
        <ion-label position="stacked">First Name</ion-label>
        <ion-input type="text" formControlName="firstName"></ion-input>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="profileForm.controls.firstName.hasError('required') && !profileForm.controls.firstName.valid && profileForm.controls.firstName.touched">
        <ion-label>Please enter a first name.</ion-label>
      </ion-item>

      <!-- last name -->
      <ion-item [class.invalid]="!profileForm.controls.lastName.valid && profileForm.controls.lastName.touched"
        lines="inset">
        <ion-label position="stacked">Last Name</ion-label>
        <ion-input type="text" formControlName="lastName"></ion-input>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="profileForm.controls.lastName.hasError('required') && !profileForm.controls.lastName.valid && profileForm.controls.lastName.touched">
        <ion-label>Please enter a last name.</ion-label>
      </ion-item>

      <!-- email -->
      <ion-item [class.invalid]="!profileForm.controls.email.valid && profileForm.controls.email.touched">
        <ion-label position="stacked">Email</ion-label>
        <ion-input type="email" formControlName="email" placeholder="email" autocapitalize="none" autocomplete="email"
          inputmode="email"></ion-input>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="profileForm.controls.email.hasError('required') && !profileForm.controls.email.valid && profileForm.controls.email.touched">
        <ion-label>Please enter an email address.</ion-label>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="profileForm.controls.email.hasError('pattern') && !profileForm.controls.email.valid && profileForm.controls.email.touched">
        <ion-label>Please enter a valid email address.</ion-label>
      </ion-item>

      <!-- password, only show if email is different -->
      <ion-item [class.invalid]="!profileForm.controls.password.valid && profileForm.controls.password.touched"
        *ngIf="isEmailNew">
        <ion-label position="stacked">Password</ion-label>
        <ion-input [type]="passwordType" formControlName="password" autocapitalize="none">
        </ion-input>
        <ion-button fill="clear" slot="end" (click)="showPassword()" style="height: -webkit-fill-available;">
          <ion-icon slot="icon-only" [name]="showHide"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="profileForm.controls.password.hasError('required') && !profileForm.controls.password.valid && profileForm.controls.password.touched">
        <ion-label>Please enter your password to change your email.</ion-label>
      </ion-item>

      <!-- GHIN -->
      <!-- <ion-item>

        <ion-label>

          <ion-text class="labelPositionStacked">Private Account</ion-text>

          <ion-input type="string" inputmode="numeric" maxlength="7" numbersOnly formControlName="ghinNumber" (ionChange)="updateGhin()">
          </ion-input>

          <ion-label class="help">
            <ion-text>Add a GHIN for automatic updates.</ion-text>
            <ion-text (click)="helpService.showFeatureHelp('ghinNumber')">Learn more.</ion-text>
          </ion-label>

        </ion-label>

      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="profileForm.controls.ghinNumber.hasError('minlength') && !profileForm.controls.ghinNumber.valid && profileForm.controls.ghinNumber.touched">
        <ion-label>The GHIN must be seven (7) digits in length.</ion-label>
      </ion-item>
      <ion-item lines="none" class="validation" *ngIf="accountService.member.getHandicapIndexError">
        <ion-label class="ion-text-wrap">{{accountService.member.getHandicapIndexError}}</ion-label>
      </ion-item> -->

      <!-- Handicap Index -->
      <ion-item>
        <ion-label position="stacked">Handicap Index<ng-container *ngIf="accountService.member.handicapIndexDt">
            &nbsp;(last updated:
            {{accountService.member.handicapIndexDt.toDate() | amTimeAgo}})
          </ng-container>
        </ion-label>
        <ion-input type="number" formControlName="handicapIndex"></ion-input>
      </ion-item>

      <!-- private account -->
      <ion-item>

        <ion-label>

          <ion-text class="labelPositionStacked">Private Account</ion-text>

          <ion-label class="toggle">
            <h2 *ngIf="profileForm.controls.privateAccount.value">Your account is private</h2>
            <h2 *ngIf="!profileForm.controls.privateAccount.value">Your account is public</h2>
            <ion-toggle color="secondary" formControlName="privateAccount"></ion-toggle>
          </ion-label>

          <ion-label class="help">
            <ion-text>Control who can follow you.</ion-text>
            <ion-text (click)="helpService.showFeatureHelp('profilePrivate')">Learn more.</ion-text>
          </ion-label>

        </ion-label>

      </ion-item>

    </ion-list>

  </form>

  <ion-fab #fab vertical="bottom" horizontal="end" slot="fixed" haptic [fabClose]="fab">
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-button shape="round" (click)="deleteProfileConfirmation(); fab.close();">
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label>Delete My Profile</ion-label>
      </ion-button>
    </ion-fab-list>
  </ion-fab>

</ion-content>