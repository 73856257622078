<ion-header class="whats-new-header ion-no-padding ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="primary">
      <ion-button slot="icon-only" (click)="done()">
        <fa-icon [icon]="faTimes" style="color: var(--ion-color-white-c);">
        </fa-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <ion-toolbar class="test">
    <h1 style="font-weight: 700; color: var(--ion-color-white); margin: 0;">Welcome
      to<br />
      <span style="color: var(--ion-color-primary);">Double Ace</span>&nbsp;<span
        style="color: var(--ion-color-secondary);">Golf</span>
    </h1>
  </ion-toolbar>

</ion-header>

<ion-content class="whats-new-content ion-padding">
  <div [innerHTML]="subject"></div>
</ion-content>

<ion-footer class="whats-new-footer">

  <ion-button class="continue" expand="block" fill="solid" shape="round" (click)="done()">
    Continue
  </ion-button>

</ion-footer>