import { Directive, HostListener, Input } from '@angular/core';
import { AppFunction, HelpService } from 'src/app/app.function';
import { RuleHelperPage } from 'src/app/pages/rule-helper/rule-helper.page';
import { SupportPage } from 'src/app/pages/support/support.page';

@Directive({
  selector: '[screenKey]'
})
export class ScreenHelp {

  @Input('screenKey') screenKey: string;

  constructor(
    public appFunction: AppFunction,
    public helpService: HelpService) {
  }

  @HostListener('click', ['$event']) onClick(click) {

    //console.log('screen-help.directive.ts click event', this.screenKey);

    click.stopPropagation();

    const buttons = [];
    const helpTopic = this.helpService.getTopicByKey(this.screenKey);

    //determine what options to show
    if (helpTopic?.help?.title) {

      buttons.push({
        text: 'How to use the ' + helpTopic.name + ' screen',
        handler: () => {
          this.gotoHelp(helpTopic);
        }
      });

    }

    if (helpTopic?.whatsNew?.version) {

      buttons.push({
        text: "What's new with the " + helpTopic.name + " screen",
        icon: 'megaphone-outline',
        handler: () => {
          this.gotoWhatsNew(helpTopic);
        }
      });

    }

    buttons.push({
      text: 'I need help or have feedback',
      icon: 'help-circle-outline',
      handler: () => {
        this.gotoSupport();
      }
    });

    buttons.push({
      text: 'AI Rules Advisor',
      icon: 'chatbubbles-outline',
      handler: () => {
        this.showRulesHelper();
      }
    });

    buttons.push({
      text: 'Cancel',
      role: 'cancel',
      handler: () => {

      }
    });

    this.appFunction
      .actionCtrl
      .create({
        header: 'How can we help?',
        //message: 'Location Services for Double Ace Golf have been turned off. Would you like to turn them on?',
        buttons: buttons
      })
      .then((alert) => {
        alert.present();
      });

  }

  async gotoSupport() {

    this.appFunction
      .modalCtrl
      .create({
        component: SupportPage,
        presentingElement: await this.appFunction.routerOutlet()
      })
      .then((modal) => {
        modal.present();
      })
      .catch((err) => {
        console.log('more.page.ts modal create error', err);
      });

  }

  gotoHelp(helpTopic) {

    this.helpService.showScreenHelp(helpTopic);

  }

  gotoWhatsNew(helpTopic) {

    this.helpService.showWhatsNew(helpTopic);

  }

  async showRulesHelper() {
    this.appFunction.modalCtrl
      .create({
        component: RuleHelperPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: "custom-modal", //for md
        backdropDismiss: false,
        canDismiss: true,
        componentProps: {},
      })
      .then((modal) => {
        //pass in modal instance
        modal.componentProps.modal = modal;

        modal.present().catch((err) => {
          console.log("home.ts viewEvent modal present error", err);
        });
      })
      .catch((err) => {
        console.log("home.ts viewEvent modal create error", err);
      });
  }

}
