import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AccountService } from '../../app.account';
import { AppGroupTrip, AppGroupTripMember, GroupTripActionCd, GroupTripSegment, memberGroupPreferences, MemberGroupRole, TripAttendanceStatus } from '../../app.group';
import { AppConfig } from '../../app.config';
import { AppFunction, DeepLinkService, HelpService, scorecardEnterFromRightAnimation, scorecardLeaveToRightAnimation } from '../../app.function'
import { AppPost } from 'src/app/app.social';
import { MediaService } from '../../app.media';
import { Subscription } from 'rxjs';
import { PostNewPage } from '../post-new/post-new.page';
import * as moment from 'moment';
import { AppEventPlayer } from 'src/app/app.event';
import { AppMatch } from 'src/app/app.match';
import { GroupTripScorecardPage } from '../group-trip-scorecard/group-trip-scorecard.page';

@Component({
  selector: AppConfig.PAGE.GroupTripView,
  templateUrl: './group-trip-view.page.html',
  styleUrls: ['./group-trip-view.page.scss']
})
export class GroupTripViewPage implements OnInit {

  groupViewSegment: string = GroupTripSegment.Information;
  group: AppGroupTrip;
  groupMember: AppGroupTripMember;
  memberGroupPreferences: memberGroupPreferences;
  emailPreference = AppConfig.GROUP_PREFERENCES.EVENT_EMAIL_PREFERENCE.values;
  notificationPreference = AppConfig.GROUP_PREFERENCES.EVENT_NOTIFICATION_PREFERENCE.values;
  postPreference = AppConfig.GROUP_PREFERENCES.POST_NOTIFICATION_PREFERENCE.values;
  roleTypes: typeof MemberGroupRole = MemberGroupRole;
  ActionCd: typeof GroupTripActionCd = GroupTripActionCd;
  noMorePosts: boolean = false;
  filteredMembersCriteria: string = '';
  private _posts: AppPost[];
  private _futurePostSubscription: Subscription;
  TripAttendanceStatus: typeof TripAttendanceStatus = TripAttendanceStatus;
  actionCd: GroupTripActionCd;
  screenKey: string = AppConfig.PAGE.GroupTripView;
  GroupTripSegment: typeof GroupTripSegment = GroupTripSegment;

  constructor(
    public accountService: AccountService,
    public appFunction: AppFunction,
    public navParams: NavParams,
    public mediaService: MediaService,
    public helpService: HelpService,
    public deepLinkService: DeepLinkService) {

    this.helpService.screenWhatsNew(AppConfig.PAGE.GroupTripView);

  }

  ngOnInit() {


    //get passed in group
    this.group = <AppGroupTrip>this.navParams.get('group');

    //get group member for logged in member
    this.groupMember = <AppGroupTripMember>this.group.getGroupMember(this.accountService.member);

    //if member already in/out then don't show an action card, otherwise set to actionCd, otherwise check to see if member is invited
    //this.actionCd = [TripAttendanceStatus.In, TripAttendanceStatus.Out].includes(this.groupMember.status) ? undefined : <GroupTripActionCd>this.navParams.get('actionCd') || this.groupMember.status === TripAttendanceStatus.Invited ? GroupTripActionCd.Invited : undefined;

    this.actionCd = this.appFunction.isNumeric(this.navParams.get('actionCd')) ? <GroupTripActionCd>this.navParams.get('actionCd') : [TripAttendanceStatus.In, TripAttendanceStatus.Out].includes(this.groupMember.status) ? undefined : this.groupMember.status === TripAttendanceStatus.Invited ? GroupTripActionCd.Invited : undefined;

    //if there's an event today then make the event tab the default
    if (this.group.events.all.some((event) => {
      return moment().isSame(moment(event.eventDt.toDate()), 'day');
    })) {
      this.groupViewSegment = GroupTripSegment.Events;
    }

    //get member group preferences
    this.accountService.member
      .getPreference(this.group.id)
      .then((preferences) => {
        this.memberGroupPreferences = new memberGroupPreferences(this.group.id, preferences);
      });

  }

  ionViewDidLeave() {
    if (this._futurePostSubscription) {
      this._futurePostSubscription.unsubscribe();
    }
  }

  private outTrip(group: AppGroupTrip) {

    this.appFunction
      .loadingCtrl
      .create({ message: 'Leaving trip...' })
      .then((loading) => {

        loading.present();

        group
          .removeMemberFromGroup(this.accountService.member)
          .then(() => {

            group
              .save()
              .then(() => {
                loading.dismiss();
              })

          });

      });

  }

  outTripConfirm(group: AppGroupTrip) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Leave Group',
            role: 'destructive',
            handler: () => {
              this.outTrip(group);
            }
          },
          {
            text: 'No',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  changeTripStatus(status: TripAttendanceStatus) {

    //call setTripStatus so that proper in/out push notificayions get sent
    this.group.setTripStatus(status, this.groupMember);
    this.group
      .save()
      .then(() => {

        //what action card to show
        this.actionCd = (status === TripAttendanceStatus.In ? GroupTripActionCd.In : GroupTripActionCd.Out);

        //if status is "in" then add to existing events
        if (status === TripAttendanceStatus.In) {
          this.group.addMemberToEvents(this.groupMember);
        } else {
          this.group.removeMemberFromEvents(this.groupMember);
        }

      });

  }

  inTrip(group: AppGroupTrip) {

    this.appFunction
      .loadingCtrl
      .create({ message: 'Joining trip...' })
      .then((loading) => {

        loading.present();

        group
          .addMemberToGroup(this.accountService.member, MemberGroupRole.Member)
          .then(() => {

            group
              .save()
              .then(() => {
                loading.dismiss();
              });

          });

      });

  }

  private updateMemberPreference(): Promise<void> {

    return new Promise<void>((resolve) => {

      //save preferences if is group member
      if (this.groupMember.status === TripAttendanceStatus.In) {

        //update member preferences for group
        this.accountService
          .member
          .updatePreference(this.memberGroupPreferences)
          .then(() => {
            resolve();
          });

      } else { //or remove 

        //remove member preferences for group
        this.accountService
          .member
          .removePreference(this.group.id)
          .then(() => {
            resolve();
          });

      }

    });

  }

  updateEmailPreferences(value) {
    this.memberGroupPreferences.e = value;
  }

  updateNotificationPreferences(value) {
    this.memberGroupPreferences.n = value;
  }

  updatePostNotificationPreferences(value) {
    this.memberGroupPreferences.p = value;
  }

  /* get filteredGroupEventMembers(): AppGroupTripMember[] {

    //if there is search criteria then filter
    if (this.filteredMembersCriteria.length > 0) {

      return <AppGroupTripMember[]>this.group.members.filter((groupMember) => {

        //cast to get member object
        const member: AppMember = (<AppGroupTripMember>groupMember).member;

        //combine first, last and email together lower case
        const searchIn: string = member.firstName.toLowerCase() + member.lastName.toLowerCase() + member.email.toLowerCase();

        //perform search
        return (searchIn.indexOf(this.filteredMembersCriteria.toLowerCase().replace(' ', '')) >= 0);

      });

    } else {
      return <AppGroupTripMember[]>this.group.members;
    }

  } */

  searchCriteriaChange(event: any) {

    //only perform a search if the user has entered criteria
    if (event.target.value && event.target.value.length > 0) {
      this.filteredMembersCriteria = event.target.value;
    } else {
      this.filteredMembersCriteria = '';
    }

  }

  searchCriteriaClear() {
    this.filteredMembersCriteria = '';
  }

  getPosts(event: any = undefined) {

    //if first time get posts
    if (!this._posts) {

      this.appFunction
        .loadingCtrl
        .create({ message: 'Getting posts...' })
        .then((loading) => {

          loading.present();

          //get this groups posts
          this.group
            .social
            .getPosts()
            .then((posts) => {
              this._posts = posts;
              loading.dismiss();
            });

          //listen for new posts
          this._futurePostSubscription = this.group
            .social
            .getFuturePosts()
            .subscribe((futurePost) => {

              //push new post on the front of the array
              this._posts.unshift(futurePost);

            });

        });

    } else if (event) { //only get next set of posts on infinite scroll

      //get next batch of (older) posts
      this.group
        .social
        .getNextPosts()
        .then((nextPosts) => {

          nextPosts
            .forEach((nextPost) => {
              this._posts.push(nextPost);
            });

          event.target.complete();

          //when no more posts to display then stop trying to fetch
          if (nextPosts.length === 0) {
            event.target.disabled = true;
            this.noMorePosts = true;
          }

        });
    }

  }

  get posts(): AppPost[] {

    if (Array.isArray(this._posts)) {

      return this._posts.filter((post) => {
        return post.exists;
      });

    } else {
      return [];
    }

  }

  groupSegmentChange(event: any) {

    if (event.detail.value === 'posts') {
      this.getPosts();
    }

  }

  async newPost() {

    this.appFunction
      .modalCtrl
      .create({
        component: PostNewPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        keyboardClose: false,
        componentProps: {
          poster: this.group
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('group-trip-view.page.ts newPost modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-trip-view.page.ts newPost modal create error', err);
      });

  }

  get isMemberOnWaitlist(): boolean {
    return (<AppGroupTrip>this.group).invited
      .membersOnWaitList()
      .some((groupMember) => {
        return groupMember.memberId === this.accountService.member.id;
      });
  }

  openScorecard = (players: AppEventPlayer[], match: AppMatch = undefined) => {

    this.appFunction
      .modalCtrl
      .create({
        component: GroupTripScorecardPage,
        enterAnimation: scorecardEnterFromRightAnimation,
        leaveAnimation: scorecardLeaveToRightAnimation,
        cssClass: 'rotate-tripscorecard-landscape',
        showBackdrop: false,
        componentProps: {
          match: match
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('group-trip-detail.page.ts openScorecard modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts openScorecard modal create error', err);
      });

  }

  done() {

    this.updateMemberPreference()
      .then(() => {
        this.appFunction.modalCtrl.dismiss();
      });

  }

}
