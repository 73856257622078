<ion-header class="ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" (click)="newHelpTopic()">
        <ion-icon name="add-circle"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">

      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>

    </ion-buttons>

    <ion-title>Help Topics</ion-title>

  </ion-toolbar>

</ion-header>

<ion-content>

  <ion-list class="itemLines">

    <ion-item *ngFor="let helpTopic of helpTopics" detail="true" (click)="helpTopicDetail(helpTopic)">

      <ion-label>
        <h2>{{helpTopic.name}}</h2>
        <p>{{helpTopic.key}}</p>
      </ion-label>

    </ion-item>

  </ion-list>

</ion-content>