<ion-header class="support-header ion-no-border">

  <ion-toolbar>

    <ion-title>Support</ion-title>

    <ion-buttons slot="secondary" *ngIf="supportForm.dirty">
      <ion-button (click)="cancel()">
        Cancel
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button *ngIf="supportForm.dirty" fill="solid" shape="round" (click)="done()">Send</ion-button>
      <ion-button *ngIf="!supportForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <!-- validation hero -->
  <validation-hero [display]="isFormSubmitted && supportForm.invalid"></validation-hero>

</ion-header>

<ion-content class="support-content">

  <form [formGroup]="supportForm">

    <ion-list class="itemLines">

      <ion-item (click)="selectSubject()" detail="true">
        <ion-label>
          <ion-text class="labelPositionStacked">Subject</ion-text>
          <h2 class="readOnly">{{subject[subjectId].description}}</h2>
        </ion-label>
      </ion-item>

      <ion-item [class.invalid]="!supportForm.controls.message.valid && supportForm.controls.message.touched"
        lines="inset">
        <ion-label position="stacked">Message</ion-label>
        <ion-textarea rows="10" formControlName="message" autocomplete="on" autocorrect="on" auto-grow="true"></ion-textarea>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="supportForm.controls.message.hasError('required') && !supportForm.controls.message.valid && supportForm.controls.message.touched">
        <ion-label>Please enter a message.</ion-label>
      </ion-item>

    </ion-list>

  </form>

</ion-content>