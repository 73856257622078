import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AccountService, AppMember } from '../../app.account';
import { AppGroupEvent, AppGroupEventMember, GroupEventActionCd, memberGroupPreferences, MemberGroupRole } from '../../app.group';
import { AppConfig } from '../../app.config';
import { AppFunction, HelpService } from '../../app.function'
import { AppPost } from 'src/app/app.social';
import { MediaService } from '../../app.media';
import { Subscription } from 'rxjs';
import { PostNewPage } from '../post-new/post-new.page';

export enum GroupTripSegment {
  Information = 'information',
  Members = 'members',
  Posts = 'posts'
}

@Component({
  selector: AppConfig.PAGE.GroupEventView,
  templateUrl: './group-event-view.page.html',
  styleUrls: ['./group-event-view.page.scss']
})
export class GroupEventViewPage implements OnInit {

  groupViewSegment: string = GroupTripSegment.Information;
  group: AppGroupEvent;
  isMember: boolean = false;
  memberGroupPreferences: memberGroupPreferences;
  emailPreference = AppConfig.GROUP_PREFERENCES.EVENT_EMAIL_PREFERENCE.values;
  notificationPreference = AppConfig.GROUP_PREFERENCES.EVENT_NOTIFICATION_PREFERENCE.values;
  postPreference = AppConfig.GROUP_PREFERENCES.POST_NOTIFICATION_PREFERENCE.values;
  roleTypes: typeof MemberGroupRole = MemberGroupRole;
  noMorePosts: boolean = false;
  filteredMembersCriteria: string = '';
  private _posts: AppPost[];
  private _futurePostSubscription: Subscription;
  actionCd: GroupEventActionCd;
  ActionCd: typeof GroupEventActionCd = GroupEventActionCd;
  screenKey: string = AppConfig.PAGE.GroupEventView;
  ownerMember: AppMember;
  GroupTripSegment: typeof GroupTripSegment = GroupTripSegment;

  constructor(
    public accountService: AccountService,
    public appFunction: AppFunction,
    public navParams: NavParams,
    public mediaService: MediaService,
    public helpService: HelpService) {

    helpService.screenWhatsNew(AppConfig.PAGE.GroupEventView);

  }

  ngOnInit() {

    this.group = <AppGroupEvent>this.navParams.get('group');

    //is the logged in user a member of this group
    this.isMember = this.group.isMember(this.accountService.member);

    //see if actionCd was passed in
    this.actionCd = <GroupEventActionCd>this.navParams.get('actionCd') ? <GroupEventActionCd>this.navParams.get('actionCd') : !this.isMember ? GroupEventActionCd.AskToJoin : undefined;

    //get owner member
    this.accountService
      .getMember(this.group.ownerMemberId)
      .toPromise()
      .then((member) => {
        this.ownerMember = member;
      });

    //get member group preferences
    this.accountService.member
      .getPreference(this.group.id)
      .then((preferences) => {
        this.memberGroupPreferences = new memberGroupPreferences(this.group.id, preferences);
      });

  }

  ionViewDidLeave() {
    if (this._futurePostSubscription) {
      this._futurePostSubscription.unsubscribe();
    }
  }

  leaveGroup(group: AppGroupEvent) {

    //console.log('group-event-view.page.ts leaveGroup');

    this.appFunction
      .loadingCtrl
      .create({ message: 'Leaving group...' })
      .then((loading) => {

        loading.present();

        group
          .removeMemberFromGroup(this.accountService.member)
          .then(() => {

            group
              .save()
              .then(() => {
                this.isMember = false;
                this.actionCd = GroupEventActionCd.Leave;
                loading.dismiss();
              })

          });

      });

  }

  leaveGroupConfirm(group: AppGroupEvent) {

    //console.log('group-event-view.page.ts leaveGroupConfirm');

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Leave Group',
            role: 'destructive',
            handler: () => {
              this.leaveGroup(group);
            }
          },
          {
            text: 'No',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  joinGroup(group: AppGroupEvent) {

    this.appFunction
      .loadingCtrl
      .create({ message: 'Joining group...' })
      .then((loading) => {

        loading.present();

        group
          .addMemberToGroup(this.accountService.member, MemberGroupRole.Member)
          .then(() => {

            group
              .save()
              .then(() => {
                this.isMember = true;
                this.actionCd = GroupEventActionCd.Joined;
                loading.dismiss();
              });

          });

      });

  }

  private updateMemberPreference(): Promise<void> {

    return new Promise<void>((resolve) => {

      //save preferences if is group member
      if (this.isMember) {

        //update member preferences for group
        this.accountService
          .member
          .updatePreference(this.memberGroupPreferences)
          .then(() => {
            resolve();
          });

      } else { //or remove 

        //remove member preferences for group
        this.accountService
          .member
          .removePreference(this.group.id)
          .then(() => {
            resolve();
          });

      }

    });

  }

  updateEmailPreferences(value) {
    this.memberGroupPreferences.e = value;
  }

  updateNotificationPreferences(value) {
    this.memberGroupPreferences.n = value;
  }

  updatePostNotificationPreferences(value) {
    this.memberGroupPreferences.p = value;
  }

  get filteredGroupEventMembers(): AppGroupEventMember[] {

    //if there is search criteria then filter
    if (this.filteredMembersCriteria.length > 0) {

      return <AppGroupEventMember[]>this.group.members.filter((groupMember) => {

        //cast to get member object
        const member: AppMember = (<AppGroupEventMember>groupMember).member;

        //combine first, last and email together lower case
        const searchIn: string = member.firstName.toLowerCase() + member.lastName.toLowerCase() + member.email.toLowerCase();

        //perform search
        return (searchIn.indexOf(this.filteredMembersCriteria.toLowerCase().replace(' ', '')) >= 0);

      });

    } else {
      return <AppGroupEventMember[]>this.group.members;
    }

  }

  searchCriteriaChange(event: any) {

    //console.log('group-trip-detail.page.ts searchCriteriaChange');

    //only perform a search if the user has entered criteria
    if (event.target.value && event.target.value.length > 0) {
      this.filteredMembersCriteria = event.target.value;
    } else {
      this.filteredMembersCriteria = '';
    }

  }

  searchCriteriaClear() {
    this.filteredMembersCriteria = '';
  }

  getPosts(event: any = undefined) {

    //if first time get posts
    if (!this._posts) {

      this.appFunction
        .loadingCtrl
        .create({ message: 'Getting posts...' })
        .then((loading) => {

          loading.present();

          //get this groups posts
          this.group
            .social
            .getPosts()
            .then((posts) => {
              this._posts = posts;
              loading.dismiss();
            });

          //listen for new posts
          this._futurePostSubscription = this.group
            .social
            .getFuturePosts()
            .subscribe((futurePost) => {

              //push new post on the front of the array
              this._posts.unshift(futurePost);

            });

        });

    } else if (event) { //only get next set of posts on infinite scroll

      //get next batch of (older) posts
      this.group
        .social
        .getNextPosts()
        .then((nextPosts) => {

          nextPosts
            .forEach((nextPost) => {
              this._posts.push(nextPost);
            });

          event.target.complete();

          //when no more posts to display then stop trying to fetch
          if (nextPosts.length === 0) {
            event.target.disabled = true;
            this.noMorePosts = true;
          }

        });
    }

  }

  get posts(): AppPost[] {

    if (Array.isArray(this._posts)) {

      return this._posts.filter((post) => {
        return post.exists;
      });

    } else {
      return [];
    }

  }

  groupSegmentChange(event: any) {

    if (event.detail.value === 'posts') {
      this.getPosts();
    }

  }

  async newPost() {

    this.appFunction
      .modalCtrl
      .create({
        component: PostNewPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        keyboardClose: false,
        componentProps: {
          poster: this.group
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('group-event-view.page.ts newPost modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-event-view.page.ts newPost modal create error', err);
      });

  }

  done() {

    this.updateMemberPreference()
      .then(() => {
        this.appFunction.modalCtrl.dismiss();
      });

  }

}
