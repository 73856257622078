import { Component, OnInit, ViewChild } from '@angular/core';
import { IonList, NavParams } from '@ionic/angular';
import { AppFunction, IonColor, ColorService } from '../../app.function';

@Component({
  selector: 'color-picker-popover',
  templateUrl: './color-picker-popover.page.html',
  styleUrls: ['./color-picker-popover.page.scss'],
})
export class ColorPickerPopoverPage implements OnInit {

  @ViewChild('theList', { static: true }) list: IonList;

  private currentColor: IonColor;

  constructor(
    public colorService: ColorService,
    public appFunction: AppFunction,
    private navParams: NavParams) {

  }

  ngOnInit() {
    this.currentColor = this.navParams.get('color');
  }

  closePopover() {

    this.appFunction
      .popoverCtrl
      .getTop()
      .then((p) => {
        p.dismiss(this.currentColor);
      });

  }

  selectColor(index: number) {

    this.currentColor = this.colorService.colorList[index];
    this.closePopover();

  }
}
