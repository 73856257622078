import { NgModule, Injectable } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate, Router } from '@angular/router';
import { AccountService } from './app.account';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingGuard implements CanActivate {

  constructor(
    public accountService: AccountService,
    public router: Router) {
  }

  canActivate(): Promise<boolean> {

    return new Promise((resolve) => {
      //console.log('app.routing.ts AppRoutingGuard canActivate', this.router, this.router.url);
      resolve(<boolean>(this.accountService.user !== undefined))
    });

  }
}

const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'main', loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule), canActivate: [AppRoutingGuard] },
  { path: 'introduction', loadChildren: () => import('./pages/introduction/introduction.module').then(m => m.IntroductionPageModule) },
  { path: 'login', loadChildren: () => import('./pages/login-email-password/login-email-password.module').then(m => m.LoginEmailPasswordPageModule) },
  { path: 'login-email-link', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'login-email', loadChildren: () => import('./pages/login-email/login-email.module').then(m => m.LoginEmailPageModule) },
  { path: 'register', loadChildren: () => import('./pages/register-password/register-password.module').then(m => m.RegisterPasswordPageModule) },
  { path: 'app-update', loadChildren: () => import('./pages/app-update/app-update.module').then(m => m.AppUpdatePageModule) },
  { path: 'forgot-password', loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'disabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
