import { NgModule } from '@angular/core';
import { DelayDragLiftDirective } from './delay-drag-lift/delay-drag-lift.directive';
import { VarDirective } from './var/var.directive';
import { HapticDirective } from './haptic/haptic.directive';
import { FABCloseDirective } from './fab-close/fab-close.directive';
import { NumbersOnly } from './numbers-only/numbers-only.directive';
import { ImgPreloadDirective } from './img-preload/img-preload.directive';
import { ScreenHelp } from './screen-help/screen-help.directive';
import { ScrollIntoView } from './scroll-into-view/scroll-into-view.directive';
import { PopoverDirective } from './popover/popover.directive';
import { SwiperDirective } from './swiper/swiper.directive';

@NgModule({
    declarations: [
        DelayDragLiftDirective,
        HapticDirective,
        ImgPreloadDirective,
        NumbersOnly,
        ScreenHelp,
        ScrollIntoView,
        PopoverDirective,
        FABCloseDirective,
        VarDirective
    ],
    imports: [SwiperDirective],
    exports: [
        DelayDragLiftDirective,
        HapticDirective,
        ImgPreloadDirective,
        NumbersOnly,
        ScreenHelp,
        ScrollIntoView,
        PopoverDirective,
        SwiperDirective,
        FABCloseDirective,
        VarDirective
    ]
})
export class DirectivesModule { }
