<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>
    </ion-buttons>
    <ion-title>Group Search</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-searchbar #searchBar (ionChange)="searchCriteriaChange($event)" (ionClear)="searchCriteriaClear()"
    show-cancel-button="focus" placeholder="Search on group name" inputmode="search"></ion-searchbar>

  <ion-list class="itemLines">

    <ion-item *ngFor="let group of searchGroups" (click)="selectGroup(group)">

      <!-- avatar -->
      <div class="avatar" slot="start">
        <ion-avatar class="avatar-small avatarImageOverlay">
          <img #imgAvatar [imgLoader]="group.avatar.URI" (click)="appFunction.showAvatarView($event, imgAvatar)"
            draggable="false">
        </ion-avatar>
      </div>

      <ion-label>
        <h2>{{group.name}}</h2>
      </ion-label>

    </ion-item>

  </ion-list>

  <ion-note class="instructional" *ngIf="firstSearch && searchBar.value !== '' && searchGroups.length === 0">
    No Groups were found
  </ion-note>

</ion-content>