import { Directive, HostListener } from '@angular/core';
import { AppFunction } from '../../app.function';

@Directive({
  selector: '[haptic]'
})
export class HapticDirective {

  constructor(public appFunction: AppFunction) {
  }

  @HostListener('click', ['$event']) onClick() {
    this.appFunction.buzz();
  }

}
