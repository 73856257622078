import { Component, Input } from '@angular/core';
import { ChatMessageI, ChatMessageType } from "../../app.function";

@Component({
  selector: 'chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss']
})
export class ChatBubbleComponent {

  @Input() chatMessage: ChatMessageI;
  public messageType = ChatMessageType;

  constructor() {

  }

}