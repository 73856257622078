import { Component, OnInit } from '@angular/core';
import { AppFunction, AppHelpTopic, HelpService } from 'src/app/app.function';
import { NavController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'help-detail',
  templateUrl: './help-detail.page.html',
  styleUrls: ['./help-detail.page.scss'],
})
export class HelpDetailPage implements OnInit {

  helpTopic: AppHelpTopic;
  helpForm: FormGroup;
  formEditMode: typeof AppConfig.EDIT_MODE = AppConfig.EDIT_MODE;
  editMode: any;
  helpDetailSegment: string = 'info';

  constructor(public builder: FormBuilder,
    public navParams: NavParams,
    public appFunction: AppFunction,
    public navCtrl: NavController,
    public helpService: HelpService) {

  }

  ngOnInit() {

    try {

      //build form group
      this.helpForm = this.builder.group({
        key: ['', Validators.required],
        name: ['', Validators.required],
        whatsNewVersion: [''],
        whatsNewSubject: [''],
        helpTitle: [''],
        helpSubject: ['']
      });

      //get passed in edit mode
      this.editMode = this.navParams.get('editMode');
      if (this.editMode === AppConfig.EDIT_MODE.update) {

        //get group
        this.helpTopic = <AppHelpTopic>this.navParams.get('helpTopic');

        //populate form 
        this.helpForm.controls['key'].setValue(this.helpTopic.key);
        this.helpForm.controls['name'].setValue(this.helpTopic.name);
        this.helpForm.controls['whatsNewVersion'].setValue(this.helpTopic.whatsNew.version);
        this.helpForm.controls['whatsNewSubject'].setValue(this.helpTopic.whatsNew.subject);
        this.helpForm.controls['helpTitle'].setValue(this.helpTopic.help.title);
        this.helpForm.controls['helpSubject'].setValue(this.helpTopic.help.subject);

      } else { //edit mode is 'new'

        //create group class...
        this.helpTopic = new AppHelpTopic();

        //...then setup
        this.helpTopic.initialize();

      }

    } catch (err) {
      console.log('help-detail.page.ts ngOnInit', err);
    }

  }

  showWhatsNew(subject: string) {
    this.helpService.showWhatsNew({ whatsNew: { subject: subject } });
  }

  done() {

    //console.log('help-detail.ts done');

    //save the form if dirty
    if (this.helpForm.dirty) {

      //save the form if valid 
      if (this.helpForm.valid) {

        //console.log('event-detail.ts done valid');

        this.appFunction
          .loadingCtrl
          .create({ message: 'Saving help topic...' })
          .then((loading) => {

            loading.present();

            //get form data
            this.helpTopic.key = this.helpForm.controls.key.value;
            this.helpTopic.name = this.helpForm.controls.name.value;
            this.helpTopic.whatsNew = {
              version: this.helpForm.controls.whatsNewVersion.value,
              subject: this.helpForm.controls.whatsNewSubject.value
            };
            this.helpTopic.help = {
              title: this.helpForm.controls.helpTitle.value,
              subject: this.helpForm.controls.helpSubject.value
            };

            //save/update event
            this.helpTopic
              .save()
              .then(() => {

                //close spinner
                loading.dismiss();

                //close page
                this.appFunction.modalCtrl.dismiss();

              });

          });

      } else {
        //show any untouched errors
        this.appFunction.setDirtyControlAsTouched(this.helpForm);
      }

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

  cancel() {

    //console.log('help-detail.page.ts cancel');

    //confirm that user wants to discard changes
    if (this.helpForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your help topic. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.appFunction.modalCtrl.dismiss();
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

}
