<form [formGroup]="matchForm">

  <ion-list>

    <ion-list-header>
      <ion-label>Format</ion-label>
    </ion-list-header>

    <!-- HANDICAP ALLOWANCE -->
    <ion-card class="matchConfigCard" [ngClass]="{ activeAccordian: accordianName === 'allowance' }">
      <ion-card-content>
        <ion-label position="stacked">Handicap Allowance</ion-label>
        <ion-accordion-group #accordionAllowance [value]="accordianName"
          (click)="accordianClick('allowance', accordionAllowance)">
          <ion-accordion value="allowance">
            <ion-item slot="header">
              <ion-label>
                <ion-grid>
                  <ion-row class="ion-align-items-center">
                    <ion-col size="auto">
                      <div class="circle-small">
                        {{handicapAllowanceOptions[matchForm.controls.handicapAllowance.value]?.descriptionHeader}}
                      </div>
                    </ion-col>
                    <ion-col>
                      {{handicapAllowanceOptions[matchForm.controls.handicapAllowance.value]?.description}}
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-label>
            </ion-item>
            <div slot="content" class="lineSeparatorTop-top">
              <ion-grid>
                <ion-row class="ion-align-items-center" *ngFor="let handicapAllowance of handicapAllowanceOptions"
                  (click)="selectHandicapAllowance(handicapAllowance.value); accordionAllowance.value = undefined;">
                  <ion-col size="auto" style="padding-left: 0px; padding-right: 0px;">
                    <div class="circle-small">
                      {{handicapAllowance.descriptionHeader}}
                    </div>
                  </ion-col>
                  <ion-col style="padding: 2px;" class="nameText">
                    {{handicapAllowance.description}}
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card-content>
    </ion-card>

    <!-- SCORE TYPE - net or gross -->
    <ion-card class="matchConfigCard" [ngClass]="{ activeAccordian: accordianName === 'scoreType' }">
      <ion-card-content>
        <ion-label position="stacked">Score Type</ion-label>
        <ion-accordion-group #accordionScoreType [value]="accordianName"
          (click)="accordianClick('scoreType', accordionScoreType)">
          <ion-accordion value="scoreType">
            <ion-item slot="header">
              <ion-label>
                <ion-grid>
                  <ion-row class="ion-align-items-center">
                    <ion-col size="auto">
                      <div class="circle-small">
                        {{netGrossTypeOptions[matchForm.controls.grossNetType.value]?.descriptionHeader}}
                      </div>
                    </ion-col>
                    <ion-col>
                      {{netGrossTypeOptions[matchForm.controls.grossNetType.value]?.description}}
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-label>
            </ion-item>
            <div slot="content" class="lineSeparatorTop">
              <ion-grid>
                <ion-row class="ion-align-items-center" *ngFor="let grossNetType of netGrossTypeOptions"
                  (click)="selectScoreType(grossNetType.value); accordionScoreType.value = undefined;">
                  <ion-col size="auto" style="padding-left: 0px; padding-right: 0px;">
                    <div class="circle-small">
                      {{grossNetType.descriptionHeader}}
                    </div>
                  </ion-col>
                  <ion-col style="padding: 2px;" class="nameText">
                    {{grossNetType.description}}
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card-content>
    </ion-card>

    <!-- HANDICAP TYPE - full or low ball -->
    <ion-card class="matchConfigCard" *ngIf="matchForm.controls.grossNetType.value===NetGrossType.Net"
      [ngClass]="{ activeAccordian: accordianName === 'handicapType' }">
      <ion-card-content>
        <ion-label position="stacked">Handicp Type</ion-label>
        <ion-accordion-group #accordionHandicapType [value]="accordianName"
          (click)="accordianClick('handicapType', accordionHandicapType)">
          <ion-accordion value="handicapType">
            <ion-item slot="header">
              <ion-label>
                <ion-grid>
                  <ion-row class="ion-align-items-center">
                    <ion-col size="auto">
                      <div class="circle-small">
                        {{handicapTypeOptions[matchForm.controls.handicapType.value]?.descriptionHeader}}
                      </div>
                    </ion-col>
                    <ion-col>
                      {{!appFunction.isNumeric(matchForm.controls.handicapType.value) ? 'Select the handicap type' :
                      handicapTypeOptions[matchForm.controls.handicapType.value]?.name}}
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-label>
            </ion-item>
            <div slot="content" class="lineSeparatorTop">
              <ion-grid>
                <ion-row class="ion-align-items-center"
                  *ngFor="let handicapType of handicapTypeOptions; let index = index;"
                  (click)="selectHandicapType(handicapType.value); accordionHandicapType.value = undefined;">
                  <ion-col size="auto" class="" style="padding-left: 0px; padding-right: 0px;">
                    <div class="circle-small">
                      {{handicapType.descriptionHeader}}
                    </div>
                  </ion-col>
                  <ion-col style="padding: 2px;">
                    <ion-grid class="ion-no-padding lineSeparatorBottom"
                      [ngClass]="{ lineSeparatorBottom: index < handicapTypeOptions.length - 1 }">
                      <ion-row>
                        <ion-col class="nameText">{{handicapType.name}}</ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col>
                          <ion-text style="font-size: 13px;" [innerHTML]="handicapType.description"></ion-text>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card-content>

    </ion-card>

    <!-- GAME -->
    <ion-card class="matchConfigCard" [ngClass]="{ activeAccordian: accordianName === 'game' }">
      <ion-card-content>
        <ion-label position="stacked">Game</ion-label>
        <ion-accordion-group #accordionGame [value]="accordianName" (click)="accordianClick('game', accordionGame)">
          <ion-accordion value="game">
            <ion-item slot="header">
              <ion-grid>
                <ion-row class="ion-align-items-center">
                  <ion-col size="auto" *ngIf="appFunction.isNumeric(matchForm.controls.game.value)">
                    <div class="circle-small">
                      {{match.getGame(matchForm.controls.game.value)?.nameHeader}}
                    </div>
                  </ion-col>
                  <ion-col>
                    {{!appFunction.isNumeric(matchForm.controls.game.value) ? 'Select a game' :
                    match.getGame(matchForm.controls.game.value)?.name}}
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
            <div slot="content" class="lineSeparatorTop">
              <ion-grid>
                <ion-row class="ion-align-items-center" (click)="selectGame(game); accordionGame.value = undefined"
                  *ngFor="let game of matchGames; let index = index;">
                  <ion-col size="auto" class="" style="padding-left: 0px; padding-right: 0px;">
                    <div class="circle-small">
                      {{game.nameHeader}}
                    </div>
                  </ion-col>
                  <ion-col style="padding: 2px;">
                    <ion-grid class="ion-no-padding lineSeparatorBottom"
                      [ngClass]="{ lineSeparatorBottom: index < matchGames.length - 1 }">
                      <ion-row>
                        <ion-col class="nameText">{{game.name}}</ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col>
                          <ion-text style="font-size: 13px;" [innerHTML]="game.description"></ion-text>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card-content>

    </ion-card>

    <!-- TEAM SIZE -->
    <ion-card class="matchConfigCard" *ngIf="appFunction.isNumeric(matchForm.controls.game.value)"
      [ngClass]="{ activeAccordian: accordianName === 'teamSize' }">
      <ion-card-content>
        <ion-label position="stacked">Team Size</ion-label>
        <ion-accordion-group #accordionTeamSize [value]="accordianName"
          (click)="accordianClick('teamSize', accordionTeamSize)">
          <ion-accordion value="teamSize">
            <ion-item slot="header">
              <ion-label>

                <ion-grid>

                  <ion-row class="ion-align-items-center">

                    <ion-col size="auto" *ngIf="appFunction.isNumeric(matchForm.controls.teamSize.value)">
                      <div class="circle-small">
                        {{match.getTeamSize(matchForm.controls.game.value,
                        matchForm.controls.teamSize.value)?.descriptionHeader}}
                      </div>
                    </ion-col>

                    <ion-col>
                      {{!appFunction.isNumeric(matchForm.controls.teamSize.value) ? 'Select a team size' :
                      match.getTeamSize(matchForm.controls.game.value,
                      matchForm.controls.teamSize.value)?.description}}
                    </ion-col>

                  </ion-row>

                </ion-grid>

              </ion-label>
            </ion-item>
            <div slot="content" class="lineSeparatorTop">

              <ion-grid>

                <ion-row class="ion-align-items-center"
                  (click)="selectTeamSize(teamSize.value); accordionTeamSize.value = undefined;"
                  *ngFor="let teamSize of matchOptions?.game[matchForm.controls.game.value].teamSize">

                  <ion-col size="auto" style="padding-left: 0px; padding-right: 0px;">
                    <div class="circle-small">
                      {{teamSize.descriptionHeader}}
                    </div>
                  </ion-col>

                  <ion-col style="padding: 2px;" class="nameText">
                    {{teamSize.description}}
                  </ion-col>

                </ion-row>

              </ion-grid>


            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card-content>
    </ion-card>
    <ion-item lines="none" class="validation"
      *ngIf="matchForm.controls.teamSize.hasError('required') && !matchForm.controls.teamSize.valid && matchForm.controls.teamSize.touched">
      <ion-label autocapitalize="on">Please select a team size.</ion-label>
    </ion-item>

    <!-- TEAM SCORING TYPE -->
    <ion-card class="matchConfigCard" *ngIf="appFunction.isNumeric(matchForm.controls.teamSize.value)"
      [ngClass]="{ activeAccordian: accordianName === 'scoringType' }">
      <ion-card-content>
        <ion-label position="stacked">Team Scoring</ion-label>
        <ion-accordion-group #accordionTeamScoring [value]="accordianName"
          (click)="accordianClick('scoringType', accordionTeamScoring)">
          <ion-accordion value="scoringType">
            <ion-item slot="header">
              <ion-label>
                <ion-grid>
                  <ion-row class="ion-align-items-center">
                    <ion-col size="auto" *ngIf="appFunction.isNumeric(matchForm.controls.scoringType.value)">
                      <div class="circle-small">
                        {{match.getTeamScoring(matchForm.controls.game.value, matchForm.controls.teamSize.value,
                        matchForm.controls.scoringType.value)?.descriptionHeader}}
                      </div>
                    </ion-col>
                    <ion-col>
                      {{!appFunction.isNumeric(matchForm.controls.scoringType.value) ? 'Select how to score' :
                      match.getTeamScoring(matchForm.controls.game.value, matchForm.controls.teamSize.value,
                      matchForm.controls.scoringType.value)?.description}}
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-label>
            </ion-item>
            <div slot="content" class="lineSeparatorTop">
              <ion-grid>
                <ion-row class="ion-align-items-center"
                  (click)="selectTeamScoringType(scoringType.value); accordionTeamScoring.value = undefined;"
                  *ngFor="let scoringType of match.getTeamSize(matchForm.controls.game.value, matchForm.controls.teamSize.value).scoringType">
                  <ion-col size="auto" style="padding-left: 0px; padding-right: 0px;">
                    <div class="circle-small">
                      {{scoringType.descriptionHeader}}
                    </div>
                  </ion-col>
                  <ion-col style="padding: 2px;" class="nameText">
                    {{scoringType.description}}
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card-content>
    </ion-card>
    <ion-item lines="none" class="validation"
      *ngIf="matchForm.controls.scoringType.hasError('required') && !matchForm.controls.scoringType.valid && matchForm.controls.scoringType.touched">
      <ion-label autocapitalize="on">Please select a scoring format.</ion-label>
    </ion-item>

    <!-- STABLEFORD POINTS -->
    <ion-card class="matchConfigCard" *ngIf="matchForm.controls.game.value===GameType.Stableford"
      [ngClass]="{ activeAccordian: accordianName === 'stablefordPoints' }">
      <ion-card-content>
        <ion-label position="stacked">Stableford Points</ion-label>
        <ion-accordion-group #accordionStablefordPoints [value]="accordianName"
          (click)="accordianClick('stablefordPoints', accordionStablefordPoints)">
          <ion-accordion value="stablefordPoints">
            <ion-item slot="header">
              <ion-label>Expand to view and adjust scoring.</ion-label>
            </ion-item>
            <div slot="content" class="lineSeparatorTop stablefordCounter">

              <!-- albatross -->
              <ion-item lines="none">

                <ion-label>
                  <ion-text class="labelPositionStacked">Albatross</ion-text>

                  <ion-grid class="counter ion-no-padding" style="padding-left: 40px;">
                    <ion-row class="ion-no-padding">
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.albatross===-10"
                          (click)="selectStablefordPoints('albatross', -1)">
                          <ion-icon slot="icon-only" name="remove"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                          {{matchForm.controls.stablefordPoints.value.albatross}}</ion-label>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.albatross===10"
                          (click)="selectStablefordPoints('albatross', 1)">
                          <ion-icon slot="icon-only" name="add"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>

                </ion-label>

              </ion-item>

              <!-- eagle -->
              <ion-item lines="none">

                <ion-label>
                  <ion-text class="labelPositionStacked">Eagle</ion-text>

                  <ion-grid class="counter ion-no-padding" style="padding-left: 40px;">
                    <ion-row class="ion-no-padding">
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.eagle===-10"
                          (click)="selectStablefordPoints('eagle', -1)">
                          <ion-icon slot="icon-only" name="remove"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                          {{matchForm.controls.stablefordPoints.value.eagle}}</ion-label>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.eagle===10"
                          (click)="selectStablefordPoints('eagle', 1)">
                          <ion-icon slot="icon-only" name="add"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>

                </ion-label>

              </ion-item>

              <!-- birdie -->
              <ion-item lines="none">

                <ion-label>
                  <ion-text class="labelPositionStacked">Birdie</ion-text>

                  <ion-grid class="counter ion-no-padding" style="padding-left: 40px;">
                    <ion-row class="ion-no-padding">
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.birdie===-10"
                          (click)="selectStablefordPoints('birdie', -1)">
                          <ion-icon slot="icon-only" name="remove"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                          {{matchForm.controls.stablefordPoints.value.birdie}}</ion-label>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.birdie===10"
                          (click)="selectStablefordPoints('birdie', 1)">
                          <ion-icon slot="icon-only" name="add"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>

                </ion-label>

              </ion-item>

              <!-- par -->
              <ion-item lines="none">

                <ion-label>
                  <ion-text class="labelPositionStacked">Par</ion-text>

                  <ion-grid class="counter ion-no-padding" style="padding-left: 40px;">
                    <ion-row class="ion-no-padding">
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.par===-10"
                          (click)="selectStablefordPoints('par', -1)">
                          <ion-icon slot="icon-only" name="remove"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                          {{matchForm.controls.stablefordPoints.value.par}}</ion-label>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.par===10"
                          (click)="selectStablefordPoints('par', 1)">
                          <ion-icon slot="icon-only" name="add"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>

                </ion-label>

              </ion-item>

              <!-- bogey -->
              <ion-item lines="none">

                <ion-label>
                  <ion-text class="labelPositionStacked">Bogey</ion-text>

                  <ion-grid class="counter ion-no-padding" style="padding-left: 40px;">
                    <ion-row class="ion-no-padding">
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.bogey===-10"
                          (click)="selectStablefordPoints('bogey', -1)">
                          <ion-icon slot="icon-only" name="remove"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                          {{matchForm.controls.stablefordPoints.value.bogey}}</ion-label>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.bogey===10"
                          (click)="selectStablefordPoints('bogey', 1)">
                          <ion-icon slot="icon-only" name="add"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>

                </ion-label>

              </ion-item>

              <!-- double bogey or worse -->
              <ion-item lines="none">

                <ion-label>
                  <ion-text class="labelPositionStacked">Double Bogey or Worse</ion-text>

                  <ion-grid class="counter ion-no-padding" style="padding-left: 40px;">
                    <ion-row class="ion-no-padding">
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.doubleBogey===-10"
                          (click)="selectStablefordPoints('doubleBogey', -1)">
                          <ion-icon slot="icon-only" name="remove"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                          {{matchForm.controls.stablefordPoints.value.doubleBogey}}</ion-label>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.stablefordPoints.value.doubleBogey===10"
                          (click)="selectStablefordPoints('doubleBogey', 1)">
                          <ion-icon slot="icon-only" name="add"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>

                </ion-label>

              </ion-item>

            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card-content>
    </ion-card>

    <!-- PRESS -->
    <ion-card class="matchConfigCard" *ngIf="allowPress" [ngClass]="{ activeAccordian: accordianName === 'press' }">
      <ion-card-content>
        <ion-label position="stacked">Press</ion-label>
        <ion-accordion-group #accordionPress [value]="accordianName" (click)="accordianClick('press', accordionPress);">
          <ion-accordion value="press">
            <ion-item slot="header">
              <ion-label>
                <ion-grid>
                  <ion-row class="ion-align-items-center">
                    <ion-col size="auto">
                      <div class="circle-small">
                        P
                      </div>
                    </ion-col>
                    <ion-col>
                      <ng-container *ngIf="matchForm.controls.pressOption.value === PressOptions.Off">
                        Presses are off
                      </ng-container>

                      <ng-container *ngIf="matchForm.controls.pressOption.value === PressOptions.Auto">
                        {{'Auto press after ' + (matchForm.controls.pressOption.value !== PressOptions.Off?
                        matchForm.controls.pressStokesDown.value.toString() + ' down': '')}}
                      </ng-container>

                      <ng-container *ngIf="matchForm.controls.pressOption.value === PressOptions.Prompt">
                        {{'Prompt for press after ' + (matchForm.controls.pressOption.value !== PressOptions.Off?
                        matchForm.controls.pressStokesDown.value.toString() + ' down': '')}}
                      </ng-container>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-label>
            </ion-item>
            <div slot="content" class="lineSeparatorTop">

              <ion-item lines="none">
                <ion-label>
                  <ion-text class="labelPositionStacked">Mode</ion-text>
                  <round-segment #press (change)="selectPressOption($event)"
                    [value]="matchForm.controls.pressOption.value" [buttons]="pressOptions"></round-segment>
                </ion-label>
              </ion-item>

              <ion-item lines="none">

                <ion-label>
                  <ion-text class="labelPositionStacked">Losing By</ion-text>

                  <ion-grid class="counter ion-no-padding" style="padding-left: 40px;">
                    <ion-row class="ion-no-padding">
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.pressStokesDown.value===1" (click)="selectPressStokesDown(-1)">
                          <ion-icon slot="icon-only" name="remove"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                          {{matchForm.controls.pressStokesDown.value}}</ion-label>
                      </ion-col>
                      <ion-col class="ion-text-center ion-no-padding">
                        <ion-button shape="round" color="secondary" haptic
                          [disabled]="matchForm.controls.pressStokesDown.value===5" (click)="selectPressStokesDown(1)">
                          <ion-icon slot="icon-only" name="add"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>

                </ion-label>

              </ion-item>

            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card-content>
    </ion-card>

  </ion-list>

  <!-- message -->
  <ion-list class="itemLines">

    <ion-list-header>
      <ion-label>Message</ion-label>
    </ion-list-header>

    <ion-item lines="inset">
      <ion-label position="stacked">Message</ion-label>
      <ion-textarea formControlName="notes" autocomplete="on" autocorrect="on" autocapitalize="on"
        placeholder="What else? $$ per player? Closest to the pin? Longest drive?">
      </ion-textarea>
    </ion-item>

  </ion-list>

</form>