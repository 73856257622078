<ion-header class="member-detail-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="secondary" *ngIf="memberForm.dirty">
      <ion-button (click)="cancel()">
        Cancel
      </ion-button>
    </ion-buttons>

    <ion-title>Member</ion-title>

    <ion-buttons slot="primary">
      <ion-button *ngIf="memberForm.dirty" fill="solid" shape="round" (click)="done()">Save</ion-button>
      <ion-button *ngIf="!memberForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>

  </ion-toolbar>

  <!-- validation hero -->
  <validation-hero [display]="isFormSubmitted && memberForm.invalid"></validation-hero>

</ion-header>

<ion-content class="member-detail-content">

  <!-- member avatar -->
  <ion-grid class="ion-no-padding">

    <ion-row class="ion-align-items-center ion-justify-content-center" style="margin-bottom: 5px;">

      <ion-col>
        <ion-avatar class="avatar-large">
          <img [imgLoader]="member?.avatar.URI" draggable="false">
        </ion-avatar>
      </ion-col>

    </ion-row>

  </ion-grid>

  <form class="member-detail-form" [formGroup]="memberForm">

    <!-- member details -->
    <ion-list class="itemLines">

      <ion-list-header>
        <ion-label>Member Details</ion-label>
      </ion-list-header>

      <!-- id -->
      <ion-item lines="inset">
        <ion-label>
          <ion-label position="stacked">Document Id</ion-label>
          <ion-label>{{member?.id}}</ion-label>
        </ion-label>
      </ion-item>

      <!-- email -->
      <ion-item lines="inset">
        <ion-label>
          <ion-label position="stacked">Email Address</ion-label>
          <ion-label>{{member?.email}}</ion-label>
        </ion-label>
      </ion-item>

      <!-- first name -->
      <ion-item [class.invalid]="!memberForm.controls.firstName.valid && memberForm.controls.firstName.touched"
        lines="inset">
        <ion-label position="stacked">First Name
        </ion-label>
        <ion-input formControlName="firstName"></ion-input>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="memberForm.controls.firstName.hasError('required') && !memberForm.controls.firstName.valid && memberForm.controls.firstName.touched">
        <ion-label>Please enter a first name.</ion-label>
      </ion-item>

      <!-- last name -->
      <ion-item [class.invalid]="!memberForm.controls.lastName.valid && memberForm.controls.lastName.touched"
        lines="inset">
        <ion-label position="stacked">Last Name</ion-label>
        <ion-input formControlName="lastName"></ion-input>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="memberForm.controls.lastName.hasError('required') && !memberForm.controls.lastName.valid && memberForm.controls.lastName.touched">
        <ion-label>Please enter a last name.</ion-label>
      </ion-item>

      <!-- private account -->
      <ion-item>
        <ion-label>
          <ion-label position="stacked">Private Account</ion-label>

          <ion-label class="toggle">
            <p *ngIf="memberForm.controls.privateAccount.value">Your account is private</p>
            <p *ngIf="!memberForm.controls.privateAccount.value">Your account is public</p>
            <ion-toggle color="secondary" formControlName="privateAccount"></ion-toggle>
          </ion-label>

        </ion-label>
      </ion-item>

      <!-- admin account -->
      <ion-item lines="inset">
        <ion-label>
          <ion-label position="stacked">Admin</ion-label>

          <ion-label class="toggle">
            <p *ngIf="memberForm.controls.admin.value">This member is an Admin</p>
            <p *ngIf="!memberForm.controls.admin.value">This member is not an Admin</p>
            <ion-toggle color="secondary" formControlName="admin"></ion-toggle>
          </ion-label>

        </ion-label>
      </ion-item>

      <!-- app version -->
      <ion-item lines="inset">
        <ion-label>
          <ion-label position="stacked">Application Version</ion-label>
          <ion-label>{{member?.appVersion}}</ion-label>
        </ion-label>
      </ion-item>

      <!-- app version -->
      <ion-item lines="inset">
        <ion-label>
          <ion-label position="stacked">Last Login Date</ion-label>
          <ion-label *ngIf="member?.lastLoginDt">{{member?.lastLoginDt.toDate().toDateString()}}</ion-label>
          <p *ngIf="!member?.lastLoginDt">Never logged in</p>
        </ion-label>
      </ion-item>

      <!-- post count -->
      <ion-item [class.invalid]="!memberForm.controls.postCount.valid && memberForm.controls.postCount.touched"
        lines="inset">
        <ion-label position="stacked">Post Count</ion-label>
        <ion-input formControlName="postCount"></ion-input>
      </ion-item>
      <ion-item lines="none" class="validation"
        *ngIf="memberForm.controls.postCount.hasError('required') && !memberForm.controls.postCount.valid && memberForm.controls.postCount.touched">
        <ion-label>Please enter a post count.</ion-label>
      </ion-item>

    </ion-list>

  </form>

  <!-- groups -->
  <ion-list class="itemLines">

    <ion-list-header>
      <ion-label>Groups</ion-label>
    </ion-list-header>

    <ng-container *ngIf="member?.groups.length > 0, else noGroups">

      <ion-list class="itemLines">

        <ion-item *ngFor="let group of member?.groups" (click)="updateGroup(group)">

          <ion-avatar slot="start">
            <img [imgLoader]="group.avatar.URI" draggable="false">
          </ion-avatar>

          <ion-label>
            <h2>{{group.name}}</h2>
          </ion-label>

        </ion-item>

      </ion-list>

    </ng-container>

  </ion-list>

  <ion-fab #fab vertical="bottom" horizontal="end" slot="fixed" haptic [fabClose]="fab">
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-button shape="round" (click)="deleteMemberConfirmation(member); fab.close();">
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label>Delete Member</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="impersonateMember(member); fab.close();">
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label>Impersonate Member</ion-label>
      </ion-button>

    </ion-fab-list>
  </ion-fab>

</ion-content>

<ng-template #noGroups>
  <ion-note class="instructional" style="margin-top: 20px;">
    This member has no groups
  </ion-note>
</ng-template>