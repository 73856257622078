import { Component, Input } from '@angular/core';
import { GameType, PressOptions } from 'src/app/app.config';
import { AppMatch } from 'src/app/app.match';

@Component({
  selector: 'match-view',
  templateUrl: './match-view.component.html',
  styleUrls: ['./match-view.component.scss']
})
export class MatchViewComponent {

  //get from the host html
  @Input() match: AppMatch;
  @Input() formatLabel: string;
  PressOptions: typeof PressOptions = PressOptions;
  GameType: typeof GameType = GameType;

  constructor() {

  }

}
