import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { IonSlides, NavParams, IonModal } from '@ionic/angular';
import { AppFunction, HelpService, scorecardEnterFromRightAnimation, scorecardLeaveToRightAnimation } from '../../app.function';
import { AppEvent, AppEventPlayer, ScoringMode, GreenInRegulation, FairwayHit } from '../../app.event';
import { AppMatch, AppColorI, MatchPlayerI } from '../../app.match';
import { AccountService } from '../../app.account';
import { EventScorecardPage } from '../event-scorecard/event-scorecard.page';
import { PlayerStatsPage } from '../player-stats/player-stats.page';
import { AppConfig, GameType, HandicapAllowance, HandicapType } from '../../app.config';
import { MatchEventDetailPage } from '../match-event-detail/match-event-detail.page';
import { MatchMemberDetailPage } from '../match-member-detail/match-member-detail.page';
import { EventMatchLiveViewComponent } from 'src/app/components/event-match-live-view/event-match-live-view.component';
import { EventViewPage } from '../event-view/event-view.page';
import { AppTee } from 'src/app/app.club';
import { Subscription } from 'rxjs';
import Swiper from 'swiper';

export enum EventScoringSegment {
  Scoring = 'scoring',
  Leaderboard = 'leaderboard',
  Matches = 'matches'
}

@Component({
  selector: 'event-scoring',
  templateUrl: './event-scoring.page.html',
  styleUrls: ['./event-scoring.page.scss']
})
export class EventScoringPage implements OnInit {

  @ViewChild(IonSlides) leaderboardSlides: IonSlides;
  @ViewChildren(EventMatchLiveViewComponent) matches: QueryList<EventMatchLiveViewComponent>;
  eventScoringSegment: string = 'scoring';
  event: AppEvent;
  startingHoleIndex: number;
  HandicapType: typeof HandicapType = HandicapType;
  HandicapAllowance: typeof HandicapAllowance = HandicapAllowance;
  ScoringMode: typeof ScoringMode = ScoringMode;
  GameType: typeof GameType = GameType;
  calcLeaderboardTimer: NodeJS.Timeout;
  GreenInRegulation: typeof GreenInRegulation = GreenInRegulation;
  FairwayHit: typeof FairwayHit = FairwayHit;
  Math: Math = Math;
  screenKey: string = 'event-scoring';
  scoreListPlayer: AppEventPlayer;
  holeSelectorMessage: string;
  currentMatch: AppMatch;
  EventScoringSegment: typeof EventScoringSegment = EventScoringSegment;
  private _currentCourseHoleIndex: number = 0;
  private _nineIndex: number = 0;
  private _holeIndex: number = 0;
  private _eventSubscription: Subscription;
  private eventScoringModal: HTMLIonModalElement;
  faUserCheck = AppConfig.faUserCheck;
  faUserPen = AppConfig.faUserPen;
  matchColors: AppColorI[] = [
    {
      'backgroundcolor': 'var(--ion-color-primary)',
      'fontcolor': 'white'
    },
    {
      'backgroundcolor': 'var(--ion-color-secondary)',
      'fontcolor': 'white'
    },
    {
      'backgroundcolor': 'green',
      'fontcolor': 'white'
    },
    {
      'backgroundcolor': '#911eb4', //purple
      'fontcolor': 'black'
    },
    {
      'backgroundcolor': '#800000', //maroon
      'fontcolor': 'white'
    },
    {
      'backgroundcolor': '#9A6324', //brown
      'fontcolor': 'white'
    },
    {
      'backgroundcolor': '#808000', //olive
      'fontcolor': 'white'
    },
    {
      'backgroundcolor': '#469990', //teal
      'fontcolor': 'white'
    },
    {
      'backgroundcolor': '#bfef45', //teal
      'fontcolor': 'black'
    },
    {
      'backgroundcolor': '#42d4f4', //cyan
      'fontcolor': 'black'
    },
    {
      'backgroundcolor': '#dcbeff', //lavender
      'fontcolor': 'black'
    },
    {
      'backgroundcolor': '#aaffc3', //mint
      'fontcolor': 'black'
    }
  ];

  slideOpts = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets'
    }
  };

  constructor(
    public accountService: AccountService,
    public appFunction: AppFunction,
    public navParams: NavParams,
    public helpService: HelpService) {

    helpService.screenWhatsNew(this.screenKey);

    //set css variable for match background color used by match swiper
    document.documentElement.style.setProperty('--match-background-color', this.matchColors[0].backgroundcolor);

  }

  ngOnInit() {

    //get modal reference (this is a reference to this modal). clean up before closing (we do this because "swipe to close" doesn't trigger an event where we can call "done")
    this.eventScoringModal = <HTMLIonModalElement>this.navParams.get('modal');
    this.eventScoringModal
      .onWillDismiss()
      .then(() => {

        //save player data
        this.saveTeeTimePlayers();

        //stop running the interval when user leaves page
        clearInterval(this.calcLeaderboardTimer);

      });

    //get event
    this.event = <AppEvent>this.navParams.get('event');

    //get the starting hole number for the event
    this.startingHoleIndex = this.event.startingHoleIndex;

    //get last hole scored for the tee time
    let holeThruIndex: number = 9999;
    this.event
      .players
      .active
      .teeTime
      .forEach((player) => {

        //determine what hole to display based on entered scores
        holeThruIndex = Math.min(holeThruIndex, (<AppEventPlayer>player).courseHoleThruIndex);

        //setup the nines...this is needed to calc strokes
        (<AppEventPlayer>player).setupHoles((<AppEventPlayer>player).tee.nines, player.nines);

      });

    //set what hole to display
    //if holeThruIndex === -1 then starting hole
    //if holeThruIndex === this.finishingHoleIndex then finishing hole
    //else get next hole
    if (holeThruIndex === -1) {
      this.currentCourseHoleIndex = this.startingHoleIndex;
    } else if (holeThruIndex === this.finishingHoleIndex) {
      this.currentCourseHoleIndex = this.finishingHoleIndex;
    } else {
      this.currentCourseHoleIndex = this.event.nextHoleIndex(holeThruIndex);
    }

    //subscribe to event updates, and listen 
    this._eventSubscription = this.event
      .updateEvent
      .subscribe((updateType) => {
        if (updateType === 'checkForPress') {
          this.checkForPress(this.event);
        }
      });

  }

  ionViewDidEnter() {

    //we call this here in the event teams are needed when the screen is opened 
    this.event.matches.createTeams(this.currentCourseHoleIndex);

    //init swiper
    const swiper = new Swiper('.matchSwiper', {
      loop: false, //i wanted this to be true but was causing several issues, including duplicate slideChange events
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets'
      },
      on: {
        slideChange: (event) => {
          this.matchSlideChange(event);
        },
        afterInit: (event) => {
          this.initializeMatchSwiper();
        }
      }
    });

  }

  ionViewWillUnload() {
    this._eventSubscription.unsubscribe();
  }

  eventScoringSegmentChange(event: any) {

    //when user selects leaderboard segment...
    if (event.detail.value === 'leaderboard') {

      //this fixed a bug with the slider
      this.leaderboardSlides?.update();

      //...run for initial display...
      this.event.players.active.calcLeaderboardPosition();
      this.event.players.active.calcLeaderboardNetPosition();

      //...and then run every ten seconds. i expect scores to flowing in and to recalc each time seems excessive, so instead do it every ten seconds
      this.calcLeaderboardTimer = setInterval(() => {
        this.event.players.active.calcLeaderboardPosition();
        this.event.players.active.calcLeaderboardNetPosition();

      }, 10000);

    } else if (event.detail.value === 'matches') {

      //stop running the interval when user goes to different segment
      clearInterval(this.calcLeaderboardTimer);

      //this is a big hack to get the slider to initialize 
      this.matches
        .forEach((match) => {
          match.updateSlides();
        });

    } else {

      //stop running the interval when user goes to different segment
      clearInterval(this.calcLeaderboardTimer);

    }

  }

  get finishingHoleIndex(): number {
    //TODO: this works but it's ugly
    return ((this.startingHoleIndex + (this.event.numberOfHoles - 2)) % this.event.numberOfHoles) + this.event.nines[0].holes[0].number;
  }

  get nineIndex(): number {
    return this._nineIndex;
  }

  get holeIndex(): number {
    return this._holeIndex;
  }

  set currentCourseHoleIndex(currentCourseHoleIndex: number) {

    //set number
    this._currentCourseHoleIndex = currentCourseHoleIndex;

    //determine nine and hole indexes
    this.event
      .nines
      .forEach((nine, nineIndex) => {

        nine
          .holes
          .forEach((hole, holeIndex) => {

            //if (hole.number === currentCourseHoleIndex) {
            if (hole.number === (currentCourseHoleIndex + 1)) {  //hole.number starts at 1, currentCourseHoleIndex starts at 0, don't love this
              this._holeIndex = holeIndex;
              this._nineIndex = nineIndex;
            }

          });

      });

  }

  get currentCourseHoleIndex(): number {
    return this._currentCourseHoleIndex;
  }

  private saveTeeTimePlayers() {

    //save tee time player scores
    (<AppEventPlayer[]>this.event.players.active.teeTime).forEach((player) => {
      player.save();
    });

  }

  decrementHole() {

    if (this.currentCourseHoleIndex !== this.startingHoleIndex) {

      //go to previous hole
      this.currentCourseHoleIndex = this.event.previousHoleIndex(this.currentCourseHoleIndex);

      //save player data
      this.saveTeeTimePlayers();

    }

  }

  async incrementHole() {

    //determine if all scores have been entered for the current hole
    const allHaveScores: boolean = this.event
      .players
      .active
      .teeTime
      .every((player) => {
        return player.nines[this.nineIndex].holes[this.holeIndex].scoreEntered;
      });

    //only advance if all scores have been entered
    if (allHaveScores) {

      //if ending hole then...
      if (this.currentCourseHoleIndex === this.finishingHoleIndex) {

        //if the 18th hole set mode to scoring complete for all players
        this.event
          .players
          .active
          .teeTime
          .forEach((player) => {
            player.scoreConfirmed = true;
          });

      } else { //...else go get next hole

        //go to next hole
        this.currentCourseHoleIndex = this.event.nextHoleIndex(this.currentCourseHoleIndex);

        //we might need new teams for spins 
        this.event.matches.createTeams(this.currentCourseHoleIndex);

      }

      //save player data
      this.saveTeeTimePlayers();

    } else {

      //display message that all scores must be entered before proceeding
      this.appFunction
        .alertCtrl
        .create({
          header: 'Oops, you forgot something!',
          message: 'All scores must be entered before proceeding to the next hole.',
          buttons: [
            {
              text: 'OK',
              handler: () => {
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    }

  }

  private async checkForPress(event: AppEvent) {

    //we need to iterate through players one at a time waiting for each player to complete before going to the next player
    const teeTimePlayers: AppEventPlayer[] = <AppEventPlayer[]>this.event.players.active.teeTime;
    await teeTimePlayers.reduce(async (previousPlayer, currentPlayer) => {

      //wait for previsous player to finish
      await previousPlayer;

      //do each match (both event and member matches) for current player
      const promiseArray: any[] = [];
      this.event
        .matches
        .getPlayerMemberMatches(currentPlayer)
        .forEach((match) => {
          const p = match.checkForPress(event);
          promiseArray.push(p);
        });

      //wait for all matches to return for current player
      await Promise
        .all(promiseArray)
        .then(() => {
          return;
        });

    }, Promise.resolve());

    //check for presses in the event matches
    this.event.matches.parent.forEach((match) => {
      match.checkForPress(event);
    });

  }

  openPlayerStats(player: MatchPlayerI) {

    //show play hole stats if still scoring
    if (player.scoringMode === ScoringMode.ScoringActive) {

      this.appFunction
        .modalCtrl
        .create({
          component: PlayerStatsPage,
          componentProps: {
            player: player,
            nineIndex: this.nineIndex,
            holeIndex: this.holeIndex
          },
          cssClass: 'custom-modal-auto-height',
          canDismiss: true
        })
        .then((modal) => {

          modal
            .present()
            .catch((err) => {
              console.log('event-scoring.page.ts openPlayerStats modal present error', err);
            });

        })
        .catch((err) => {
          console.log('event-scoring.page.ts openPlayerStats modal create error', err);
        });

    }

  }

  openPlayerActions(click: Event, player: AppEventPlayer) {

    //prevent the list item click
    click.stopPropagation();
    player.dynamicData.playerInfoOpen = true;

  }

  openScorecard = (players: MatchPlayerI[], match: AppMatch = undefined) => {

    this.appFunction
      .modalCtrl
      .create({
        component: EventScorecardPage,
        enterAnimation: scorecardEnterFromRightAnimation,
        leaveAnimation: scorecardLeaveToRightAnimation,
        cssClass: 'rotate-eventscorecard-landscape',
        showBackdrop: false,
        componentProps: {
          players: players,
          match: match,
          event: this.event,
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('event-scoring.page.ts openScorecard modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-scoring.page.ts openScorecard modal create error', err);
      });

  }

  async matchEdit(match: AppMatch = undefined) {

    //create match page
    this.appFunction
      .modalCtrl
      .create({
        component: MatchEventDetailPage,
        cssClass: 'custom-modal', //for md
        presentingElement: await this.appFunction.routerOutlet(),
        componentProps: {
          parent: this.event,
          match: match,
          matchOptions: AppConfig.EVENT_MATCH_CONFIGURATION
        },
        canDismiss: true
      })
      .then((modal) => {

        //
        modal
          .onDidDismiss()
          .then((result) => {

            if (result.role === 'update') {

              //if event match is successfully added then create team
              const match: AppMatch = result.data.match;
              const isAdded: boolean = this.event.matches.addMatch(match, true);
              if (isAdded) {

                this.event
                  .save()
                  .then(() => {

                    //create team for the event match
                    //TODO: I should only do this for my foursome, in the event of a two person team I might not know the teams of the other tee times 
                    match.createTeam(this.event.players.active.teeTime, this.event, this.currentCourseHoleIndex);

                    //make sure match swiper on score tab is initialized
                    this.initializeMatchSwiper(match);

                  });

              };

            }

          });

        modal
          .present()
          .catch((err) => {
            console.log('event-scoring.ts setupEventMatch modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-scoring.ts setupEventMatch modal create error', err);
      });

  }

  //this a is a call back function, need to do it this way so "this" is in the context of the event-scoring page
  matchUpdate = (args: any): void => {
    //if match has been updated check to see if teams need to be created
    this.event.matches.createTeams(this.currentCourseHoleIndex);
  }

  async newMemberMatch() {

    this.appFunction
      .modalCtrl
      .create({
        component: MatchMemberDetailPage,
        cssClass: 'custom-modal', //for md
        presentingElement: await this.appFunction.routerOutlet(),
        componentProps: {
          parent: this.event,
          editMode: AppConfig.EDIT_MODE.new,
          matchOptions: AppConfig.EVENT_MATCH_CONFIGURATION
        },
        canDismiss: true
      })
      .then((modal) => {

        //
        modal
          .onDidDismiss()
          .then((result) => {

            //if match was created then...
            if (result.role === 'update') {

              //...create the teams
              const match: AppMatch = result.data.match;
              match.createTeam(match.players, this.event);

              //make sure match swiper on score tab is initialized
              this.initializeMatchSwiper(match);

            }

          });

        modal
          .present()
          .catch((err) => {
            console.log('event-scoring.page.ts newMemberMatch modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-scoring.page.ts newMemberMatch modal create error', err);
      });

  }

  async viewEvent() {

    this.appFunction
      .modalCtrl
      .create({
        component: EventViewPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        backdropDismiss: false,
        canDismiss: true,
        componentProps: {
          event: this.event
        }
      })
      .then((modal) => {

        //pass in modal instance
        modal.componentProps.modal = modal;

        modal
          .present()
          .catch((err) => {
            console.log('event-scoreing.page.ts viewEvent modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-scoreing.page.ts viewEvent modal create error', err);
      });

  }

  openPlayerInfo(click: Event, player: MatchPlayerI, currentCourseHoleIndex: number) {

    //prevent the parent item click
    click.stopPropagation();

    //if player info is not complete
    if (player.infoConfirmed) {
      player.infoConfirmed = false;
    } else {

      //now save player data
      player.dynamicData.playerInfoSaving = true;
      player.infoConfirmed = true;
      (<AppEventPlayer>player).save()
        .then(async () => {

          //check to see if team can be created for any member match
          this.event
            .matches
            .getPlayerMemberMatches(player)
            .forEach(async (match) => {
              await match.createTeam(match.players, this.event, currentCourseHoleIndex); //can this.hole just be the first hole
            });

          //check to see if team can be create for the Event (big) match but only for current your tee time
          this.event.matches.parent.forEach(async (match) => {
            await match.createTeam(this.event.players.active.teeTime, this.event, currentCourseHoleIndex);
          });

          //turn off spinning icon and msrk as infoConfirmed
          player.dynamicData.playerInfoSaving = false;

        })
        .catch((err) => {
          console.log('event-scoring.page.ts done player save error', err);
        });

      //save index to member object if exists
      if (player.member) {

        player.member.handicapIndex = player.handicapIndex;
        player
          .member
          .save();

      }

    }

  }

  async setScoreListPlayer(modal: IonModal, player: MatchPlayerI) {

    //what player are we opening this list for?
    this.scoreListPlayer = <AppEventPlayer>player;

    //set present element
    modal.presentingElement = await this.appFunction.routerOutlet();

    //show the modal
    modal.isOpen = true;

  }

  openHoleSelector(modal: IonModal, message: string) {

    //set vars
    this.holeSelectorMessage = message;

    //show the inline modal
    modal.isOpen = true;

  }

  closeHoleSelector(modal: IonModal, selectedHoleIndex: number = undefined) {

    //show the modal
    modal.isOpen = false;

    //if user selected a hole...
    if (this.appFunction.isNumeric(selectedHoleIndex)) {

      //don't allow user to go to a hole that hasn't been played yet
      if (((<AppEventPlayer>this.event.players.active.teeTime[0]).courseHoleThruIndex + this.event.startingHoleIndex + 1) >= selectedHoleIndex) {

        //go to selected hole
        this.currentCourseHoleIndex = selectedHoleIndex;

        //save player data
        this.saveTeeTimePlayers();

      }

    }

  }

  updateHandicapIndex(player: MatchPlayerI, amount: number) {
    player.handicapIndex = Number.parseFloat((player.handicapIndex + amount).toFixed(1));
  }

  async selectCourseTee(player: MatchPlayerI, tee: AppTee) {
    player.tee = tee;
  }

  matchSlideChange(event: any) {

    //get and set the current match
    const activeSwiperIndex: number = (<Swiper>event).activeIndex;
    this.currentMatch = this.event.matches.active[activeSwiperIndex];

    //set css variable for match background color
    document.documentElement.style.setProperty('--match-background-color', this.matchColors[activeSwiperIndex].backgroundcolor);

  }

  private initializeMatchSwiper(match: AppMatch = undefined) {

    //if current match is not set then set it
    if (!this.currentMatch) {

      //if match passed in has a value then set it  
      if (match) {
        this.currentMatch = match;
      } else {
        //if match passed in is undefined then set the first match
        this.currentMatch = this.event.matches?.active[0];
      }

    }

  }

  get loggedInPlayerMatches(): AppMatch[] {
    return this.event.matches.getPlayerMatches(this.event.players.memberPlayer);
  }

  done() {

    //close page
    this.appFunction
      .modalCtrl
      .dismiss();

  }

}