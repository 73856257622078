import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableProdMode } from "@angular/core";
import { AppModule } from "./app/app.module";
import { defineCustomElements } from "@teamhive/lottie-player/loader";
import * as Sentry from "@sentry/angular-ivy";
import { BrowserTracing } from "@sentry/tracing";
import { AppConfig } from "./app/app.config";
import { environment } from "./environments/environment";

Sentry.init({
  environment: environment.production ? "production" : "development",
  release: `double-ace-golf@${AppConfig.APP_VERSION}`,
  dist: AppConfig.BUILD_NUMBER,
  dsn: "https://417d7b89ea0d43db866fc648f44f512e@o1219597.ingest.sentry.io/6362053",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: environment.production ? 0.1 : 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay(),
  ],
});

enableProdMode();
platformBrowserDynamic().bootstrapModule(AppModule);
defineCustomElements(window);
