import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, NavParams } from '@ionic/angular';
import { AppFunction } from '../../app.function';
import { ChatBotComponent } from 'src/app/components/chat-bot/chat-bot.component';
import { AccountService } from 'src/app/app.account';

@Component({
  selector: 'chat',
  templateUrl: './chat-bot.page.html',
  styleUrls: ['./chat-bot.page.scss'],
})
export class ChatBotPage {

  @ViewChild('chatBotConversation', { static: true }) chatBotConversation: ChatBotComponent;
  chat: any = {
    openAiPrompt: [
      {
        role: 'system',
        content: "You're a pga certified golf profession that teaches golf."
      }
    ],
    start: {
      prompts: ['Welcome to the Double Ace Golf swing advisor!', 'What part of your game would you like to work on?'],
      answers: [
        {
          id: 'chipping',
          display: "Chipping",
          aiPrompt: `${this.accountService.member.firstName} would like to work on chipping.`,
          nextChat: 'handicap'
        },
        {
          id: 'putting',
          display: "Putting",
          aiPrompt: `${this.accountService.member.firstName} would like to work on putting.`,
          nextChat: 'handicap'
        },
        {
          id: 'pitching',
          display: "Pitching",
          aiPrompt: `${this.accountService.member.firstName} would like to work on pitching.`,
          nextChat: 'handicap'
        },
        {
          id: 'ironPlay',
          display: "Iron Play",
          aiPrompt: `${this.accountService.member.firstName} would like to work on iron play.`,
          nextChat: 'handicap'
        },
        {
          id: 'other',
          display: "Other",
          aiPrompt: undefined,
          nextChat: 'gameOther'
        }
      ]
    },
    gameOther: {
      prompts: ['Ok, please let the Swing Advisor know what part of your game would you like to work on.'],
      input: {
        id: 'whatPartOfGame',
        placeholder: 'What part of your game?',
        aiPrompt: undefined,
        nextChat: 'handicap'
      }
    },
    handicap: {
      prompts: ["What range is your handicap index in?"],
      answers: [
        {
          id: 'scratch',
          display: "0-5",
          aiPrompt: `${this.accountService.member.firstName}'s handicapp index is beteween 0-5.`,
          nextChat: 'complete',
          callOpenAI: true
        },
        {
          id: 'expert',
          display: "5-10",
          aiPrompt: `${this.accountService.member.firstName}'s handicapp index is beteween 5-10.`,
          nextChat: 'complete',
          callOpenAI: true
        },
        {
          id: 'intermediate',
          display: "10-15",
          aiPrompt: `${this.accountService.member.firstName}'s handicapp index is beteween 10-15.`,
          nextChat: 'complete',
          callOpenAI: true
        },
        {
          id: 'novice',
          display: "15-20",
          aiPrompt: `${this.accountService.member.firstName}'s handicapp index is beteween 15-20.`,
          nextChat: 'complete',
          callOpenAI: true
        },
        {
          id: 'beginner',
          display: "20+",
          aiPrompt: `${this.accountService.member.firstName}'s handicapp index is 20 or more.`,
          nextChat: 'complete',
          callOpenAI: true
        }
      ]
    },
    complete: {
      prompts: ['Thank you for your time.']
    }
  };

  constructor(
    public router: Router,
    public appFunction: AppFunction,
    public navParams: NavParams,
    public navCtrl: NavController,
    public accountService: AccountService) {

    //console.log('club-search.page.ts constructor');

  }

  ionViewDidEnter() {
    this.chatBotConversation.start(this.chat);
  }

  restartConversation() {
    this.chatBotConversation.restart(this.chat);
  }

  done() {
    this.appFunction
      .modalCtrl
      .dismiss();
  }

}
