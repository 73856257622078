import { Component, Input } from '@angular/core';
import { AppFunction } from 'src/app/app.function';
import { AccountService } from 'src/app/app.account';
import { AppConfig } from 'src/app/app.config';
import { AppPost } from 'src/app/app.social';
import { IonContent } from '@ionic/angular';
import { MediaService } from '../../app.media';
import { PostCommentPage } from 'src/app/pages/post-comment/post-comment.page';

@Component({
  selector: 'post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss']
})
export class PostCardComponent {

  @Input() post: AppPost;
  @Input() content: IonContent;
  AppConfig: typeof AppConfig = AppConfig;

  constructor(public appFunction: AppFunction,
    public accountService: AccountService,
    public mediaService: MediaService) {
  }

  async comments(post: AppPost) {

    //open event details page
    this.appFunction
      .modalCtrl
      .create({
        component: PostCommentPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        componentProps: {
          post: post
        }
      })
      .then((modal) => {
        modal
          .present()
          .catch((err) => {
            console.log('post-list.page.ts postComments comments modal present error', err);
          });
      })
      .catch((err) => {
        console.log('post-list.page.ts postComments comments modal create error', err);
      });

  }

  likePost(post: AppPost) {

    this.appFunction
      .loadingCtrl
      .create({
        spinner: null,
        cssClass: 'likeLoadingCss'
      })
      .then((loading) => {

        loading.present();

        post
          .likePost()
          .then(() => {
            loading.dismiss();
          });

      });

  }

  postDeleteConfirm(post: AppPost) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Confirm Delete',
            role: 'destructive',
            handler: () => {
              post.delete();
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }


  postActionConfirm(post: AppPost) {

    //console.log('post-list.page.ts deletePostConfirm');

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Comments',
            handler: () => {
              this.comments(post);
            }
          },
          {
            text: 'Delete Post',
            role: 'destructive',
            handler: () => {
              this.postDeleteConfirm(post);
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

}
