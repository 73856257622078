import { Injectable } from '@angular/core';
import { AppConfig, EventEmailPreference, EventJoinDropEmailPreference, EventJoinDropNotificationPreference, EventNotificationPreference, PostNotificationPreference, TripNotificationPreference } from './app.config';
import { EventService, AppEvent, AppEventPlayer, ScoringMode, AppPlayerScoreNineI } from './app.event';
import { AppMatchParentI, AppMatch, MatchesI, PlayersI, ActivePlayersI, MatchPlayerI, MatchEventsI } from './app.match';
import { AccountService, AppMember, memberPreferenceI } from './app.account';
import { AppClassI, AppFunction, DeepLinkCampaign, DeepLinkChannel, DeepLinkParmsI, DeepLinkService, SortByOrder } from './app.function';
import { MediaService, imageManagementI } from './app.media';
import { AppClub, AppCourse, AppTee, ClubService } from './app.club';
import firebase from 'firebase/compat/app';
import { AppPost } from './app.social';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import * as moment from 'moment';
import algoliasearch from 'algoliasearch/lite';
import { environment } from '../environments/environment';

export enum GroupTripSegment {
    Information = 'information',
    Members = 'members',
    Events = 'events',
    Posts = 'posts',
    Matches = 'matches'
}

export enum GroupType {
    Event = 1,
    Trip = 2
}

export enum TripAttendanceStatus {
    In = 0,
    Invited = 1,
    Out = 2
}

export enum MemberGroupRole {
    Owner = 1,
    Administrator = 2,
    Member = 3
}

export enum GroupTripActionCd {
    In = 0,
    Invited = 1,
    Out = 2,
    NewGroup = 3
}

export enum GroupEventActionCd {
    AskToJoin = 0,
    Joined = 1,
    Leave = 2,
    AddedToGroupByOrganizer = 3,
    NewGroup = 4
}

export class memberGroupPreferences implements memberPreferenceI {

    key: string
    dirty: boolean = false;
    private _n: number = undefined; //event notification
    private _e: number = undefined; //event email
    private _p: number = undefined; //event post notifications
    private _tripNotification: number = undefined; //trip notifications
    private _eventEmailJoinDrop: number = undefined; //event email join drop notifications
    private _eventNotificationJoinDrop: number = undefined; //event notification join drop notifications

    constructor(key: string, values: any) {

        this.key = key;
        this._n = (values.n === undefined ? EventNotificationPreference.First : values.n);
        this._e = (values.e === undefined ? EventEmailPreference.All : values.e);
        this._p = (values.p === undefined ? PostNotificationPreference.All : values.p);
        this._tripNotification = (values.tripNotification === undefined ? TripNotificationPreference.InOut : values.tripNotification);
        this._eventEmailJoinDrop = (values.eventEmailJoinDrop === undefined ? EventJoinDropEmailPreference.JoinDrop : values.eventEmailJoinDrop);
        this._eventNotificationJoinDrop = (values.eventNotificationJoinDrop === undefined ? EventJoinDropNotificationPreference.JoinDrop : values.eventNotificationJoinDrop);

        //if this is a new prefernce (identified by undefined values) then set to dirty so to be sure to save
        if (values.n === undefined
            || values.e === undefined
            || values.p === undefined
            || values.tripNotification === undefined
            || values.eventEmailJoinDrop === undefined
            || values.eventNotificationJoinDrop === undefined) {

            this.dirty = true;

        }

    }

    set n(value: number) {

        if (this._n !== value) {
            this.dirty = true;
        }

        //default to 1, 1 = for new event only
        this._n = value === undefined ? EventNotificationPreference.First : value;

    }

    get n(): number {
        return this._n;
    }

    set e(value: number) {

        if (this._e !== value) {
            this.dirty = true;
        }

        //default to 2, 2 = for all event updates
        this._e = value === undefined ? EventEmailPreference.All : value;

    }

    get e(): number {
        return this._e;
    }

    set p(value: number) {

        if (this._p !== value) {
            this.dirty = true;
        }

        //default to all
        this._p = value === undefined ? PostNotificationPreference.All : value;

    }

    get p(): number {
        return this._p;
    }

    set tripNotification(value: number) {

        if (this._tripNotification !== value) {
            this.dirty = true;
        }

        //default to all notifications, TripNotificationPreference.InOut
        this._tripNotification = value === undefined ? TripNotificationPreference.InOut : value;

    }

    get tripNotification(): number {
        return this._tripNotification;
    }

    set eventEmailJoinDrop(value: number) {

        if (this._eventEmailJoinDrop !== value) {
            this.dirty = true;
        }

        //default to all join/drop notifications
        this._eventEmailJoinDrop = value === undefined ? EventJoinDropEmailPreference.JoinDrop : value;

    }

    get eventEmailJoinDrop(): number {
        return this._eventEmailJoinDrop;
    }

    set eventNotificationJoinDrop(value: number) {

        if (this._eventNotificationJoinDrop !== value) {
            this.dirty = true;
        }

        //default to all join/drop notifications
        this._eventNotificationJoinDrop = value === undefined ? EventJoinDropNotificationPreference.JoinDrop : value;

    }

    get eventNotificationJoinDrop(): number {
        return this._eventNotificationJoinDrop;
    }

    value(): any {

        return {
            n: this._n,
            e: this._e,
            p: this._p,
            tripNotification: this._tripNotification,
            eventEmailJoinDrop: this._eventEmailJoinDrop,
            eventNotificationJoinDrop: this._eventNotificationJoinDrop,
        };

    }

}

export interface AppGroupI {
    id: string;
    ownerMemberId: string;
    name: string;
    description: string;
    exists: boolean;
    removeMemberFromGroup(member: AppMember): Promise<AppGroupTripMember | AppGroupEventMember>;
    initialize(groupDoc: firebase.firestore.QueryDocumentSnapshot): Promise<AppGroupI>;
    save(batch: firebase.firestore.WriteBatch): Promise<AppGroupI>;
    members: AppGroupEventMemberI[] | AppGroupTripMemberI[];
    social;
    avatar;
    cover;
    events;
    type: GroupType;
    deleted: boolean;
    public: boolean;
    getPostNotificationPreferenceDistributionList(preferenceName: string, post: AppPost): Promise<any[]>;
    isMember(member: AppMember): boolean;
    isMemberAdmin(member: AppMember): boolean;
    sendGroupMessageEmail(editMode, subject: string, message: string): Promise<void>;
    getAdmins(): AppMember[];
}

export interface AppGroupEventMemberI {
    memberId: string;
    role: MemberGroupRole;
}

export class AppGroupEventMember implements AppGroupEventMemberI {

    memberId: string;
    role: MemberGroupRole;
    member: AppMember;
    private _accountService: AccountService;

    constructor() {
        this._accountService = AppFunction.serviceLocator.get(AccountService);
    }

    initialize(member: AppGroupEventMemberI): Promise<boolean> {

        return new Promise<boolean>((resolve) => {
            this.memberId = member.memberId;
            this.update(member)

            this._accountService
                .getMember(this.memberId)
                .toPromise()
                .then((foundMember) => {

                    if (foundMember) {
                        this.member = foundMember;
                        resolve(true);
                    } else {
                        resolve(false);
                    }

                })
                .catch((err) => {
                    console.log('app.group.ts AppGroupEventMember getMember error', err);
                    resolve(false);
                });

        });

    }

    update(member: AppGroupEventMemberI) {
        this.role = member.role;
    }

    /*  private getMember(memberId: string): Promise<boolean> {
 
         return new Promise<boolean>((resolve) => {
 
             this._accountService
                 .getMember(memberId)
                 .toPromise()
                 .then((foundMember) => {
 
                     if (foundMember) {
                         this.member = foundMember;
                         resolve(true);
                     } else {
                         resolve(false);
                     }
 
                 })
                 .catch((err) => {
                     console.log('app.group.ts AppGroupEventMember getMember error', err);
                     resolve(false);
                 });
 
         });
 
     } */

    data(): AppGroupEventMemberI {
        return {
            memberId: this.memberId,
            role: this.role
        }
    }

}

export interface AppGroupEventI {
    ownerMemberId: string;
    name: string;
    searchName: string;
    description: string;
    members: AppGroupEventMemberI[];
    searchMemberIds: string[];
    clubId: string;
    courseId: string;
    teeId: string;
    numberTeeTimes: number;
    teeTimeInterval: number;
    avatarFileName: string;
    coverFileName: string;
    public: boolean;
    updatedDt: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
    postCount: number;
    numberOfHoles: number;
    type: GroupType;
    nineHolesOnlyIndex: number;
    deleted: boolean;
}

export class AppGroupEvent implements AppGroupEventI, AppGroupI {

    id: string;
    ownerMemberId: string;
    name: string;
    description: string = null;
    numberTeeTimes: number = AppConfig.NUMBER_TEE_TIMES;
    teeTimeInterval: number = AppConfig.TEE_TIME_INTERVAL;
    updatedDt: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
    members: AppGroupEventMember[] = [];
    public: boolean = true;
    postCount: number = 0;
    exists: boolean = false;
    club: AppClub = null;
    course: AppCourse = null;
    tee: AppTee = null;
    nineHolesOnlyIndex: number;
    searchMemberIds: string[];
    dynamicData: any = {};
    type: GroupType = GroupType.Event;
    deleted: boolean = false;
    private _numberOfHoles: number = 18;
    private _avatarFileName: string = '';
    private _coverFileName: string = '';
    private _groupDoc: firebase.firestore.DocumentSnapshot;
    private _clubId: string = undefined;
    private _courseId: string = undefined;
    private _teeId: string;
    private _appFunction: AppFunction;
    private _accountService: AccountService;
    private _eventService: EventService;
    private _deepLinkService: DeepLinkService;
    private _clubService: ClubService;
    private _originalAvatarFileName: string = '';
    private _originalCoverFileName: string = '';
    private _newlyAddedGroupMembers: AppGroupEventMember[] = [];

    constructor() {

        //get services
        this._deepLinkService = AppFunction.serviceLocator.get(DeepLinkService);
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);
        this._accountService = AppFunction.serviceLocator.get(AccountService);
        this._eventService = AppFunction.serviceLocator.get(EventService);
        this._clubService = AppFunction.serviceLocator.get(ClubService);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.group.ts AppGroupEvent shutdown', this.id);
            });

    }

    initialize(groupDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Promise<AppGroupI> {

        return new Promise<AppGroupEvent>((resolve, reject) => {

            if (groupDoc) {

                //save document refenence
                this._groupDoc = groupDoc;
                this.id = groupDoc.id;

                //listen for group updates
                const groupSnapShotUnsubscribe = groupDoc
                    .ref
                    .onSnapshot((groupUpdate) => {

                        this.update(groupUpdate)
                            .then(() => {
                                resolve(this);
                            })
                            .catch((err) => {
                                reject(err);
                            });

                    });

                this._appFunction.registerUnsubscribe(groupSnapShotUnsubscribe);

            } else {

                //create new group doc ref
                const groupDoc: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.Groups).doc();

                //now get it, it should be empty as this is a new group object
                groupDoc
                    .get()
                    .then((newGroupDoc) => {

                        //save doc, this will be used when saving
                        this._groupDoc = newGroupDoc;
                        this.id = groupDoc.id;
                        resolve(this);

                    });

            }

        });

    }

    private update(updatedGroup: firebase.firestore.DocumentSnapshot): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                this._appFunction
                    .ngZone
                    .run(() => {

                        if (updatedGroup.exists) {

                            //update high level attributes
                            this.ownerMemberId = updatedGroup.data().ownerMemberId || undefined;
                            this.name = updatedGroup.data().name || null;
                            this.description = updatedGroup.data().description || null;
                            this.numberTeeTimes = updatedGroup.data().numberTeeTimes || AppConfig.NUMBER_TEE_TIMES;
                            this.teeTimeInterval = updatedGroup.data().teeTimeInterval || AppConfig.TEE_TIME_INTERVAL;
                            this.avatarFileName = this._originalAvatarFileName = updatedGroup.data().avatarFileName || '';
                            this.coverFileName = this._originalCoverFileName = updatedGroup.data().coverFileName || '';
                            this.createdDt = updatedGroup.data().createdDt || undefined;
                            this.public = updatedGroup.data().public === undefined ? true : updatedGroup.data().public;
                            this.postCount = updatedGroup.data().postCount || 0;
                            this.numberOfHoles = updatedGroup.data().numberOfHoles || 18;
                            this.nineHolesOnlyIndex = updatedGroup.data().nineHolesOnlyIndex;
                            this.deleted = updatedGroup.data().deleted || false;
                            this.exists = true;

                            //get the selected course and tee
                            this.setClub(updatedGroup.data().clubId, updatedGroup.data().courseId, updatedGroup.data().teeId)
                                .then(() => {

                                    //get the members
                                    const p = this.getMembers(updatedGroup.data().members);

                                    //get events only after getting the course and tee
                                    const q = this.events.get(this);

                                    //when done return
                                    Promise
                                        .all([p, q])
                                        .then(() => {
                                            resolve();
                                        });

                                });

                        } else {
                            this.exists = false;
                            resolve();
                        }

                    });

            } catch (err) {
                console.log('app.group.ts AppGroupEvent update error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    avatar = new class implements imageManagementI {

        private _mediaService: MediaService = AppFunction.serviceLocator.get(MediaService);

        constructor(private parentMember: AppGroupEvent) {
        }

        save(localFileURI: string): Promise<void> {

            //console.log('app.group.ts AppGroupEvent saveAvatar', localFileURI);

            return new Promise<void>((resolve, reject) => {

                //delete current/previous avatar 
                this.delete(false)
                    .then(() => {

                        //upload and save new avatar reference
                        this
                            .saveAvatarToStorage(localFileURI)
                            .then(() => {
                                //console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage success');
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage error', JSON.stringify(err));
                                reject(err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage error', JSON.stringify(err));
                        reject(err);
                    });

            });

        }

        private saveAvatarToStorage(localFileURI: string): Promise<void> {

            //console.log('app.group.ts AppGroupEvent saveAvatarToStorage', localFileURI);

            return new Promise<void>((resolve, reject) => {

                //now save avatar file
                this._mediaService
                    .saveMedia(localFileURI, this.parentMember._appFunction.newGuid(), AppConfig.MEDIA_STORAGE.AVATAR)
                    .then((avatarURI) => {

                        //console.log('app.group.ts AppGroupEvent saveAvatarToStorage saveMedia success', avatarURI);

                        //set avatar names
                        this.parentMember.avatarFileName = avatarURI;
                        this.parentMember._originalAvatarFileName = avatarURI;

                        //now save new avatar name
                        this.parentMember
                            .save()
                            .then(() => {
                                //console.log('app.group.ts AppGroupEvent saveAvatarToStorage save success');
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.group.ts AppGroupEvent saveAvatarToStorage error', JSON.stringify(err));
                                reject(err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts AppGroupEvent saveAvatarToStorage saveMedia error', JSON.stringify(err));
                        reject(err);
                    });

            });

        }

        delete(save: boolean): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                if (this.parentMember._originalAvatarFileName.trim().length > 0) {

                    this._mediaService
                        .deleteMedia(this.parentMember._originalAvatarFileName)
                        .then(() => {

                            //console.log('app.group.ts AppGroupEvent deleteAvatar remove old avatar success');

                            //clear out avatar
                            this.parentMember._originalAvatarFileName = '';

                            //now optionally save
                            if (save) {

                                //if saving then clear out avatar file name
                                this.parentMember.avatarFileName = '';

                                this.parentMember
                                    .save()
                                    .then(() => {
                                        //console.log('app.group.ts AppGroupEvent deleteAvatar save success');
                                        resolve();
                                    })
                                    .catch((err) => {
                                        console.log('app.group.ts AppGroupEvent deleteAvatar save error', JSON.stringify(err));
                                        reject(err);
                                    });

                            } else {
                                //no op
                                resolve();
                            }

                        })
                        .catch((err) => {
                            console.log('app.group.ts AppGroupEvent deleteAvatar remove old avatar error', this.parentMember._originalAvatarFileName, err, JSON.stringify(err));
                            reject();
                        });

                } else {
                    //noop, no image to delete
                    console.log('app.group.ts AppGroupEvent deleteAvatar no image to delete');
                    resolve()
                }

            });

        }

        get URI(): string {

            if (this.parentMember.avatarFileName.length > 0) {
                return this.parentMember.avatarFileName;
            } else {
                return AppConfig.NO_GROUP_AVATAR_URI;
            }

        }

        get URIEmail(): string {

            if (this.parentMember.avatarFileName.length > 0) {
                return this.parentMember.avatarFileName;
            } else {
                return AppConfig.NO_AVATAR_URI_EMAIL;
            }

        }

    }(this);

    cover = new class implements imageManagementI {

        private _mediaService: MediaService = AppFunction.serviceLocator.get(MediaService);

        constructor(private parentMember: AppGroupEvent) {
        }

        save(localFileURI: string): Promise<void> {

            console.log('app.group.ts AppGroupEvent saveCover', localFileURI);

            return new Promise<void>((resolve, reject) => {

                //delete old/previous cover
                this.delete(false)
                    .then(() => {

                        //upload and save cover reference
                        this
                            .saveCoverToStorage(localFileURI)
                            .then(() => {
                                console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage success');
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage error', JSON.stringify(err));
                                reject(err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage error', JSON.stringify(err));
                        reject(err);
                    });

            });

        }

        private saveCoverToStorage(localFileURI: string): Promise<void> {

            //console.log('app.group.ts AppGroupEvent saveCoverToStorage', localFileURI);

            return new Promise<void>((resolve, reject) => {

                //now save avatar file
                this._mediaService
                    .saveMedia(localFileURI, this.parentMember._appFunction.newGuid(), AppConfig.MEDIA_STORAGE.AVATAR)
                    .then((coverURI) => {

                        //console.log('app.group.ts AppGroupEvent saveCoverToStorage saveMedia success', avatarURI);

                        //set avatar names
                        this.parentMember.coverFileName = coverURI;
                        this.parentMember._originalCoverFileName = coverURI;

                        //now save new avatar name
                        this.parentMember
                            .save()
                            .then(() => {
                                //console.log('app.group.ts AppGroupEvent saveCoverToStorage save success');
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.group.ts AppGroupEvent saveCoverToStorage error', JSON.stringify(err));
                                reject(err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts AppGroupEvent saveCoverToStorage saveMedia error', JSON.stringify(err));
                        reject(err);
                    });

            });

        }

        delete(save: boolean): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                if (this.parentMember._originalCoverFileName.trim().length > 0) {

                    this._mediaService
                        .deleteMedia(this.parentMember._originalCoverFileName)
                        .then(() => {

                            //console.log('app.group.ts AppGroupEvent deleteCover remove old cover success');

                            //clear out avatar
                            this.parentMember._originalCoverFileName = '';

                            //now optionally save
                            if (save) {

                                //if saving then clear out avatar file name
                                this.parentMember.coverFileName = '';

                                this.parentMember
                                    .save()
                                    .then(() => {
                                        //console.log('app.group.ts AppGroupEvent deleteCover save success');
                                        resolve();
                                    })
                                    .catch((err) => {
                                        console.log('app.group.ts AppGroupEvent deleteCover save error', JSON.stringify(err));
                                        reject(err);
                                    });

                            } else {
                                //no op
                                resolve();
                            }

                        })
                        .catch((err) => {
                            console.log('app.group.ts AppGroupEvent deleteCover remove old avatar error', this.parentMember._originalAvatarFileName, err, JSON.stringify(err));
                            reject();
                        });

                } else {
                    //noop, no image to delete
                    console.log('app.group.ts AppGroupEvent deleteCover no image to delete');
                    resolve()
                }

            });

        }

        get URI(): string {

            if (this.parentMember.coverFileName.length > 0) {
                return this.parentMember.coverFileName;
            } else {
                return AppConfig.NO_COVER_URI;
            }

        }

    }(this);

    social = new class {

        private _posts: AppPost[];
        private _lastPostCreatedDt: firebase.firestore.Timestamp = undefined;
        private _futurePostCreatedDt: firebase.firestore.Timestamp = undefined;
        private _futurePostsUnsubscribe: any;

        constructor(private parentMember: AppGroupEvent) {
        }

        getPosts(): Promise<AppPost[]> {

            return new Promise<AppPost[]>((resolve) => {

                if (Array.isArray(this._posts)) {
                    resolve(this._posts);
                } else {

                    //init posts aray
                    this._posts = [];

                    //get first set of posts
                    this.getNextPosts()
                        .then((posts) => {
                            resolve(posts);
                        });

                }

            });

        }

        getNextPosts(): Promise<AppPost[]> {

            return new Promise<AppPost[]>((resolve, reject) => {

                try {

                    //create the array for promises
                    const promiseArray: any[] = [];

                    //array for this call
                    const nextPosts: AppPost[] = [];

                    //for the first query set the next and future post dates
                    if (!this._lastPostCreatedDt) {
                        this._lastPostCreatedDt = firebase.firestore.Timestamp.now();
                        this._futurePostCreatedDt = this._lastPostCreatedDt;
                    }

                    //create snapshot listener
                    this.parentMember
                        ._appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Posts)
                        .where('groupId', '==', this.parentMember.id)
                        .where('createdDt', '<', this._lastPostCreatedDt)
                        .orderBy('createdDt', 'desc')
                        .limit(AppConfig.NUMBER_OF_POSTS_TO_FETCH)
                        .get({ source: 'server' })
                        .then((foundPosts) => {

                            //get social following updates
                            foundPosts
                                .docs
                                .forEach((foundPost) => {

                                    //create following object with data retrieved from following collection
                                    const post: AppPost = new AppPost();

                                    //once object has been initialized...
                                    const p = post
                                        .initialize(foundPost)
                                        .then(() => {

                                            //save last retrieved post date (this will be used to get the next set of posts)
                                            this._lastPostCreatedDt = post.createdDt;

                                            //posts for this call
                                            nextPosts.push(post)

                                            //add to group's posts
                                            this._posts.push(post);

                                        });

                                    promiseArray.push(p);

                                });

                            //wait for all promises to return
                            Promise
                                .all(promiseArray)
                                .then(() => {
                                    resolve(nextPosts);
                                })
                                .catch((err) => {
                                    console.log('app.group.ts getPosts error', err);
                                    reject(err);
                                });

                        })
                        .catch((err) => {
                            console.log('app.group.ts AppGroupEvent getNextPosts get', err, JSON.stringify(err));
                            reject();
                        });

                } catch (err) {
                    console.log('app.group.ts AppGroupEvent getNextPosts', err, JSON.stringify(err));
                    reject();
                }

            });

        }

        getFuturePosts(): Observable<AppPost> {

            return new Observable((observer) => {

                try {

                    //only allow this once 
                    if (!this._futurePostsUnsubscribe) {

                        //create snapshot listener for future posts
                        this._futurePostsUnsubscribe = this.parentMember
                            ._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Posts)
                            .where('groupId', '==', this.parentMember.id)
                            .where('createdDt', '>=', this._futurePostCreatedDt)
                            .onSnapshot((foundPosts) => {

                                //get social following updates
                                foundPosts
                                    .docChanges()
                                    .forEach((foundPost) => {

                                        if (foundPost.type === AppConfig.UPDATE_TYPE.Added) {

                                            //create post object with data retrieved from post collection
                                            const appPost: AppPost = new AppPost();

                                            //once object has been initialized...
                                            const p = appPost
                                                .initialize(foundPost.doc)
                                                .then(() => {

                                                    //add to group's posts
                                                    this._posts.push(appPost);

                                                });

                                            //...then publish the new post
                                            p.then(() => {
                                                observer.next(appPost);
                                            });

                                        }

                                    });

                            });

                        this.parentMember._appFunction.registerUnsubscribe(this._futurePostsUnsubscribe);

                    }

                } catch (err) {
                    console.log('app.group.ts AppGroupEvent getFuturePosts', err, JSON.stringify(err));
                }

            });

        }

        updatePostCount(value: number): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                try {

                    //now increment count
                    this.parentMember
                        ._appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Groups)
                        .doc(this.parentMember.id)
                        .set({ postCount: firebase.firestore.FieldValue.increment(value) }, { merge: true })
                        .then(() => {
                            //console.log('app.group.ts AppMember updatePostCount add success');
                            resolve();
                        })
                        .catch((err) => {
                            console.log('app.group.ts AppGroupEvent updatePostCount add error', JSON.stringify(err));
                            reject(err);
                        });

                } catch (err) {
                    console.log('app.group.ts AppGroupEvent updatePostCount error', JSON.stringify(err));
                    reject(err);
                }

            });

        }

    }(this);

    events = <MatchEventsI>new class {

        private _events: AppEvent[];

        constructor(private parentGroup: AppGroupEvent) {
        }

        get all(): AppEvent[] {

            return this._events?.filter((event) => {
                return event.exists;
            }) || [];

        }

        in(event: AppEvent): boolean {
            return this.all.some((allEvent) => {
                return allEvent.id === event.id;
            });
        }

        get(group: AppGroupI): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                try {

                    //only run once
                    if (!Array.isArray(this._events)) {

                        //initialize array
                        this._events = [];

                        //get now for retrieving events
                        const today: firebase.firestore.Timestamp = firebase.firestore.Timestamp.fromDate(moment().startOf('day').toDate());

                        //get events for given group
                        const getEventsUnsubscribe = this.parentGroup._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Events)
                            .where('groupId', '==', group.id) //get for given group
                            .where('deleted', '==', false)
                            .where('eventDt', '>=', today) //any event today or in the future
                            .onSnapshot((foundEvents) => {

                                const promiseArray: any[] = [];

                                //for each found event...
                                foundEvents
                                    .docChanges()
                                    .forEach((foundEvent) => {

                                        //try to find event in local trip storage...
                                        const foundLocalEvent: AppEvent = this._events.find((event) => {
                                            return event.id === foundEvent.doc.id;
                                        })

                                        //if new
                                        if (foundEvent.type === AppConfig.UPDATE_TYPE.Added) {

                                            //...if not found then create
                                            if (!foundLocalEvent) {

                                                const p = this.parentGroup._eventService
                                                    .getEvent(group, foundEvent.doc.id, foundEvent.doc)
                                                    .then((event) => {

                                                        //update event with data
                                                        event.update(foundEvent.doc, foundEvent.type);

                                                        //store locally
                                                        this._events.push(event);

                                                        //publish event to app
                                                        this.parentGroup._eventService.newEvent.next(event);

                                                    });

                                                promiseArray.push(p);

                                            } else { //this would happen if the event was created during this session and and saved for the first time 

                                                // new event locally found, update local object');

                                                //this would happen if the event was created during this session and and saved for the first time 
                                                foundLocalEvent.update(foundEvent.doc, AppConfig.UPDATE_TYPE.Modified);

                                                //publish event to app (do we need to do this?)
                                                this.parentGroup._eventService.newEvent.next(foundLocalEvent);

                                            }

                                        } else {
                                            //for updated or removed events
                                            foundLocalEvent?.update(foundEvent.doc, foundEvent.type);
                                        }

                                    });

                                Promise.all(promiseArray)
                                    .then(() => {
                                        resolve();
                                    });

                            }, (err) => {
                                console.log('app.event.ts AppGroupEvent events get onSnapshot error', err);
                            });

                        //store snapshot in the event it should be removed
                        this.parentGroup._appFunction.registerUnsubscribe(getEventsUnsubscribe);

                    }

                } catch (err) {
                    console.log('app.group.ts AppGroupEvent events get error', err);
                    reject();
                }

            });

        }

    }(this);

    //#region properties

    set clubId(clubId: string) {

        //only retrieve if course id is new
        if (this._clubId !== clubId) {

            //set the new course id
            this._clubId = clubId;

            //with the new club the course is no longer valid
            this._courseId = undefined;
            this.course = undefined;

            //with the new club the tee id is no longer valid
            this._teeId = undefined;
            this.tee = undefined;

            //now get course
            this._clubService
                .getClub(clubId)
                .toPromise()
                .then((club) => {
                    this.club = club;
                });

        }

    }

    get clubId(): string {
        return this._clubId;
    }

    set courseId(courseId: string) {

        //console.log('app.group.ts set courseId');

        //only retrieve if course id is new
        if (this._courseId !== courseId) {

            //set courseId
            this._courseId = courseId;

            //reset tees
            this._teeId = undefined;
            this.tee = undefined;

        }

    }

    get courseId(): string {
        return this._courseId;
    }

    set teeId(teeId: string) {

        //console.log('app.group.ts set teeId');

        if (this.club) {

            //only retrieve if course id is new
            if (this._teeId !== teeId) {

                //set teeId
                this._teeId = teeId;

                //now get the tee object from the course
                /* this.club
                    .tees
                    .forEach((tee) => {

                        if (tee.id === teeId) {
                            this.tee = tee;
                            return;
                        }

                    }); */

            }

        } else {
            console.log('app.group.ts set teeId course is undefined');
        }

    }

    get teeId(): string {
        return this._teeId;
    }

    set numberOfHoles(numberOfHoles: number) {

        //console.log('app.group.ts set teeId');

        //set numberOfHoles
        this._numberOfHoles = numberOfHoles;

    }

    get numberOfHoles(): number {
        return this._numberOfHoles;
    }

    set avatarFileName(avatarfileName: string) {
        //set property
        this._avatarFileName = avatarfileName;
    }

    get avatarFileName(): string {
        return this._avatarFileName;
    }

    set coverFileName(coverfileName: string) {
        //set property
        this._coverFileName = coverfileName;
    }

    get coverFileName(): string {
        return this._coverFileName;
    }

    get searchName(): string {
        return this.name.toUpperCase();
    }

    //#endregion properties

    getPostNotificationPreferenceDistributionList(preferenceName: string, post: AppPost): Promise<any[]> {

        return new Promise<any[]>((resolve) => {

            const notifications = [];
            const promiseArray: any[] = [];

            //for each group member...
            this.members
                .forEach((groupMember) => {

                    //cast to get member
                    const member: AppMember = (<AppGroupEventMember>groupMember).member;

                    //get member preferences for this group
                    const p = member
                        .getPreference(this.id)
                        .then((foundPreference: memberGroupPreferences) => {

                            let sendNotification: boolean = false;

                            switch (preferenceName) {
                                case AppConfig.GROUP_PREFERENCES.POST_NOTIFICATION_PREFERENCE.name: {

                                    //console.log('app.group.ts getPostNotificationPreferenceDistributionList GROUP_PREFERENCES.POST_NOTIFICATION_PREFERENCE', member.email, foundPreference.p);

                                    //0 - none
                                    //1 - all group posts
                                    //2 - from organizer only

                                    //determine who gets a post notification
                                    sendNotification =
                                        foundPreference.p === undefined || //member hasn't set this preference (undefined)(default)
                                        foundPreference.p === 1 || //member wants all post notifications(1) 
                                        (foundPreference.p === 2 && post.createdMemberId === this.ownerMemberId); //member only wants post notifications (2) from owner

                                    break;
                                }
                                default: {
                                    //console.log('app.group.ts getPostNotificationPreferenceDistributionList getPreference invalid preferenceName', preferenceName);
                                    break;
                                }
                            }

                            //if new event (push) notification (n) preference is set or is undefined (will then assume true)
                            if (sendNotification) {

                                notifications.push({
                                    memberId: member.id
                                });

                            }

                        });

                    promiseArray.push(p);

                });

            Promise
                .all(promiseArray)
                .then(() => {
                    resolve(notifications);
                });

        });

    }

    setClub(clubId: string, courseId: string, teeId: string): Promise<void> {

        return new Promise<void>((resolve) => {

            //be sure all data is passed in
            if (clubId) {

                //set the new course id
                this._clubId = clubId;

                //with the new club the course no longer valid
                this._courseId = undefined;
                this.courseId = undefined;

                //now get club
                this._clubService
                    .getClub(clubId)
                    .toPromise()
                    .then((club) => {

                        //set course and...
                        this.club = club;

                        //...if only one course then auto select course
                        if (this.club.courses.length === 1) {
                            this.course = this.club.courses[0];
                            this.courseId = this.course.courseId;
                        } else {
                            //...else get selected course (if any)...
                            this.course = this.club.getCourse(courseId);
                            this.courseId = this.course?.courseId;
                        }

                        //...then get tee
                        if (this.courseId) {
                            this.tee = this.course.getTee(teeId);
                            this.teeId = this.tee?.teeId;
                        }

                        resolve();

                    });

            } else {
                console.log('app.group.ts AppGroupEvent setClub invalid parameters', this.id, this.name, clubId, courseId, teeId);
                resolve();
            }

        });

    }

    //#region member methods

    removeMemberFromGroup(removeMember: AppMember): Promise<AppGroupEventMember> {

        return new Promise<AppGroupEventMember>((resolve, reject) => {

            try {

                //find member
                const memberIndex: number = this.members.findIndex((groupMember) => {
                    return removeMember.email.trim().toLowerCase() === (<AppGroupEventMember>groupMember).member.email.trim().toLowerCase();
                });

                //find member in newly added member list...
                const newlyAddedMemberIndex: number = this._newlyAddedGroupMembers.findIndex((groupMember) => {
                    return removeMember.email.trim().toLowerCase() === (<AppGroupEventMember>groupMember).member.email.trim().toLowerCase();
                });

                //...and if found remove from newly added member list
                if (newlyAddedMemberIndex > -1) {
                    this._newlyAddedGroupMembers.splice(newlyAddedMemberIndex, 1);
                }

                //TODO: remove group preferences from member record

                //remove and return
                resolve(<AppGroupEventMember>this.members.splice(memberIndex, 1)[0]);

            }
            catch (err) {
                console.log('app.group.ts AppGroupEvent removeMemberFromGroup error', JSON.stringify(err));
            }

        });

    }

    addMemberToGroup(addMember: AppMember, role: MemberGroupRole): Promise<AppGroupEventMember> {

        return new Promise<AppGroupEventMember>((resolve, reject) => {

            try {

                //console.log('app.group.ts AppGroupEvent addMemberToGroup');

                //if this is a new member then it needs saving
                addMember
                    .save()
                    .then(() => {

                        //add memberId
                        const groupMember: AppGroupEventMember = new AppGroupEventMember();
                        groupMember.initialize({ memberId: addMember.id, role: role })
                            .then(() => {

                                //add to group
                                this.members.push(groupMember);

                                //add to list of newly invited members...we will use this to send out an invite email
                                this._newlyAddedGroupMembers.push(groupMember);

                                resolve(groupMember);

                            });

                    });

            } catch (err) {
                console.log('app.group.ts AppGroupEvent addMemberToGroup err', err);
                reject(err)
            }

        });

    }

    isMember(member: AppMember): boolean {

        //determine if member is a member of the group
        return !(this.getGroupMember(member) === undefined);

    }

    getGroupMember(member: AppMember): AppGroupEventMemberI {

        //find member
        return this.members.find((groupMember) => {
            //cast to get member
            return (<AppGroupEventMember>groupMember).member.id === member.id;
        });

    }

    makeAdmin(member: AppMember) {

        //then make admin
        this.getGroupMember(member).role = MemberGroupRole.Administrator;

    }

    removeAdmin(member: AppMember) {

        //then make admin
        this.getGroupMember(member).role = MemberGroupRole.Member;

    }

    private getMembers(groupMembers: AppGroupEventMemberI[]): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            //save the array of promises
            const promiseArray: any[] = [];

            //create new array
            const members: AppGroupEventMember[] = [];

            //now create member array
            groupMembers
                .forEach((groupMember) => {

                    //find group member in group members array
                    const foundGroupMember: AppGroupEventMember = this.members.find((member) => {
                        return (<AppGroupEventMember>member).member.id === groupMember.memberId;
                    });

                    //if not found then create
                    if (!foundGroupMember) {

                        const member: AppGroupEventMember = new AppGroupEventMember();
                        const p = member.initialize(groupMember);

                        //only add to array if initialized successfully
                        p.then((isSuccessful) => {
                            if (isSuccessful) {
                                members.push(member);
                            }
                        });

                        promiseArray.push(p);

                    } else {
                        //if found then update
                        members.push(foundGroupMember);
                        foundGroupMember.update(groupMember);
                    }

                });

            //wait for all promises to return
            Promise
                .all(promiseArray)
                .then(() => {
                    //now save to main array, this is sort of hacky but by doing this it will effectively remove any members that have been removed from the group
                    this.members = members;
                    this.members.sortBy('role', SortByOrder.ASC);
                    resolve();
                })
                .catch((err) => {
                    console.log('app.group.ts AppGroupEvent getMembers error', err, JSON.stringify(err));
                    reject(err);
                });

        });

    }

    private saveMember(member: AppMember): Promise<string> {

        return new Promise<string>((resolve, reject) => {

            //if member is new to group then...
            if (member.dirty) {

                //see if new group member is already exists
                this._accountService
                    .getMemberByEmail(member.email)
                    .then((memberFound) => {

                        //member doesn't exist
                        if (memberFound === undefined) {

                            member
                                .save()
                                .then((member) => {

                                    //console.log('group-detail.ts saveMember member.save success');

                                    //add new member to array
                                    resolve(member.id);

                                })
                                .catch((err) => {
                                    console.log('group-detail.ts saveMember error', JSON.stringify(err));
                                    reject(err);
                                });

                        } else {
                            //add exsiting member new to array
                            resolve(memberFound.id);
                        }

                    })
                    .catch((err) => {
                        console.log('group-detail.ts saveMember getMemberByEmail error', JSON.stringify(err));
                        reject(err);
                    });

            } else {
                //add exiting member to array
                resolve(member.id);
            }

        });

    }

    //#endregion member methods

    sendGroupMessageEmail(editMode, subject: string, message: string): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                const personalizations = [];
                let showGroupAvatar: string = 'none';
                const promiseArray: any[] = [];

                //if the group has an avatar then show
                if (this.avatar.URI.length > 0) {
                    showGroupAvatar = 'table-row';
                }

                //send to each member of the group
                this.members
                    .forEach((groupMember) => {

                        //cast to get member
                        const member: AppMember = (<AppGroupEventMember>groupMember).member;

                        //get email preferences
                        const q = member
                            .getPreference(this.id)
                            .then((preference: memberGroupPreferences) => {

                                //determine if this user wants this email
                                const sendEmail: boolean =
                                    (editMode === AppConfig.EDIT_MODE.new && preference.e === EventEmailPreference.First) || //member only wants new event emails
                                    (preference.e === EventEmailPreference.All || preference.e === undefined) //member wants all emails or member hasn't set preferences

                                //console.log('event-detail.ts sendEventEmail getPreference', preference, sendEmail);

                                //if event update email (e) preference is set or is undefined (will then assume true)
                                if (sendEmail) {

                                    personalizations.push({
                                        "subject": subject,
                                        "templateId": AppConfig.SENDGRID_TEMPLATES.GroupMessage,
                                        "to": {
                                            "name": member.firstName + ' ' + member.lastName,
                                            "email": member.email
                                        },
                                        "from": {
                                            "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                            "email": AppConfig.NOREPLY_EMAIL
                                        },
                                        "replyTo": {
                                            "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                            "email": this._accountService.member.email
                                        },
                                        "dynamic_template_data": {
                                            //"domain": environment.hosting.app,
                                            "subject": subject,
                                            "message": message,
                                            "firstName": member.firstName,
                                            "lastName": member.lastName,
                                            "groupName": this.name,
                                            "groupAvatarURI": this.avatar.URIEmail,
                                            "showGroupAvatar": showGroupAvatar,
                                            "memberId": member.id,
                                            "groupId": this.id
                                        },
                                        "hideWarnings": true
                                    });

                                }

                            });

                        promiseArray.push(q);

                    });

                Promise
                    .all(promiseArray)
                    .then(() => {

                        this._appFunction
                            .sendEmail(personalizations)
                            .then(() => {

                                this._appFunction
                                    .queueToast({
                                        message: 'The group email has been sent.',
                                        position: 'top',
                                        duration: 4000,
                                        color: 'secondary',
                                        closeButtonText: 'Ok'
                                    });

                                resolve();
                            })
                            .catch((err) => {
                                reject(err);
                            });

                    });

            } catch (err) {
                console.log('app.group.ts sendGroupMessageEmail promise.all error', err, JSON.stringify(err));
                reject();
            }

        });

    }

    private sendNewlyAddedMemberEmail(): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //only send invite email if...
                if (this._newlyAddedGroupMembers.length > 0) {

                    const personalizations = [];
                    const promiseArray: any[] = [];

                    //if the group has an avatar then show
                    let showGroupAvatar: string = 'none';
                    if (this.avatar.URI.length > 0) {
                        showGroupAvatar = 'table-row';
                    }

                    //send to each player of the event
                    this._newlyAddedGroupMembers
                        .forEach((groupMember) => {

                            //create deep link object
                            const deepLink: DeepLinkParmsI = {
                                route: '/main/groups',
                                page: AppConfig.PAGE.GroupEventView,
                                id: this.id,
                                segment: '',
                                actionCd: GroupEventActionCd.AddedToGroupByOrganizer,
                                actionCdMessage: null,
                                email: groupMember.member.email,
                                welcomeMessage: 'Hi ' + groupMember.member.firstName + ', you have been added you to the <b>' + this.name + '</b> by ' + this._accountService.member.firstName + ' ' + this._accountService.member.lastName + '.',
                                emailHasAccount: null,
                                additionalData: null
                            }

                            //create deep link
                            const p = this._deepLinkService
                                .createDeepLink(DeepLinkCampaign.GroupMemberAdded, DeepLinkChannel.email, deepLink, { title: 'Welcome to Double Ace Golf', description: 'For the best experience please leave the checkbox below selected.' })
                                .then((groupAddedMemberDeepLink) => {

                                    personalizations.push({
                                        "subject": this.name,
                                        "templateId": AppConfig.SENDGRID_TEMPLATES.GroupMemberAdded,
                                        "to": {
                                            "name": groupMember.member.firstName + ' ' + groupMember.member.lastName,
                                            "email": groupMember.member.email
                                        },
                                        "from": {
                                            "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                            "email": AppConfig.NOREPLY_EMAIL
                                        },
                                        "replyTo": {
                                            "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                            "email": this._accountService.member.email
                                        },
                                        "dynamic_template_data": {
                                            "subject": this.name,
                                            "memberFirstName": groupMember.member.firstName,
                                            "memberLastName": groupMember.member.lastName,
                                            "organizerFirstName": this._accountService.member.firstName,
                                            "organizerLastName": this._accountService.member.lastName,
                                            "groupName": this.name,
                                            "groupAvatarURI": this.avatar.URIEmail,
                                            "showGroupAvatar": showGroupAvatar,
                                            "addedDeepLink": groupAddedMemberDeepLink

                                            /*"domain": environment.hosting.join,
                                            "groupId": this.id,
                                            "memberId": groupMember.member.id,
                                            "environment": environment.cloudfunctions,*/

                                        },
                                        "hideWarnings": true
                                    });

                                });

                            promiseArray.push(p);

                        });

                    //once all deep links have been created send email
                    Promise
                        .all(promiseArray)
                        .then(() => {

                            this._appFunction
                                .sendEmail(personalizations)
                                .then(() => {

                                    this._appFunction
                                        .queueToast({
                                            message: 'An email has been sent to your new group members.',
                                            position: 'top',
                                            duration: 4000,
                                            color: 'secondary',
                                            closeButtonText: 'Ok'
                                        });

                                    //clear array
                                    this._newlyAddedGroupMembers = [];

                                    resolve();

                                })
                                .catch((err) => {
                                    console.log('app.group.ts AppGroupEvent sendNewlyAddedMemberEmail sendEmail error', err, JSON.stringify(err));
                                    reject(err);
                                });

                        });

                } else {
                    //noop
                    resolve();
                }

            } catch (err) {
                console.log('app.group.ts AppGroupEvent sendNewlyAddedMemberEmail error', err, JSON.stringify(err));
                reject();
            }

        });

    }

    sendNewGroupEmail(member: AppMember): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                const personalizations = [];

                //if the group has an avatar then show
                let showGroupAvatar: string = 'none';
                if (this.avatar.URI.length > 0) {
                    showGroupAvatar = 'table-row';
                }

                //create deep link object for new group
                const deepLink: DeepLinkParmsI = {
                    route: '/main/groups',
                    page: AppConfig.PAGE.GroupEventDetail,
                    id: this.id,
                    segment: '',
                    actionCd: GroupEventActionCd.NewGroup,
                    actionCdMessage: null,
                    email: member.email,
                    welcomeMessage: 'Hi ' + member.firstName + ', congratulations on your new group. Please find these helpful tips to get you started.',
                    emailHasAccount: null,
                    additionalData: null
                }

                //create deep link
                this._deepLinkService
                    .createDeepLink(DeepLinkCampaign.NewGroup, DeepLinkChannel.email, deepLink, { title: 'Welcome to Double Ace Golf', description: 'For the best experience please leave the checkbox below selected.' })
                    .then((newGroupDeepLink) => {

                        personalizations.push({
                            "subject": this.name,
                            "templateId": AppConfig.SENDGRID_TEMPLATES.NewGolfGroup,
                            "to": {
                                "name": member.firstName + ' ' + member.lastName,
                                "email": member.email
                            },
                            "from": {
                                "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                "email": AppConfig.NOREPLY_EMAIL
                            },
                            "replyTo": {
                                "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                "email": this._accountService.member.email
                            },
                            "dynamic_template_data": {
                                "subject": this.name,
                                "organizerFirstName": this._accountService.member.firstName,
                                "organizerLastName": this._accountService.member.lastName,
                                "groupName": this.name,
                                "groupAvatarURI": this.avatar.URIEmail,
                                "showGroupAvatar": showGroupAvatar,
                                "addedDeepLink": newGroupDeepLink
                            },
                            "hideWarnings": true
                        });

                        this._appFunction
                            .sendEmail(personalizations)
                            .then(() => {
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.group.ts AppGroupEvent sendNewGroupEmail sendEmail error', err, JSON.stringify(err));
                                reject(err);
                            });

                    });


            } catch (err) {
                console.log('app.group.ts AppGroupEvent sendNewGroupEmail error', err, JSON.stringify(err));
                reject();
            }

        });

    }

    isMemberAdmin(member: AppMember): boolean {
        return this.members?.some((memberId) => { return memberId.memberId === member.id && [MemberGroupRole.Owner, MemberGroupRole.Administrator].includes(memberId.role) });
    }

    getAdmins(): AppMember[] {

        const admins: AppMember[] = [];
        this.members?.forEach((groupMember) => {
            const member: AppMember = (<AppGroupEventMember>groupMember).member;
            if (this.isMemberAdmin(member)) {
                admins.push(member);
            }
        });

        return admins;

    }

    save(batch: firebase.firestore.WriteBatch = undefined): Promise<AppGroupI> {

        return new Promise<AppGroupEvent>((resolve, reject) => {

            try {

                //first loop through member array and create any new members
                const promiseArray: any[] = [];
                this.members
                    .forEach((groupMember) => {

                        //cast to get member
                        const member: AppMember = (<AppGroupEventMember>groupMember).member;

                        const p = this.saveMember(member);
                        promiseArray.push(p);

                    });

                //now save this group object
                Promise
                    .all(promiseArray)
                    .then(() => {

                        //if a batch passed in then... 
                        if (batch) {

                            try {
                                //console.log('app.group.ts AppGroupEvent save batch set success');
                                batch.set(this._groupDoc.ref, this.data(), { merge: true });

                                //send add emails
                                this.sendNewlyAddedMemberEmail();

                                resolve(this);
                            }
                            catch (err) {
                                console.log('app.group.ts AppGroupEvent save batch set error', JSON.stringify(err));
                                reject(err);
                            }

                        } else {

                            this._groupDoc
                                .ref
                                .set(this.data(), { merge: true })
                                .then(() => {

                                    //send add emails
                                    this.sendNewlyAddedMemberEmail();

                                    resolve(this);
                                })
                                .catch((err) => {
                                    console.log('app.group.ts AppGroupEvent save set error', JSON.stringify(err));
                                    reject(err);
                                });

                        }

                    });

            } catch (err) {
                console.log('app.group.ts AppGroupEvent save try error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    delete(): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //set status
                this.deleted = true;

                //save event
                this.save()
                    .then(() => {

                        //save the array of promises
                        const promiseArray: any[] = [];

                        //remove member preferences from group, do it here (rather than cloud function) because we already have members loaded in app
                        this.members
                            .forEach((groupMember) => {
                                //cast to get member
                                const member: AppMember = (<AppGroupEventMember>groupMember).member;

                                //remove pref
                                const p = member.removePreference(this.id);
                                promiseArray.push(p);
                            });

                        //once all done ...
                        Promise
                            .all(promiseArray)
                            .then(() => {
                                resolve();
                            });

                    })
                    .catch((err) => {
                        console.log('app.event.ts AppGroupEvent delete save error', JSON.stringify(err));
                        reject(err);
                    });

            } catch (err) {
                console.log('app.group.ts AppGroupEvent delete error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    private data(): AppGroupEventI {

        try {

            //build group member and member search arrays
            const groupMembers: AppGroupEventMemberI[] = [];
            const searchMemberIds: string[] = [];
            this.members.forEach((groupMember) => {
                groupMembers.push((<AppGroupEventMember>groupMember).data());
                searchMemberIds.push(groupMember.memberId);
            });

            return {
                name: this.name.trim(),
                searchName: this.searchName,
                description: this.description ? this.description.trim() : null,
                numberTeeTimes: this.numberTeeTimes,
                teeTimeInterval: this.teeTimeInterval,
                avatarFileName: this.avatarFileName,
                coverFileName: this.coverFileName,
                members: groupMembers, //new member struct
                searchMemberIds: searchMemberIds,  //new search array
                ownerMemberId: this.ownerMemberId,
                clubId: this.clubId,
                courseId: this.courseId || null,
                teeId: this.teeId || null,
                public: this.public,
                postCount: this.postCount,
                type: this.type,
                deleted: this.deleted,
                //adminMemberIds: this.adminMemberIds || null, //old member struct, this will go away in the future
                //memberIds: this.memberIds || null, //old member admin array, this will go away in the future
                numberOfHoles: this.numberOfHoles || null,
                nineHolesOnlyIndex: this.numberOfHoles === 18 ? null : this.nineHolesOnlyIndex === undefined ? null : this.nineHolesOnlyIndex,
                updatedDt: firebase.firestore.Timestamp.fromDate(new Date()),
                createdDt: this.createdDt || firebase.firestore.Timestamp.fromDate(new Date())
            };

        }
        catch (err) {
            console.log('app.group.ts AppGroupEvent data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

export interface AppGroupTripMemberI {
    memberId: string;
    role: MemberGroupRole;
    status: TripAttendanceStatus;
    statusDt: firebase.firestore.Timestamp;
}

export class AppGroupTripMember implements AppGroupTripMemberI, MatchPlayerI {

    id: string;
    memberId: string;
    role: MemberGroupRole;
    member: AppMember;
    statusDt: firebase.firestore.Timestamp;
    private _status: TripAttendanceStatus;
    private _accountService: AccountService;

    constructor() {
        this._accountService = AppFunction.serviceLocator.get(AccountService);
    }

    initialize(groupMember: AppGroupTripMemberI): Promise<boolean> {

        return new Promise<boolean>((resolve) => {
            this.id = this.memberId = groupMember.memberId;
            this.update(groupMember);
            this._accountService
                .getMember(this.memberId)
                .toPromise()
                .then((foundMember) => {

                    if (foundMember) {
                        this.member = foundMember;
                        resolve(true);
                    } else {
                        resolve(false);
                    }

                })
                .catch((err) => {
                    console.log('app.group.ts AppGroupTripMember getMember error', err, JSON.stringify(err));
                    resolve(false);
                });
        });

    }

    update(groupMember: AppGroupTripMemberI) {
        this.role = groupMember.role;
        this._status = groupMember.status;
        this.statusDt = groupMember.statusDt;
    }

    get scoringPlayerId(): string {
        return this.member.id;
    }

    get status(): TripAttendanceStatus {
        return this._status;
    }

    set status(status: TripAttendanceStatus) {
        this._status = status;
        this.statusDt = firebase.firestore.Timestamp.fromDate(new Date());
    }

    /* private getMember(memberId: string): Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {

            this._accountService
                .getMember(memberId)
                .toPromise()
                .then((foundMember) => {

                    if (foundMember) {
                        this.member = foundMember;
                        resolve(true);
                    } else {
                        resolve(false);
                    }

                })
                .catch((err) => {
                    console.log('app.group.ts AppGroupTripMember getMember error', err, JSON.stringify(err));
                    resolve(false);
                });

        });

    } */

    get firstName(): string {
        return this.member?.firstName;
    }

    get lastName(): string {
        return this.member?.lastName;
    }

    get email(): string {
        return this.member?.email;
    }

    get nines(): AppPlayerScoreNineI[] {
        return [];
    }

    teeHandicapDisplay() { return undefined }

    data(): AppGroupTripMemberI {
        return {
            memberId: this.memberId,
            role: this.role,
            status: this.status,
            statusDt: this.statusDt
        }
    }

}

export interface AppGroupTripI {
    ownerMemberId: string;
    name: string;
    searchName: string;
    description: string;
    avatarFileName: string;
    coverFileName: string;
    public: boolean;
    numberOfPlayers: number;
    members: AppGroupTripMemberI[];
    searchMemberIds: string[];
    type: GroupType;
    matchIds: string[];
    departureDt: firebase.firestore.Timestamp;
    returnDt: firebase.firestore.Timestamp;
    updatedDt: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
    postCount: number;
    deleted: boolean;
}

interface AppInOutPushNotificationI {
    originalStatus: TripAttendanceStatus,
    newStatus: TripAttendanceStatus,
    groupMember: AppGroupTripMember,
    group: AppGroupI
}

export class AppGroupTrip implements AppGroupTripI, AppGroupI, AppClassI, AppMatchParentI {

    id: string;
    name: string;
    description: string = null;
    updatedDt: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
    public: boolean = false;
    postCount: number = 0;
    exists: boolean = false;
    members: AppGroupTripMember[] = [];
    type: GroupType = GroupType.Trip;
    searchMemberIds: string[];
    class: string = 'AppGroupTrip';
    deleted: boolean = false;
    ownerMember: AppMember;
    matchIds: string[] = [];
    updateEventPlayerScore: Subject<string> = new BehaviorSubject<string>(undefined);
    private _ownerMemberId: string;
    private _defaultNuymberOfPlayers: number = 8;
    private _departureDt: firebase.firestore.Timestamp;
    private _returnDt: firebase.firestore.Timestamp;
    private _numberOfPlayers: number = this._defaultNuymberOfPlayers;
    private _avatarFileName: string = '';
    private _coverFileName: string = '';
    private _groupDoc: firebase.firestore.DocumentSnapshot;
    private _appFunction: AppFunction;
    private _accountService: AccountService;
    private _eventService: EventService;
    private _deepLinkService: DeepLinkService;
    private _originalAvatarFileName: string = '';
    private _originalCoverFileName: string = '';
    private _newlyInvitedGroupMembers: AppGroupTripMember[] = [];
    private _inOutPushNotifications: AppInOutPushNotificationI[] = []; //used to keep track of new in or out group members so we can message the group organizer appropriately

    constructor() {

        //get services
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);
        this._accountService = AppFunction.serviceLocator.get(AccountService);
        this._eventService = AppFunction.serviceLocator.get(EventService);
        this._deepLinkService = AppFunction.serviceLocator.get(DeepLinkService);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.group.ts AppGroupTrip shutdown', this.id);
            });

    }

    initialize(groupDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Promise<AppGroupTrip> {

        return new Promise<AppGroupTrip>((resolve, reject) => {

            if (groupDoc) {

                //save document refenence
                this._groupDoc = groupDoc;
                this.id = groupDoc.id;

                //listen for group updates
                const groupSnapShotUnsubscribe = groupDoc
                    .ref
                    .onSnapshot((groupUpdate) => {

                        this.update(groupUpdate)
                            .then(() => {
                                resolve(this);
                            })
                            .catch((err) => {
                                reject(err);
                            });

                    });

                this._appFunction.registerUnsubscribe(groupSnapShotUnsubscribe);

            } else {

                //create new group doc ref
                const groupDoc: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.Groups).doc();

                //now get it, it should be empty as this is a new group object
                groupDoc
                    .get()
                    .then((newGroupDoc) => {

                        //save doc, this will be used when saving
                        this._groupDoc = newGroupDoc;
                        this.id = groupDoc.id;
                        resolve(this);

                    });

            }

        });

    }

    private update(updatedGroup: firebase.firestore.DocumentSnapshot): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                this._appFunction
                    .ngZone
                    .run(async () => {

                        if (updatedGroup.exists) {

                            //update high level attributes
                            this.ownerMemberId = updatedGroup.data().ownerMemberId || undefined;
                            this.name = updatedGroup.data().name || null;
                            this.description = updatedGroup.data().description || null;
                            this.numberOfPlayers = updatedGroup.data().numberOfPlayers || this._defaultNuymberOfPlayers;
                            this._departureDt = updatedGroup.data().departureDt || undefined;
                            this._returnDt = updatedGroup.data().returnDt || undefined;
                            this.avatarFileName = this._originalAvatarFileName = updatedGroup.data().avatarFileName || '';
                            this.coverFileName = this._originalCoverFileName = updatedGroup.data().coverFileName || '';
                            this.createdDt = updatedGroup.data().createdDt || undefined;
                            this.postCount = updatedGroup.data().postCount || 0;
                            this.deleted = updatedGroup.data().deleted || false;
                            this.matchIds = updatedGroup.data().matchIds;
                            this.exists = true;

                            //get data
                            const p = await this.getMembers(updatedGroup.data().members);
                            const q = await this.events.get(this);
                            const r = await this.matches.getMatches();

                            //when done return
                            Promise
                                .all([p, q, r])
                                .then(() => {
                                    resolve();
                                });

                        } else {
                            this.exists = false;
                            resolve();
                        }

                    });

            } catch (err) {
                console.log('app.group.ts AppGroupTrip update error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    //#region properties

    set avatarFileName(avatarfileName: string) {
        //set property
        this._avatarFileName = avatarfileName;
    }

    get avatarFileName(): string {
        return this._avatarFileName;
    }

    set coverFileName(coverfileName: string) {
        //set property
        this._coverFileName = coverfileName;
    }

    get coverFileName(): string {
        return this._coverFileName;
    }

    get searchName(): string {
        return this.name.toUpperCase();
    }

    get numberOfPlayers(): number {
        return this._numberOfPlayers;
    }

    set numberOfPlayers(numberOfPlayers: number) {
        this._numberOfPlayers = numberOfPlayers;
    }

    get departureDt(): firebase.firestore.Timestamp {
        return this._departureDt;
    }

    set departureDt(departureDt: firebase.firestore.Timestamp) {
        this._departureDt = departureDt;
    }

    get returnDt(): firebase.firestore.Timestamp {
        return this._returnDt;
    }

    set returnDt(returnDt: firebase.firestore.Timestamp) {
        this._returnDt = returnDt;
    }

    get allFull(): boolean {
        return this.invited.in.length === this.numberOfPlayers;
    }

    get ownerMemberId(): string {
        return this._ownerMemberId;
    }

    set ownerMemberId(ownerMemberId: string) {
        this._ownerMemberId = ownerMemberId;
        this._accountService
            .getMember(ownerMemberId)
            .toPromise()
            .then((ownerMember) => {
                this.ownerMember = ownerMember;
            });
    }

    //#endregion properties

    invited = new class {

        constructor(private parentMember: AppGroupTrip) {
        }

        get in(): AppGroupTripMember[] {

            //return members that have accepted the invitation ('in')
            const inMembers: AppGroupTripMember[] = <AppGroupTripMember[]>(this.parentMember.members)?.filter((groupMember) => {
                return groupMember.status === TripAttendanceStatus.In;
            }) || [];

            //sort all the 'in' members based on when they accepted the invitation 
            inMembers.sortBy('role', SortByOrder.ASC, 'statusDt', SortByOrder.ASC, 'member.firstName', SortByOrder.ASC, 'member.lastName', SortByOrder.ASC);

            //now get the top x members based on the trip size (number of configured players)...and return
            return inMembers.slice(0, this.parentMember.numberOfPlayers);

        }

        get out(): AppGroupTripMember[] {

            //return members that have accepted the invitation ('out')
            const inMembers: AppGroupTripMember[] = <AppGroupTripMember[]>(this.parentMember.members)?.filter((groupMember) => {
                return groupMember.status === TripAttendanceStatus.Out;
            }) || [];

            //sort all the 'out' members based on when they were out
            inMembers.sortBy('role', SortByOrder.ASC, 'statusDt', SortByOrder.ASC, 'member.firstName', SortByOrder.ASC, 'member.lastName', SortByOrder.ASC);

            //now ge the top x members based on the number of players...and return
            return inMembers;

        }

        membersOnWaitList(): AppGroupTripMember[] {

            //return members that have accepted the invitation ('in') but are on the outside of the numberOfPlayers for the trip
            return <AppGroupTripMember[]>(this.parentMember.members)?.filter((groupMember) => {
                return groupMember.status === TripAttendanceStatus.In;
            }).slice(this.parentMember.numberOfPlayers) || [];

        }

        get waitList(): AppGroupTripMember[] {

            //
            this.parentMember.sortMembers();

            //return members that have accepted the invitation ('in') but are on the outside of the numberOfPlayers for the trip
            const inMembersOnWaitList: AppGroupTripMember[] = this.membersOnWaitList();

            //return members that have neither accepted or declined the invitation (i.e. still in Invited status)
            const invitedMembers: AppGroupTripMember[] = <AppGroupTripMember[]>(this.parentMember.members)?.filter((groupMember) => {
                return groupMember.status === TripAttendanceStatus.Invited;
            }) || [];

            //merge the two lists
            const waitListMembers: AppGroupTripMember[] = [...inMembersOnWaitList, ...invitedMembers];

            //sort,
            waitListMembers.sortBy('role', SortByOrder.ASC, 'status', SortByOrder.ASC, 'statusDt', SortByOrder.ASC);

            //and return
            return waitListMembers;

        }

        changeTripStatus(groupMember: AppGroupTripMember, status: TripAttendanceStatus) {

            //set status 
            groupMember.status = status;

            //if status is "in" then add to existing events
            if (status === TripAttendanceStatus.In) {
                this.parentMember.addMemberToEvents(groupMember);
            } else {
                this.parentMember.removeMemberFromEvents(groupMember);
            }

        }

    }(this);

    avatar = new class implements imageManagementI {

        private _mediaService: MediaService = AppFunction.serviceLocator.get(MediaService);

        constructor(private parentMember: AppGroupTrip) {
        }

        save(localFileURI: string): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                //delete current/previous avatar 
                this.delete(false)
                    .then(() => {

                        //upload and save new avatar reference
                        this
                            .saveAvatarToStorage(localFileURI)
                            .then(() => {
                                //console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage success');
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage error', JSON.stringify(err));
                                reject(err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage error', JSON.stringify(err));
                        reject(err);
                    });

            });

        }

        private saveAvatarToStorage(localFileURI: string): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                //now save avatar file
                this._mediaService
                    .saveMedia(localFileURI, this.parentMember._appFunction.newGuid(), AppConfig.MEDIA_STORAGE.AVATAR)
                    .then((avatarURI) => {

                        //set avatar names
                        this.parentMember.avatarFileName = avatarURI;
                        this.parentMember._originalAvatarFileName = avatarURI;

                        //now save new avatar name
                        this.parentMember
                            .save()
                            .then(() => {
                                //console.log('app.group.ts AppGroupEvent saveAvatarToStorage save success');
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.group.ts AppGroupEvent saveAvatarToStorage error', JSON.stringify(err));
                                reject(err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts AppGroupEvent saveAvatarToStorage saveMedia error', JSON.stringify(err));
                        reject(err);
                    });

            });

        }

        delete(save: boolean): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                if (this.parentMember._originalAvatarFileName.trim().length > 0) {

                    this._mediaService
                        .deleteMedia(this.parentMember._originalAvatarFileName)
                        .then(() => {

                            //console.log('app.group.ts AppGroupEvent deleteAvatar remove old avatar success');

                            //clear out avatar
                            this.parentMember._originalAvatarFileName = '';

                            //now optionally save
                            if (save) {

                                //if saving then clear out avatar file name
                                this.parentMember.avatarFileName = '';

                                this.parentMember
                                    .save()
                                    .then(() => {
                                        //console.log('app.group.ts AppGroupEvent deleteAvatar save success');
                                        resolve();
                                    })
                                    .catch((err) => {
                                        console.log('app.group.ts AppGroupEvent deleteAvatar save error', JSON.stringify(err));
                                        reject(err);
                                    });

                            } else {
                                //no op
                                resolve();
                            }

                        })
                        .catch((err) => {
                            console.log('app.group.ts AppGroupEvent deleteAvatar remove old avatar error', this.parentMember._originalAvatarFileName, err, JSON.stringify(err));
                            reject();
                        });

                } else {
                    //noop, no image to delete
                    console.log('app.group.ts AppGroupEvent deleteAvatar no image to delete');
                    resolve()
                }

            });

        }

        get URI(): string {

            if (this.parentMember.avatarFileName.length > 0) {
                return this.parentMember.avatarFileName;
            } else {
                return AppConfig.NO_TRIP_AVATAR_URI;
            }

        }

        get URIEmail(): string {

            if (this.parentMember.avatarFileName.length > 0) {
                return this.parentMember.avatarFileName;
            } else {
                return AppConfig.NO_AVATAR_URI_EMAIL;
            }

        }

    }(this);

    cover = new class implements imageManagementI {

        private _mediaService: MediaService = AppFunction.serviceLocator.get(MediaService);

        constructor(private parentMember: AppGroupTrip) {
        }

        save(localFileURI: string): Promise<void> {

            console.log('app.group.ts AppGroupEvent saveCover', localFileURI);

            return new Promise<void>((resolve, reject) => {

                //delete old/previous cover
                this.delete(false)
                    .then(() => {

                        //upload and save cover reference
                        this
                            .saveCoverToStorage(localFileURI)
                            .then(() => {
                                console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage success');
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage error', JSON.stringify(err));
                                reject(err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts AppMember saveAvatar saveAvatarToStorage error', JSON.stringify(err));
                        reject(err);
                    });

            });

        }

        private saveCoverToStorage(localFileURI: string): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                //now save avatar file
                this._mediaService
                    .saveMedia(localFileURI, this.parentMember._appFunction.newGuid(), AppConfig.MEDIA_STORAGE.AVATAR)
                    .then((coverURI) => {

                        //set avatar names
                        this.parentMember.coverFileName = coverURI;
                        this.parentMember._originalCoverFileName = coverURI;

                        //now save new avatar name
                        this.parentMember
                            .save()
                            .then(() => {
                                //console.log('app.group.ts AppGroupEvent saveCoverToStorage save success');
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.group.ts AppGroupEvent saveCoverToStorage error', JSON.stringify(err));
                                reject(err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts AppGroupEvent saveCoverToStorage saveMedia error', JSON.stringify(err));
                        reject(err);
                    });

            });

        }

        delete(save: boolean): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                if (this.parentMember._originalCoverFileName.trim().length > 0) {

                    this._mediaService
                        .deleteMedia(this.parentMember._originalCoverFileName)
                        .then(() => {

                            //clear out avatar
                            this.parentMember._originalCoverFileName = '';

                            //now optionally save
                            if (save) {

                                //if saving then clear out avatar file name
                                this.parentMember.coverFileName = '';

                                this.parentMember
                                    .save()
                                    .then(() => {
                                        //console.log('app.group.ts AppGroupEvent deleteCover save success');
                                        resolve();
                                    })
                                    .catch((err) => {
                                        console.log('app.group.ts AppGroupEvent deleteCover save error', JSON.stringify(err));
                                        reject(err);
                                    });

                            } else {
                                //no op
                                resolve();
                            }

                        })
                        .catch((err) => {
                            console.log('app.group.ts AppGroupEvent deleteCover remove old avatar error', this.parentMember._originalAvatarFileName, err, JSON.stringify(err));
                            reject();
                        });

                } else {
                    //noop, no image to delete
                    console.log('app.group.ts AppGroupEvent deleteCover no image to delete');
                    resolve()
                }

            });

        }

        get URI(): string {

            if (this.parentMember.coverFileName.length > 0) {
                return this.parentMember.coverFileName;
            } else {
                return AppConfig.NO_COVER_TRIP_URI;
            }

        }

    }(this);

    social = new class {

        private _posts: AppPost[];
        private _lastPostCreatedDt: firebase.firestore.Timestamp = undefined;
        private _futurePostCreatedDt: firebase.firestore.Timestamp = undefined;
        private _futurePostsUnsubscribe: any;

        constructor(private parentMember: AppGroupTrip) {
        }

        getPosts(): Promise<AppPost[]> {

            return new Promise<AppPost[]>((resolve) => {

                if (Array.isArray(this._posts)) {
                    resolve(this._posts);
                } else {

                    //init posts aray
                    this._posts = [];

                    //get first set of posts
                    this.getNextPosts()
                        .then((posts) => {
                            resolve(posts);
                        });

                }

            });

        }

        getNextPosts(): Promise<AppPost[]> {

            return new Promise<AppPost[]>((resolve, reject) => {

                try {

                    //create the array for promises
                    const promiseArray: any[] = [];

                    //array for this call
                    const nextPosts: AppPost[] = [];

                    //for the first query set the next and future post dates
                    if (!this._lastPostCreatedDt) {
                        this._lastPostCreatedDt = firebase.firestore.Timestamp.now();
                        this._futurePostCreatedDt = this._lastPostCreatedDt;
                    }

                    //create snapshot listener
                    this.parentMember
                        ._appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Posts)
                        .where('groupId', '==', this.parentMember.id)
                        .where('createdDt', '<', this._lastPostCreatedDt)
                        .orderBy('createdDt', 'desc')
                        .limit(AppConfig.NUMBER_OF_POSTS_TO_FETCH)
                        .get({ source: 'server' })
                        .then((foundPosts) => {

                            //get social following updates
                            foundPosts
                                .docs
                                .forEach((foundPost) => {

                                    //create following object with data retrieved from following collection
                                    const post: AppPost = new AppPost();

                                    //once object has been initialized...
                                    const p = post
                                        .initialize(foundPost)
                                        .then(() => {

                                            //save last retrieved post date (this will be used to get the next set of posts)
                                            this._lastPostCreatedDt = post.createdDt;

                                            //posts for this call
                                            nextPosts.push(post)

                                            //add to group's posts
                                            this._posts.push(post);

                                        });

                                    promiseArray.push(p);

                                });

                            //wait for all promises to return
                            Promise
                                .all(promiseArray)
                                .then(() => {
                                    resolve(nextPosts);
                                })
                                .catch((err) => {
                                    console.log('app.group.ts getPosts error', err);
                                    reject(err);
                                });

                        })
                        .catch((err) => {
                            console.log('app.group.ts AppGropTrip getPosts get', err, JSON.stringify(err));
                            reject(err);
                        });

                } catch (err) {
                    console.log('app.group.ts AppGropTrip getPosts', err, JSON.stringify(err));
                    reject(err);
                }

            });

        }

        getFuturePosts(): Observable<AppPost> {

            return new Observable((observer) => {

                try {

                    //only allow this once 
                    if (!this._futurePostsUnsubscribe) {

                        //create snapshot listener for future posts
                        this._futurePostsUnsubscribe = this.parentMember
                            ._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Posts)
                            .where('groupId', '==', this.parentMember.id)
                            .where('createdDt', '>=', this._futurePostCreatedDt)
                            .onSnapshot((foundPosts) => {

                                //get social following updates
                                foundPosts
                                    .docChanges()
                                    .forEach((foundPost) => {

                                        if (foundPost.type === AppConfig.UPDATE_TYPE.Added) {

                                            //create post object with data retrieved from post collection
                                            const appPost: AppPost = new AppPost();

                                            //once object has been initialized...
                                            const p = appPost
                                                .initialize(foundPost.doc)
                                                .then(() => {

                                                    //add to group's posts
                                                    this._posts.push(appPost);

                                                });

                                            //...then publish the new post
                                            p.then(() => {
                                                observer.next(appPost);
                                            });

                                        }

                                    });

                            });

                        this.parentMember._appFunction.registerUnsubscribe(this._futurePostsUnsubscribe);

                    }

                } catch (err) {
                    console.log('app.group.ts AppGroupTrip getFuturePosts', err, JSON.stringify(err));
                }

            });

        }

        updatePostCount(value: number): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                try {

                    //now increment count
                    this.parentMember
                        ._appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Groups)
                        .doc(this.parentMember.id)
                        .set({ postCount: firebase.firestore.FieldValue.increment(value) }, { merge: true })
                        .then(() => {
                            resolve();
                        })
                        .catch((err) => {
                            console.log('app.group.ts AppGroupTrip updatePostCount set error', JSON.stringify(err));
                            reject(err);
                        });

                } catch (err) {
                    console.log('app.group.ts AppGroupTrip updatePostCount error', JSON.stringify(err));
                }

            });

        }

    }(this);

    matches = <MatchesI>new class {

        private _matches: AppMatch[] = [];
        private _runOnceGetMatches: boolean = false;

        constructor(private parentGroupTrip: AppGroupTrip) {
        }

        //return all non deleted group and member? matches
        get active(): AppMatch[] {

            return this._matches?.filter((match) => {
                return match.exists;
            }) || [];

        }

        //return just group matches...matches created as group matches
        get parent(): AppMatch[] {

            return this.active
                .filter((match) => {
                    return this.parentGroupTrip.matchIds?.includes(match.id);
                }) || [];

        }

        createMatch(): AppMatch {
            const match: AppMatch = new AppMatch();
            return match;
        }

        getMatch(matchId: string): AppMatch {

            //turn team
            return this._matches.find((match) => {
                return match.id === matchId;
            });

        }

        getMatches(): Promise<void> {

            return new Promise<void>((resolve) => {

                try {

                    //only run once
                    if (!this._runOnceGetMatches) {

                        //setup
                        const promiseArray: any[] = [];
                        this._runOnceGetMatches = true;

                        //get all matched (group trip and member) for given event
                        const getMatchesUnsubscribe = this.parentGroupTrip
                            ._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Matches)
                            .where('parentId', '==', this.parentGroupTrip.id)
                            .onSnapshot((foundMatches) => {

                                //for each found match...
                                foundMatches
                                    .docChanges()
                                    .forEach((foundMatch) => {

                                        //try to find match...
                                        const localMatchFound: AppMatch = this.getMatch(foundMatch.doc.id);

                                        //for new matches that aren't already in array
                                        if (foundMatch.type === AppConfig.UPDATE_TYPE.Added) {

                                            //...if not found then create
                                            if (!localMatchFound) {

                                                //create new match and init...
                                                const match: AppMatch = this.parentGroupTrip.matches.createMatch();
                                                const p = match.initialize(this.parentGroupTrip, foundMatch.doc)
                                                    .then(() => {
                                                        //add to event matches array 
                                                        this._matches.push(match);
                                                    });

                                                promiseArray.push(p);

                                            } else {
                                                //this would happen if the match was created during this session and and saved for the first time 
                                                localMatchFound.update(foundMatch.doc, AppConfig.UPDATE_TYPE.Modified);
                                            }

                                        } else {
                                            //for updated or removed matches
                                            localMatchFound?.update(foundMatch.doc, foundMatch.type);
                                        }

                                    });

                                Promise
                                    .all(promiseArray)
                                    .then(() => {
                                        resolve();
                                    });

                            }, (err) => {
                                console.log('app.group.ts AppGroupTrip getMatches onSnapshot error', err);
                            });

                        //store snapshot for shutdown
                        this.parentGroupTrip._appFunction.registerUnsubscribe(getMatchesUnsubscribe);

                    } else {
                        resolve();
                    }

                } catch (err) {
                    console.log('app.group.ts AppGroupTrip getMatches error', err, JSON.stringify(err));
                    resolve();
                }

            });

        }

        getPlayerMatches(player: AppEventPlayer): AppMatch[] {
            return [...this.parent, ...this.getPlayerMemberMatches(player)];
        }

        //this will return all member matches that the player is in
        getPlayerMemberMatches(player: AppEventPlayer): AppMatch[] {

            return this.active
                .filter((match) => {
                    return match.isPlayerInMemberMatch(player.id);
                }) || [];

        }

        //add match that is not already added
        addMatch(match: AppMatch, isMatch: boolean): boolean {

            const foundMatch: AppMatch = this.getMatch(match.id);
            if (!foundMatch) {

                //add match to event matches array
                this._matches.push(match);

                //if is event match then set event match
                if (isMatch) {
                    this.parentGroupTrip.matchIds.push(match.id);
                }

                return true;
            } else {
                return false;
            }

        }

        //create teams
        async createTeams() {

            //because getPlayerMemberMatches (maybe fix up getPlayerMemberMatches in the future) only gets member matches do this for event matches
            this.parentGroupTrip.matches.parent.forEach((match) => {
                match.createTeam(this.parentGroupTrip.players.active.all, this.parentGroupTrip);
            });

        }

    }(this);

    players = <PlayersI>new class Players {

        constructor(private parentGroup: AppGroupTrip) {
        }

        active = <ActivePlayersI>new class {

            constructor(private parentPlayers: Players) {
            }

            get all(): AppGroupTripMember[] {
                return this.parentPlayers.parentGroup.members?.filter((player) => {
                    return player.status === TripAttendanceStatus.In;
                }) || [];
            }

            get withTeeTime(): AppGroupTripMember[] {
                return this.all;
            }

            get groupMembersOnly(): AppGroupTripMember[] {
                return undefined;
            }

            get teeTime(): AppGroupTripMember[] {
                return undefined;
            }

            get teeTimeScoringMode(): ScoringMode {
                return undefined;
            }

            get scoringMode(): ScoringMode {
                return undefined;
            }

            get leaderboard(): AppGroupTripMember[] {
                return undefined;
            }

            get leaderboardNet(): AppGroupTripMember[] {
                return undefined;
            }

            get members(): AppMember[] {
                return this.parentPlayers.parentGroup.members.map((groupMember) => {
                    return groupMember.member;
                });
            }

        }(this);

        get groupMembersOnly(): AppEventPlayer[] {
            return undefined;
        }

        get dropped(): AppEventPlayer[] {
            return undefined;
        }

        //this returns the logged in member player object
        get memberPlayer(): AppEventPlayer {
            return <AppEventPlayer>this.active.all.find((player) => {
                return player.member?.id === this.parentGroup._accountService.member.id;
            });
        }

        playerInfoConfirmed(players: AppEventPlayer[]): ScoringMode {
            return ScoringMode.ScoringActive;
        };

    }(this);

    events = <MatchEventsI>new class {

        private _events: AppEvent[];

        constructor(private parentGroup: AppGroupTrip) {
        }

        get all(): AppEvent[] {
            return this._events?.filter((event) => {
                return event.exists;
            }) || [];
        }

        in(event: AppEvent): boolean {
            return this.all.some((allEvent) => {
                return allEvent.id === event.id;
            });
        }

        get(group: AppGroupI): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                try {

                    //only run once
                    if (!Array.isArray(this._events)) {

                        //init array
                        this._events = [];

                        //get events for given trip group...get all events including the past
                        const getEventsUnsubscribe = this.parentGroup._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Events)
                            .where('groupId', '==', group.id)
                            .where('deleted', '==', false)
                            .onSnapshot((foundEvents) => {

                                const promiseArray: any[] = [];

                                //for each found event...
                                foundEvents
                                    .docChanges()
                                    .forEach((foundEvent) => {

                                        //try to find event in group cache...
                                        const foundLocalEvent: AppEvent = this._events.find((event) => {
                                            return event.id === foundEvent.doc.id;
                                        })

                                        if (foundEvent.type === AppConfig.UPDATE_TYPE.Added) {

                                            //...if not found in group cache then create
                                            if (!foundLocalEvent) {

                                                //find in global cache
                                                const p = this.parentGroup._eventService
                                                    .getEvent(group, foundEvent.doc.id, foundEvent.doc)
                                                    .then((event) => {

                                                        //update event with data
                                                        event.update(foundEvent.doc, foundEvent.type);

                                                        //store locally
                                                        this._events.push(event);

                                                        //publish event to app
                                                        //this.parentGroup._eventService.newEvent.next(event);

                                                    });

                                                promiseArray.push(p);

                                            } else { //this would happen if the event was created during this session and and saved for the first time 

                                                //this would happen if the event was created during this session and and saved for the first time 
                                                foundLocalEvent.update(foundEvent.doc, AppConfig.UPDATE_TYPE.Modified);

                                                //publish event to app (do we need to do this?)
                                                //this.parentGroup._eventService.newEvent.next(foundLocalEvent);

                                            }

                                        } else {
                                            //for updated or removed events
                                            foundLocalEvent?.update(foundEvent.doc, foundEvent.type);
                                        }

                                    });

                                Promise
                                    .all(promiseArray)
                                    .then(() => {

                                        //now sort events by event dt 
                                        this._events.sortBy('eventDt', SortByOrder.ASC);

                                        resolve();

                                    });

                            }, (err) => {
                                console.log('app.group.ts AppGroupTrip events get onSnapshot error', err);
                            });

                        //store snapshot in the event it should be removed
                        this.parentGroup._appFunction.registerUnsubscribe(getEventsUnsubscribe);

                    }

                } catch (err) {
                    console.log('app.group.ts AppGroupEvent getEvents error', err);
                    reject();
                }

            });

        }

    }(this)

    getPostNotificationPreferenceDistributionList(preferenceName: string, post: AppPost): Promise<any[]> {

        return new Promise<any[]>((resolve) => {

            const notifications = [];
            const promiseArray: any[] = [];

            //for each group member...
            this.members
                .forEach((groupMember) => {

                    //cast to get member
                    const member: AppMember = (<AppGroupTripMember>groupMember).member;

                    //get member preferences for this group
                    const p = member
                        .getPreference(this.id)
                        .then((foundPreference: memberGroupPreferences) => {

                            let sendNotification: boolean = false;

                            switch (preferenceName) {
                                case AppConfig.GROUP_PREFERENCES.POST_NOTIFICATION_PREFERENCE.name: {

                                    //determine who gets a post notification
                                    sendNotification =
                                        foundPreference.p === undefined || //member hasn't set this preference (undefined)(default)
                                        foundPreference.p === PostNotificationPreference.All ||
                                        (foundPreference.p === PostNotificationPreference.OrganizerOnly && post.createdMemberId === this.ownerMemberId);

                                    break;
                                }
                                default: {
                                    //console.log('app.group.ts getPostNotificationPreferenceDistributionList getPreference invalid preferenceName', preferenceName);
                                    break;
                                }
                            }

                            //if new event (push) notification (n) preference is set or is undefined (will then assume true)
                            if (sendNotification) {

                                notifications.push({
                                    memberId: member.id
                                });

                            }

                        });

                    promiseArray.push(p);

                });

            Promise
                .all(promiseArray)
                .then(() => {
                    resolve(notifications);
                });

        });

    }

    //#region member methods

    removeMemberFromGroup(removeMember: AppMember): Promise<AppGroupTripMember> {

        return new Promise<AppGroupTripMember>((resolve, reject) => {

            try {

                //find member...
                const memberIndex: number = this.members.findIndex((groupMember) => {
                    return removeMember.email.trim().toLowerCase() === (<AppGroupTripMember>groupMember).member.email.trim().toLowerCase();
                });

                //...and remove from main member list
                const groupMember: AppGroupTripMember = <AppGroupTripMember>this.members.splice(memberIndex, 1)[0];

                //find member in newly invited list...
                const newlyInviteMemberIndex: number = this._newlyInvitedGroupMembers.findIndex((groupMember) => {
                    return removeMember.email.trim().toLowerCase() === (<AppGroupTripMember>groupMember).member.email.trim().toLowerCase();
                });

                //...and if found remove from new member list
                if (newlyInviteMemberIndex > -1) {
                    this._newlyInvitedGroupMembers.splice(newlyInviteMemberIndex, 1);
                }

                //TODO: remove group preferences from member record

                //return
                resolve(groupMember);

            }
            catch (err) {
                console.log('app.group.ts AppGroupTrip removeMemberFromGroup error', JSON.stringify(err));
                reject(err)
            }

        });

    }

    addMemberToGroup(addMember: AppMember, role: MemberGroupRole): Promise<AppGroupTripMember> {

        return new Promise<AppGroupTripMember>((resolve, reject) => {

            try {

                //if this is a new member then it needs saving
                addMember
                    .save()
                    .then(() => {

                        const groupMember: AppGroupTripMember = new AppGroupTripMember();
                        groupMember.initialize({ memberId: addMember.id, status: TripAttendanceStatus.Invited, role: role, statusDt: firebase.firestore.Timestamp.fromDate(new Date()) })
                            .then(() => {

                                this.members.push(groupMember);

                                //add to list of newly invited members...we will use this to send out an invite email
                                this._newlyInvitedGroupMembers.push(groupMember);

                                resolve(groupMember);

                            });

                    });

            } catch (err) {
                console.log('app.group.ts AppGroupTrip addMemberToGroup err', err);
                reject(err)
            }

        });

    }

    private sortMembers() {
        this.members.sortBy('status', SortByOrder.ASC, 'statusDt', SortByOrder.ASC);
    }

    isMember(member: AppMember): boolean {

        //console.log('app.group.ts AppGroupEvent isMember');

        //determine if member is a member of the group
        return !(this.getGroupMember(member) === undefined);

    }

    isNewMember(groupMember: AppGroupTripMember): boolean {

        //console.log('app.group.ts AppGroupTrip isNewMember');

        const isNew: boolean = this._newlyInvitedGroupMembers.some((newlyInvitedMember) => {
            return newlyInvitedMember.memberId === groupMember.member.id;
        });

        //determine if member is newly invited to the group
        return isNew;

    }

    isMemberAdmin(member: AppMember): boolean {
        return this.members?.some((groupMember) => {
            return groupMember.memberId === member.id && [MemberGroupRole.Owner, MemberGroupRole.Administrator].includes(groupMember.role);
        });
    }

    getAdmins(): AppMember[] {

        const admins: AppMember[] = [];
        this.members?.forEach(<AppMember>(member) => {
            if (this.isMemberAdmin(member)) {
                admins.push(member);
            }
        });

        return admins;

    }

    getGroupMember(member: AppMember): AppGroupTripMemberI {
        return this.members?.find((groupMember) => {
            //cast to get member
            return (<AppGroupTripMember>groupMember).member.id === member.id;
        });
    }

    makeAdmin(member: AppMember) {

        //then make admin
        this.getGroupMember(member).role = MemberGroupRole.Administrator;

    }

    removeAdmin(member: AppMember) {

        //then make admin
        this.getGroupMember(member).role = MemberGroupRole.Member;

    }

    private getMembers(groupMembers: AppGroupTripMemberI[]): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            //save the array of promises
            const promiseArray: any[] = [];

            //array for this call, this is hacky but needed so we know what members have bee removed from the group
            const members: AppGroupTripMember[] = [];

            //now create member array
            groupMembers
                .forEach((groupMember) => {

                    //find group member in group members array
                    const foundGroupMember: AppGroupTripMember = this.members.find((member) => {
                        return (<AppGroupTripMember>member).member.id === groupMember.memberId;
                    });

                    //if not found then create
                    if (!foundGroupMember) {

                        const member: AppGroupTripMember = new AppGroupTripMember();
                        const p = member.initialize(groupMember);

                        //only add to array if initialized successfully
                        p.then((isSuccessful) => {
                            if (isSuccessful) {
                                members.push(member);
                            }
                        });

                        promiseArray.push(p);

                    } else {
                        //if found then update
                        members.push(foundGroupMember);
                        foundGroupMember.update(groupMember);
                    }

                });

            //wait for all promises to return
            Promise
                .all(promiseArray)
                .then(() => {
                    //now save to main array, this is sort of hacky but by doing this it will effectively remove any members that have been removed from the group
                    this.members = members;
                    resolve();
                })
                .catch((err) => {
                    console.log('app.group.ts AppGroupTrip getMembers error', err, JSON.stringify(err));
                    reject(err);
                });

        });

    }

    private saveMember(member: AppMember): Promise<string> {

        return new Promise<string>((resolve, reject) => {

            //if member is new to group then...
            if (member.dirty) {

                //see if new group member is already exists
                this._accountService
                    .getMemberByEmail(member.email)
                    .then((memberFound) => {

                        //member doesn't exist
                        if (memberFound === undefined) {

                            member
                                .save()
                                .then((member) => {

                                    //add new member to array
                                    resolve(member.id);

                                })
                                .catch((err) => {
                                    console.log('app.group.ts AppGroupTrip saveMember error', JSON.stringify(err));
                                    reject(err);
                                });

                        } else {
                            //add exsiting member new to array
                            resolve(memberFound.id);
                        }

                    })
                    .catch((err) => {
                        console.log('app.group.ts AppGroupTrip saveMember getMemberByEmail error', JSON.stringify(err));
                        reject(err);
                    });

            } else {
                //add exiting member to array
                resolve(member.id);
            }

        });

    }

    //#endregion member methods

    sendGroupMessageEmail(editMode, subject: string, message: string): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                const personalizations = [];
                const promiseArray: any[] = [];

                //if the group has an avatar then show
                let showGroupAvatar: string = 'none';
                if (this.avatar.URI.length > 0) {
                    showGroupAvatar = 'table-row';
                }

                //send to each member of the group
                this.members
                    .forEach((groupMember) => {

                        //cast to get member
                        const member: AppMember = (<AppGroupTripMember>groupMember).member;

                        //get email preferences
                        const q = member
                            .getPreference(this.id)
                            .then((preference: memberGroupPreferences) => {

                                //determine if this user wants this email
                                const sendEmail: boolean =
                                    (editMode === AppConfig.EDIT_MODE.new && preference.e === EventEmailPreference.First) || //member only wants new event emails
                                    (preference.e === EventEmailPreference.All || preference.e === undefined) //member wants all emails or member hasn't set preferences

                                //if event update email (e) preference is set or is undefined (will then assume true)
                                if (sendEmail) {

                                    personalizations.push({
                                        "subject": subject,
                                        "templateId": AppConfig.SENDGRID_TEMPLATES.GroupMessage,
                                        "to": {
                                            "name": member.firstName + ' ' + member.lastName,
                                            "email": member.email
                                        },
                                        "from": {
                                            "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                            "email": AppConfig.NOREPLY_EMAIL
                                        },
                                        "replyTo": {
                                            "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                            "email": this._accountService.member.email
                                        },
                                        "dynamic_template_data": {
                                            //"domain": environment.hosting.app,
                                            "subject": subject,
                                            "message": message,
                                            "firstName": member.firstName,
                                            "lastName": member.lastName,
                                            "groupName": this.name,
                                            "groupAvatarURI": this.avatar.URIEmail,
                                            "showGroupAvatar": showGroupAvatar,
                                            "memberId": member.id,
                                            "groupId": this.id
                                        },
                                        "hideWarnings": true
                                    });

                                }

                            });

                        promiseArray.push(q);

                    });

                Promise
                    .all(promiseArray)
                    .then(() => {

                        this._appFunction
                            .sendEmail(personalizations)
                            .then(() => {

                                this._appFunction
                                    .queueToast({
                                        message: 'The group email has been sent.',
                                        position: 'top',
                                        duration: 4000,
                                        color: 'secondary',
                                        closeButtonText: 'Ok'
                                    });

                                resolve();
                            })
                            .catch((err) => {
                                reject(err);
                            });

                    });

            } catch (err) {
                console.log('app.group.ts sendGroupMessageEmail promise.all error', err, JSON.stringify(err));
                reject();
            }

        });

    }

    resendInviteEmail(groupMember: AppGroupTripMember): Promise<void> {
        //return this.sendInviteEmail([groupMember]);
        return this.sendInviteCommunications([groupMember]);
    }

    private sendInviteEmail(groupMembers: AppGroupTripMember[]): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //only send invite email if...
                if (groupMembers.length > 0) {

                    const personalizations = [];
                    const promiseArray: any[] = [];

                    //if the group has an avatar then show
                    let showGroupAvatar: string = 'none';
                    if (this.avatar.URI.length > 0) {
                        showGroupAvatar = 'table-row';
                    }

                    //send to each player of the event
                    groupMembers
                        .forEach((groupMember) => {

                            const deepLink: DeepLinkParmsI = {
                                route: '/main/groups',
                                page: AppConfig.PAGE.GroupTripView,
                                id: this.id,
                                segment: '',
                                actionCd: GroupTripActionCd.Invited,
                                actionCdMessage: null,
                                email: groupMember.member.email,
                                welcomeMessage: 'Hi ' + groupMember.member.firstName + ', ' + this._accountService.member.firstName + ' ' + this._accountService.member.lastName + ' has invited you to the ' + this.name + ' group trip.',
                                emailHasAccount: null,
                                additionalData: null
                            }

                            const p = this._deepLinkService
                                .createDeepLink(DeepLinkCampaign.TripInvite, DeepLinkChannel.email, deepLink, { title: 'Welcome to Double Ace Golf', description: 'For the best experience please leave the checkbox below selected.' })
                                .then((tripInviteDeepLink) => {

                                    personalizations.push({
                                        "subject": this.name,
                                        "templateId": AppConfig.SENDGRID_TEMPLATES.GroupTripInvite,
                                        "to": {
                                            "name": groupMember.member.firstName + ' ' + groupMember.member.lastName,
                                            "email": groupMember.member.email
                                        },
                                        "from": {
                                            "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                            "email": AppConfig.NOREPLY_EMAIL
                                        },
                                        "replyTo": {
                                            "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                            "email": this._accountService.member.email
                                        },
                                        "dynamic_template_data": {
                                            "subject": this.name,
                                            "firstName": groupMember.member.firstName,
                                            "lastName": groupMember.member.lastName,
                                            "groupName": this.name,
                                            "groupAvatarURI": this.avatar.URIEmail,
                                            "showGroupAvatar": showGroupAvatar,
                                            "departureDt": moment(this.departureDt.toDate()).format('dddd MMMM D, YYYY').toString(),
                                            "returnDt": moment(this.returnDt.toDate()).format('dddd MMMM D, YYYY').toString(),
                                            "domain": environment.hosting.join, //TODO: is this used
                                            "groupId": this.id,
                                            "memberId": groupMember.member.id,
                                            "environment": environment.cloudfunctions,
                                            "inviteDeepLink": tripInviteDeepLink
                                        },
                                        "hideWarnings": true
                                    });

                                });

                            promiseArray.push(p);

                        });

                    Promise
                        .all(promiseArray)
                        .then(() => {

                            this._appFunction
                                .sendEmail(personalizations)
                                .then(() => {

                                    this._appFunction
                                        .queueToast({
                                            message: 'The trip invite email has been sent.',
                                            position: 'top',
                                            duration: 4000,
                                            color: 'secondary',
                                            closeButtonText: 'Ok'
                                        });

                                    //clear array
                                    //groupMembers = [];

                                    resolve();

                                })
                                .catch((err) => {
                                    console.log('app.group.ts AppGroupTrip sendInviteEmail sendEmail error', err, JSON.stringify(err));
                                    reject(err);
                                });

                        });

                } else {
                    //noop
                    resolve();
                }

            } catch (err) {
                console.log('app.group.ts AppGroupTrip sendInviteEmail error', err, JSON.stringify(err));
                reject();
            }

        });

    }

    private sendInvitePushNotification(groupMembers: AppGroupTripMember[]): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            //send to each new group member
            groupMembers
                .forEach(async (groupMember) => {

                    let deeplink: string;

                    const deepLinkParms: DeepLinkParmsI = {
                        route: '/main/groups',
                        page: AppConfig.PAGE.GroupTripView,
                        id: this.id,
                        segment: '',
                        actionCd: GroupTripActionCd.Invited,
                        actionCdMessage: null,
                        email: groupMember.member.email,
                        welcomeMessage: 'Hi ' + groupMember.member.firstName + ', ' + this._accountService.member.firstName + ' ' + this._accountService.member.lastName + ' has invited you to the ' + this.name + ' group trip.',
                        emailHasAccount: null,
                        additionalData: null
                    }

                    //create deep link
                    await this._deepLinkService
                        .createDeepLink(DeepLinkCampaign.TripInvite, DeepLinkChannel.push, deepLinkParms, { title: 'Welcome to Double Ace Golf', description: 'For the best experience please leave the checkbox below selected.' })
                        .then((tripInviteDeepLink) => {
                            deeplink = tripInviteDeepLink;
                        })
                        .catch((err) => {
                            reject(err);
                        });

                    const memberIds = [];
                    memberIds.push({ memberId: groupMember.member.id });

                    this._appFunction
                        .sendNotification(this._accountService.member.id, memberIds, 'Trip Invite', "Youi've been invite to a golf trip.", this.avatar.URI, { groupId: this.id }, deeplink)
                        .then(() => {
                            resolve();
                        })
                        .catch((err) => {
                            reject(err);
                        });

                });

        });

    }

    private sendInviteCommunications(groupMembers: AppGroupTripMember[]): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            //send communications
            const p = this.sendInvitePushNotification(groupMembers);
            const q = this.sendInviteEmail(groupMembers);

            Promise
                .all([p, q])
                .then(() => {

                    //clear array
                    groupMembers = [];

                    this._appFunction
                        .queueToast({
                            message: 'The trip invite communications have been sent.',
                            position: 'top',
                            duration: 4000,
                            color: 'secondary',
                            closeButtonText: 'Ok'
                        });

                    resolve();

                })
                .catch((err) => {
                    console.log('app.group.page.ts sendInviteCommunications error', JSON.stringify(err));
                    reject(err);
                });

        });

    }

    private sendInOutPushNotifications() {

        const receivingInNotificationMemberIds = [];
        const receivingOutNotificationMemberIds = [];
        const promiseArray: any[] = [];

        //first get a list of who is to receive the push notification
        this.members
            .forEach((groupMember) => {

                //cast to get member
                const member: AppMember = (<AppGroupEventMember | AppGroupTripMember>groupMember).member;

                //get member preferences for this group
                const p = member
                    .getPreference(this.id)
                    .then((preference: memberGroupPreferences) => {

                        //determine if organizer wants 'in' notifications
                        const sendInNotification: boolean = [TripNotificationPreference.In, TripNotificationPreference.InOut].includes(preference.tripNotification);
                        if (sendInNotification) {

                            receivingInNotificationMemberIds.push({
                                memberId: member.id
                            });

                        }

                        //determine if organizer wants 'out' notifications
                        const sendOutNotification: boolean = [TripNotificationPreference.Out, TripNotificationPreference.InOut].includes(preference.tripNotification);
                        if (sendOutNotification) {

                            receivingOutNotificationMemberIds.push({
                                memberId: member.id
                            });

                        }
                    });

                promiseArray.push(p);

            });

        Promise
            .all(promiseArray)
            .then(() => {

                if (receivingInNotificationMemberIds.length > 0) {

                    //if anyone has elected to receive this 'in' push notification then...
                    this._inOutPushNotifications
                        .forEach((inOut) => {

                            //only send the notification if the new status has changed from the old status
                            if (inOut.newStatus !== inOut.originalStatus && inOut.newStatus === TripAttendanceStatus.In) {

                                const title: string = inOut.group.name;
                                const message: string = inOut.groupMember.member.firstName + ' ' + inOut.groupMember.member.lastName + ' has accepted the trip invitation!';

                                //send notification
                                this._appFunction
                                    .sendNotification(this._accountService.member.id, receivingInNotificationMemberIds, title, message, this.avatar.URI, { groupId: this.id }, '/main/home')
                                    .catch((err) => {
                                        console.log('app.group.ts AppGroupTrip sendInOutPushNotifications sendNotification (in) error', err, JSON.stringify(err));
                                    });

                            }

                        });

                }

                if (receivingOutNotificationMemberIds.length > 0) {

                    //if anyone has elected to receive this 'out' push notification then...
                    this._inOutPushNotifications
                        .forEach((inOut) => {

                            //only send the notification if the new status has changed from the old status
                            if (inOut.newStatus !== inOut.originalStatus && inOut.newStatus === TripAttendanceStatus.Out) {

                                const title: string = inOut.group.name;
                                const message: string = inOut.groupMember.member.firstName + ' ' + inOut.groupMember.member.lastName + ' has declined the trip invitation!';

                                //send notification
                                this._appFunction
                                    .sendNotification(this._accountService.member.id, receivingOutNotificationMemberIds, title, message, this.avatar.URI, { groupId: this.id }, '/main/home')
                                    .catch((err) => {
                                        console.log('app.group.ts AppGroupTrip sendInOutPushNotifications sendNotification (out) error', err, JSON.stringify(err));
                                    });

                            }

                        });

                }

                //lastly clear 
                this._inOutPushNotifications = [];

            });

    }

    /* this is use to sort group trips (and events) on the home screen */
    get sortByDt(): Date {
        return this.departureDt.toDate();
    }

    setTripStatus(newStatus: TripAttendanceStatus, groupMember: AppGroupTripMember) {

        //check to see if there's any in/out group member changes
        const inOutPushNotification: AppInOutPushNotificationI = this._inOutPushNotifications.find((entry) => {
            return entry.groupMember.memberId === groupMember.memberId;
        });

        //if there's already a change then update existing entry   
        if (inOutPushNotification) {
            inOutPushNotification.newStatus = newStatus
        } else { //else create a new entry
            this._inOutPushNotifications.push({ originalStatus: groupMember.status, newStatus: newStatus, group: this, groupMember: groupMember });
        }

        //finally set the new status to group member
        groupMember.status = newStatus;

    }

    removeMemberFromEvents(groupMember: AppGroupTripMember) {

        //remove from existing events
        this.events
            .all
            .forEach((event) => {

                //find player in event
                const player: MatchPlayerI = event.players.getPlayerByMemberId(groupMember.member.id);

                //if player exists then remove
                if (player) {
                    event.players.remove(player);
                }

            });

    }

    addMemberToEvents(groupMember: AppGroupTripMember) {

        //add to existing events
        this.events
            .all
            .forEach((event) => {

                //don't add player if already exists
                if (!event.players.is(groupMember.member)) {

                    //push new player on new player array, this player will get saved during "Done"
                    const player: AppEventPlayer = new AppEventPlayer();
                    player
                        .initialize(groupMember.member.id, event)
                        .then(() => {
                            player.save();
                        });

                }

            });

    }

    sendNewGroupEmail(member: AppMember): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                const personalizations = [];

                //if the group has an avatar then show
                let showGroupAvatar: string = 'none';
                if (this.avatar.URI.length > 0) {
                    showGroupAvatar = 'table-row';
                }

                //create deep link object for new group
                const deepLink: DeepLinkParmsI = {
                    route: '/main/groups',
                    page: AppConfig.PAGE.GroupTripDetail,
                    id: this.id,
                    segment: '',
                    actionCd: GroupTripActionCd.NewGroup,
                    actionCdMessage: null,
                    email: member.email,
                    welcomeMessage: 'Hi ' + member.firstName + ', congratulations on your new trip. Please find these helpful tips to get you started.',
                    emailHasAccount: null,
                    additionalData: null
                }

                //create deep link
                this._deepLinkService
                    .createDeepLink(DeepLinkCampaign.NewGroup, DeepLinkChannel.email, deepLink, { title: 'Welcome to Double Ace Golf', description: 'For the best experience please leave the checkbox below selected.' })
                    .then((newGroupDeepLink) => {

                        personalizations.push({
                            "subject": this.name,
                            "templateId": AppConfig.SENDGRID_TEMPLATES.NewGolfTrip,
                            "to": {
                                "name": member.firstName + ' ' + member.lastName,
                                "email": member.email
                            },
                            "from": {
                                "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                "email": AppConfig.NOREPLY_EMAIL
                            },
                            "replyTo": {
                                "name": this._accountService.member.firstName + ' ' + this._accountService.member.lastName,
                                "email": this._accountService.member.email
                            },
                            "dynamic_template_data": {
                                "subject": this.name,
                                "organizerFirstName": this._accountService.member.firstName,
                                "organizerLastName": this._accountService.member.lastName,
                                "groupName": this.name,
                                "groupAvatarURI": this.avatar.URIEmail,
                                "showGroupAvatar": showGroupAvatar,
                                "addedDeepLink": newGroupDeepLink
                            },
                            "hideWarnings": true
                        });

                        this._appFunction
                            .sendEmail(personalizations)
                            .then(() => {
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.group.ts AppGroupTrip sendNewGroupEmail sendEmail error', err, JSON.stringify(err));
                                reject(err);
                            });

                    });


            } catch (err) {
                console.log('app.group.ts AppGroupEvent AppGroupTrip error', err, JSON.stringify(err));
                reject();
            }

        });

    }

    get isScoringEnabled(): boolean {

        //scoring is enable if today is between the departure and return date
        return moment().isBetween(moment(this.departureDt.toDate()), moment(this.returnDt.toDate()), 'days', '[]');

    }

    save(): Promise<AppGroupTrip> {

        return new Promise<AppGroupTrip>((resolve, reject) => {

            try {

                const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();

                //first loop through member array and create any new members
                const promiseArray: any[] = [];
                this.members
                    .forEach((groupMember) => {
                        const p = this.saveMember((<AppGroupTripMember>groupMember).member);
                        promiseArray.push(p);
                    });

                //next iterate through all group trip matches
                this.matches
                    .parent
                    .forEach((match) => {
                        const p = match.save(batch);
                        promiseArray.push(p)
                    });

                //now save the group trip
                batch.set(this._groupDoc.ref, this.data(), { merge: true });

                //send invite emails for any newly invited members
                //this.sendInviteEmail(this._newlyInvitedGroupMembers);
                this.sendInviteCommunications(this._newlyInvitedGroupMembers);

                //send in out notifiactions
                this.sendInOutPushNotifications();

                //now commit batch
                Promise
                    .all(promiseArray)
                    .then(() => {
                        batch.commit();
                        resolve(this);
                    });

            } catch (err) {
                console.log('app.group.ts AppGroupTrip save error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    delete(): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //set status
                this.deleted = true;

                //save event
                this.save()
                    .then(() => {

                        //save the array of promises
                        const promiseArray: any[] = [];

                        //remove member preferences from group, do it here (rather than cloud function) because we already have members loaded in app
                        this.members
                            .forEach((groupMember) => {

                                //cast to get member
                                const member: AppMember = (<AppGroupTripMember>groupMember).member;

                                //remove pref
                                const p = member.removePreference(this.id);
                                promiseArray.push(p);

                            });

                        //once all done ...
                        Promise
                            .all(promiseArray)
                            .then(() => {
                                resolve();
                            });

                    })
                    .catch((err) => {
                        console.log('app.event.ts AppGroupTrip delete save error', JSON.stringify(err));
                        reject(err);
                    });

            } catch (err) {
                console.log('app.group.ts AppGroupTrip delete error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    private data(): AppGroupTripI {

        try {

            //build group member and member search arrays
            const groupMembers: AppGroupTripMemberI[] = [];
            const searchMemberIds: string[] = [];
            this.members.forEach((groupMember) => {
                groupMembers.push((<AppGroupTripMember>groupMember).data());
                searchMemberIds.push(groupMember.memberId);
            });

            //get group trip match ids
            const matchIds: string[] = [];
            this.matches
                .parent
                .forEach((match) => {
                    matchIds.push(match.id);
                });

            return {
                name: this.name.trim(),
                searchName: this.searchName,
                description: this.description ? this.description.trim() : null,
                numberOfPlayers: this.numberOfPlayers,
                avatarFileName: this.avatarFileName,
                coverFileName: this.coverFileName,
                ownerMemberId: this.ownerMemberId,
                public: this.public,
                departureDt: this._departureDt,
                returnDt: this._returnDt,
                members: groupMembers,
                matchIds: matchIds,
                postCount: this.postCount,
                type: this.type,
                searchMemberIds: searchMemberIds,
                deleted: this.deleted,
                updatedDt: firebase.firestore.Timestamp.fromDate(new Date()),
                createdDt: this.createdDt || firebase.firestore.Timestamp.fromDate(new Date())
            };

        }
        catch (err) {
            console.log('app.group.ts AppGroupTrip data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    private _groupCache: AppGroupI[] = []; //this is the group data store
    private _groupCacheRequest: {
        groupId: string,
        obseverer: Subscriber<AppGroupI>,
        processed: boolean
    }[] = [];
    private client = algoliasearch(environment.ALGOLIA_CONFIG.appId, environment.ALGOLIA_CONFIG.searchAPIKey);
    private groupSearchIndex = this.client.initIndex(environment.ALGOLIA_CONFIG.groupIndex);

    constructor(
        public appFunction: AppFunction,
        public accountService: AccountService) {

        //clean up
        this.appFunction
            .shutDown
            .subscribe(() => {

                console.log('app.group.ts GroupService shutdown');

                //clear group cache
                this._groupCache = [];
                this._groupCacheRequest = [];

            });

    }

    getGroupEventsByClub(clubId: string): Promise<AppGroupEvent[]> {

        return new Promise<AppGroupEvent[]>((resolve, reject) => {

            try {

                this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Groups)
                    .where('clubId', '==', clubId)
                    .where('public', '==', true)
                    .where('type', '==', GroupType.Event)
                    .get({ source: 'server' })
                    .then((foundGroups) => {

                        const groups: AppGroupEvent[] = [];

                        //save the array of promises
                        const promiseArray: any[] = [];

                        if (!foundGroups.empty) {

                            foundGroups
                                .forEach((foundGroup) => {

                                    const p = this.getGroup(foundGroup.id, foundGroup)
                                        .toPromise()
                                        .then((group) => {
                                            groups.push(<AppGroupEvent>group);
                                        });

                                    promiseArray.push(p);

                                });

                        }

                        Promise
                            .all(promiseArray)
                            .then(() => {
                                resolve(groups);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts getGroupEventsByClub get error', JSON.stringify(err));
                        reject(err);
                    });

            } catch (err) {
                console.log('app.group.ts getGroupEventsByClub error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    searchGroupOnName(searchCriteria: string): Promise<AppGroupI[]> {

        return new Promise<AppGroupI[]>((resolve, reject) => {

            try {

                this.groupSearchIndex
                    .search(searchCriteria)
                    .then((foundGroups) => {

                        //save the array of promises
                        const promiseArray: any[] = [];
                        const appGroups: AppGroupI[] = [];

                        //if found then get the group
                        if (foundGroups.hits.length > 0) {

                            foundGroups
                                .hits
                                .forEach((foundGroup: any) => {

                                    const p = new Promise<void>((resolve) => {
                                        this.getGroup(foundGroup.objectID)
                                            .toPromise()
                                            .then((group) => {
                                                appGroups.push(group);
                                                resolve();
                                            });
                                    });

                                    promiseArray.push(p);

                                });

                        } else {
                            resolve(appGroups);
                        }

                        Promise
                            .all(promiseArray)
                            .then(() => {
                                resolve(appGroups);
                            })
                            .catch((err) => {
                                console.log('app.group.ts searchGroupOnName promise error', err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts searchGroupOnName algolia search error', JSON.stringify(err));
                        reject(err);
                    });

            } catch (err) {

                console.log('app.group.ts searchGroupOnName error', JSON.stringify(err));
                reject(err);

            }

        });

    }

    searchGroupEventOnCourse(searchCriteria: string): Promise<AppGroupI[]> {

        return new Promise<AppGroupI[]>((resolve, reject) => {

            try {

                searchCriteria = searchCriteria.toUpperCase();
                let strlength = searchCriteria.length;
                let strFrontCode = searchCriteria.slice(0, strlength - 1);
                let strEndCode = searchCriteria.slice(strlength - 1, searchCriteria.length);
                let startcode = searchCriteria;
                let endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

                this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Groups)
                    .where('searchName', '>=', startcode)
                    .where('searchName', '<', endcode)
                    //.where('public', '==', true) //TODO: DOESN'T WORK
                    .get()
                    .then((foundGroups) => {

                        //save the array of promises
                        const promiseArray: any[] = [];
                        const appGroups: AppGroupI[] = [];

                        if (!foundGroups.empty) {

                            foundGroups
                                .forEach((foundGroup) => {

                                    if (foundGroup.data().public) {

                                        //create and then send temporary event object
                                        const p = this.getGroup(foundGroup.id, foundGroup)
                                            .toPromise()
                                            .then((group) => {
                                                appGroups.push(group);
                                            });

                                        promiseArray.push(p);

                                    }

                                });

                        }

                        Promise
                            .all(promiseArray)
                            .then(() => {
                                resolve(appGroups);
                            })
                            .catch((err) => {
                                console.log('app.event.ts AppGroupEvent searchGroupOnCourse error', err);
                            });

                    })
                    .catch((err) => {
                        console.log('app.group.ts searchGroupOnCourse error', JSON.stringify(err));
                        reject(err);
                    });

            } catch (err) {
                console.log('app.group.ts searchGroupOnCourse error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    getGroup(groupId: string, groupDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Observable<AppGroupI> {

        return new Observable((observer) => {

            try {

                //look for cached group
                const index: number = this._groupCache.findIndex((group) => {
                    return group.id === groupId;
                });

                //if found then return
                if (index > -1) {

                    observer.next(this._groupCache[index]);
                    observer.complete();

                } else if (groupDoc) {

                    //create...
                    let group: AppGroupI;

                    if (groupDoc.data().type === GroupType.Event) {
                        group = new AppGroupEvent();
                    } else {
                        group = new AppGroupTrip();
                    }

                    group
                        .initialize(groupDoc)
                        .then(() => {

                            //save to chache
                            this._groupCache.push(group);

                            //return this request
                            observer.next(<AppGroupI>group);
                            observer.complete();

                            //return to those waiting/previsously requested
                            this._groupCacheRequest
                                .forEach((request) => {
                                    if (request.groupId === group.id && request.processed === false) {

                                        //return member and close observable
                                        request.obseverer.next(<AppGroupI>group);
                                        request.obseverer.complete();

                                        //mark as processed
                                        request.processed = true;

                                    }
                                });

                        });

                } else { //else go get it

                    //look for cached request
                    const existingRequest: boolean = this._groupCacheRequest.some((request) => {
                        return request.groupId === groupId;
                    });

                    //store request
                    this._groupCacheRequest.push({ groupId: groupId, obseverer: observer, processed: false });

                    //if member hasn't been requested then go get it...
                    if (!existingRequest) {

                        //if not found in cache the get it from the database
                        this.appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Groups)
                            .doc(groupId)
                            .get()
                            .then((groupDoc) => {

                                //if group found...
                                if (groupDoc.exists) {

                                    //create proper group
                                    let group: AppGroupI;
                                    if (groupDoc.data().type === GroupType.Event) {
                                        group = new AppGroupEvent();
                                    } else {
                                        group = new AppGroupTrip();
                                    }

                                    //initialize
                                    group
                                        .initialize(groupDoc)
                                        .then(() => {

                                            //save to cache
                                            this._groupCache.push(group);

                                            //return member to those waiting
                                            this._groupCacheRequest
                                                .forEach((request) => {

                                                    if (request.groupId === group.id && request.processed === false) {

                                                        //return member and close observable
                                                        request.obseverer.next(group);
                                                        request.obseverer.complete();

                                                        //mark as processed
                                                        request.processed = true;

                                                    }

                                                });

                                        });

                                } else {
                                    console.log('app.group.ts GroupService getGroup group not found', { 'groupId': groupId });
                                    observer.error('app.group.ts getGroup group not found. groupId: ' + groupId);
                                    observer.complete();
                                }

                            })
                            .catch((err) => {
                                console.log('app.group.ts getGroup collection get error', err, JSON.stringify(err));
                            });

                    }

                }

            } catch (err) {
                console.log('app.group.ts getGroup error', err, JSON.stringify(err));
            }

        });

    }

}