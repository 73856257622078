<ion-grid class="ion-no-padding" [@validationHeroDisplay]="displayState" style="overflow: hidden;">
  
  <ion-row class="ion-no-padding" alert>

    <ion-col class="ion-no-padding iconTextSingleLine" size="auto">

      <ion-icon name="information-circle"></ion-icon>

    </ion-col>

    <ion-col class="ion-no-padding ion-text-start">

      <ion-text>{{_header}}</ion-text>
    
    </ion-col>

  </ion-row>

  <ion-row class="ion-no-padding" alert *ngIf="_messages && _messages.length > 0">

    <ion-col class="ion-no-padding">

      <ul style="padding-inline-start: 20px; margin-block-start: unset; margin-block-end: unset;">
        <li *ngFor="let message of _messages" class="ion-text-start" style="padding-bottom: 5px;">
          <ion-text>{{message}}</ion-text>
        </li>
      </ul>

    </ion-col>

  </ion-row>

</ion-grid>