<ion-header class="group-event-detail-header ion-no-border">

  <ion-toolbar>

    <ion-title><span *ngIf="editMode===formEditMode.new">New&nbsp;</span>Group</ion-title>

    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" [screenKey]="screenKey">
        <ion-icon name="help-circle-outline"></ion-icon>
      </ion-button>
      <ion-button (click)="cancel()" *ngIf="groupForm.dirty">
        Cancel
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button *ngIf="groupForm.dirty" fill="solid" shape="round" (click)="done()" haptic>Save</ion-button>
      <ion-button *ngIf="!groupForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>

  </ion-toolbar>

  <!-- validation hero -->
  <validation-hero [display]="isFormSubmitted && groupForm.invalid"></validation-hero>

</ion-header>

<ion-content #content class="group-event-detail-content">

  <!-- segment -->
  <div class="ion-padding">

    <ion-segment [(ngModel)]="groupDetailSegment" (ionChange)="groupSegmentChange($event)">

      <ion-segment-button [value]="GroupEventSegment.Information">
        <ion-label>Details</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="GroupEventSegment.Members">
        <ion-label>Members</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="GroupEventSegment.Events" *ngIf="group.exists">
        <ion-label>Events</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="GroupEventSegment.Posts" *ngIf="group.exists">
        <ion-label>Posts</ion-label>
      </ion-segment-button>

    </ion-segment>

  </div>

  <div *ngIf="groupDetailSegment === GroupEventSegment.Information">

    <form class="group-detail-form" [formGroup]="groupForm">

      <!-- cover and avatar -->
      <div class="coverAvatar" style="margin-top: -10px">

        <!-- cover -->
        <div class="cover" style="padding: 16px;" (click)="updateCover()">
          <img [src]="localCoverURL | sanitizeUrl" style="border-radius: 16px;" draggable="false">
          <div class="avatarImageOverlay imageSelector" style="right: 23px; bottom: -10px;">
            <ion-icon name="camera" size="small" style="color: black"></ion-icon>
          </div>
        </div>

        <!-- avatar -->
        <div class="avatar" style="margin-top: -30px;">
          <ion-avatar class="avatar-large avatarImageOverlay" (click)="updateAvatar($event, imgAvatar)">
            <img #imgAvatar [src]="localAvatarURL | sanitizeUrl" draggable="false">
            <div class="avatarImageOverlay imageSelector">
              <ion-icon name="camera" size="small" style="color: black"></ion-icon>
            </div>
          </ion-avatar>
        </div>

      </div>

      <!-- 'Ask to Join' action card -->
      <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.NewGroup">

        <ion-card-content>
          <ion-card-title>
            Congratulations!
          </ion-card-title>

          <p class="message">Congratulations on your new group! The email you received has several helpful hints to
            manage your group.</p>

        </ion-card-content>

      </ion-card>

      <!-- group details -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Group Details</ion-label>
        </ion-list-header>

        <!-- Name -->
        <ion-item [class.invalid]="!groupForm.controls.name.valid && groupForm.controls.name.touched" lines="inset">
          <ion-label position="stacked">Name</ion-label>
          <ion-input formControlName="name"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="groupForm.controls.name.hasError('required') && !groupForm.controls.name.valid && groupForm.controls.name.touched">
          <ion-label autocapitalize="on">Please enter a group name.</ion-label>
        </ion-item>

        <!-- Description -->
        <ion-item lines="inset">
          <ion-label position="stacked">Description</ion-label>
          <ion-textarea formControlName="description" auto-grow="true" autocomplete="on" autocorrect="on"
            autocapitalize="on" maxlength="200" rows="3">
          </ion-textarea>
        </ion-item>

        <!-- Default Number of Tee Times -->
        <ion-item>

          <ion-label>

            <ion-text class="labelPositionStacked">Number of Tee Times (default)</ion-text>

            <ion-grid class="counter">
              <ion-row>
                <ion-col class="ion-text-center">
                  <ion-button shape="round" color="secondary" haptic
                    [disabled]="groupForm.controls.numberTeeTimes.value===1"
                    (click)="decrementCounter('numberTeeTimes')">
                    <ion-icon slot="icon-only" name="remove"></ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col class="ion-text-center">
                  <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                    {{groupForm.controls.numberTeeTimes.value}}</ion-label>
                </ion-col>
                <ion-col class="ion-text-center">
                  <ion-button shape="round" color="secondary" haptic
                    [disabled]="groupForm.controls.numberTeeTimes.value===15"
                    (click)="incrementCounter('numberTeeTimes')">
                    <ion-icon slot="icon-only" name="add"></ion-icon>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>

          </ion-label>

        </ion-item>

        <!-- Private/Public -->
        <ion-item>

          <ion-label>

            <ion-text class="labelPositionStacked">Public</ion-text>

            <ion-label class="toggle">
              <h2 *ngIf="groupForm.controls.public.value">Your group is public</h2>
              <h2 *ngIf="!groupForm.controls.public.value">Your group is private</h2>
              <ion-toggle formControlName="public"></ion-toggle>
            </ion-label>

            <ion-label class="help">
              <ion-text>Control who can see and join this group.</ion-text>
              <ion-text (click)="helpService.showFeatureHelp('groupPrivate')">Learn more.</ion-text>
            </ion-label>

          </ion-label>

        </ion-item>

      </ion-list>

      <!-- club details -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Club Details</ion-label>
        </ion-list-header>
        <ion-list-header class="help">
          <ion-text>Setup your club preferences.</ion-text>
          <ion-text (click)="helpService.showFeatureHelp('groupClub')">Learn more.</ion-text>
        </ion-list-header>

        <!-- club -->
        <ion-item (click)="confirmClubAction()" detail="true">

          <ion-label>

            <ion-text class="labelPositionStacked">
              Golf Club (default)
            </ion-text>

            <h2 *ngIf="!groupForm.controls.club.value" class="readOnly">Add golf club</h2>
            <h2 *ngIf="groupForm.controls.club.value" style="overflow: visible;">
              {{groupForm.controls.club.value.name}}</h2>

          </ion-label>

        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="groupForm.controls.club.hasError('required') && !groupForm.controls.club.valid && groupForm.controls.club.touched">
          <ion-label>Please select a club.</ion-label>
        </ion-item>

        <!-- courses, only show if club has more than one course -->
        <ion-item class="accordionItem" *ngIf="groupForm.controls.club.value?.courses?.length > 1">
          <ion-label position="stacked">Courses</ion-label>
          <ion-accordion-group #accordionCourse>
            <ion-accordion>
              <ion-item slot="header">
                <ion-label>
                  <!-- selected course -->
                  <h2 *ngIf="!groupForm.controls.course.value" class="readOnly">Select a course</h2>
                  <h2 *ngIf="groupForm.controls.course.value" style="overflow: visible;">
                    {{groupForm.controls.course.value.name}}
                  </h2>
                </ion-label>
              </ion-item>
              <div slot="content" class="lineSeparatorTop">

                <ion-grid class="ion-no-padding">

                  <ion-row class="ion-align-items-center" *ngFor="let course of groupForm.controls.club.value.courses"
                    (click)="selectCourse(course); accordionCourse.value = undefined;" style="height: 35px;">

                    <ion-col size="auto">
                      <div class="circle-small descriptionHeader">{{course.name.charAt(0)}}</div>
                    </ion-col>
                    <ion-col>
                      <div>{{course.name}}</div>
                    </ion-col>

                  </ion-row>

                </ion-grid>

              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-item>

        <!-- tees -->
        <ion-item class="accordionItem" *ngIf="groupForm.controls.course.value">
          <ion-label position="stacked">Tees (default)</ion-label>
          <ion-accordion-group #accordionTee>
            <ion-accordion>
              <ion-item slot="header">
                <ion-label>

                  <h2 *ngIf="!groupForm.controls.tee.value" class="readOnly">Select a tee</h2>
                  <h2 *ngIf="groupForm.controls.tee.value" style="overflow: visible;">

                    <tee [tee]="groupForm.controls.tee.value"
                      [nineHolesOnlyIndex]="groupForm.controls.nineHolesOnlyIndex.value"></tee>

                  </h2>

                </ion-label>
              </ion-item>
              <div slot="content" class="lineSeparatorTop">
                <tee [tee]="tee" [nineHolesOnlyIndex]="groupForm.controls.nineHolesOnlyIndex.value"
                  *ngFor="let tee of groupForm.controls.course.value.tees"
                  (click)="selectTee(tee); accordionTee.value = undefined;"></tee>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-item>

        <!-- number of holes -->
        <ion-item *ngIf="groupForm.controls.tee.value && groupForm.controls.course.value?.holes===18">

          <ion-label>

            <ion-text class="labelPositionStacked">
              Number of holes (default)
            </ion-text>

            <ion-grid class="holesPlayed ion-no-padding">

              <ion-row class="ion-align-items-center">

                <ion-col size="4" (click)="selectHoles(18);">
                  <ion-button shape="round" class="circle"
                    [ngClass]="{ circleOn: groupForm.controls.numberOfHoles.value===18 }"
                    style="margin-top: 0px; margin-bottom: 0px; font-size: 16px;">18</ion-button>holes
                </ion-col>

                <ion-col size="auto" (click)="selectHoles(9);">
                  <ion-button shape="round" class="circle"
                    [ngClass]="{ circleOn: groupForm.controls.numberOfHoles.value===9 }"
                    style="margin-top: 0px; margin-bottom: 0px; font-size: 16px;">9</ion-button>holes
                </ion-col>

              </ion-row>

            </ion-grid>

          </ion-label>

        </ion-item>

        <!-- course nines, if the tee was selected and the course is an 18 hole course and we're only playing 9 holes -->
        <ion-item #nines class="item-expand-closed"
          style="--max-height-expand: {{this.ninesItem?.nativeElement?.scrollHeight}}px;"
          [ngClass]="{'item-expand-open' : groupForm.controls.tee.value && groupForm.controls.course.value?.holes===18 && groupForm.controls.numberOfHoles.value===9}">

          <ion-label>

            <ion-text class="labelPositionStacked">
              Nines (default)
            </ion-text>

            <ion-radio-group formControlName="nineHolesOnlyIndex"> <!-- valueChanges.subscribe in ngOnInit -->
              <ion-item lines="none" *ngFor="let nine of groupForm.controls.tee.value?.nines; let i = index;"
                style="--padding-start: 0px;">
                <ion-radio [value]="i" mode="md" slot="start" color="secondary" style="height: 30px;">
                </ion-radio>
                <ion-label>{{nine.name}}</ion-label>
              </ion-item>
            </ion-radio-group>

          </ion-label>

        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="groupForm.controls.nineHolesOnlyIndex.hasError('required') && !groupForm.controls.nineHolesOnlyIndex.valid && groupForm.controls.nineHolesOnlyIndex.touched">
          <ion-label>Please select which nine holes to play.</ion-label>
        </ion-item>

        <!-- Default Tee Time Interval (minutes) -->
        <ion-item>

          <ion-label>

            <ion-text class="labelPositionStacked">Tee Time Interval (minutes) (default)</ion-text>

            <ion-grid class="counter ion-no-padding">
              <ion-row class="ion-no-padding">
                <ion-col class="ion-text-center ion-no-padding">
                  <ion-button shape="round" color="secondary" haptic
                    [disabled]="groupForm.controls.teeTimeInterval.value===1"
                    (click)="decrementCounter('teeTimeInterval')">
                    <ion-icon slot="icon-only" name="remove"></ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col class="ion-text-center ion-no-padding">
                  <ion-label color="secondary" style="font-size: 30px; margin-top: 5px;">
                    {{groupForm.controls.teeTimeInterval.value}}</ion-label>
                </ion-col>
                <ion-col class="ion-text-center ion-no-padding">
                  <ion-button shape="round" color="secondary" haptic
                    [disabled]="groupForm.controls.teeTimeInterval.value===15"
                    (click)="incrementCounter('teeTimeInterval')">
                    <ion-icon slot="icon-only" name="add"></ion-icon>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>

          </ion-label>

        </ion-item>

      </ion-list>

      <!-- event Preferences -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Event Preferences</ion-label>
        </ion-list-header>
        <ion-list-header class="help">
          <ion-text>Customize event notifications.</ion-text>
          <ion-text (click)="helpService.showFeatureHelp('eventCommunicationPreferences')">Learn more.</ion-text>
        </ion-list-header>

        <!-- Email -->
        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Email</ion-text>
            <round-segment (change)="updateEmailPreferences($event)" [value]="this.memberGroupPreferences?.e"
              [buttons]="emailPreference"></round-segment>
          </ion-label>
        </ion-item>

        <!-- Notification -->
        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Notification</ion-text>
            <round-segment (change)="updateNotificationPreferences($event)" [value]="this.memberGroupPreferences?.n"
              [buttons]="emailPreference">
            </round-segment>
          </ion-label>
        </ion-item>

      </ion-list>

      <!-- post preferences -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Post Preferences</ion-label>
        </ion-list-header>
        <ion-list-header class="help">
          <ion-text>Customize post notifications.</ion-text>
          <ion-text (click)="helpService.showFeatureHelp('postCommunicationPreferences')">Learn more.</ion-text>
        </ion-list-header>

        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Notification</ion-text>
            <round-segment (change)="updatePostNotificationPreferences($event)" [value]="this.memberGroupPreferences?.p"
              [buttons]="postPreference"></round-segment>
          </ion-label>
        </ion-item>

      </ion-list>

      <!-- event join/drop notification preferences -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Event Attendance Notifications</ion-label>
        </ion-list-header>
        <ion-list-header class="help">
          <ion-text>Customize Event join/drop notifications.</ion-text>
          <ion-text (click)="helpService.showFeatureHelp('joinDropNotificationPreferences')">Learn more.</ion-text>
        </ion-list-header>

        <!-- Email -->
        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Email</ion-text>
            <round-segment (change)="updateEmailEventNotificationPreferences($event)"
              [value]="this.memberGroupPreferences?.eventEmailJoinDrop" [buttons]="eventJoinDropPreference">
            </round-segment>
          </ion-label>
        </ion-item>

        <!-- Notification -->
        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Notification</ion-text>
            <round-segment (change)="updateNotificationEventNotificationPreferences($event)"
              [value]="this.memberGroupPreferences?.eventNotificationJoinDrop" [buttons]="eventJoinDropPreference">
            </round-segment>
          </ion-label>
        </ion-item>

      </ion-list>

    </form>

  </div>

  <div *ngIf="groupDetailSegment === GroupEventSegment.Members">

    <ion-searchbar (ionChange)="searchCriteriaChange($event)" (ionClear)="searchCriteriaClear()"
      show-cancel-button="focus" inputmode="search"></ion-searchbar>

    <ion-list class="itemLines">

      <ion-list-header>
        <ion-label>Members</ion-label>
      </ion-list-header>

      <!-- add group member -->
      <ion-item (click)="addMemberConfirm()">

        <ion-avatar style="background-color: lightgrey;" slot="start">
          <ion-icon color="primary" name="person-add" style="height: 35px; width: 35px; padding: 10px;"></ion-icon>
        </ion-avatar>

        <ion-label>
          <h2>Add a member</h2>
        </ion-label>

      </ion-item>

      <!-- group members -->
      <ion-item *ngFor="let groupMember of filteredMembers;">

        <avatar #avatar [member]="groupMember.member" slot="start"></avatar>

        <ion-label (click)="avatar.displayProfileSummary($event)">

          <h2>{{groupMember.member?.firstName}} {{groupMember.member?.lastName}}</h2>
          <p [ngStyle]="{'color': group.isMemberAdmin(groupMember.member) ? 'red': 'initial' }">
            {{(groupMember.member.id === group.ownerMemberId) ? 'organizer' : group.isMemberAdmin(groupMember.member) ?
            'administrator' :
            groupMember.member?.email}}
          </p>
        </ion-label>

        <!-- don't show if member is owner of group -->
        <ion-icon name="ellipsis-vertical" (click)="memberAction(groupMember.member)" slot="end"
          style="align-self: end; margin-right: 0px;" color="primary"
          *ngIf="groupMember.member.id !== group.ownerMemberId">
        </ion-icon>

      </ion-item>

    </ion-list>

  </div>

  <div *ngIf="groupDetailSegment === GroupEventSegment.Events" class="greyBackgroundForSegment">

    <ng-container *ngIf="events?.length > 0, else noEvents">

      <!-- list of active event -->
      <ion-list>

        <ion-list-header>
          <ion-label>Events</ion-label>
        </ion-list-header>

        <ng-container *ngFor="let event of events">
          <event-card [event]="event"></event-card>
        </ng-container>

      </ion-list>

    </ng-container>

  </div>

  <div *ngIf="groupDetailSegment === GroupEventSegment.Posts" class="greyBackgroundForSegment">

    <ng-container *ngIf="posts?.length > 0, else noPosts">

      <ion-list>

        <ion-list-header>
          <ion-label>Posts</ion-label>
        </ion-list-header>

        <ng-container *ngFor="let post of posts;">
          <post-card [post]="post" [content]="content"></post-card>
        </ng-container>

      </ion-list>

      <ion-infinite-scroll (ionInfinite)="getPosts($event)" threshold="1px">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more posts...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>

      <ion-note *ngIf="noMorePosts" class="instructional"
        style="height: 85px; margin-bottom: 10px; background-color: var(--ion-color-white-b);">No more posts
      </ion-note>

    </ng-container>

  </div>

  <ion-fab #fab #popOver vertical="bottom" horizontal="end" slot="fixed" haptic popoverHelp [fabClose]="fab">
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-button shape="round" (click)="deleteGroupConfirmation(group); fab.close();" *ngIf="group.exists">
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label>Delete Group</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="newPost(); fab.close();" *ngIf="group.exists"
        [ngClass]="{'fabButtonHighlight' : groupDetailSegment === GroupEventSegment.Posts}">
        <ion-icon name="chatbubbles" slot="start"></ion-icon>
        <ion-label>Post Message</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="composeEmail(); fab.close();" *ngIf="group.exists">
        <ion-icon name="mail" slot="start"></ion-icon>
        <ion-label>Send Email</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="newEvent(group); fab.close();" *ngIf="group.exists"
        [ngClass]="{'fabButtonHighlight' : groupDetailSegment === GroupEventSegment.Events}">
        <ion-icon name="calendar" slot="start"></ion-icon>
        <ion-label>New Event</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="addMemberConfirm(); fab.close();"
        [ngClass]="{'fabButtonHighlight' : groupDetailSegment === GroupEventSegment.Members}">
        <ion-icon name="person-add" slot="start"></ion-icon>
        <ion-label>Add a member</ion-label>
      </ion-button>
    </ion-fab-list>
  </ion-fab>

</ion-content>

<ng-template #noPosts>
  <div style="text-align: center; font-size: 70px; margin-top: 50px;">
    <ion-icon name="chatbubbles"></ion-icon>
  </div>
  <ion-note class="message" style="margin-top: 12px; margin-bottom: 12px; line-height: 25px;">
    There are no Posts for this group
  </ion-note>
</ng-template>

<ng-template #noEvents>
  <div style="text-align: center; font-size: 70px; margin-top: 50px;">
    <ion-icon name="calendar"></ion-icon>
  </div>
  <ion-note class="message" style="margin-top: 12px; margin-bottom: 12px; line-height: 25px;">
    There are no Events for this group.
  </ion-note>
</ng-template>