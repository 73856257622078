import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppFunction } from '../../app.function';
import { AppConfig } from '../../app.config';
import { AppTee, AppCourseHoleI, AppCourseNineI } from 'src/app/app.club';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'course-hole',
  templateUrl: './course-hole.page.html',
  styleUrls: ['./course-hole.page.scss'],
})
export class CourseHolePage implements OnInit {

  tee: AppTee;
  number: number;
  hole: AppCourseHoleI

  constructor(
    public navParams: NavParams,
    public appFunction: AppFunction,
    public appConfig: AppConfig) {

  }

  ngOnInit() {

    //get passed in edit mode
    this.tee = <AppTee>this.navParams.get('tee');
    this.number = this.navParams.get('number');

    //get data
    this.gethole();

  }

  previous() {

    this.number--;

    //get data
    this.gethole();

  }

  next() {

    this.number++;

    //get data
    this.gethole();

  }

  private gethole() {

    //get the proper nine, hole
    const nineIndex: number = Math.floor((this.number - 1) / 9);
    const nineHoleIndex: number = (this.number - 1) % 9;
    this.hole = this.tee.nines[nineIndex].holes[nineHoleIndex];

    //default the values
    this.hole.par = this.hole.par || 4;
    this.hole.hdcp = this.hole.hdcp || 9;

  }

  firstHole(): boolean {
    return this.number === 1;
  }

  lastHole(): boolean {
    const lastNine: AppCourseNineI = this.tee.nines[this.tee.nines.length - 1];
    const lastHole: AppCourseHoleI = lastNine.holes[lastNine.holes.length - 1];
    return this.number === lastHole.number;
  }

  decrementCounter(controlName: string) {
    this.hole[controlName] = this.hole[controlName] - 1;
  }

  incrementCounter(controlName: string) {
    this.hole[controlName] = this.hole[controlName] + 1;
  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}
