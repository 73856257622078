import { Injectable } from '@angular/core';
import { AppConfig } from './app.config';
import { AppFunction } from './app.function';
import { MediaService } from './app.media';
import { AccountService, AppMember } from './app.account';
import { GroupService, AppGroupI } from './app.group';
import firebase from 'firebase/compat/app';
import { Timestamp } from '@firebase/firestore-types';
import * as moment from 'moment';
import { Observable, Subscriber } from 'rxjs';

export enum PostType {
    member = 0,
    group = 1
}

export interface AppFollowingI {
    memberId: string;
    followedMemberId: string;
    status: number;
    updatedDt: firebase.firestore.FieldValue;
    createdDt: firebase.firestore.FieldValue;
}

export class AppFollowing implements AppFollowingI {

    id: string;
    status: number;
    createdDt: firebase.firestore.FieldValue;
    updatedDt: firebase.firestore.FieldValue; //this declaration is only need to satisfy the implements stastement
    exists: boolean = false;
    private _followingDoc: firebase.firestore.DocumentSnapshot;
    private _memberId: string;
    private _followedMemberId: string;
    private _appAccountService: AccountService;
    private _appFunction: AppFunction;
    private _member: AppMember;
    private _followedMember: AppMember;

    constructor() {

        this._appFunction = AppFunction.serviceLocator.get(AppFunction);
        this._appAccountService = AppFunction.serviceLocator.get(AccountService);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.social.ts AppFollowing shutdown', this.id);
            });

    }

    initialize(followingDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Promise<AppFollowing> {

        return new Promise<AppFollowing>((resolve) => {

            if (followingDoc) {

                this.id = followingDoc.id;
                this._followingDoc = followingDoc;

                //listen for following updates
                const followingSnapShotUnsubscribe = followingDoc
                    .ref
                    .onSnapshot((followingUpdate) => {
                        this.update(followingUpdate);
                        resolve(this);
                    });

                this._appFunction.registerUnsubscribe(followingSnapShotUnsubscribe);

            } else {

                //create new follow doc ref
                const followingDoc: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.Following).doc();

                //now get it, it should be empty as this is a new following object
                followingDoc
                    .get()
                    .then((newFollowingDoc) => {

                        //save doc, this will be used when saving
                        this._followingDoc = newFollowingDoc;
                        this.id = followingDoc.id;
                        resolve(this);

                    });

            }

        });

    }

    private update(updatedFollowing: firebase.firestore.DocumentSnapshot) {

        //console.log('app.social.ts AppFollowing update', updatedFollowing);

        this._appFunction
            .ngZone
            .run(() => {

                if (updatedFollowing.exists) {

                    //update high level attributes
                    this.memberId = updatedFollowing.data().memberId;
                    this.followedMemberId = updatedFollowing.data().followedMemberId;
                    this.status = updatedFollowing.data().status;
                    this.createdDt = updatedFollowing.data().createdDt;
                    this.exists = true;

                }
                else {
                    this.exists = false;
                    this.status = AppConfig.SOCIAL_STATUS.NotFollowing;
                }

            });

    }

    set memberId(memberId: string) {

        if (memberId) {

            this._memberId = memberId;

            //get member
            this._appAccountService
                .getMember(this._memberId)
                .toPromise()
                .then((member) => {

                    if (member) {
                        this._member = member;
                    } else {
                        console.log('app.social.ts AppFollowing set memberId getMember Member not found...this is an error...member should be found.', { memberId: memberId });
                    }

                });

        }

    }

    get memberId(): string {
        return this._memberId;
    }

    set followedMemberId(followedMemberId: string) {

        if (followedMemberId) {

            this._followedMemberId = followedMemberId;

            //get member
            this._appAccountService
                .getMember(this._followedMemberId)
                .toPromise()
                .then((member) => {
                    this._followedMember = member;
                });

        }

    }

    get followedMemberId(): string {
        return this._followedMemberId;
    }

    get member(): AppMember {
        return this._member;
    }

    get followedMember(): AppMember {
        return this._followedMember;
    }

    save(): Promise<AppFollowing> {

        return new Promise<AppFollowing>((resolve, reject) => {

            //console.log('app.social.ts AppSocialFollow save');

            //make sure the bare minimum has been specified
            if (this._memberId && this._followedMemberId) {

                this._followingDoc
                    .ref
                    .set(this.data())
                    .then(() => {
                        //console.log('app.social.ts AppFollowing save set success');
                        resolve(this);
                    })
                    .catch((err) => {
                        console.log('app.social.ts AppFollowing save set error', JSON.stringify(err));
                        reject(err);
                    });

            }

        });

    }

    delete(): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            this._appFunction
                .firestore
                .collection(AppConfig.COLLECTION.Following)
                .doc(this.id)
                .delete()
                .then(() => {
                    //this.id = undefined;
                    //this.status = AppConfig.SOCIAL_STATUS.NotFollowing;
                    //this.exists = false;
                    resolve();
                })
                .catch((err) => {
                    console.log('app.social.ts AppFollowing delete error', JSON.stringify(err));
                    reject(err);
                });

        });

    }

    private data(): AppFollowingI {

        try {

            return {
                status: this.status,
                memberId: this._memberId,
                followedMemberId: this._followedMemberId,
                createdDt: this.createdDt || firebase.firestore.Timestamp.fromDate(new Date()),
                updatedDt: firebase.firestore.Timestamp.fromDate(new Date())
            };

        }
        catch (err) {
            console.log('app.social.ts AppFollowing data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

export interface AppPostCommentI {
    id: string;
    postId: string;
    memberId: string;
    text: string;
    createdDt: firebase.firestore.Timestamp;
}

export class AppPostComment implements AppPostCommentI {

    id: string = undefined;
    postId: string;
    member: AppMember;
    text: string;
    createdDt: firebase.firestore.Timestamp = undefined;
    exists: boolean = false;
    private _memberId: string;
    private _appAccountService: AccountService;
    private _commentDoc: firebase.firestore.DocumentSnapshot;
    private _appFunction: AppFunction;

    constructor() {

        //console.log('app.social.ts AppPostComment constructor');

        this._appFunction = AppFunction.serviceLocator.get(AppFunction);
        this._appAccountService = AppFunction.serviceLocator.get(AccountService);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.social.ts AppPostComment shutdown', this.id);
            });

    }

    initialize(commentDoc: firebase.firestore.DocumentSnapshot = undefined): Promise<AppPostComment> {

        return new Promise<AppPostComment>((resolve) => {

            if (commentDoc) {

                this.id = commentDoc.id;
                this._commentDoc = commentDoc;

                //listen for following updates
                const commentSnapShotUnsubscribe = commentDoc
                    .ref
                    .onSnapshot((commentUpdate) => {
                        this.update(commentUpdate);
                        resolve(this);
                    });

                this._appFunction.registerUnsubscribe(commentSnapShotUnsubscribe);

            } else {

                //create new follow doc ref
                const followingDoc: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.PostComments).doc();

                //now get it, it should be empty as this is a new following object
                followingDoc
                    .get()
                    .then((newCommentDoc) => {

                        //save doc, this will be used when saving
                        this._commentDoc = newCommentDoc;
                        this.id = followingDoc.id;
                        resolve(this);

                    });

            }

        });

    }

    private update(updatedComment: firebase.firestore.DocumentSnapshot) {

        try {

            this._appFunction
                .ngZone
                .run(() => {

                    if (updatedComment.exists) {

                        this.postId = updatedComment.data().postId || undefined;
                        this.memberId = updatedComment.data().memberId || undefined;
                        this.text = updatedComment.data().text || undefined;
                        this.createdDt = updatedComment.data().createdDt || undefined;
                        this.exists = true;

                    }
                    else {
                        this.exists = false;
                    }

                });

        } catch (err) {
            console.log('app.socal.ts AppPostComment update error', JSON.stringify(err));
        }

    }

    set memberId(memberId: string) {

        if (memberId && !this.member) {

            this._memberId = memberId;

            //get member
            this._appAccountService
                .getMember(memberId)
                .toPromise()
                .then((member) => {
                    this.member = member;
                })
                .catch((err) => {
                    console.log('app.social.ts AppPostComment memberId set error', err);
                });

        }

    }

    get memberId(): string {
        return this._memberId;
    }

    get getFromNow(): string {

        try {
            if (this.createdDt) {
                const now: Timestamp = this.createdDt as Timestamp;
                return moment(now.toDate()).fromNow();
            } else {
                return '';
            }
        }
        catch (err) {
            console.log('app.social.ts AppPostComment getFromNow error', err, JSON.stringify(err));
            throw err;
        }

    }

    save(batch: firebase.firestore.WriteBatch): Promise<void> {

        //console.log('app.social.ts AppPostComment save');

        return new Promise<void>((resolve) => {

            //save post comment
            batch.set(this._commentDoc.ref, this.data());

            //return
            resolve();

        });

    }

    delete(batch: firebase.firestore.WriteBatch): Promise<void> {

        //console.log('app.social.ts AppPostComment delete');

        return new Promise<void>((resolve) => {
            batch.delete(this._commentDoc.ref);
            resolve();
        });

    }

    private data() {

        try {

            return {
                text: this.text,
                postId: this.postId,
                memberId: this.memberId,
                createdDt: this.createdDt || firebase.firestore.Timestamp.fromDate(new Date())
            };

        }
        catch (err) {
            console.log('app.social.ts AppPostComment data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

export interface AppPostI {
    id: string;
    memberId: string;
    groupId: string;
    text: string;
    media: string[];
    createdDt: firebase.firestore.Timestamp;
    createdMemberId: string;
    totalComments: number;
    totalLikes: number;
}

export class AppPost implements AppPostI {

    id: string;
    text: string;
    member: AppMember;
    group: AppGroupI;
    media: string[] = [];
    createdMember: AppMember;
    totalComments: number;
    totalLikes: number;
    exists: boolean = false;
    createdDt: firebase.firestore.Timestamp;
    private _comments: AppPostComment[];
    private _likeDocumentRef: firebase.firestore.DocumentReference = undefined;
    private _accountService: AccountService;
    private _groupService: GroupService;
    private _appFunction: AppFunction;
    private _mediaService: MediaService;
    private _postDoc: firebase.firestore.DocumentSnapshot;

    constructor() {

        //console.log('app.social.ts AppPost constructor');

        //get services
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);
        this._accountService = AppFunction.serviceLocator.get(AccountService);
        this._mediaService = AppFunction.serviceLocator.get(MediaService);
        this._groupService = AppFunction.serviceLocator.get(GroupService);

        //clean up
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.social.ts AppPost shutdown');
            });

    }

    initialize(postDoc: firebase.firestore.DocumentSnapshot = undefined): Promise<AppPost> {

        return new Promise<AppPost>((resolve) => {

            if (postDoc) {

                this.id = postDoc.id;
                this._postDoc = postDoc;

                //listen for following updates
                const postSnapShotUnsubscribe = postDoc
                    .ref
                    .onSnapshot((postUpdate) => {
                        this.update(postUpdate);
                        resolve(this);
                    });

                this._appFunction.registerUnsubscribe(postSnapShotUnsubscribe);

            } else {

                //create new follow doc ref
                const postUpdate: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.Posts).doc();

                //now get it, it should be empty as this is a new following object
                postUpdate
                    .get()
                    .then((newPostDoc) => {

                        //save doc, this will be used when saving
                        this._postDoc = newPostDoc;
                        this.id = postUpdate.id;
                        resolve(this);

                    });

            }

        });

    }

    private update(updatedPost: firebase.firestore.DocumentSnapshot) {

        try {

            this._appFunction
                .ngZone
                .run(() => {

                    if (updatedPost.exists) {

                        this.memberId = updatedPost.data().memberId || undefined;
                        this.groupId = updatedPost.data().groupId || undefined;
                        this.text = updatedPost.data().text || undefined;
                        this.media = updatedPost.data().media || [];
                        this.createdMemberId = updatedPost.data().createdMemberId;
                        this.totalComments = updatedPost.data().totalComments || 0;
                        this.totalLikes = updatedPost.data().totalLikes || 0;
                        this.createdDt = updatedPost.data().createdDt || undefined;
                        this.exists = true;

                        this.getMemberLike(this._accountService.member.id);

                    }
                    else {
                        this.exists = false;
                        this.id = undefined;
                    }

                });

        } catch (err) {
            console.log('app.socal.ts AppPost update error', JSON.stringify(err));
        }

    }

    save(): Promise<AppPost> {

        //console.log('app.social.ts AppPost save');

        return new Promise<AppPost>((resolve, reject) => {

            try {

                //save media..if any
                this.saveMedia()
                    .then(() => {

                        try {

                            console.log('app.social.ts AppPost saveMedia success');

                            this._postDoc
                                .ref
                                .set(this.data())
                                .then(() => {

                                    //console.log('app.social.ts AppPost save add success');

                                    //update the post count
                                    if (this.memberId) {

                                        //update member post count
                                        this.member
                                            .social
                                            .updatePostCount(1)
                                            .then(() => {
                                                resolve(this);
                                            });

                                    } else {

                                        //update group post count
                                        this.group
                                            .social
                                            .updatePostCount(1)
                                            .then(() => {
                                                resolve(this);
                                            });

                                        //now send a push notification for the new post
                                        this.group
                                            .getPostNotificationPreferenceDistributionList(AppConfig.GROUP_PREFERENCES.POST_NOTIFICATION_PREFERENCE.name, this)
                                            .then((members) => {
                                                this._appFunction.sendNotification(this._accountService.member.id, members, 'Group Post', this.createdMember.firstName + ' ' + this.createdMember.lastName + ' has created a new post for ' + this.group.name, this.group.avatar.URI, { postId: this.id }, '/main/posts');
                                            });

                                    }

                                })
                                .catch((err) => {
                                    console.log('app.social.ts AppPost save set error', JSON.stringify(err));
                                    reject(err);
                                });

                        } catch (err) {
                            console.log('app.social.ts AppPost save setMedia error', err, JSON.stringify(err));
                            reject(err);
                        }

                    });

            } catch (err) {
                console.log('app.social.ts AppPost save error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    delete(): Promise<void> {

        //console.log('app.social.ts AppPost delete');

        //TODO: add a transaction around delete and updatePostCount

        return new Promise<void>((resolve, reject) => {

            this._postDoc
                .ref
                .delete()
                .then(() => {

                    //console.log('app.social.ts AppPost delete success');

                    //update the post count
                    if (this.memberId) {

                        //update member post count
                        this.member
                            .social
                            .updatePostCount(-1)
                            .then(() => {
                                resolve();
                            });

                    } else {

                        //update group post count
                        this.group
                            .social
                            .updatePostCount(-1)
                            .then(() => {
                                resolve();
                            });

                    }

                    resolve();

                })
                .catch((err) => {
                    console.log('app.social.ts AppPost delete error', JSON.stringify(err));
                    reject(err);
                });

        });

    }

    private saveMedia(): Promise<string> {

        return new Promise<string>((resolve, reject) => {

            //console.log('app.social.ts AppPost saveMedia');

            //save the array of promises
            const promiseArray: any[] = [];

            this.media
                .forEach((fileURI, i) => {

                    console.log('app.social.ts AppPost saveMedia for each', fileURI);

                    const p = this._mediaService
                        .saveMedia(fileURI, this._appFunction.newGuid(), AppConfig.MEDIA_STORAGE.SOCIAL)
                        .then((storageURI) => {

                            //replace file name
                            this.media[i] = storageURI;

                        })
                        .catch((err) => {
                            console.log('app.social.ts AppPost saveMedia error', JSON.stringify(err));
                            reject(err);
                        });

                    promiseArray.push(p);

                });

            Promise
                .all(promiseArray)
                .then(() => {
                    resolve(undefined);
                });

        });

    }

    get getFromNow(): string {

        try {
            if (this.createdDt) {
                const now: Timestamp = this.createdDt as Timestamp;
                return moment(now.toDate()).fromNow();
            } else {
                return '';
            }
        }
        catch (err) {
            console.log('app.social.ts AppPost getFromNow error', err, JSON.stringify(err));
            throw err;
        }

    }

    set memberId(memberId: string) {

        if (memberId && !this.member) {

            //get member
            this._accountService
                .getMember(memberId)
                .toPromise()
                .then((member) => {

                    this.member = member;

                    //remove group
                    this.group = undefined;
                    this.groupId = undefined;

                });

        }

    }

    get memberId(): string {
        if (this.member) {
            return this.member.id;
        } else {
            return undefined;
        }
    }

    set createdMemberId(createdMemberId: string) {

        if (createdMemberId && !this.createdMemberId) {

            //get member
            this._accountService
                .getMember(createdMemberId)
                .toPromise()
                .then((createdMember) => {
                    this.createdMember = createdMember;
                });

        }

    }

    get createdMemberId(): string {

        if (this.createdMember) {
            return this.createdMember.id;
        } else {
            return undefined;
        }

    }

    set groupId(groupId: string) {

        if (groupId && !this.group) {

            //get group
            this._groupService
                .getGroup(groupId)
                .toPromise()
                .then((group) => {

                    this.group = group;

                    //remove member
                    this.member = undefined;
                    this.memberId = undefined;

                });

        }

    }

    get groupId(): string {
        if (this.group) {
            return this.group.id;
        } else {
            return undefined;
        }
    }

    likePost(): Promise<void> {

        //console.log('app.social.ts AppPost likePost');

        return new Promise<void>((resolve, reject) => {

            //create batch transaction
            const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();

            if (!this.memberLike) {

                //create new like document reference
                this._likeDocumentRef = this._appFunction.firestore.collection(AppConfig.COLLECTION.PostLikes).doc();

                //save post comment
                batch.set(this._likeDocumentRef, {
                    memberId: this._accountService.member.id,
                    postId: this.id
                });

                //increment counter
                this.updateLikeCounter(batch, 1);

                //commit
                batch
                    .commit()
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        console.log('app.social.ts likePost like error', err);
                        reject(err);
                    });

            } else {

                //delete like
                batch.delete(this._likeDocumentRef);

                //decrement counter
                this.updateLikeCounter(batch, -1);

                //commit
                batch
                    .commit()
                    .then(() => {
                        //reset document ref
                        this._likeDocumentRef = undefined;

                        resolve();
                    })
                    .catch((err) => {
                        console.log('app.social.ts likePost unlike error', err);
                        reject(err);
                    });

            }

        });

    }

    get memberLike(): boolean {
        return !(this._likeDocumentRef === undefined);
    }

    saveComment(postComment: string): Promise<void> {

        //console.log('app.social.ts AppPost saveComment');

        return new Promise<void>((resolve, reject) => {

            //create comment
            const comment: AppPostComment = new AppPostComment();
            comment.postId = this.id;
            comment.memberId = this._accountService.member.id;
            comment.text = postComment;

            comment
                .initialize()
                .then(() => {

                    //create batch transaction
                    const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();

                    //save comment
                    comment
                        .save(batch)
                        .then(() => {

                            //increment post comment counter
                            this.updateCommentCounter(batch, 1)
                                .then(() => {

                                    //save changes
                                    batch
                                        .commit()
                                        .then(() => {
                                            resolve();
                                        })
                                        .catch((err) => {
                                            console.log('app.social.ts AppPost saveComment save error', JSON.stringify(err));
                                            reject(err);
                                        });

                                });

                        });

                });

        });

    }

    deleteComment(postComment: AppPostComment): Promise<void> {

        //console.log('app.social.ts AppPost deleteComment');

        return new Promise<void>((resolve, reject) => {

            //create batch transaction
            const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();

            //create comment
            postComment
                .delete(batch)
                .then(() => {

                    //decrement post comment counter
                    this.updateCommentCounter(batch, -1)
                        .then(() => {

                            //save changes
                            batch
                                .commit()
                                .then(() => {
                                    resolve();
                                })
                                .then((err) => {
                                    console.log('app.social.ts AppPost deleteComment save error', JSON.stringify(err));
                                    reject(err);
                                });

                        });

                })
                .catch((err) => {
                    reject(err);
                });

        });

    }

    get comments(): AppPostComment[] {

        //return only "active" comments
        return this._comments?.filter((comment) => {
            return comment.exists;
        }) || [];

    }

    getComments(): Promise<AppPostComment[]> {

        return new Promise<AppPostComment[]>((resolve, reject) => {

            try {

                //only query once
                if (Array.isArray(this._comments)) {
                    resolve(this.comments);
                } else {

                    //initialize array
                    this._comments = [];

                    const promiseArray: any[] = [];

                    //query posts
                    const getCommentsUnsubscribe = this._appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.PostComments)
                        .where('postId', '==', this.id)
                        .orderBy('createdDt', 'asc')
                        .onSnapshot(
                            (foundComments) => {

                                //for each found comment...
                                foundComments
                                    .docChanges()
                                    .forEach((foundComment) => {

                                        //if the comment is new...
                                        if (foundComment.type === AppConfig.UPDATE_TYPE.Added) {

                                            //create post...
                                            const comment: AppPostComment = new AppPostComment();

                                            //...then initialize
                                            const p = comment
                                                .initialize(foundComment.doc)
                                                .then(() => {
                                                    this._comments.push(comment);
                                                });

                                            promiseArray.push(p);

                                        }

                                    });

                                Promise.all(promiseArray)
                                    .then(() => {
                                        resolve(this.comments);
                                    });

                            }, (err) => {
                                console.log('app.social.ts AppPost getComments onSnapshot error', err);
                                reject(err);
                            });

                    this._appFunction.registerUnsubscribe(getCommentsUnsubscribe);

                }

            } catch (err) {
                console.log('app.social.ts AppPost getComments error', err, JSON.stringify(err));
                reject(err);
            }

        });

    }

    private updateLikeCounter(batch: firebase.firestore.WriteBatch, value: number) {
        //now update counter
        batch.update(this._postDoc.ref, { totalLikes: firebase.firestore.FieldValue.increment(value) });
    }

    private updateCommentCounter(batch: firebase.firestore.WriteBatch, value: number): Promise<void> {

        return new Promise<void>((resolve) => {
            batch.set(this._postDoc.ref, { totalComments: firebase.firestore.FieldValue.increment(value) }, { merge: true });
            resolve();
        });

    }

    private getMemberLike(memberId: string) {

        //only get likes doc ref if undefined
        if (this._likeDocumentRef === undefined) {

            try {

                //query posts
                const getMemberLikeUnsubscribe = this._appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.PostLikes)
                    .where('postId', '==', this.id)
                    .where('memberId', '==', memberId)
                    .onSnapshot((foundLike) => {

                        this._appFunction
                            .ngZone
                            .run(() => {

                                //if a doc is found there only should be one
                                if (foundLike.size === 1) {

                                    if (foundLike.docs[0].exists) {
                                        this._likeDocumentRef = foundLike.docs[0].ref;
                                    } else {
                                        this._likeDocumentRef = undefined;
                                    }

                                } else {
                                    this._likeDocumentRef = undefined;
                                }

                            });

                    });

                this._appFunction.registerUnsubscribe(getMemberLikeUnsubscribe);

            } catch (err) {
                console.log('app.social.ts AppPost getMemberLike error', err);
            }

        }
    }

    private data() {

        try {

            return {
                text: this.text,
                memberId: this.memberId ? this.memberId : null,
                groupId: this.groupId ? this.groupId : null,
                media: this.media,
                createdDt: this.createdDt || firebase.firestore.Timestamp.fromDate(new Date()),
                totalComments: this.totalComments || 0,
                totalLikes: this.totalLikes || 0,
                createdMemberId: this.createdMemberId
            };

        }
        catch (err) {
            console.log('app.social.ts AppPost data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

interface postFollowingI {
    member: AppMember,
    group: AppGroupI,
    type: number  /* create enum for this */
}

@Injectable({
    providedIn: 'root'
})
export class SocialService {

    private initializedPosts: boolean = false;
    private postDates: postFollowingI[] = [];
    private _followingCache: AppFollowing[] = [];
    private _followingCacheRequest: {
        followingId: string,
        obseverer: Subscriber<AppFollowing>,
        processed: boolean
    }[] = [];

    constructor(
        public appFunction: AppFunction) {

        console.log('app.social.ts constructor');

        //clean up
        this.appFunction
            .shutDown
            .subscribe(() => {

                console.log('app.social.ts SocialService shutdown');

                //clear following cache
                this._followingCache = [];
                this._followingCacheRequest = [];
                this.initializedPosts = false;
                this.postDates = [];

            });

    }

    getFollowing(followingId: string, followingDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Observable<AppFollowing> {

        return new Observable((observer) => {

            //look for cached following
            const index: number = this._followingCache.findIndex((following) => {
                return following.id === followingId;
            });

            //if found then return
            if (index > -1) {

                //console.log('app.social.ts getFollowing from cache', groupId);
                observer.next(this._followingCache[index]);
                observer.complete();

            } else if (followingDoc) {

                //console.log('app.social.ts getFollowing from passed in data', groupId);

                //create...
                const following: AppFollowing = new AppFollowing();

                //...then initialize
                following
                    .initialize(followingDoc)
                    .then((following) => {

                        //save to chache
                        this._followingCache.push(following);

                        //return this request
                        observer.next(following);
                        observer.complete();

                        //return to those waiting/previsously requested
                        this._followingCacheRequest
                            .forEach((request) => {
                                if (request.followingId === following.id && request.processed === false) {

                                    //console.log('app.group.ts getGroup return response', groupId);

                                    //return member and close observable
                                    request.obseverer.next(following);
                                    request.obseverer.complete();

                                    //mark as processed
                                    request.processed = true;

                                }
                            });

                    });

            } else {
                console.log('app.social.ts getFollowing error, following not returned');
            }

        });

    }

    getPosts(member: AppMember): Promise<AppPost[]> {

        return new Promise<AppPost[]>((resolve, reject) => {

            const promiseArray: any[] = [];
            const nextPosts: AppPost[] = [];

            //if first time, only allow once
            if (!this.initializedPosts) {

                //this is commented out to overcome an issue with the new main page router that reloads the top level pages with each navigation
                //this.initializedPosts = true;
                this.postDates = []; //this is also needed to over come this routing issue

                //add requested member (because they're not following themselves)
                this.postDates.push({
                    member: member,
                    group: undefined,
                    type: PostType.member
                });

                //get initial posts for requested member
                const p = member
                    .social
                    .getPosts()
                    .then((posts) => {
                        posts
                            .forEach((post) => {
                                nextPosts.push(post);
                            });
                    });

                promiseArray.push(p);

                //get all groups (being followed by design) (TODO: listen to group changes)
                member
                    .groups
                    .forEach((group) => {

                        this.postDates.push({
                            group: group,
                            member: undefined,
                            type: PostType.group
                        });

                        //get initial post for group
                        const p = group
                            .social
                            .getPosts()
                            .then((posts) => {
                                posts
                                    .forEach((post) => {
                                        nextPosts.push(post);
                                    });
                            });

                        promiseArray.push(p);

                    });

                //get members thast logged in member is following (TODO: not listening to new members though)
                member
                    .social
                    .following
                    .forEach((following) => {

                        this.postDates.push({
                            member: following.followedMember,
                            group: undefined,
                            type: PostType.member
                        });

                        //get initial post for member
                        const p = following
                            .followedMember
                            .social
                            .getPosts()
                            .then((posts) => {
                                posts
                                    .forEach((post) => {
                                        nextPosts.push(post);
                                    });
                            });

                        promiseArray.push(p);

                    });

                Promise
                    .all(promiseArray)
                    .then(() => {
                        resolve(nextPosts);
                    })
                    .catch((err) => {
                        console.log('app.social.ts getPosts onSnapshot error', err);
                        reject(err);
                    });

            } else {

                this.getNextPosts()
                    .then((nextPosts: AppPost[]) => {
                        resolve(nextPosts);
                    })
                    .catch((err) => {
                        reject(err);
                    });

            }

        });

    }

    getNextPosts(): Promise<AppPost[]> {

        return new Promise<AppPost[]>((resolve, reject) => {

            const promiseArray: any[] = [];
            let nextPosts: AppPost[] = [];

            //query all members async with oldest date as a start
            this.postDates
                .forEach((postDate) => {

                    //get member (0) posts
                    if (postDate.type === PostType.member) {

                        //get next set of posts
                        const p = postDate
                            .member
                            .social
                            .getNextPosts()
                            .then((posts) => {
                                posts
                                    .forEach((post) => {
                                        nextPosts.push(post);
                                    });
                            });

                        promiseArray.push(p);

                    } else { //get group (1) posts

                        //get next set of posts
                        const p = postDate
                            .group
                            .social
                            .getNextPosts()
                            .then((posts) => {
                                posts
                                    .forEach((post) => {
                                        nextPosts.push(post);
                                    });
                            });

                        promiseArray.push(p);

                    }

                });

            Promise
                .all(promiseArray)
                .then(() => {
                    resolve(nextPosts);
                })
                .catch((err) => {
                    console.log('app.social.ts getPosts onSnapshot error', err);
                    reject(err);
                });

        });

    }

    getFuturePosts(): Observable<AppPost> {

        return new Observable((observer) => {

            this.postDates
                .forEach((postDate) => {

                    //get member posts
                    if (postDate.type === PostType.member) {

                        //listen for new posts
                        const futurePostUnsubscribe = postDate
                            .member
                            .social
                            .getFuturePosts()
                            .subscribe((post) => {
                                observer.next(post);
                            });

                        this.appFunction.registerUnsubscribe(futurePostUnsubscribe);

                    } else { //get group posts

                        //listen for new posts
                        const futurePostUnsubscribe = postDate
                            .group
                            .social
                            .getFuturePosts()
                            .subscribe((post) => {
                                observer.next(post);
                            });

                        this.appFunction.registerUnsubscribe(futurePostUnsubscribe);

                    }

                });

        });

    }

    deleteUserPosts(batch: firebase.firestore.WriteBatch, createdMemberId: string): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                // First perform the query
                this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Posts)
                    .where('createdMemberId', '==', createdMemberId)
                    .get()
                    .then((querySnapshot) => {

                        querySnapshot
                            .forEach((doc) => {
                                //for each doc, add a delete operation to the batch
                                batch.delete(doc.ref);
                            });

                        //return
                        resolve();

                    })
                    .catch((err) => {
                        console.log('app.social.ts deleteUserPosts error', err);
                        reject(err);
                    });

            } catch (err) {
                console.log('app.social.ts deleteUserPosts error', err);
                reject(err);
            }

        });

    }

    deleteUserPostComments(batch: firebase.firestore.WriteBatch, memberId: string): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //first perform the query
                this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.PostComments)
                    .where('memberId', '==', memberId)
                    .get({ source: 'server' })
                    .then((postCommentsSnapshot) => {

                        //array of promises
                        const promiseArray: any[] = [];

                        //delete each comment
                        postCommentsSnapshot
                            .forEach((commentDoc) => {

                                //delete comment
                                batch.delete(commentDoc.ref);

                                //create inner post so not to return to calling function until totalComments value is updated
                                const p = new Promise<void>((resolve) => {

                                    //get comment post so to decrement comment counter
                                    this.appFunction
                                        .firestore
                                        .collection(AppConfig.COLLECTION.Posts)
                                        .doc(commentDoc.data().postId)
                                        .get({ source: 'server' })
                                        .then((postSnapshot) => {

                                            if (postSnapshot.exists) {

                                                //decrement post like counter
                                                batch.set(postSnapshot.ref, { totalComments: firebase.firestore.FieldValue.increment(-1) }, { merge: true });

                                                //resolve the inner promise
                                                resolve();

                                            } else {

                                                const err: Error = new Error('app.social.ts deleteUserPostComments get post error');
                                                console.log('app.social.ts deleteUserPostComments get post error', err);
                                                reject(err);

                                            }

                                        })
                                        .catch((err) => {
                                            console.log('app.social.ts deleteUserPostComments error', err);
                                            reject(err);
                                        });

                                });

                                promiseArray.push(p);

                            });

                        Promise
                            .all(promiseArray)
                            .then(() => {
                                console.log('app.social.ts deleteUserPostComments resolve');
                                resolve();
                            });

                    })
                    .catch((err) => {
                        console.log('app.social.ts deleteUserPostComments error', err);
                        reject(err);
                    });

            } catch (err) {
                console.log('app.social.ts deleteUserPostComments error', err);
                reject(err);
            }

        });

    }

    deleteUserPostLikes(batch: firebase.firestore.WriteBatch, memberId: string): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                // First perform the query
                this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.PostLikes)
                    .where('memberId', '==', memberId)
                    .get({ source: 'server' })
                    .then((likesSnapshot) => {

                        //array of promises
                        const promiseArray: any[] = [];

                        //delete all likes
                        likesSnapshot
                            .forEach((likeDoc) => {

                                //delete like doc
                                batch.delete(likeDoc.ref);

                                //create inner post so not to return to calling function until totalLikes value is updated
                                const p = new Promise<void>((resolve) => {

                                    //get like post document
                                    this.appFunction
                                        .firestore
                                        .collection(AppConfig.COLLECTION.Posts)
                                        .doc(likeDoc.data().postId)
                                        .get({ source: 'server' })
                                        .then((postSnapshot) => {

                                            if (postSnapshot.exists) {

                                                //decrement post like counter
                                                batch.set(postSnapshot.ref, { totalLikes: firebase.firestore.FieldValue.increment(-1) }, { merge: true });

                                                //resolve the inner promise
                                                resolve();

                                            } else {
                                                const err: Error = new Error('app.social.ts deleteUserPostLikes batch set error');
                                                console.log('app.social.ts deleteUserPostLikes batch set error', err);
                                                reject(err);
                                            }

                                        })
                                        .catch((err) => {
                                            console.log('app.social.ts deleteUserPostLikes get error', err);
                                            reject(err);
                                        });

                                });

                                promiseArray.push(p);

                            });

                        Promise
                            .all(promiseArray)
                            .then(() => {
                                resolve();
                            });

                    })
                    .catch((err) => {
                        console.log('app.social.ts deleteUserPostLikes main get error', err);
                        reject(err);
                    });

            } catch (err) {
                console.log('app.social.ts deleteUserPostLikes error', err);
                reject(err);
            }

        });

    }

    deleteUserFollowing(batch: firebase.firestore.WriteBatch, memberId: string): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //get members that memberId is following
                this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Following)
                    .where('memberId', '==', memberId)
                    .get({ source: 'server' })
                    .then((querySnapshot) => {

                        querySnapshot
                            .forEach((doc) => {
                                //for each doc, add a delete operation to the batch
                                batch.delete(doc.ref);
                            });

                        //return
                        resolve();

                    })
                    .catch((err) => {
                        console.log('app.social.ts deleteUserFollowing get error', err);
                        reject(err);
                    });

            } catch (err) {
                console.log('app.social.ts deleteUserFollowing error', err);
                reject(err);
            }

        });

    }

    deleteUserFollowed(batch: firebase.firestore.WriteBatch, memberId: string): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //get members that memberId is followed by
                this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Following)
                    .where('followedMemberId', '==', memberId)
                    .get({ source: 'server' })
                    .then((querySnapshot) => {

                        querySnapshot
                            .forEach((doc) => {
                                //for each doc, add a delete operation to the batch
                                batch.delete(doc.ref);
                            });

                        //return
                        resolve();

                    })
                    .catch((err) => {
                        console.log('app.social.ts deleteUserFollowed error', err);
                        reject(err);
                    });

            } catch (err) {
                console.log('app.social.ts deleteUserFollowed error', err);
                reject(err);
            }
        });

    }

}